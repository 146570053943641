import React, { useState } from "react";
import { Formik, FormikActions, FormikProps } from "formik";
import Select from "react-select";
import { ValueType } from "react-select/lib/types";
import Translator from '../../../services/translate-factory';
import * as Types from '../../../store/types';
import MultipleCheckbox from "../../checkboxes/multiple-checkbox";
import Button from "../../button";
import cn, { flexIC, flexJB, flexRow } from "../../ui/Tailwind";

export interface IFilterTable {
    campus_code?: string;
    building_code?: string;
    name?: string;
    status?: Array<string>;
    buildings?: Array<Types.ISelectOption>;
    building_ids?: Array<number>;
    campus_ids?: Array<number>;
    query?: string;
    size: number;
    page: number;
    total: number;
    order_by?: string;
    search_id?: number;
    reset?: boolean;
    select_all?: boolean;
    [key: string]: any;
}

interface FilterProps {
    initialFilter: Types.IFilterCampus | Types.IFilterBuilding;
    onFormikSubmit: (model: IFilterTable, FormActions: FormikActions<Types.IFilterCampus>) => void;
    onFormReset: () => void;
    components: Array<FilterComponent>;
    children?: React.ReactNode;
}

export interface FilterComponent {
    type: "input" | "dropdown-single" | "dropdown-multi" | "checkbox";
    title?: string;
    placeholder?: string;
    icon?: any;
    fieldName: string;
    value?: any;
    options?: Array<Types.ISelectOption>; // For dropdowns
}

const T = Translator.create();

export default function Filter({
    onFormikSubmit,
    initialFilter,
    onFormReset,
    components,
    children,
}: Readonly<FilterProps>) {
    const [filterOpen, setFilterOpen] = useState(false);

    const renderComponent = (component: FilterComponent, props: FormikProps<IFilterTable>) => {
        switch (component.type) {
            case "input":
                return (
                    <div className="col-md-4" key={component.fieldName}>
                        <div className="form-input form-group date-picker">
                            <input
                                id={component.fieldName}
                                name={component.fieldName}
                                value={props.values[component.fieldName]}
                                onChange={props.handleChange}
                                type="text"
                                required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor={component.fieldName}>{component.title || T.t('gen_name')}</label>
                            <i className="material-icons">{component.icon || "title"}</i>
                        </div>
                    </div>
                );
            case "dropdown-single":
                return (
                    <div className="col-md-4 form-input" key={component.fieldName}>
                        <div className="react-select-container">
                            <h6>{component.title}</h6>
                            <Select
                                id={component.fieldName}
                                className="react-select"
                                options={component.options || []}
                                placeholder={component.placeholder || T.t('gen_select')}
                                value={props.values[component.fieldName]}
                                onChange={(option: ValueType<Types.ISelectOption>) => {
                                    props.setFieldValue(component.fieldName, option);
                                }}
                                noOptionsMessage={() => T.t('gen_no_records_found')}
                            />
                        </div>
                    </div>
                );
            case "dropdown-multi":
                return (
                    <div className="col-md-4 form-input" key={component.fieldName}>
                        <div className="react-select-container">
                            <h6>{component.title}</h6>
                            <Select
                                id={component.fieldName}
                                className="react-select"
                                isMulti={true}
                                options={component.options || []}
                                placeholder={component.placeholder || T.t('gen_select')}
                                value={props.values[component.fieldName]}
                                onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                    const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                                    props.setFieldValue(component.fieldName, list);
                                    props.setFieldValue(`${component.fieldName}_ids`, list.map(item => item.value));
                                }}
                                noOptionsMessage={() => T.t('gen_no_records_found')}
                            />
                        </div>
                    </div>
                );
            case "checkbox":
                return (
                    <div className="col-md-4" key={component.fieldName}>
                        <div className="text-left">
                            <h6>{component.title}</h6>
                            <div className="mb-3 tick-radio position-relative d-inline-block">
                                <MultipleCheckbox name={component.fieldName} text={component.title || ""} value={component.value} />
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className={cn(flexRow, flexIC, flexJB, "mb-2")}>
                {children}
                <button
                    id='button_filter'
                    className="category-tag-square tag-glass float-right ml-3 mr-3"
                    onClick={() => setFilterOpen(!filterOpen)}
                >
                    <i className="material-icons">filter_list</i>{T.t('gen_filter')}
                </button>
            </div>
            <div className={`white-container collapse ` + (filterOpen ? `show` : ``)} id="advance-search">
                <div className="mt-3 advance-search d-block">
                    <Formik
                        initialValues={initialFilter}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => onFormikSubmit(values, actions)}
                        onReset={onFormReset}
                    >
                        {(props: FormikProps<IFilterTable>) => (
                            <form onSubmit={props.handleSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <h6>{T.t('gen_filter_parameters')}</h6>
                                    </div>
                                    {components.map(component => renderComponent(component, props))}
                                </div>
                                <hr />
                                <div className="mt-3 row">
                                    <div className="col-6">
                                        <Button
                                            id='button_arrow_upword'
                                            type="button"
                                            icon="arrow_upward"
                                            onClick={() => setFilterOpen(false)}
                                            color="light-gray-100"

                                        >
                                        </Button>
                                        <Button
                                            id='button_delete_sweep'
                                            type="reset"
                                            onClick={props.handleReset}
                                            icon="delete_sweep"
                                            className="ml-2"
                                            color="red"
                                        >
                                        </Button>
                                    </div>
                                    <div className="text-right col-6">
                                        <Button
                                            id='button_search'
                                            type="button"
                                            size="xl"
                                            onClick={() => props.handleSubmit()}
                                            disabled={props.isSubmitting}
                                            icon="search"
                                            color="blue"
                                        >
                                            {T.t('gen_search')}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}
