import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { routes as Routes } from '../../store/constants/routes';
import {
  ChangeAlldataModalInitialValues
} from '../../store/constants/classroom-const';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Translator from '../../services/translate-factory';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';

const T = Translator.create();
const L = Log.create('ChangeAllDataModal');

function getInitialState(): Types.ChangeAllDataModalState {
  const initialValues: Types.ChangeAllDataModalState = {
    all_ids: [],
    selected_ids: []
  };
  return Object.assign({}, initialValues);
}

class ChangeAllDataModal extends Component<any, Types.ChangeAllDataModalState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.ChangeAllDataModalState) {
    let hasNewState: boolean = false;
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onChangeAllData(values: any, FormActions: FormikActions<Types.IFilterChangeAllDataModal>) {
    let model;
    const resultCallback = (status: number) => {
      if (status == 200) {
        this.props.dispatch(Actions.Notification('notification_all_data_for_courses_changed', 'gen_success'))
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    };

    if (values.selected_type_ids == 0) {
      model = {
        selected_type: 0,
        term_id: this.props.term_id,
        status: values.status,
        classroom_ids: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 1) {
      model = {
        selected_type: 1,
        term_id: this.props.term_id,
        classroom_type: values.classroom_type.value.toString(),
        classroom_ids: this.props.selected_ids
      }
    }

    this.props.dispatch(
      Actions.ApiRequest(Constants.classroom.CLASSROOM_CHANGE_ALL_DATA, model, 'activity-list-spin', resultCallback)
    );
  }

  render() {
    let listOptions = [
      { label: T.t('gen_status'), value: 0 },
      { label: T.t('gen_type'), value: 1 },
    ]

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_changing_all_data')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={ChangeAlldataModalInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  this.onChangeAllData(values, actions);
                }}
              >
                {(props: FormikProps<Types.IFilterChangeAllDataModal>) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="col-md-12 pb-3">
                            {T.t('gen_change_all_data_description')}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_change_all_data')}</label>
                                <Select
                                  id='select_type'
                                  className="react-select"
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_type_ids',
                                      list.value
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                          {
                            props.values.selected_type_ids === 0 ?
                              <div className="col-md-8">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                  <div className="tick-radio position-relative d-inline-block">
                                    <Checkbox name="status" />
                                  </div>
                                </div>
                              </div> : null
                          }
                          {
                            props.values.selected_type_ids === 1 ?
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_type')}</label>
                                    <Select
                                      id='classroom_type'
                                      className="react-select"
                                      isMulti={false}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.classroom_types_active
                                          ? this.props.selectOptions.classroom_types_active.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))
                                          : []
                                      }
                                      placeholder={T.t('gen_select_type')}
                                      value={props.values.classroom_type ? props.values.classroom_type : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_type', list);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                    />
                                  </div>
                                </div>
                              </div> : null
                          }
                          {
                            props.values.selected_type_ids !== undefined ?
                              <div className="col-6 pt-3 pb-3">
                                <button
                                  id='button_change'
                                  type="button"
                                  className="btn btn-green"
                                  onClick={() => props.handleSubmit()}
                                >
                                  {T.t('gen_change')}
                                </button>
                              </div> : null
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IClassroomPageProps): Types.IClassroomPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IClassroomPageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    selectOptions: store.state.select_options && store.state.select_options.classroomPage,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.classroom_page) {
    return (
      !!equal(
        prev.state.classroom_page && prev.state.classroom_page.results,
        next.state.classroom_page && next.state.classroom_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.classroomPage,
        next.state.select_options && next.state.select_options.classroomPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeAllDataModal);

export default container;
