import * as Types from '../types';
import Translator from '../../services/translate-factory';

export const constants = {
  NOTIFICATION_LIST_SEARCH: 'NOTIFICATION:LIST_SEARCH',
  NOTIFICATION_CREATE: 'NOTIFICATION:CREATE',
  NOTIFICATION_UPDATE: 'NOTIFICATION:UPDATE',
  NOTIFICATION_DELETE: 'NOTIFICATION:DELETE',
  NOTIFICATION_GET_BY_ID: 'NOTIFICATION:GET_BY_ID',
  NOTIFICATION_GET_SIGNED_URL_BY_ID: 'NOTIFICATION:GET_SIGNED_URL_BY_ID',
  NOTIFICATION_GET_SELECT_OPTIONS: 'NOTIFICATION:GET_SELECT_OPTIONS',
};

export const NotificationTypes = (T: Translator) => [
  { label: T.t('gen_file_export'), value: 1 },
  { label: T.t('gen_term_clone'), value: 4 },
  { label: T.t('gen_exam_list'), value: 5 },
  { label: T.t('gen_synchronization'), value: 2 },
  { label: T.t('gen_mail'), value: 3 },
  { label: T.t('gen_backup'), value: 6 },
  { label: T.t('gen_data_processing'), value: 7 }
];

export const NotificationStatuses = (T: Translator) => [
  { label: T.t('gen_ready'), value: 1 },
  { label: T.t('gen_pending'), value: 0 },
  { label: T.t('gen_error_main'), value: -2 },
  { label: T.t('gen_expired'), value: -1 }
];

export const NotificationSearchInitialValues: Types.IFilterNotification = {
  order_by: '',
  term_ids: [],
  types: [],
  status: [],
  select_all: false,
  page: 1,
  query: '',
  total: -1,
  size: 10
};