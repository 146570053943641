import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';

const validation = (T: Translator) => Yup.object().shape({
  user_id: Yup.string(),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')),
  surname: Yup.string().required(T.t('gen_cannot_leave_empty')),
  username: Yup.string().required(T.t('gen_cannot_leave_empty')),
  email: Yup.string()
    .required(T.t('gen_cannot_leave_empty'))
    .email(T.t('gen_invalid_email')),
  expires_at: Yup.date()
    .nullable(true)
    .typeError(T.t('gen_invalid_date')),
  role: Yup.string().required(T.t('gen_cannot_leave_empty')),
  // password: Yup.string()
  // .when('user_id', {
  //   is: (id: string) => !!id,
  //   then: Yup.string()
  //     .notRequired()
  //     .nullable(true),
  //   otherwise: Yup.string()
  //     .nullable(false)
  //     .required(T.t('gen_cannot_leave_empty'))
  // }),
  password_confirmation: Yup.string()
    // .when('user_id', {
    //   is: (id: string) => !!id,
    //   then: Yup.string()
    //     .notRequired()
    //     .nullable(true),
    //   otherwise: Yup.string()
    //     .nullable(false)
    //     .required(T.t('gen_cannot_leave_empty'))
    // })
    .when('password', {
      is: (password: string) => !!password,
      then: Yup.string()
        .nullable(false)
        .required(T.t('gen_cannot_leave_empty'))
        .oneOf([Yup.ref('password'), null], T.t('gen_diffrent_passwords')),
      otherwise: Yup.string().notRequired()
    })
});
export default validation;
