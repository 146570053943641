import React from 'react';
import { Log } from 'ng2-logger';
import imgRingSvg from '../../assets/images/ring.svg';
import { ISpinnerProps } from '../../store/types';
const L = Log.create('Spinner');

let _instance: Spinner;

export default class Spinner extends React.Component<ISpinnerProps, any> {
  _element: HTMLDivElement | undefined | null;

  constructor(props?: any) {
    super(props);
    if (!_instance) {
      _instance = this;
    }
  }

  static getInstance = (): Spinner => {
    if (_instance) {
      return _instance;
    } else {
      return new Spinner();
    }
  };

  show = (name?: string) => {
    if (name) {
      this.updateDisplay(name, 'flex');
    }
  };

  hide = (name?: string) => {
    if (name) {
      this.updateDisplay(name, 'none');
    }
  };

  updateDisplay = (name: string, display: string) => {
    if (name && display) {
      let spinners = document.getElementsByClassName('spinner-container');
      if (spinners && spinners.length > 0) {
        for (let i = 0; i < spinners.length; i++) {
          const element = spinners[i] as HTMLDivElement;
          if (element && element.dataset.name === name) {
            element.style.display = display;
          }
        }
      }
    }
  };

  componentDidMount() {
    if (this._element) {
      this._element.style.display = this._element && this.props.defaultOpen ? 'flex' : 'none';
    }
  }

  render() {
    return (
      <div className="spinner-container" ref={e => (this._element = e)} data-name={this.props.name}>
        <img src={imgRingSvg} />
      </div>
    );
  }
}
