import Types from '../store/types';
import Translator from '../services/translate-factory';
import Turkish from 'flatpickr/dist/l10n/tr.js';
import { useEffect, useState } from 'react';

declare var appSettings: Types.IAppSettings;
const T = Translator.create();

export function GetInitialState(): Types.IAppState {
  return {
    account: {
      account_id: '',
      base_url: '',
      lang: '',
      name: '',
      timezone: '',
      timezone_offset: 0
    },
    course_period_page: {
      activity_relations: []
    },
    user: {
      email: '',
      username: '',
      name: '',
      role: '',
      permissions: [],
      surname: '',
      userId: '',
      applyCalendarRestrictions: false
    },
    auth: null,
    definitions: {
      account_logo: '',
      custom_css: '',
      header_bg_img: '',
      login_bg_img: '',
      login_logo: ''
    },
    status: 'initial',
    pages: {
      user: {}
    },
    evn: 0,
    version: '',
    term_id: -1,
    term_type: -1,
    integration: {
      results: [],
      all_ids: []
    },
    settings: {}
  };
}

export function Route(path: string, prms: string = ''): string {
  return appSettings.BASE_PATH + path + prms;
}

export function SessionLaunch(attendance_code: string): string {
  return appSettings.LIVE_URL.replace(/code/g, attendance_code);
}

export function MakeSelectOptions(list: Array<any>): Array<Types.ISelectOption> {
  let arr: any[] = [];
  list.map((item) => {
    arr.push({ value: item.item2, label: `${item.item1}` });
  });
  return arr;
}

export function TimeFormat(h: number, m: number = 0, s: number = 0): string {
  var time = new Date();
  time.setHours(h, m, s, 0);
  var format = time.toTimeString().substring(0, 8);
  if (format.startsWith('00:')) {
    format = format.substring(3);
  }
  return format;
}

export function Languages(): Array<Types.ISelectOption> {
  return [
    { label: 'gen_lang_tr', value: 'tr-TR' },
    { label: 'gen_lang_en', value: 'en-US' }
  ];
}

export function TimeFormatDesc(h: number, m: number = 0, s: number = 0): string {
  var time = new Date();
  time.setHours(h, m, s, 0);
  var format = time.toTimeString().substring(0, 8);
  var result: Array<string> = [];
  format.split(':').forEach((t: string, i: number) => {
    if (t !== '00') {
      if (i === 0) {
        result.push(parseInt(t) + ' saat');
      } else if (i === 1) {
        result.push(parseInt(t) + ' dk');
      } else {
        result.push(parseInt(t) + ' sn');
      }
    }
  });
  return result.join(', ');
}

export function DateTimeFormat(value: any, getDate = true, getTime = true): string {
  var dateTime = new Date(value);
  var date = dateTime.toISOString().substring(0, 10);
  var time = dateTime.toISOString().substring(11, 16);
  return getDate && getTime ? date + ' ' + time : getDate ? date : getTime ? time : '';
}

export function DateTimeOffsetToDateTime(value: any, noSeconds = false, getDate = true, getTime = true): string {
  if (value) {
    let dt = new Date(value);
    let day = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
    let month = dt.getMonth() + 1 < 10 ? '0' + (dt.getMonth() + 1) : dt.getMonth() + 1;
    let year = dt.getFullYear();
    let hours = dt.getHours() < 10 ? '0' + dt.getHours() : dt.getHours();
    let minutes = dt.getMinutes() < 10 ? '0' + dt.getMinutes() : dt.getMinutes();
    let seconds = dt.getSeconds() < 10 ? '0' + dt.getSeconds() : dt.getSeconds();
    let date = `${day}.${month}.${year}`;
    let time = noSeconds ? `${hours}:${minutes}` : `${hours}:${minutes}:${seconds}`;
    return getDate && getTime ? date + ' ' + time : getDate ? date : getTime ? time : '';
  } else {
    return '';
  }
}

export function DateTimeDiff(date1_string: any, date2_string: any): string {
  let result = '';
  let date1 = new Date(date1_string);
  let date2 = new Date(date2_string);
  let diffMs = date1.valueOf() - date2.valueOf();
  let diffDays = Math.floor(diffMs / 86400000);
  let diffHrs = Math.floor((diffMs % 86400000) / 3600000);
  let diffMins = Math.ceil(((diffMs % 86400000) % 3600000) / 60000);

  result += diffDays > 0 ? (diffHrs > 0 ? diffDays + ' Gün, ' : diffDays + ' Gün') : '';
  result += diffHrs > 0 ? (diffMins > 0 ? diffHrs + ' Saat, ' : diffHrs + ' Saat') : '';
  result += diffMins > 0 ? diffMins + ' Dakika' : '';

  return result === '' ? 'Az' : result;
}

export function GetActiveStatus(status?: number): string {
  switch (status) {
    case 1:
      return T.t('gen_active_upp');
    case 0:
      return T.t('gen_passive_upp');
    case -1:
      return T.t('gen_deleted');
    default:
      return '';
  }
}

export function GetAcademicTermText(type?: number): string {
  switch (type) {
    case 1:
      return T.t('gen_term_fall');
    case 2:
      return T.t('gen_term_spring');
    case 3:
      return T.t('gen_term_summer');
    default:
      return '';
  }
}

export function GetTermTypeText(type?: number): string {
  switch (type) {
    case 0:
      return T.t('gen_course_term');
    case 1:
      return T.t('gen_exam_term');
    default:
      return '';
  }
}

export function GetCourseTermStatus(status?: number): string {
  switch (status) {
    case 0:
      return T.t('gen_active_upp'); //InTerm
    case 1:
      return T.t('gen_active_upp'); //InTerm
    case 2:
      return T.t('gen_cannot_be_added'); //InTermCombined
    case 3:
      return T.t('gen_active_upp'); // NotInTerm
    default:
      return '';
  }
}

export function GetUserRoleText(role?: string): string {
  switch (role) {
    case 'u':
      return T.t('gen_user');
    case 'a':
      return T.t('gen_user_manager');
    case 'e':
      return T.t('gen_user_instructor');
    case 'p':
      return T.t('gen_user_panel');
    default:
      return '';
  }
}

export const GetTermIdFromURL = (window: Window, saveLocation: number | undefined) => {
  /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
  let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
  var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
  let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
  saveLocation = id;
};

export const GetUnknownSelectOption = (text: string = '') => {
  return {
    label: text !== '' ? T.t('gen_unknown') : '-',
    value: 'Unknown'
  };
};

export function fileSize(bytes: any, si: any) {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}

export function DistinctTextArray(value: Array<string>): Array<string> {
  for (let i = value.length; i >= 0; i--) {
    for (let index = i - 1; index >= 0; index--) {
      if (value[i] === undefined || value[i] === '' || value[i] === value[index]) {
        value.splice(i, 1);
      }
    }
  }
  return value;
}

export function DistinctObjectListById(values: Array<any>): Array<any> {
  return values.filter((v, i, a) => a.findIndex((t: any) => t.id === v.id) === i)
}

export function getQueryStringParameter(key: string, url: string = ''): string {
  url = url || window.location.search;
  const search = (new RegExp(key + '=([^&]+)')).exec(url);
  if (search && search.length > 1) {
    return search[1];
  }
  return '';
}

export function getLocaleFromLangCode(): any {
  let lang = localStorage.getItem('langid');
  if (lang) {
    if (lang.indexOf('tr') > -1) {
      return Turkish.tr;
    }
  }
  return Turkish.en;
}

export function getLocaleCode(): any {
  return localStorage.getItem('langid')
}

export function convertNumberToSelectOptionType(number: number): Types.ISelectOption[] {
  let selectOptionType: Types.ISelectOption[] = [];
  for (let index = 1; index < number + 1; index++) {
    selectOptionType.push({ label: index.toString(), value: index.toString() });
  }
  return selectOptionType;
}

export function removeElementsFromArray(arrayElements: Array<number>, removingElements: Array<number>): Array<number> {
  arrayElements = arrayElements.filter(item => !removingElements.includes(item));
  return arrayElements;
}

const allEqual = (arr: any) => arr.every((item: any) => item === arr[0])
export function isAllEqual(arr: Array<any>): boolean {
  return allEqual(arr);
}

export function Timer(createDate: string): string {
  const [partyTime, setPartyTime] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const now1 = new Date();
    const target = new Date(now1.getTime());

    const interval = setInterval(() => {
      const now = new Date();
      const difference = (now.getTime() - target.getTime());

      const d = Math.floor(difference / (1000 * 60 * 60 * 24));
      setDays(d);

      const h = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      setHours(h);

      const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      setMinutes(m);

      const s = Math.floor((difference % (1000 * 60)) / 1000);
      setSeconds(s);

      if (d <= 0 && h <= 0 && m <= 0 && s <= 0) {
        setPartyTime(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    `${hours}: ${minutes}: ${seconds}`
  );
}