import * as React from 'react';
import classNames from "classnames"

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
    _color?: keyof typeof colorList
}

const colorList = {
    "green": "tw-bg-green-200 tw-text-green-900",
    "red": "tw-bg-red-200 tw-text-red-900",
    "blue": "tw-bg-blue-200 tw-text-blue-900",
    "yellow": "tw-bg-yellow-200 tw-text-yellow-900",
    "gray": "tw-bg-gray-200 tw-text-gray-900",
    "white": "tw-bg-white tw-text-gray-900",
    "black": "tw-bg-black tw-text-white",
    "transparent": "tw-bg-transparent tw-text-gray-900",
}


function Badge({ _color = "green", className, ...props }: Readonly<BadgeProps>) {
    return (
        <div className={classNames('tw-px-2 tw-py-0.5 flex tw-items-center tw-justify-center tw-rounded-full tw-w-fit tw-text-xs tw-font-semibold', colorList[_color], className)} {...props} />
    )
}

export { Badge }
