import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, NotificationSearchInitialValues } from '../constants/notification-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';

function* getNotification(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.NotificationSearch(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getNotificationData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.NotificationGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getNotificationSignedUrl(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.NotificationGetSignedUrlById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getNotificationSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.NotificationGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateNotification(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.NotificationUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createNotification(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.NotificationCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status == 200) {
    yield put(Actions.Notification('notification_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteNotification(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.NotificationDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.NOTIFICATION_LIST_SEARCH, NotificationSearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
    yield put(Actions.ApiResponse(action, response, content));
  }
}

export function* watchNotificationSearch() {
  yield takeEvery((action: any) => action.target === constants.NOTIFICATION_LIST_SEARCH, getNotification);
}

export function* watchNotificationGetById() {
  yield takeEvery((action: any) => action.target === constants.NOTIFICATION_GET_BY_ID, getNotificationData);
}

export function* watchNotificationGetSignedUrlById() {
  yield takeEvery((action: any) => action.target === constants.NOTIFICATION_GET_SIGNED_URL_BY_ID, getNotificationSignedUrl);
}

export function* watchNotificationGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.NOTIFICATION_GET_SELECT_OPTIONS, getNotificationSelectOptions);
}

export function* watchNotificationsUpdate() {
  yield takeEvery((action: any) => action.target === constants.NOTIFICATION_UPDATE, updateNotification);
}

export function* watchNotificationCreate() {
  yield takeEvery((action: any) => action.target === constants.NOTIFICATION_CREATE, createNotification);
}

export function* watchNotificationDelete() {
  yield takeEvery((action: any) => action.target === constants.NOTIFICATION_DELETE, deleteNotification);
}