import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { routes as Routes } from '../../store/constants/routes';
import Select from 'react-select';
import { Log } from 'ng2-logger';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { SyncFormInitialValues, Terms } from '../../store/constants/sync-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { SyncTypesStaticTerm, SyncTypesExamTerm, SyncTypesCourseTerm, SyncTypesEventManager } from '../../store/constants/sync-const';
import { SyncResultIds, UserRole } from '../../store/constants/enums';
import Translator from '../../services/translate-factory';
const T = Translator.create();

const L = Log.create('SyncJobModal');

function getInitialState(): Types.ISyncFormState {
  const initialValues: Types.ISyncFormState = {
    model: Object.assign({}, SyncFormInitialValues),
    dataTransferPageIsOpen: false,
    numberOfClick: 1,
    dataTransferButton: false
  };
  return Object.assign({}, initialValues);
}

class SyncJobModal extends Component<any, any> {
  state = getInitialState();

  componentDidMount() {
    this.getSyncSelectOptions();
  }

  courseDataTransferPageIsOpenButton = (sync_type: number = 1) => {
    // Refreshing if on the same page
    window.location.href.indexOf('/sync/campus') > -1 && sync_type == 1 ? window.location.reload() :
      window.location.href.indexOf('/sync/building') > -1 && sync_type == 2 ? window.location.reload() :
        window.location.href.indexOf('/sync/classroom') > -1 && sync_type == 3 ? window.location.reload() :
          window.location.href.indexOf('/sync/faculty') > -1 && sync_type == 4 ? window.location.reload() :
            window.location.href.indexOf('/sync/program') > -1 && sync_type == 5 ? window.location.reload() :
              window.location.href.indexOf('/sync/instructor') > -1 && sync_type == 6 ? window.location.reload() :
                window.location.href.indexOf('/sync/course') > -1 && sync_type == 7 ? window.location.reload() :
                  window.location.href.indexOf('/sync/student') > -1 && sync_type == 8 ? window.location.reload() :
                    (
                      sync_type == 1 ? this.props.dispatch(Actions.Navigation(GT.Route(Routes.SYNC_CAMPUS))) :
                        sync_type == 2 ? this.props.dispatch(Actions.Navigation(GT.Route(Routes.SYNC_BUILDING))) :
                          sync_type == 3 ? this.props.dispatch(Actions.Navigation(GT.Route(Routes.SYNC_CLASSROOM))) :
                            sync_type == 4 ? this.props.dispatch(Actions.Navigation(GT.Route(Routes.SYNC_FACULTY))) :
                              sync_type == 5 ? this.props.dispatch(Actions.Navigation(GT.Route(Routes.SYNC_PROGRAM))) :
                                sync_type == 6 ? this.props.dispatch(Actions.Navigation(GT.Route(Routes.SYNC_INSTRUCTOR))) :
                                  sync_type == 7 ? this.props.dispatch(Actions.Navigation(GT.Route(Routes.SYNC_COURSE))) :
                                    sync_type == 8 ? this.props.dispatch(Actions.Navigation(GT.Route(Routes.SYNC_STUDENT))) :
                                      this.props.dispatch(Actions.Navigation(GT.Route(Routes.DASHBOARD)))
                    )
  };

  // Synchronization For Modal List START (Getting Data From API)

  getSyncSelectOptions = () => {
    this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_MODAL_SELECT_OPTIONS, 'sync-list-spin'));
    if (this.state.model.sync_ids != undefined) this.state.dataTransferPageIsOpen = true;
  }

  getSyncCampusSelectOptions = (campusIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_CAMPUS_SELECT_OPTIONS, campusIds, 'sync-list-spin'));
    if (this.state.model.sync_ids != undefined) this.state.dataTransferPageIsOpen = true;
  }

  getSyncBuildingSelectOptions = (buildingIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_BUILDING_SELECT_OPTIONS, buildingIds, 'sync-list-spin'));
    if (this.state.model.sync_ids != undefined) this.state.dataTransferPageIsOpen = true;
  }

  getSyncProgramSelectOptions = (programIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_PROGRAM_SELECT_OPTIONS, programIds, 'sync-list-spin'));
    if (this.state.model.sync_ids != undefined) this.state.dataTransferPageIsOpen = true;
  }

  getSyncFacultySelectOptions = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_FACULTY_SELECT_OPTIONS, facultyIds, 'sync-list-spin'));
    if (this.state.model.sync_ids != undefined) this.state.dataTransferPageIsOpen = true;
  }

  // Synchronization For Modal List END

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.integration.GET_INTEGRATION, { reset: true }, 'integration-spinner')
      );
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onFormSave = (model: Types.ISyncItem, FormActions: FormikActions<Types.ISyncItem>) => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
    };
    if (this.props.syncId) {
      this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_CREATE, model, 'sync-form-spin', resultCallback));
    } else {
      this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_CREATE, model, 'sync-form-spin', resultCallback));
    }

    FormActions.setSubmitting(false);
  };

  onFormReset = () => {
    this.state.model = Object.assign({}, SyncFormInitialValues);
    this.setState(this.state);
  };

  static getDerivedStateFromProps(props: Types.IModalSelectOptionsPageResults, state: Types.IModalSelectOptionsState) {
    let hasNewState: boolean = false;
    if (props.modalSelectOptions && props.modalSelectOptions != state.model.modalSelectOptions) {
      state.model.modalSelectOptions = props.modalSelectOptions;
      hasNewState = true;
    }

    if (hasNewState) {
      return state;
    } else return null;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.synchronization.sync_job_id && prevProps.synchronization.sync_job_id !== this.props.synchronization.sync_job_id) {
      this.state.model.sync_job_id = this.props.synchronization.sync_job_id;
      var model = {
        sync_job_id: this.props.synchronization.sync_job_id
      }
      this.props.dispatch(Actions.ClearStatus());
      this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_GET_SYNC_JOB_STATUS, model, 'sync-form-spin'));
      this.state.dataTransferButton = true;
    }
    if (this.props.synchronization.result_id && (prevProps.synchronization.result_id !== this.props.synchronization.result_id) && (this.props.synchronization.result_id == SyncResultIds.None || this.props.synchronization.result_id == SyncResultIds.FetchStarted)) {
      this.props.dispatch(Actions.ClearStatus());
      var model = {
        sync_job_id: this.props.synchronization.sync_job_id
      }
      this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_GET_SYNC_JOB_STATUS, model, 'sync-form-spin'));
    }
    if (this.props.synchronization.result_id && prevProps.synchronization.result_id !== this.props.synchronization.result_id && this.props.synchronization.result_id == SyncResultIds.Fetched) {
      this.props.dispatch(Actions.Notification(T.t('gen_data_transfered'), T.t('gen_success')))
      this.state.dataTransferButton = true;
    }
    if (this.props.synchronization.result_id && prevProps.synchronization.result_id !== this.props.synchronization.result_id && this.props.synchronization.result_id == SyncResultIds.FetchFailed) {
      this.props.dispatch(Actions.Notification(T.t('gen_error_please_try_again'), T.t('gen_warning'), 'danger'))
    }
  }

  handleSubmit(sync_type: any, lastId?: any, year?: number, term?: number) {
    var model = {
      sync_type: sync_type,
      job_parameters: lastId,
      year: year,
      term: term,
    }
    var numberOfClick = this.state.numberOfClick
    this.props.dispatch(Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_MODAL_SEND_SYNCTYPE, model, 'sync-form-spin'));
    this.props.dispatch(Actions.Notification(T.t('gen_sync_job_added'), T.t('gen_success')));
    this.props.dispatch(Actions.Notification(T.t('gen_trying_to_transfer_data') + '(' + numberOfClick + '.' + T.t('gen_attempt') + ')', T.t('gen_please_wait'), 'warning'));
    this.state.numberOfClick = this.state.numberOfClick + 1;

    sync_type == 1 ? this.props.synchronization.synctype_campus_clicked = true :
      sync_type == 2 ? this.props.synchronization.synctype_building_clicked = true :
        sync_type == 3 ? this.props.synchronization.synctype_classroom_clicked = true :
          sync_type == 4 ? this.props.synchronization.synctype_faculty_clicked = true :
            sync_type == 5 ? this.props.synchronization.synctype_program_clicked = true :
              sync_type == 6 ? this.props.synchronization.synctype_instructor_clicked = true :
                sync_type == 7 ? this.props.synchronization.synctype_course_clicked = true :
                  sync_type == 8 ? this.props.synchronization.synctype_student_clicked = true :
                    this.props.synchronization.synctype_campus_clicked = true
  }

  render() {
    let currentTime = new Date();
    let currentYear = currentTime.getFullYear();

    const Years = [
      { label: String(currentYear) + ' / ' + String(currentYear + 1), value: currentYear },
      { label: String(currentYear - 1) + ' / ' + String(currentYear), value: currentYear - 1 },
      { label: String(currentYear - 2) + ' / ' + String(currentYear - 1), value: currentYear - 2 }
    ];

    const campusOptions = this.props.modalSelectOptions && this.props.modalSelectOptions.campuses ? this.props.modalSelectOptions.campuses : []
    const CampusSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...campusOptions];
    const buildingOptions = this.props.modalSelectOptions && this.props.modalSelectOptions.buildings ? this.props.modalSelectOptions.buildings : []
    const BuildingSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...buildingOptions];
    const facultyOptions = this.props.modalSelectOptions && this.props.modalSelectOptions.faculties ? this.props.modalSelectOptions.faculties : []
    const FacultySelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...facultyOptions];
    const programOptions = this.props.modalSelectOptions && this.props.modalSelectOptions.programs ? this.props.modalSelectOptions.programs : []
    const ProgramSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...programOptions];
    const courseOptions = this.props.modalSelectOptions && this.props.modalSelectOptions.courses ? this.props.modalSelectOptions.courses : []
    const CourseSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...courseOptions];

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_define_service_parameters')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={this.state.model}
                enableReinitialize={true}
                onSubmit={(values, Actions) => {
                  /*this.onFormSave(values, Actions);*/
                }}
                onReset={this.onFormReset}
              >
                {(props: FormikProps<Types.ISyncItem>) => {

                  const { values, handleChange, errors, handleBlur, handleSubmit } = props;
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-6">
                          {values.sync_type != undefined &&
                            (
                              values.sync_type == 6 ? (values.term != undefined && values.year != undefined) :
                                values.sync_type == 7 ? (values.term != undefined && values.year != undefined) :
                                  values.sync_type == 8 ? (values.term != undefined && values.year != undefined) :
                                    values.sync_type != undefined
                            ) && !this.state.dataTransferButton && this.state.numberOfClick <= 4
                            /*&& (
                              values.sync_type == 1 ? !this.props.synchronization.synctype_campus_clicked :
                                values.sync_type == 2 ? !this.props.synchronization.synctype_building_clicked :
                                  values.sync_type == 3 ? !this.props.synchronization.synctype_classroom_clicked :
                                    values.sync_type == 4 ? !this.props.synchronization.synctype_faculty_clicked :
                                      values.sync_type == 5 ? !this.props.synchronization.synctype_program_clicked :
                                        values.sync_type == 6 ? !this.props.synchronization.synctype_instructor_clicked :
                                          values.sync_type == 7 ? !this.props.synchronization.synctype_course_clicked :
                                            values.sync_type == 8 ? !this.props.synchronization.synctype_student_clicked :
                                              undefined
                            )*/
                            ? (
                              <React.Fragment>
                                <button
                                  className="category-tag-square tag-green float-right pr-2  pl-2"
                                  style={{ margin: '5px' }}
                                  onClick={() => {
                                    this.handleSubmit(values.sync_type,
                                      values.sync_type == 2 ? values.campus_ids :
                                        values.sync_type == 3 ? values.building_ids :
                                          values.sync_type == 5 ? values.faculty_ids :
                                            values.sync_type == 7 ? values.program_ids :
                                              values.sync_type == 8 ? values.course_ids : [],
                                      values.year, values.term
                                    )
                                  }}
                                  onChange={() => {
                                    this.state.dataTransferPageIsOpen = !this.state.dataTransferPageIsOpen
                                  }}
                                >
                                  <i className="material-icons mr-2">playlist_add</i>
                                  <span>{T.t('gen_start_sync')}</span>
                                </button>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <button
                                  className="category-tag-square tag-gray float-right pr-2  pl-2"
                                  style={{ margin: '5px', cursor: 'not-allowed' }}
                                  onClick={() => props.handleSubmit()}
                                  disabled={true}
                                  onChange={() => {
                                    this.state.dataTransferPageIsOpen = !this.state.dataTransferPageIsOpen
                                  }}
                                >
                                  <i className="material-icons mr-2">playlist_add</i>
                                  <span>{T.t('gen_start_sync')}</span>
                                </button>
                              </React.Fragment>
                            )}
                        </div>
                        <div className="col-6">
                          <div className="quick-actions">
                            {
                              this.state.dataTransferButton ? (
                                <React.Fragment>
                                  <button
                                    className="category-tag-square tag-green float-left pr-2 pl-2"
                                    style={{ margin: "5px" }}
                                    onClick={() => this.courseDataTransferPageIsOpenButton(values.sync_type)}
                                  >
                                    <i className="material-icons mr-2">input</i>
                                    <span>{T.t('gen_go_data_export_page')}</span>
                                  </button>
                                </React.Fragment>
                              ) : (
                                  <React.Fragment>
                                    <button
                                      className="category-tag-square tag-gray float-left pr-2 pl-2"
                                      style={{ margin: '5px', cursor: 'not-allowed' }}
                                      disabled={true}
                                    >
                                      <i className="material-icons mr-2">input</i>
                                      <span>{T.t('gen_go_data_export_page')}</span>
                                    </button>
                                  </React.Fragment>
                                )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4"></div>
                        <div className="col-4">
                          <div className="col-md-12 mt-2">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_sync_type')}</label>
                                <Select
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={this.props.user && this.props.user.role == UserRole.EVENT_MANAGER ? SyncTypesEventManager(T) :
                                    (this.props.term_type === 1 ? SyncTypesExamTerm(T) : (this.props.term_type === 0 ? SyncTypesCourseTerm(T) : SyncTypesStaticTerm(T)))
                                  }
                                  placeholder={T.t('gen_select_sync_type')}
                                  value={values.sync_type != undefined ?
                                    (this.props.term_type === 1 ?
                                      SyncTypesExamTerm(T).find(option => option.value === values.sync_type) :
                                      (this.props.term_type === 0 ? SyncTypesCourseTerm(T).find(option => option.value === values.sync_type) :
                                        SyncTypesStaticTerm(T).find(option => option.value === values.sync_type)))
                                    : null}
                                  onChange={(option: any) => {
                                    props.setFieldValue('sync_type', option.value)
                                    props.setFieldValue('campuses', [])
                                    props.setFieldValue('buildings', [])
                                    props.setFieldValue('programs', [])
                                    props.setFieldValue('faculties', [])
                                    props.setFieldValue('courses', [])

                                    props.setFieldValue('campus_ids', undefined)
                                    props.setFieldValue('building_ids', undefined)
                                    props.setFieldValue('program_ids', undefined)
                                    props.setFieldValue('faculty_ids', undefined)
                                    props.setFieldValue('course_ids', undefined)

                                    this.state.dataTransferButton = false
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="advance-search d-block mt-3" style={{}}>
                              <div className="col-md-12 mt-2">
                                <div className="add-custom-tag mb-3">
                                  <div
                                    className={`white-container mt-4 collapse ` + (values.sync_type == 2 ? `show` : ``)}
                                    id="advance-search"
                                  >
                                    <div className="react-select-container">
                                      <label>{T.t('gen_campus')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={CampusSelectOptions}
                                        placeholder={T.t('gen_select_campus')}
                                        value={props.values.campuses}
                                        onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                          const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                                          props.setFieldValue('campuses',
                                            list.map(item => item.value).includes(-1) ? campusOptions : list
                                          );
                                          props.setFieldValue(
                                            'campus_ids',
                                            list.map(item => item.value).includes(-1) ? campusOptions.map((item: any) => item.value) : list.map(item => item.value)
                                          );
                                          list.map(item => item.value).includes(-1) ? this.getSyncCampusSelectOptions(campusOptions.map((item: any) => item.value)) : this.getSyncCampusSelectOptions(list.map(item => item.value));
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                      />
                                    </div>
                                    {errors && errors.campus_ids && props.submitCount > 0 && <div className="error">{T.t('gen_cannot_leave_empty')}</div>}
                                  </div>
                                  <div
                                    className={`white-container mt-4 collapse ` + (values.sync_type == 3 ? `show` : ``)}
                                    id="advance-search"
                                  >
                                    <div className="react-select-container">
                                      <label>{T.t('gen_campus')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={CampusSelectOptions}
                                        placeholder={T.t('gen_select_campus')}
                                        value={props.values.campuses}
                                        onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                          const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                                          props.setFieldValue('campuses',
                                            list.map(item => item.value).includes(-1) ? campusOptions : list
                                          );
                                          props.setFieldValue(
                                            'campus_ids',
                                            list.map(item => item.value).includes(-1) ? campusOptions.map((item: any) => item.value) : list.map(item => item.value)
                                          );
                                          list.map(item => item.value).includes(-1) ? this.getSyncCampusSelectOptions(campusOptions.map((item: any) => item.value)) : this.getSyncCampusSelectOptions(list.map(item => item.value));
                                        }
                                        }
                                        noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                      />
                                    </div>
                                    <div className="react-select-container">
                                      <label>{T.t('gen_building')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={
                                          props.values.campus_ids !== undefined ?
                                            (BuildingSelectOptions) :
                                            undefined
                                        }
                                        placeholder={T.t('gen_select_building')}
                                        value={props.values.buildings}
                                        onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                          const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                                          props.setFieldValue('buildings',
                                            list.map(item => item.value).includes(-1) ? buildingOptions : list
                                          );
                                          props.setFieldValue(
                                            'building_ids',
                                            list.map(item => item.value).includes(-1) ? buildingOptions.map((item: any) => item.value) : list.map(item => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_building')}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={`white-container mt-4 collapse ` + (values.sync_type == 5 ? `show` : ``)}
                                    id="advance-search"
                                  >
                                    <div className="react-select-container">
                                      <label>{T.t('gen_faculty_college_institute')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={FacultySelectOptions}
                                        placeholder={T.t('gen_select_faculty_college_institute')}
                                        value={props.values.faculties}
                                        onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                          const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                                          props.setFieldValue('faculties',
                                            list.map(item => item.value).includes(-1) ? facultyOptions : list
                                          );
                                          props.setFieldValue(
                                            'faculty_ids',
                                            list.map(item => item.value).includes(-1) ? facultyOptions.map((item: any) => item.value) : list.map(item => item.value)
                                          );
                                          list.map(item => item.value).includes(-1) ? this.getSyncFacultySelectOptions(facultyOptions.map((item: any) => item.value)) : this.getSyncFacultySelectOptions(list.map(item => item.value));
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={`white-container mt-4 collapse ` + (values.sync_type == 6 ? `show` : ``)}
                                    id="advance-search"
                                  >
                                    <div className="react-select-container">
                                      <label>{T.t('gen_year')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={false}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={Years}
                                        placeholder={T.t('gen_select_year')}
                                        value={Years.find((year: Types.ISelectOption) => values.year === year.value)}
                                        onChange={(option: any) => {
                                          props.setFieldValue('year', option.value)
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_year')}
                                      />
                                    </div>
                                    <div className="react-select-container">
                                      <label>{T.t('gen_term')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={false}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={Terms(T)}
                                        placeholder={T.t('gen_select_term')}
                                        value={Terms(T).find((term: Types.ISelectOption) => values.term === term.value)}
                                        onChange={(option: any) => props.setFieldValue('term', option.value)}
                                        noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={`white-container mt-4 collapse ` + (values.sync_type == 7 ? `show` : ``)}
                                    id="advance-search"
                                  >
                                    <div className="react-select-container">
                                      <label>{T.t('gen_year')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={false}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={Years}
                                        placeholder={T.t('gen_select_year')}
                                        value={Years.find((year: Types.ISelectOption) => values.year === year.value)}
                                        onChange={(option: any) => {
                                          props.setFieldValue('year', option.value)
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_year')}
                                      />
                                    </div>
                                    <div className="react-select-container">
                                      <label>{T.t('gen_term')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={false}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={Terms(T)}
                                        placeholder={T.t('gen_select_term')}
                                        value={Terms(T).find((term: Types.ISelectOption) => values.term === term.value)}
                                        onChange={(option: any) => props.setFieldValue('term', option.value)}
                                        noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                      />
                                    </div>
                                    <div className="react-select-container">
                                      <label>{T.t('gen_faculty_college_institute')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={FacultySelectOptions}
                                        placeholder={T.t('gen_select_faculty_college_institute')}
                                        value={props.values.faculties}
                                        onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                          const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                                          props.setFieldValue('faculties',
                                            list.map(item => item.value).includes(-1) ? facultyOptions : list
                                          );
                                          props.setFieldValue(
                                            'faculty_ids',
                                            list.map(item => item.value).includes(-1) ? facultyOptions.map((item: any) => item.value) : list.map(item => item.value)
                                          );
                                          list.map(item => item.value).includes(-1) ? this.getSyncFacultySelectOptions(facultyOptions.map((item: any) => item.value)) : this.getSyncFacultySelectOptions(list.map(item => item.value));
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                      />
                                    </div>
                                    <div className="react-select-container">
                                      <label>{T.t('gen_program_department')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={
                                          props.values.faculty_ids !== undefined ?
                                            (ProgramSelectOptions)
                                            : undefined
                                        }
                                        placeholder={T.t('gen_select_program_department')}
                                        value={props.values.programs}
                                        onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                          const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                                          props.setFieldValue('programs',
                                            list.map(item => item.value).includes(-1) ? programOptions : list
                                          );
                                          props.setFieldValue(
                                            'program_ids',
                                            list.map(item => item.value).includes(-1) ? programOptions.map((item: any) => item.value) : list.map(item => item.value)
                                          );
                                          list.map(item => item.value).includes(-1) ? this.getSyncProgramSelectOptions(programOptions.map((item: any) => item.value)) : this.getSyncProgramSelectOptions(list.map(item => item.value));
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                      />
                                    </div>
                                  </div>
                                  {this.props.term_type === 1 ? (
                                    <div
                                      className={`white-container mt-4 collapse ` + (values.sync_type == 8 ? `show` : ``)}
                                      id="advance-search"
                                    >
                                      <div className="react-select-container">
                                        <label>{T.t('gen_year')}</label>
                                        <Select
                                          className="react-select"
                                          isMulti={false}
                                          filterOption={(option: any, query: any) =>
                                            option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                          }
                                          closeMenuOnSelect={true}
                                          options={Years}
                                          placeholder={T.t('gen_select_year')}
                                          value={Years.find((year: Types.ISelectOption) => values.year === year.value)}
                                          onChange={(option: any) => {
                                            props.setFieldValue('year', option.value)
                                          }}
                                          noOptionsMessage={(): string => T.t('gen_select_no_year')}
                                        />
                                      </div>
                                      <div className="react-select-container">
                                        <label>{T.t('gen_term')}</label>
                                        <Select
                                          className="react-select"
                                          isMulti={false}
                                          filterOption={(option: any, query: any) =>
                                            option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                          }
                                          closeMenuOnSelect={true}
                                          options={Terms(T)}
                                          placeholder={T.t('gen_select_term')}
                                          value={Terms(T).find((term: Types.ISelectOption) => values.term === term.value)}
                                          onChange={(option: any) => props.setFieldValue('term', option.value)}
                                          noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                        />
                                      </div>
                                      <div className="react-select-container">
                                        <label>{T.t('gen_faculty_college_institute')}</label>
                                        <Select
                                          className="react-select"
                                          isMulti={true}
                                          filterOption={(option: any, query: any) =>
                                            option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                          }
                                          closeMenuOnSelect={true}
                                          options={FacultySelectOptions}
                                          placeholder={T.t('gen_select_faculty_college_institute')}
                                          value={props.values.faculties}
                                          onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                            const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                                            props.setFieldValue('faculties',
                                              list.map(item => item.value).includes(-1) ? facultyOptions : list
                                            );
                                            props.setFieldValue(
                                              'faculty_ids',
                                              list.map(item => item.value).includes(-1) ? facultyOptions.map((item: any) => item.value) : list.map(item => item.value)
                                            );
                                            list.map(item => item.value).includes(-1) ? this.getSyncFacultySelectOptions(facultyOptions.map((item: any) => item.value)) : this.getSyncFacultySelectOptions(list.map(item => item.value));
                                          }}
                                          noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                        />
                                      </div>
                                      <div className="react-select-container">
                                        <label>{T.t('gen_program_department')}</label>
                                        <Select
                                          className="react-select"
                                          isMulti={true}
                                          filterOption={(option: any, query: any) =>
                                            option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                          }
                                          closeMenuOnSelect={true}
                                          options={
                                            props.values.faculty_ids !== undefined ?
                                              (ProgramSelectOptions)
                                              : undefined
                                          }
                                          placeholder={T.t('gen_select_program_department')}
                                          value={props.values.programs}
                                          onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                            const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                                            props.setFieldValue('programs',
                                              list.map(item => item.value).includes(-1) ? programOptions : list
                                            );
                                            props.setFieldValue(
                                              'program_ids',
                                              list.map(item => item.value).includes(-1) ? programOptions.map((item: any) => item.value) : list.map(item => item.value)
                                            );
                                            list.map(item => item.value).includes(-1) ? this.getSyncProgramSelectOptions(programOptions.map((item: any) => item.value)) : this.getSyncProgramSelectOptions(list.map(item => item.value));
                                          }}
                                          noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                        />
                                      </div>
                                      {this.props.term_type === 1 ? (
                                        <div className="react-select-container">
                                          <label>{T.t('gen_course')}</label>
                                          <Select
                                            className="react-select"
                                            isMulti={true}
                                            filterOption={(option: any, query: any) =>
                                              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            closeMenuOnSelect={true}
                                            options={
                                              props.values.program_ids !== undefined ?
                                                (CourseSelectOptions)
                                                : undefined
                                            }
                                            placeholder={T.t('gen_select_course')}
                                            value={props.values.courses}
                                            onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                              const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                                              props.setFieldValue('courses',
                                                list.map(item => item.value).includes(-1) ? courseOptions : list
                                              );
                                              props.setFieldValue(
                                                'course_ids',
                                                list.map(item => item.value).includes(-1) ? courseOptions.map((item: any) => item.value) : list.map(item => item.value)
                                              );
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_select_no_course')}
                                          />
                                        </div>
                                      ) : null}
                                    </div>) : null}
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className="col-4"></div>
                      </div>

                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    // selectOptions: store.state.select_options && store.state.select_options.syncPage,
    // results: store.state.integration && store.state.integration.results,
    filters: store.state.integration && store.state.integration.filters,
    all_ids: store.state.integration && store.state.integration.all_ids,
    term_id: store.state.term_id,
    term_list: store.state.term_list,
    sync_type: store.state.synchronization && store.state.synchronization.sync_type,
    modalSelectOptions: store.state.synchronization && store.state.synchronization,
    synchronization: store.state.synchronization,
    term_type: store.state.term_type,
    user: store.state.user
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  /* if (next.state.integration) {
    return (
      !!equal(
        prev.state.integration && prev.state.integration.results,
        next.state.integration && next.state.integration.results
      ) &&
      !!equal(
        prev.state.integration && prev.state.integration.filters,
        next.state.integration && next.state.integration.filters
      ) &&
      !!equal(
        prev.state.integration && prev.state.integration.all_ids,
        next.state.integration && next.state.integration.all_ids
      )
    );
  } else {
    return true;
  } */
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SyncJobModal);

export default container;
