import React from "react";
import SimplePage from "../../../components/templates/simple-page";
import MainLayout from "../../layouts/main-layout";
import APlanHeader from '../../../components/templates/aplan-header';
import Sketches from "..";

export default function SketchesWithHeader() {
    return (
        <MainLayout header={<APlanHeader />}>
            <SimplePage name="sketch-page">
                <Sketches />
            </SimplePage>
        </MainLayout>
    )
}