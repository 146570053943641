import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './index.scss';
import './output.css'
import App from './pages/app';

import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import storeParameter from './store';

import 'bootstrap/dist/css/bootstrap.css';
// import './assets/scss/sqi-style.scss';
import './assets/scss/style.scss';
import './assets_aplan/css/style.css';
import './assets_aplan/css/custom.css';
import './assets_aplan/css/bootstrap-sortable.css';
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

const storeParam = storeParameter();
ReactDOM.render(
  <Provider store={storeParam.store}>
    <PersistGate loading={<div>Yükleniyor...</div>} persistor={storeParam.persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
