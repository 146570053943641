import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';

export const CombineActivityFormValidation = (T: Translator) => Yup.object().shape({
  course_code: Yup.string().required(T.t('gen_cannot_leave_empty')),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')),
  lesson_count: Yup.number().required(T.t('gen_cannot_leave_empty')).moreThan(0, T.t('gen_must_be_greater_than_zero')),
  student_count: Yup.number().required(T.t('gen_cannot_leave_empty')).moreThan(0, T.t('gen_must_be_greater_than_zero')),
  education_type: Yup.string().required(T.t('gen_cannot_leave_empty')),
  lecture_location_id: Yup.number().required(T.t('gen_cannot_leave_empty')),
  week: Yup.string()
    .required(T.t('gen_cannot_leave_empty'))
    .min(1),
  activity_type: Yup.string().required(T.t('gen_cannot_leave_empty')),
  instructor_ids: Yup.array().of(Yup.number()).required(T.t('gen_cannot_leave_empty')),
  description: Yup.string().max(1000, T.t('gen_can_be_up_to_n').replace('{0}', '1000'))
});