import { IAction } from '../types';
import AppFactory from '../../services/app-factory';
const AF = AppFactory.create();

const ActionEmiterMiddleware = () => (next: any) => (action: IAction) => {
  let result = next(action);
  AF.emitting(action);
  return result;
};

export default ActionEmiterMiddleware;
