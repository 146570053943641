import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import Translator from '../../services/translate-factory';
import { ISelectOption } from '../../store/types';
import Spinner from '../templates/spinner';
import cn from '../ui/Tailwind';

interface SelectFieldProps extends React.ComponentProps<typeof CreatableSelect> {
    id: string;
    label?: string;
    options: ISelectOption[] | undefined;
    onChange: (options: any) => void;
    placeholder: string;
    error?: string;
    submitCount?: number;
    T: Translator;
    spinner?: string,
    marginBottom?: boolean,
    isCreatable?: boolean,
    showError?: boolean
}

const SelectField: React.FC<SelectFieldProps> = ({
    id,
    label,
    options,
    onChange,
    placeholder,
    error,
    showError,
    spinner,
    submitCount,
    marginBottom = true,
    T,
    isCreatable,
    ...otherSelectBaseProps
}) => {
    const [currentOptions, setCurrentOptions] = useState<ISelectOption[] | undefined>(options);

    useEffect(() => {
        setCurrentOptions(options)
    }, [options])

    const handleCreateOption = (inputValue: string) => {
        const newOption: ISelectOption = { label: inputValue, value: inputValue };
        const updatedOptions: ISelectOption[] = [...(currentOptions || []), newOption];
        setCurrentOptions(updatedOptions);
        onChange(newOption);
    };

    const SelectComponent = isCreatable
        ? (CreatableSelect as React.ComponentType<React.ComponentProps<typeof CreatableSelect>>)
        : (Select as React.ComponentType<React.ComponentProps<typeof Select>>);

    return (
        <>
            {spinner && <Spinner name={spinner} />}
            <div className={cn(
                "add-custom-tag",
                { "mb-3": marginBottom }
            )}>
                <div className={cn({ "react-select-container": marginBottom })}>
                    {label && <label>{label}</label>}
                    <SelectComponent
                        id={id}
                        className="react-select"
                        formatCreateLabel={(inputValue: string) => T.t('gen_create') + ` "${inputValue}"`}
                        filterOption={(option, query) =>
                            option.label
                                .toLocaleLowerCase(T.getSelectedLanguage())
                                .includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                        }
                        options={currentOptions}
                        value={otherSelectBaseProps.value}
                        placeholder={placeholder}
                        onChange={onChange}
                        onCreateOption={isCreatable ? handleCreateOption : undefined}
                        noOptionsMessage={() => T.t('gen_no_options_available')}
                        {...otherSelectBaseProps}
                    />
                </div>
                {showError && error && (
                    <div className="error">{error}</div>
                )}
            </div>
        </>
    );
};

export default SelectField;
