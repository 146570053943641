import React, { ChangeEvent, useEffect, useReducer, useCallback } from 'react';
import Button from '../../../components/button';
import Input from '../../../components/Input';
import Translator from '../../../services/translate-factory';
import { DefinitionPieceData, Item } from '../defitinions-settings';
import * as Constants from '../../../store/constants/all';
import { debounce } from 'lodash';
import { searchLocalizedLabel } from '../../../store/constants/classroom-const';

type SetDefinitionData = React.Dispatch<React.SetStateAction<DefinitionPieceData>>

interface DefinitionPieceProps {
    values: { [key: string]: any };
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    definitionPiece: DefinitionPieceData;
    setDefinitionPiece: SetDefinitionData;
    onAddItem: (item: string, definitionPiece: DefinitionPieceData, setDefinitionPiece: SetDefinitionData, localizedLabels?: (T: Translator) => {
        label: any;
        value: number | string;
    }[]) => void;
    deleteItem: (index: number | string, item: Item, definitionPiece: DefinitionPieceData, setDefinitionPiece: SetDefinitionData) => void;
    itemLabel: string;
    enterItemLabel: string;
    localizedLabel?: (T: Translator) => {
        label: any;
        value: number | string;
    }[],
    setModelSettings: (newStates: any) => void,
    aditionalError?: string,
}

const DefinitionPiece = ({
    values,
    handleChange,
    definitionPiece,
    setDefinitionPiece,
    onAddItem,
    deleteItem,
    itemLabel,
    enterItemLabel,
    localizedLabel,
    aditionalError,
}: DefinitionPieceProps) => {
    const T = new Translator();

    const [, forceRender] = useReducer(x => x + 1, 0);
    const handleLanguageChange = useCallback(
        debounce(() => {
            forceRender(1);
        }, 1000),
        []
    );
    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        };
    }, []);

    const undeletableString = localizedLabel && localizedLabel(T).map(_ => _.value)

    return (
        <div className="col-md-6">
            <h6>{itemLabel}</h6>
            <div className="row align-items-center">
                <div className="col-md-10">
                    <div className="form-input form-group date-picker">
                        <input
                            id={definitionPiece.itemType}
                            name={definitionPiece.itemType}
                            value={values[definitionPiece.itemType]}
                            onChange={handleChange}
                            type="text"
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor={definitionPiece.itemType}>{enterItemLabel}</label>
                        <i className="material-icons">playlist_add</i>
                    </div>
                    <div className="col-md-12 list-error">
                        {definitionPiece.isError ? (
                            <>
                                <span>{T.t('gen_entered_value_already_exists_list')}</span>
                                {aditionalError && <span>&nbsp;{aditionalError}</span>}
                            </>
                        ) : null}
                    </div>
                </div>
                <div className="col-md-2">
                    <button
                        id="button_add_circle_outline"
                        className="mss-table-add-button"
                        disabled={!values[definitionPiece.itemType]}
                        onClick={() => onAddItem(values[definitionPiece.itemType], definitionPiece, setDefinitionPiece, localizedLabel)}
                    >
                        <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                    </button>
                </div>
                <div className="col-md-11">
                    <div className="table-scrollable-td">
                        <ul className="tw-space-y-2">
                            {definitionPiece.items.filter(item => !item.isDeleted).map((item, index) => {
                                let localizedName = ""
                                if (localizedLabel) {
                                    const translated = searchLocalizedLabel(Number(item.id), localizedLabel, T)
                                    localizedName = translated === "-" ? item.name : translated
                                } else {
                                    localizedName = item.name
                                }
                                if (values[definitionPiece.itemType] && !localizedName.toLowerCase().includes(values[definitionPiece.itemType].toLowerCase())) {
                                    return null
                                }

                                return (
                                    <li key={localizedName} className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                                        <div className='form-input' style={{ width: '85%' }}>
                                            <Input
                                                id={`${definitionPiece.itemType}_${item.id}`}
                                                name={`${definitionPiece.itemType}-${item.id}`}
                                                value={localizedName}
                                                disabled={true}
                                                type="text"
                                                required
                                            />
                                        </div>
                                        {!(undeletableString && undeletableString.includes(Number(item.id))) ? (
                                            <div className="form-input" style={{ width: '10%' }}>
                                                <Button
                                                    id="button_delete_outline"
                                                    className="ms-table-delete-button tw-p-1"
                                                    icon='delete'
                                                    size='disable'
                                                    onClick={() => deleteItem(index, item, definitionPiece, setDefinitionPiece,)}
                                                />
                                            </div>
                                        ) : null}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DefinitionPiece;



    // burada setModelSettings'in icerisine girilen field'in (classroom_types, etc.) adinin dogru yazilmasi onemli
    // parentta bulunan degistirilmek istenen state ile ayni isimde olmasi gerekiyor.