import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, ActivitySearchInitialValues } from '../constants/activity-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';
import Translator from '../../services/translate-factory';

const T = Translator.create();

function* getActivities(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivitySearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content || null));
}

function* getActivityChangeAllData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivityChangeAllData(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getActivityData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivityGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateActivity(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivityUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_updated', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createActivity(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivityCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_created', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteActivity(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivityDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.ACTIVITY_LIST_SEARCH, ActivitySearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else if (response && response.status === 406) {
    yield put(Actions.Notification('notification_courses_exists_in_solutions', 'gen_error', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* isRelatedActivity(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivityIsRelated(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status === 200) {
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getActivitySelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ActivityGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createActivityExcelBulk(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ActivityCreateExcelBulk(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      content = yield MockAPI.GetContent(response);
    } else if (response && response.status === 406) {
      yield put(Actions.Notification('notification_courses_exists_in_solutions', 'gen_error', 'danger'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* activityCreateBreak(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivityCreateBreakCourses());
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_break_created', 'gen_success'));
    yield put(Actions.ApiRequest(constants.ACTIVITY_LIST_SEARCH, ActivitySearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getActivityGetProgramsByFaculties(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ActivityGetProgramsByFaculties(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* dublicationActivity(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivityDublication(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* dublicationActivityBySection(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivityDublicationBySection(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateCourseSections(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ActivityCourseSectionsUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status == 200) {
    yield put(Actions.Notification('notification_process_started', 'gen_success'));
  } else if (response && response.status === 409) {
    yield put(
      Actions.ShowModal({
        body: content,
        title: T.t('gen_pending'),
        name: 'gen_pending',
        icon: 'warning',
        iconColor: 'red',
        cancel: T.t('gen_close')
      })
    );
  } else if (response && response.status !== 208) {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchActivitySearch() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_LIST_SEARCH, getActivities);
}

export function* watchActivityChangeAllData() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_CHANGE_ALL_DATA, getActivityChangeAllData);
}

export function* watchCreateActivityExcelBulk() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_CREATE_EXCEL_BULK, createActivityExcelBulk);
}

export function* watchActivityGetById() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_GET_BY_ID, getActivityData);
}

export function* watchActivityIsRelated() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_IS_RELATED, isRelatedActivity);
}

export function* watchActivityGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_GET_SELECT_OPTIONS, getActivitySelectOptions);
}

export function* watchActivityUpdate() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_UPDATE, updateActivity);
}

export function* watchActivityCreate() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_CREATE, createActivity);
}

export function* watchActivityDelete() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_DELETE, deleteActivity);
}

export function* watchActivityCreateBreakCourses() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_CREATE_BREAK_COURSES, activityCreateBreak);
}

export function* watchActivityGetProgramsByFaculties() {
  yield takeEvery(
    (action: any) => action.target === constants.ACTIVITY_GET_PROGRAMS_BY_FACULTIES,
    getActivityGetProgramsByFaculties
  );
}

export function* watchActivityDublication() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_DUBLICATION, dublicationActivity);
}

export function* watchActivityDublicationBySection() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_DUBLICATION_BY_SECTION, dublicationActivityBySection);
}

export function* watchActivityCourseSectionsUpdate() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_COURSE_SECTIONS_UPDATE, updateCourseSections);
}