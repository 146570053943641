import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  CLASSROOM_LIST_SEARCH: 'CLASSROOM:LIST_SEARCH',
  CLASSROOM_CREATE: 'CLASSROOM:CREATE',
  CLASSROOM_UPDATE: 'CLASSROOM:UPDATE',
  CLASSROOM_DELETE: 'CLASSROOM:DELETE',
  CLASSROOM_GET_BY_ID: 'CLASSROOM:GET_BY_ID',
  CLASSROOM_GET_SELECT_OPTIONS: 'CLASSROOM:GET_SELECT_OPTIONS',
  CLASSROOM_GET_BUILDINGS_BY_CAMPUSES: 'CLASSROOM:GET_BUILDINGS_BY_CAMPUSES',
  CLASSROOM_CREATE_EXCEL_BULK: 'CLASSROOM:CREATE_EXCEL_BULK',
  CLASSROOM_IMPORT_LIST_SEARCH: 'CLASSROOM:IMPORT_LIST_SEARCH',
  CLASSROOM_IMPORT_GET_SELECT_OPTIONS: 'CLASSROOM:IMPORT_GET_SELECT_OPTIONS',
  CLASSROOM_IMPORT_GET_BUILDINGS_BY_CAMPUSES: 'CLASSROOM:GET_BUILDINGS_BY_CAMPUSES',
  CLASSROOM_IMPORT_CREATE: 'CLASSROOM:IMPORT_CREATE',
  CLASSROOM_CHANGE_ALL_DATA: 'CLASSROOM:CHANGE_ALL_DATA',
};

export const ClassroomSearchInitialValues: Types.IFilterClassroom = {
  classroom_code: '',
  name: '',
  status: [],
  buildings: [],
  classroom_features: [],
  classroom_types: [],
  classroom_ids: [],
  course_ids: [],
  term_id: undefined,
  select_all: false,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  selected_area_radio: 'office',
  selected_volume_radio: 'office',
};

export const ClassroomHoursSearchInitialValues: Types.IFilterClassroom = {
  classroom_code: '',
  name: '',
  status: [1],
  buildings: [],
  classroom_types: [],
  classroom_ids: [],
  course_ids: [],
  term_id: undefined,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  select_all: false
};

export const ClassroomFormInitialValues: Types.IClassroomItem = {
  classroom_id: undefined,
  classroom_code: '',
  status: 1,
  name: '',
  description: '',
  building_name: '',
  campus_name: '',
  door_order: undefined,
  exam_capacity: undefined,
  lecture_capacity: undefined,
  invigilator_count: undefined,
  classroom_type: '',
  features: []
};

export const ChangeAlldataModalInitialValues: Types.IFilterChangeAllDataModal = {
  status: 0
};

export const ClassroomFeatureOptions = (T: Translator) => [
  { label: T.t('gen_smartboard'), value: 'AT' },
  { label: T.t('gen_computer'), value: 'Bil' },
  { label: T.t('gen_dvd'), value: 'DVD' },
  { label: T.t('gen_internet_access'), value: 'Int' },
  { label: T.t('gen_air_conditioner'), value: 'Klima' },
  { label: T.t('gen_projector'), value: 'Proj' },
  { label: T.t('gen_fixed_curtain'), value: 'SbtPer' },
  { label: T.t('gen_sound_system'), value: 'SesSist' },
  { label: T.t('gen_video'), value: 'Vid' }
];

export const ClassroomCategoryOptions = (T: Translator) => [
  { label: T.t('gen_accommodation'), value: 1 },
  { label: T.t('gen_auxiliary'), value: 2 },
  { label: T.t('gen_circulation'), value: 3 },
  { label: T.t('gen_conference_hall'), value: 4 },
  { label: T.t('gen_gastronomy'), value: 5 },
  { label: T.t('gen_general_sanitary'), value: 6 },
  { label: T.t('gen_laboratory'), value: 7 },
  { label: T.t('gen_lecture_hall'), value: 8 },
  { label: T.t('gen_office_space'), value: 9 },
  { label: T.t('gen_other'), value: 10 },
  { label: T.t('gen_residential'), value: 11 },
  { label: T.t('gen_sanitary'), value: 12 },
  { label: T.t('gen_shared_facilities'), value: 13 },
  { label: T.t('gen_social_infrastructure'), value: 14 },
  { label: T.t('gen_special_infrastructure'), value: 15 },
  { label: T.t('gen_sports'), value: 16 },
  { label: T.t('gen_technical_infrastructure'), value: 17 }
];

export const ClassroomSeatingArrangementOptions = (T: Translator) => [
  { label: T.t('gen_fixed_seating'), value: 1 },
  { label: T.t('gen_armchair_seating'), value: 2 },
  { label: T.t('gen_sequential_seating'), value: 3 },
  { label: T.t('gen_round_type'), value: 4 },
  { label: T.t('gen_oval_type'), value: 5 },
  { label: T.t('gen_u_type'), value: 6 },
  { label: T.t('gen_l_type'), value: 7 },
  { label: T.t('gen_cluster_type'), value: 8 },
  { label: T.t('gen_amphitheater_type'), value: 9 }
];

export const ClassroomSeatingTypeOptions = (T: Translator) => [
  { label: T.t('gen_fixed_desk'), value: 1 },
  { label: T.t('gen_chair'), value: 2 },
  { label: T.t('gen_movable_desk'), value: 3 },
  { label: T.t('gen_table'), value: 4 }
];

export const ClassroomUserTypeOptions = (T: Translator) => [
  { label: T.t('gen_academic_staff'), value: 1 },
  { label: T.t('gen_admin_staff'), value: 2 },
  { label: T.t('gen_other'), value: 3 },
  { label: T.t('gen_public'), value: 4 },
  { label: T.t('gen_staff'), value: 5 },
  { label: T.t('gen_students'), value: 6 },
  { label: T.t('gen_teaching_activitity'), value: 7 },
  { label: T.t('gen_technician'), value: 8 },
  { label: T.t('gen_third_party'), value: 9 }
];

export const ClassroomSpecialUsingStateOptions = (T: Translator) => [
  { label: T.t('gen_exhibition_area'), value: 1 },
  { label: T.t("gen_female"), value: 2 },
  { label: T.t("gen_male"), value: 3 },
  { label: T.t("gen_unisex"), value: 4 },
  { label: T.t("gen_cleaning_staff"), value: 5 },
  { label: T.t("gen_mailboxes"), value: 6 },
  { label: T.t("gen_outdoor_terrace"), value: 7 },
  { label: T.t("gen_recycling"), value: 8 },
  { label: T.t("gen_waste_disposal"), value: 9 },
  { label: T.t("gen_server_room"), value: 10 },
  { label: T.t("gen_rehearsal_room"), value: 11 }
]


export const ClassroomStatusOptions = (T: Translator) => [
  { label: T.t('gen_in_use'), value: 1 },
  { label: T.t('gen_potentially_unused'), value: 2 },
  { label: T.t('gen_incomplete'), value: 3 },
  { label: T.t('gen_not_specified'), value: 4 },
  { label: T.t('gen_unused'), value: 5 }
]

export const ClassroomAttributeOptions = (T: Translator) => [
  { label: T.t('gen_large'), value: 1 },
  { label: T.t('gen_public'), value: 2 },
  { label: T.t('gen_small'), value: 3 },
  { label: T.t('gen_medium'), value: 4 },
  { label: T.t('gen_private'), value: 5 }
];

export const ClassroomUsageStateOptions = (T: Translator) => [
  { label: T.t('gen_in_use'), value: 1 },
  { label: T.t('gen_potentially_unused'), value: 2 },
  { label: T.t('gen_incomplete'), value: 3 },
  { label: T.t('gen_not_specified'), value: 4 },
  { label: T.t('gen_unused'), value: 5 }
];

export const ClassroomInventoryOptions = (T: Translator) => [
  { label: T.t('gen_table'), value: 1 },
  { label: T.t('gen_lectern'), value: 2 },
  { label: T.t('gen_blackboard'), value: 3 },
  { label: T.t('gen_projection'), value: 4 },
  { label: T.t('gen_air_conditioner'), value: 5 },
  { label: T.t('gen_door'), value: 6 },
  { label: T.t('gen_window'), value: 7 },
  { label: T.t('gen_camera'), value: 8 },
];

export const searchLocalizedLabel = (
  value: number,
  from: (T: Translator) => {
    label: any;
    value: number | string;
  }[],
  T: Translator): string => {
  const obj = from(T).find(_ => _.value === value);

  return obj ? obj.label : '-';
}

export const ImportingKeySelectOptions = (T: Translator) => [
  { label: T.t('gen_building_code_and_classroom_code'), value: 'BUILDINGCODE_CLASSROOMCODE' },
  { label: T.t('gen_do_not_check'), value: 'NONE' }
];