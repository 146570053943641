import React from 'react';
import { MdAttachFile } from 'react-icons/md';
import cn, { flexCenter, flexCol, flexIC, flexRow, gap2 } from '../../../ui/Tailwind';

interface UploadButtonProps {
    onClick: () => void;
    uploadAreaText: string;
}

const UploadButton: React.FC<UploadButtonProps> = ({ onClick, uploadAreaText }) => {
    return (
        <button type='button' onClick={onClick} className={cn(
            flexCol,
            gap2,
            flexCenter,
            'tw-w-full tw-py-4 tw-border tw-duration-150 tw-rounded-lg',
        )}>
            <div className={cn(flexRow, flexIC)}>
                <MdAttachFile />
                <span>{uploadAreaText}</span>
            </div>
        </button>
    );
};

export default UploadButton;
