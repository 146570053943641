import React from 'react';
import { FormikProps } from "formik"
import { FaRegCalendar, FaRegClock } from "react-icons/fa"
import InputField from "../../components/Input/default-input"
import SelectField from "../../components/Input/select-field"
import cn, { flexCol, flexIC, flexRow, gap2 } from "../../components/ui/Tailwind"
import { IAppSelectOptions, IExtendedEvent, IPersistedState, ISelectOption } from "../../store/types"
import Translator from "../../services/translate-factory"
import { RBCEventTypes } from "./constants"
import Flatpickr from 'react-flatpickr';
import * as GT from '../../tools/general-tools';
import moment from "moment"
import { connect } from 'react-redux';
import Warning from '../../components/warning';
import { Log } from 'ng2-logger';

const L = Log.create("EventDetail.tsx")
interface EventDetailProps {
    formikProps: FormikProps<IExtendedEvent>
    selectOptions?: any;
    dispatch?: any
}

const EventDetailIn: React.FC<EventDetailProps> = ({ formikProps: props, selectOptions, dispatch }) => {
    const T = new Translator()
    const locale = GT.getLocaleFromLangCode()
    const isDone = props.values.start_date && props.values.end_date && props.values.title && props.values.event_type


    const eventTypeOptions = [
        ...(selectOptions && selectOptions.additional_event_types ? selectOptions.additional_event_types : []),
        ...RBCEventTypes(T),
    ].sort((a, b) => a.label.localeCompare(b.label))

    const userOptions = [
        ...(selectOptions && selectOptions.users ? selectOptions.users : [])
    ].sort((a, b) => a.label.localeCompare(b.label))

    return <div className={cn(flexCol, gap2)}>
        <Warning show={!isDone} classNames='col-md-12 tw-mb-4'>
            {T.t("gen_warning_event_date_not_selected")}
        </Warning>
        <div className='row tw-max-w-screen-md tw-mb-12'>
            <div className="col-md-6">
                <InputField
                    id="title"
                    value={props.values.title}
                    label={T.t("gen_event_name") + " *"}
                    onChange={props.handleChange('title')}
                    required
                    error={props.errors.title}
                    showError={props.submitCount > 0}
                />
            </div>
            <div className="col-md-6">
                <SelectField
                    id="event_type"
                    spinner="event-type-options"
                    isCreatable
                    isClearable
                    label={T.t("gen_event_type") + " *"}
                    options={eventTypeOptions}
                    value={[props.values.event_type]}
                    onChange={props.handleChange('event_type')}
                    placeholder={T.t('gen_select_or_type_event_type')}
                    error={props.errors.event_type}
                    showError={props.submitCount > 0}
                    T={T}
                />
            </div>
            <div className="col-md-6">
                <InputField
                    id="description"
                    label={T.t("gen_description")}
                    value={props.values.description}
                    onChange={props.handleChange('description')}
                    type="textarea"
                    required
                />
            </div>
            <div className="col-md-6">
                <SelectField
                    id="responsibles"
                    spinner="responsibles-options"
                    isClearable
                    isMulti={true}
                    label={T.t("gen_responsibles")}
                    options={userOptions}
                    value={props.values.responsibles}
                    onChange={props.handleChange('responsibles')}
                    placeholder={T.t('gen_choose_responsible')}
                    T={T} />
            </div>
            <div className={cn("col-md-12 tw-flex tw-flex-row tw-gap-4 tw-justify-center")}>
                <div className="tw-w-full form-input form-group with-icon">
                    <div className="tw-relative add-custom-tag mt-3">
                        <Flatpickr
                            id='start_date'
                            value={props.values.start_date}
                            name="start_date"
                            placeholder={T.t('gen_select_date_and_time')}
                            options={{
                                dateFormat: "d.m.Y H:i",
                                locale: locale,
                                enableTime: true,
                                maxDate: props.values.end_date,
                            }}
                            onChange={(value) => {
                                props.setFieldValue('start_date', moment(value[0], "DD.MM.YYYY HH:mm").toISOString())
                            }}
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label className="tw-absolute tw-left-0 -tw-top-2" htmlFor="start">{T.t('gen_start_date_and_time') + " *"}</label>
                        <div className={cn(flexRow, flexIC, gap2, "tw-absolute tw-bottom-2 tw-right-1 tw-transform -tw-translate-y-1/2 tw-pointer-events-none tw-text-gray-400")}>
                            <FaRegCalendar />
                            <FaRegClock />
                        </div>
                    </div>
                    {props.errors && props.errors.start_date && props.submitCount > 0 && (
                        <div className="error mt-3">{T.t('gen_cannot_leave_empty')}</div>
                    )}
                </div>
                <div className="tw-w-full form-input form-group with-icon">
                    <div className="tw-relative add-custom-tag mt-3">
                        <Flatpickr
                            id='end_date'
                            value={props.values.end_date}
                            name="end_date"
                            placeholder={T.t('gen_select_date_and_time')}
                            options={{
                                dateFormat: "d.m.Y H:i",
                                locale: locale,
                                enableTime: true,
                                minDate: props.values.start_date,
                            }}
                            onChange={(value) => {
                                props.setFieldValue('end_date', moment(value[0], "DD.MM.YYYY HH:mm").toISOString())
                            }}
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label className="tw-absolute tw-left-0 -tw-top-2" htmlFor="end">{T.t('gen_end_date_and_time') + " *"}</label>
                        <div className={cn(flexRow, flexIC, gap2, "tw-absolute tw-bottom-2 tw-right-1 tw-transform -tw-translate-y-1/2 tw-pointer-events-none tw-text-gray-400")}>
                            <FaRegCalendar />
                            <FaRegClock />
                        </div>
                    </div>
                    {props.errors && props.errors.end_date && props.submitCount > 0 && (
                        <div className="error mt-3">{T.t('gen_cannot_leave_empty')}</div>
                    )}
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (store: IPersistedState, ownProps: EventDetailProps) => ({
    ...ownProps,
    selectOptions: store.state.select_options && store.state.select_options.solutionEventPage,
});

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const EventDetail = connect(mapStateToProps, dispatchProps)(EventDetailIn);


export default EventDetail