import * as Yup from 'yup';
const validation = Yup.object().shape({
  currentPassword: Yup.string().required('Boş bırakılamaz!'),
  newPassword: Yup.string().required('Boş bırakılamaz!'),
  newPasswordConfirm: Yup.string()
    .notRequired()
    .when('newPassword', {
      is: val => val !== undefined,
      then: Yup.string()
        .required('Boş bırakılamaz!')
        .oneOf([Yup.ref('newPassword'), null], 'Şifreler birbirinden farklı!'),
      otherwise: Yup.string().notRequired()
    })
});
export default validation;
