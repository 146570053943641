import moment from "moment"
import { DateLocalizer, momentLocalizer } from "react-big-calendar"
import Translator from "../services/translate-factory"

export const getMomentLocale = (locale: string) => {
    return locale.split('-')[0]
}


export const getLocalizedDateLocalizer = (T: Translator): DateLocalizer => {

    moment.locale(getMomentLocale(T.getSelectedLanguage()), {
        week: {
            dow: 1,//
            doy: 4,// First day of year is Monday
        }
    })

    return momentLocalizer(moment)
}