import React, { useEffect, useState } from 'react';
import { FormikProps } from "formik";
import Button from "../../components/button";
import InputField from "../../components/Input/default-input";
import RangeInputField from "../../components/Input/range-input";
import SelectField from "../../components/Input/select-field";
import { Log } from 'ng2-logger';
import cn, { flexCenter, flexRow } from "../../components/ui/Tailwind";
import { IExtendedEvent, IPersistedState, ISelectOption } from "../../store/types";
import Translator from "../../services/translate-factory";
import { RBCEventType } from "./constants";
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import AvailablePlaceTable from "./add-place-table";
import { connect } from 'react-redux';
import Warning from '../../components/warning';
import { ClassroomFeatureOptions, ClassroomSeatingArrangementOptions, ClassroomSeatingTypeOptions } from '../../store/constants/classroom-const';
import { ClassroomTypes } from '../../store/constants/setting-const';

const L = Log.create('eventCalendarAddPlace');

interface AvailablePlaceProps {
    formikProps: FormikProps<IExtendedEvent>
    dispatch?: any,
    selectOptions?: any;
}

const AvailablePlaceIn: React.FC<AvailablePlaceProps> = ({ formikProps: props, dispatch, selectOptions }) => {
    const T = new Translator();

    const [selectOptionsState, setSelectOptionsState] = useState<{
        campusOptions?: ISelectOption[],
        buildingOptions?: ISelectOption[],
        featureOptions?: ISelectOption[],
        sittingTypeOptions?: ISelectOption[],
        typeFunctionOptions?: ISelectOption[],
        seatingArrangementOptions?: ISelectOption[]
    }>({
        campusOptions: [],
        buildingOptions: [],
        featureOptions: [],
        sittingTypeOptions: [],
        typeFunctionOptions: [],
        seatingArrangementOptions: []
    });

    useEffect(() => {
        if (props.values.campuses) {
            search();
        }
        dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_GET_SELECT_OPTIONS, {}, 'add-place-select-options', loadSelectOptions));
    }, [])

    const loadSelectOptions = () => {
        setSelectOptionsState({
            campusOptions: selectOptions && selectOptions.campuses ? selectOptions.campuses : [],
            buildingOptions: selectOptions && selectOptions.buildings_related_campus ? selectOptions.buildings_related_campus : [],
            featureOptions: [
                ...ClassroomFeatureOptions(T),
                ...(selectOptions && selectOptions.eventRelation && selectOptions.eventRelation.features || []),
            ].sort((a, b) => a.label.localeCompare(b.label)),
            sittingTypeOptions: [
                ...ClassroomSeatingTypeOptions(T),
                ...(selectOptions && selectOptions.eventRelation && selectOptions.eventRelation.sitting_types || []),
            ].sort((a, b) => a.label.localeCompare(b.label)),
            typeFunctionOptions: [
                ...ClassroomTypes(T),
                ...(selectOptions && selectOptions.eventRelation && selectOptions.eventRelation.type_functions || []),
            ].sort((a, b) => a.label.localeCompare(b.label)),
            seatingArrangementOptions: [
                ...ClassroomSeatingArrangementOptions(T),
                ...(selectOptions && selectOptions.eventRelation && selectOptions.eventRelation.seating_arrangement || []),
            ].sort((a, b) => a.label.localeCompare(b.label))
        })
    }

    useEffect(() => {
        setSelectOptionsState(prev => ({
            ...prev,
            buildingOptions: selectOptions.buildings_related_campus || []
        }))
    }, [selectOptions.buildings_related_campus])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, minKey: keyof IExtendedEvent, maxKey: keyof IExtendedEvent) => {
        const { name, value } = e.target;
        if (name.includes('min')) {
            props.setFieldValue(minKey, value);
        } else {
            props.setFieldValue(maxKey, value);
        }
    };

    const handleSliderChange = (values: number[], minKey: keyof IExtendedEvent, maxKey: keyof IExtendedEvent) => {
        props.setFieldValue(minKey, values[0]);
        props.setFieldValue(maxKey, values[1]);
    };

    const isValid = (option: any) => {
        return option && Array.isArray(option) && option.length !== 0;
    }

    const isDisable = (values: IExtendedEvent, key: keyof IExtendedEvent): boolean => {
        const value = values[key];

        if (Array.isArray(value)) {
            return value.length <= 0;  // eger array ise, bos olup olmadigini kontrol eder
        }
        return !(value !== null && value !== undefined && value !== '');
    };

    const resetFilter = () => {
        props.resetForm(
            {
                ...props.values,
                campuses: undefined,
                buildings: undefined,
                classroom_features: [],
                classroom_floor: undefined,
                classroom_types: [],
                classroom_seating_types: [],
                classroom_seating_arrangements: [],
                lecture_capacity_min: undefined,
                lecture_capacity_max: undefined,
                exam_capacity_min: undefined,
                exam_capacity_max: undefined,
                isSearched: false
            }
        )
    }

    const search = () => {
        props.setFieldValue('isSearched', true);
        dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_SEARCH_AVAILABLE_CLASSROOMS, props.values, 'available-place-table'));
    }

    useEffect(() => {
        //loads selectOptions.campusRelation.relatedBuildings and selectOptions.campusRelation.relatedFaculities
        if (props.values.campuses) {
            dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_GET_SELECT_OPTIONS, { campus_ids: [props.values.campuses.value] }, 'select-options-related-with-campus-ids', loadSelectOptions));
        }
    }, [props.values.campuses])

    const isCampusSelected = props.values.campuses
    const isCourseOrOther = props.values.event_type && props.values.event_type.value !== RBCEventType.EXAM
    const isExam = props.values.event_type && props.values.event_type.value === RBCEventType.EXAM
    const isCourse = props.values.event_type && props.values.event_type.value === RBCEventType.COURSE

    return <div className="row tw-justify-center">
        <div className='col-md-12 tw-mb-4'>
            <Warning show={!isCampusSelected}>
                {T.t('gen_warning_event_select_campus_for_search')}
            </Warning>
        </div>
        <div className="col-md-3">
            <SelectField
                key={JSON.stringify(props.values.campuses)}
                spinner="add-place-select-options"
                id="campuses"
                label={T.t("gen_campus") + " *"}
                options={selectOptionsState.campusOptions}
                value={props.values.campuses}
                onChange={(option) => {
                    props.setFieldValue('campuses', option);
                    props.setFieldValue('buildings', undefined);
                }}
                placeholder={T.t('gen_select_campus') + " *"}
                T={T}
            />
        </div>
        <div className="col-md-3">
            <SelectField
                key={JSON.stringify(props.values.campuses)}
                spinner="select-options-related-with-campus-ids"
                id="building"
                label={T.t("gen_building")}
                options={selectOptionsState.buildingOptions}
                isDisabled={isDisable(props.values, 'campuses')}
                value={props.values.buildings}
                onChange={(option) => {
                    props.setFieldValue('buildings', option);
                }}
                placeholder={T.t('gen_select_building')}
                T={T}
            />
        </div>
        <div className="col-md-3">
            <SelectField
                key={JSON.stringify(props.values.campuses)}
                spinner="add-place-select-options"
                id="feature"
                isMulti
                closeMenuOnSelect={false}
                label={T.t("gen_feature")}
                options={selectOptionsState.featureOptions}
                value={props.values.classroom_features}
                onChange={props.handleChange('classroom_features')}
                placeholder={T.t('gen_select_feature')}
                T={T}
            />
        </div>
        <div className="col-md-3">
            <InputField
                id="classroom_floor"
                type="number"
                min={0}
                label={T.t("gen_floor")}
                value={props.values.classroom_floor}
                onChange={props.handleChange('classroom_floor')}
                required
            />
        </div>
        <div className="col-md-4">
            <SelectField
                key={JSON.stringify(props.values.campuses)}
                spinner="add-place-select-options"
                id="=type_function"
                isMulti
                closeMenuOnSelect={false}
                label={T.t("gen_type_function")}
                options={selectOptionsState.typeFunctionOptions}
                value={props.values.classroom_types}
                onChange={props.handleChange('classroom_types')}
                placeholder={T.t('gen_select_type')}
                T={T}
            />
        </div>
        <div className="col-md-4">
            <SelectField
                key={JSON.stringify(props.values.campuses)}
                spinner="add-place-select-options"
                id="=sitting_type"
                isMulti
                closeMenuOnSelect={false}
                label={T.t("gen_sitting_type")}
                options={selectOptionsState.sittingTypeOptions}
                value={props.values.classroom_seating_types}
                onChange={props.handleChange('classroom_seating_types')}
                placeholder={T.t('gen_select_sitting_type')}
                T={T}
            />
        </div>
        <div className="col-md-4">
            <SelectField
                key={JSON.stringify(props.values.campuses)}
                spinner="add-place-select-options"
                id="=seating_arrangement"
                isMulti
                closeMenuOnSelect={false}
                label={T.t("gen_seating_arrangement")}
                options={selectOptionsState.seatingArrangementOptions}
                value={props.values.classroom_seating_arrangements}
                onChange={props.handleChange('classroom_seating_arrangements')}
                placeholder={T.t('gen_select_seating_arrangement')}
                T={T}
            />
        </div>
        <div className={cn(flexRow, "col-md-12", flexCenter, 'row')} key={JSON.stringify(props.values.campuses)}>
            {
                isCourseOrOther &&
                <div className='col-md-4'>
                    <RangeInputField
                        id="lecture_capacity"
                        minValue={props.values.lecture_capacity_min}
                        maxValue={props.values.lecture_capacity_max}
                        onChange={(e) => handleChange(e, 'lecture_capacity_min', 'lecture_capacity_max')}
                        label={isCourse ? T.t('gen_lecture_capacity') : T.t("gen_capacity")}
                        setSlider={(values) => handleSliderChange(values, 'lecture_capacity_min', 'lecture_capacity_max')}
                        T={T}
                    />
                </div>
            }
            {
                isExam &&
                <div className='col-md-4'>
                    <RangeInputField
                        id="exam_capacity"
                        minValue={props.values.exam_capacity_min}
                        maxValue={props.values.exam_capacity_max}
                        onChange={(e) => handleChange(e, 'exam_capacity_min', 'exam_capacity_max')}
                        setSlider={(values) => handleSliderChange(values, 'exam_capacity_min', 'exam_capacity_max')}
                        label={T.t('gen_exam_capacity')}
                        T={T}
                    />
                </div>
            }
        </div>
        <div className="col-md-12 tw-flex">
            <div className="tw-ml-auto tw-flex tw-flex-row tw-gap-2">
                <Button size='md' color="red" onClick={resetFilter}>{T.t("reset")}</Button>
                <Button size='md' disabled={!props.values.campuses} color="blue" icon="search" onClick={search}>{T.t('gen_search')}</Button>
            </div>
        </div>
        <div className="col-md-12">
            <AvailablePlaceTable formikProps={props} />
        </div>
    </div>
}

const mapStateToProps = (store: IPersistedState, ownProps: AvailablePlaceProps) => ({
    ...ownProps,
    selectOptions: store.state.select_options && store.state.select_options.solutionEventPage,
});

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const AvailablePlace = connect(mapStateToProps, dispatchProps)(AvailablePlaceIn);

export default AvailablePlace