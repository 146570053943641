import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';

export const BuildingFormValidation = (T: Translator) => Yup.object().shape({
  building_code: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  campus_id: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  description: Yup.string().max(1000, T.t('gen_can_be_up_to_n').replace('{0}', '1000')),
  floor_count: Yup.number().required(T.t('gen_cannot_leave_empty')).min(0, T.t('gen_must_be_positive')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  // BuildingFloor array tipi
  floors: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      floor: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
      name: Yup.string().max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')).required(T.t('gen_cannot_leave_empty')).min(0, T.t('gen_cannot_leave_empty')),
      building_name: Yup.string().max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
      deleted: Yup.boolean(),

      // BuildingRoom array tipi
      rooms: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string()
            .required(T.t('gen_cannot_leave_empty'))
            .max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
          orderLine: Yup.number().required().max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
          marker: Yup.object().shape({
            top: Yup.number().required(),
            left: Yup.number().required(),
          }).nullable(),

          // Image array tipi
          photos: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required(),
              src: Yup.string().required(),
              name: Yup.string().max(200),
              alt: Yup.string().max(200),
              timestamp: Yup.number(),
              deleted: Yup.boolean(),
            })
          )
        })
      ),

      // Image array tipi
      sketches: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          src: Yup.string().required(),
          name: Yup.string().max(200),
          alt: Yup.string().max(200),
          timestamp: Yup.number(),
          deleted: Yup.boolean(),
        })
      )
    })
  ),
});
