import { constants } from '../constants/building-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const buildingReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.BUILDING_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          building_page: {
            ...state.building_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters
          }
        };
      }
      return state;
    }
    case constants.BUILDING_GET_BY_ID: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          building_page: {
            ...state.building_page,
            form: result.content.data
          }
        });
      }
      return state;
    }
    case constants.BUILDING_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            buildingPage: result.content
          }
        });
      }
      return state;
    }
    case constants.BUILDING_GET_DISTANCES: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          building_page: {
            ...state.building_page,
            buildingDistanceData: result.content.distanceData ? result.content.distanceData : {}
          }
        });
      }
      return state;
    }
    case constants.BUILDING_CREATE_EXCEL_BULK: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          building_import_result: result.content
        });
      }
      return state;
    }
    case constants.BUILDING_IMPORT_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          building_import_form: {
            ...state.building_import_form,
            results: result.content ? result.content.data : [],
            filters: result.content.filters
          }
        };
      }
      return state;
    }
    case constants.BUILDING_IMPORT_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            buildingImportForm: result.content
          }
        });
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default buildingReducer;
