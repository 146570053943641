import React, { createContext, useContext, useState, useEffect } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import * as Types from '../../store/types';
import { connect } from 'react-redux';
declare const appSettings: Types.IAppSettings;

const SSEContext = createContext<EventSourcePolyfill | null>(null);

export const useSSE = () => {
    return useContext(SSEContext);
};

export const SSEProviderIn: React.FC<Types.IPageProps> = ({ children, user, term_id }) => {
    const [eventSource, setEventSource] = useState<EventSourcePolyfill | null>(null);

    useEffect(() => {
        const startEventSource = () => {
            if (!user || user && !user.userId || !term_id) {
                return;
            }
            const apiUrl = new URL(appSettings.API_URL).origin;
            const path = apiUrl === "http://localhost:3000" ? "http://localhost:2900" : apiUrl + "/api";
            const userId = user && user.userId;

            if (eventSource) {
                eventSource.close();
            }

            const eventSourceLocal = new EventSourcePolyfill(`${path}/notification/${userId}/${term_id}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
                    'Content-Type': 'text/event-stream',
                    'Cache-Control': 'no-cache',
                }
            });

            setEventSource(eventSourceLocal);

            eventSourceLocal.onerror = () => {
                eventSourceLocal.close();
            };
        };

        const intervalId = setInterval(() => {
            startEventSource();
        }, 30000);

        return () => {
            clearInterval(intervalId);
            if (eventSource) {
                eventSource.close();
            }
        };
    }, [eventSource, term_id, user]);

    return (
        <SSEContext.Provider value={eventSource}>
            {children}
        </SSEContext.Provider>
    );
};


const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IPageProps): Types.IPageProps => {
    if (!store || !store.state) {
        return ownProps;
    }
    const newProps: Types.IPageProps = {
        ...ownProps,
        user: store.state.user,
        term_id: store.state.term_id,
    }
    return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const SSEProvider = connect(mapStateToProps, dispatchProps)(SSEProviderIn);

export default SSEProvider;