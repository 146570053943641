import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Modal } from 'reactstrap';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import {
  CourseTermLectureLocationsForSolution,
  DayOfWeek,
  Weeks
} from '../../../store/constants/activity-const';
import { SolutionEditModalInitialValues } from '../../../store/constants/solution-const';
import MultiSelectionTable from '../components/multi-selection-table';
import Translator from '../../../services/translate-factory';
import { SolutionEditModalValidation } from './validations/solution-edit-modal-val';
import * as GT from '../../../tools/general-tools';
import { Days } from '../../../store/constants/course-period-const';

const T = Translator.create();
const L = Log.create('SolutionEditModal');

let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;
let combined_values_selected: Array<any> = new Array<any>();

function getInitialState(): Types.SolutionEditModalState {
  const initialValues: Types.SolutionEditModalState = {
    isAdded: false,
    alreadyInTheList: false,
    all_ids: [],
    selected_ids: [],
    week: '',
    isAvailableHours: true
  };
  return Object.assign({}, initialValues);
}

class SolutionEditModal extends Component<any, Types.SolutionEditModalState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    let newModel = {
      term_id: this.props.term_id,
      solution_id: this.props.solutionId,
      activity_no: this.props.activityNo,
    };
    this.getActivitySelectOptions();
    this.getSettings()
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_GET_ACTIVITY_PROGRAMS, newModel, 'solution-activity-programs-list-spin'));
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.spliceSubModalValues();
  }

  spliceSubModalValues() {
    combined_values_selected && combined_values_selected.splice(0, combined_values_selected.length);
  }

  getSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_SETTINGS, 'activity-list-spin'));
  }

  getProgramsByFacultiesAtActivities = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'activity-list-spin'));
  }

  getActivitySelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  getSolutionActivityPrograms() {
    if (combined_values_selected == undefined || combined_values_selected.length == 0) {
      this.putToTable(this.createFieldValues(), false);
    }
  }

  getSolutionActivitySelectOptions(campus: any, dayOfWeek: any, lessonCount: any, startHour: any) {
    if (campus != null && campus.length == 0)
      campus = null;

    let event: any = this.props.solutionEvent;

    let term_id = this.props.termId === undefined ? this.props.term_id : this.props.termId;
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_ACTIVITY_SELECT_OPTIONS, term_id, 'solution-activity-select-options-spin'));

    let times_range: Array<number> = [];

    let start_hour = startHour ? startHour : event.startHour;
    const course_start_hour = moment(start_hour, 'HH:mm').format('H');
    const course_start_mins = moment(start_hour, 'HH:mm').format('m');

    const duration_course_start_hour = parseInt(course_start_hour, 10);
    const duration_course_start_minute = parseInt(course_start_mins, 10);
    let duration_course_start_in_minutes = (duration_course_start_hour * 60) + duration_course_start_minute;
    times_range.push(duration_course_start_in_minutes);

    const duration_hour = moment(this.props.examDates.slot_duration, 'HH:mm').format('H');
    const duration_mins = moment(this.props.examDates.slot_duration, 'HH:mm').format('m');
    const slot_duration_in_minutes = parseInt(duration_hour, 10) * 60 + parseInt(duration_mins, 10);

    let lesson_count = lessonCount ? lessonCount : event.lessonCount;

    for (let index = 1; index < lesson_count; index++) {
      duration_course_start_in_minutes = duration_course_start_in_minutes + slot_duration_in_minutes;
      times_range.push(duration_course_start_in_minutes);
    }

    let day_of_week = dayOfWeek ? dayOfWeek : event.dayOfWeek;

    let model = {
      term_id: event.termId,
      activity_no: event.id,
      solution_id: Number(event.solutionId),
      hour: Number(start_hour.split(':')[0]),
      minute: Number(start_hour.split(':')[1]),
      lesson_count: lesson_count,
      campus_code: campus ? null : event.campusCode,
      campus_id: campus ? campus.value : null,
      day_of_week: day_of_week,
      day: Days[(day_of_week) - 1].value,
      times_range: times_range
    }
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_ACTIVITY_CLASSROOM_SELECT_OPTIONS, model, 'solution-activity-select-options-spin'));
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.spliceSubModalValues();
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  setHourRestriction = (values: Types.IFilterSolutionEditModal) => {
    if (values.lesson_count != 0) {
      this.state.minHour = this.props.examDates && this.props.examDates.start_hour;

      const calendar_start_hour = moment(this.props.examDates.start_hour, 'HH:mm').format('H');
      const calendar_start_mins = moment(this.props.examDates.start_hour, 'HH:mm').format('m');
      const calendar_end_hour = moment(this.props.examDates.end_hour, 'HH:mm').format('H');
      const calendar_end_mins = moment(this.props.examDates.end_hour, 'HH:mm').format('m');

      const calendar_duration_hour = parseInt(calendar_end_hour, 10) - parseInt(calendar_start_hour, 10);
      const calendar_duration_minute = parseInt(calendar_end_mins, 10) - parseInt(calendar_start_mins, 10);
      const calendar_duration_in_minutes = (calendar_duration_hour * 60) + calendar_duration_minute;

      const duration_hour = moment(this.props.examDates.slot_duration, 'HH:mm').format('H');
      const duration_mins = moment(this.props.examDates.slot_duration, 'HH:mm').format('m');
      const slot_duration_in_minutes = parseInt(duration_hour, 10) * 60 + parseInt(duration_mins, 10);

      const start_in_minutes = parseInt(calendar_start_hour, 10) * 60 + parseInt(calendar_start_mins, 10);
      const calendar_max_duration_in_minute = calendar_duration_in_minutes - (Number(values.lesson_count) * slot_duration_in_minutes);
      const max_start = start_in_minutes + calendar_max_duration_in_minute;

      const calendar_max_start_hour = Math.floor(max_start / 60);
      const calendar_max_start_minute = Math.floor(max_start % 60);
      const calendar_max_start = calendar_max_start_hour.toString() + ':' + calendar_max_start_minute.toString();

      this.state.maxHour = calendar_max_start;

      const value_start_hour = moment(values.start_hour, 'HH:mm').format('H');
      const value_start_mins = moment(values.start_hour, 'HH:mm').format('m');
      const value_start_in_minutes = parseInt(value_start_hour, 10) * 60 + parseInt(value_start_mins, 10);

      if (value_start_in_minutes > max_start)
        this.state.isAvailableHours = false;
      else
        this.state.isAvailableHours = true;
    }
  };

  static getDerivedStateFromProps(props: any, state: Types.SolutionEditModalState) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onSolutionEdit(values: any, FormActions: FormikActions<Types.IFilterSolutionEditModal>) {
    if (saveButtonClicked) {
      const resultCallback = (result: any, status: number) => {
        if (status === 200) {
          this.props.dispatch(Actions.Notification(result.activity_no + ' ' + T.t('notification_all_data_for_courses_changed'), 'gen_success'));
          this.setClose(true);
        }
      };

      let newModel = {
        term_id: this.props.term_id,
        solution_id: this.props.solutionId,
        activity_no: this.props.activityNo,
        activity_code: values.activity_code ? values.activity_code : this.props.solutionEvent.code,
        activity_name: values.activity_name ? values.activity_name : this.props.solutionEvent.name,
        week_of_day: values.week_of_day ? values.week_of_day : this.props.solutionEvent.dayOfWeek,
        lesson_count: values.lesson_count ? values.lesson_count : this.props.solutionEvent.lessonCount,
        start_hour: values.start_hour ? values.start_hour : this.props.solutionEvent.startHour,
        student_count: values.student_count ? values.student_count : this.props.solutionEvent.studentCount,
        campus_id: values.campuses ? values.campuses.value :
          this.props.solutionEvent.campus === CourseTermLectureLocationsForSolution(T)[0].label ? 1000 :
            this.props.solutionEvent.campus === CourseTermLectureLocationsForSolution(T)[1].label ? 1001 :
              this.props.solutionEvent.campus === CourseTermLectureLocationsForSolution(T)[2].label ? 1002 : 0,
        classroom_id: values.campuses ?
          ((values.campuses.value === 1000 || values.campuses.value === 1001 || values.campuses.value === 1002) ?
            null : (values.classrooms ? values.classrooms.value : 0))
          : values.classrooms ? values.classrooms.value : 0,
        instructor_ids: values.instructor_ids ? values.instructor_ids : [],
        program_ids: combined_values_selected.map((item: any) => item.program_id),
        faculty_ids: combined_values_selected.map((item: any) => item.faculty_id),
        sections: combined_values_selected.map((item: any) => item.section),
        weeks: values.weeks ? values.weeks.map((item: any) => item.value) : this.props.solutionEvent.weeks
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.solution.SOLUTION_EDIT_ACTIVITY, newModel, 'course-form-spin', resultCallback)
      );

      saveButtonClicked = false;
      FormActions.setSubmitting(false);
    }
  }

  disableEditButton = (values: Types.IFilterSolutionEditModal) => {
    let disableValue: boolean = false;
    if (values.selected_ids) {
      if (values.selected_ids.includes(0)) {
        if (!values.week_of_day || !values.start_hour || !values.lesson_count || !this.state.isAvailableHours)
          disableValue = true;
      }
      if (values.selected_ids.includes(1)) {
        if (values.classrooms && values.classrooms.length == 0)
          disableValue = true;
      }
      if (values.selected_ids.includes(2)) {
        if (values.weeks && values.weeks.length == 0)
          disableValue = true;
      }
      return disableValue;
    }
    return true
  };

  refreshValuesClosedSelectedTypes = (values: Types.IFilterSolutionEditModal) => {
    if (values.selected_types.length == 0) {
      values.week_of_day = undefined;
      values.start_hour = undefined;
      values.lesson_count = undefined;
      values.activity_code = "";
      values.activity_name = "";
      values.instructors = [];
      values.campuses = [];
      values.classrooms = [];
      values.student_count = "";
      values.weeks = [];
      values.faculties = [];
      values.section = "";
      values.instructors = [];
      values.programs = [];
      this.spliceSubModalValues();
    }
  }

  putToTable = (values: any, plusButtonClicked: boolean) => {
    values.section = values.section == '' ? '1' : values.section;

    combined_values_selected = values.faculty_ids && values.faculty_ids.map((element: any, index: number) => (
      {
        faculty_id: values.faculty_ids[index],
        faculty: values.faculties[index],
        program_id: values.program_ids[index],
        program: values.programs[index],
        section: values.sections[index],
        index: [...Array(values.faculty_ids && values.faculty_ids.length)].map((element: any, index: number) => index + 1)[index],
      }));

    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = !plusButtonClicked;
  };

  multiSelecteds(
    combined_values: any
  ) {
    multiSelectedRow = true;
    combined_values_selected = combined_values
  }

  createFieldValues = (): Types.IActivityItem => {
    let fields: Types.IActivityItem = {
      programs: [],
      program_ids: [],
      faculties: [],
      faculty_ids: [],
      sections: [],
    };
    let activityPrograms = this.props.activityDetails ? this.props.activityDetails : [];
    fields = {
      programs: activityPrograms ? activityPrograms.map((item: any) => ({ label: item.programName, value: item.programId })) : [],
      faculties: activityPrograms ? activityPrograms.map((item: any) => ({ label: item.facultyName, value: item.facultyId })) : [],
      program_ids: activityPrograms ? activityPrograms.map((item: any) => item.programId) : [],
      faculty_ids: activityPrograms ? activityPrograms.map((item: any) => item.facultyId) : [],
      sections: activityPrograms ? activityPrograms.map((item: any) => (item.section)) : [],
    };
    return fields;
  };

  render() {
    let listOptions: Array<Types.ISelectOption> = [
      { label: T.t('gen_date') + ' ' + T.t('gen_time'), value: 0 },
      { label: T.t('gen_classroom'), value: 1 },
      { label: T.t('gen_week'), value: 2 }
    ];

    const campusOptions =
      this.props.solutionActivityPage && this.props.solutionActivityPage.campuses ? this.props.solutionActivityPage.campuses : [];
    const CampusSelectOptions = [...CourseTermLectureLocationsForSolution(T), ...campusOptions];

    let Weeks: Types.ISelectOption[] = GT.convertNumberToSelectOptionType(this.props.solutionEvent.weeks.length + 1);
    const WeekSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...Weeks];
    const weekOptions = this.state.week.split(",").map((item: any) => ({ label: item, value: item }));
    let weekOptionSelector = this.state.week.split(",")[0] === "" ? true : false;

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_solution_edit')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={SolutionEditModalInitialValues}
                enableReinitialize={true}
                validationSchema={SolutionEditModalValidation}
                onSubmit={(values, actions) => {
                  this.onSolutionEdit(values, actions);
                }}
              >
                {(props: FormikProps<Types.IFilterSolutionEditModal>) => {
                  const { values, handleChange, errors, handleBlur, handleSubmit } = props;

                  this.refreshValuesClosedSelectedTypes(values);
                  this.disableEditButton(values);
                  this.setHourRestriction(values);

                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="alert alert-warning" role="alert" style={{ maxWidth: 'none' }}>
                            {T.t('gen_solution_edit_warning')}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_editable_data')}</label>
                                <Select
                                  id='select_type'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    this.getSolutionActivitySelectOptions(null, null, null, null);
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_ids',
                                      list.map((item: any) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[0].value) ?
                            <div className="col-md-4">
                              <div className="react-select-container">
                                <label>{T.t('gen_day')}</label>
                                <Select
                                  id='select_day'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={T.t_options(DayOfWeek, 'null')}
                                  placeholder={T.t('gen_select_day')}
                                  values={props.values.week_of_day}
                                  value={
                                    props.values.week_of_day != undefined && props.values.week_of_day != null
                                      ? T.t_options(DayOfWeek, "null").find((option) => option.value == props.values.week_of_day)
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('week_of_day', option.value);
                                    this.getSolutionActivitySelectOptions(props.values.campuses !== undefined ? props.values.campuses : null,
                                      option.value,
                                      values.lesson_count !== undefined ? values.lesson_count : null,
                                      props.values.start_hour !== undefined ? props.values.start_hour : null);
                                    props.setFieldValue('classrooms', null);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_day')}
                                />
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[0].value) ?
                            <div className="col-md-4 form-input form-group with-icon mt-2">
                              <div className="add-custom-tag mt-2">
                                <input
                                  id="lesson_count"
                                  name="lesson_count"
                                  value={values.lesson_count}
                                  onChange={(e) => {
                                    handleChange(e);
                                    props.setFieldValue('classrooms', null);
                                  }}
                                  onBlur={handleBlur}
                                  type="number"
                                  placeholder={T.t('gen_lesson_count')}
                                  disabled={values.start_hour == null ? false : true}
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="lesson_count"><b>{T.t('gen_lesson_count')}</b></label>
                                {values && values.lesson_count && values.lesson_count < 1 && (
                                  <div className="error">{T.t('gen_must_be_greater_than_zero')}</div>
                                )}
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[0].value) ?
                            <div className="col-md-4 form-input form-group with-icon">
                              <div className="add-custom-tag mt-3">
                                <Flatpickr
                                  id='start_hour'
                                  value={props.values.start_hour}
                                  placeholder={T.t('gen_select_time')}
                                  disabled={values.lesson_count ? false : true}
                                  options={{
                                    enableTime: true,
                                    dateFormat: 'H:i',
                                    noCalendar: true,
                                    time_24hr: true,
                                    minDate: this.state.minHour ? this.state.minHour : undefined
                                  }}
                                  onClose={(value) => {
                                    props.setFieldValue('start_hour', moment(value[0]).format('HH:mm'));
                                    this.getSolutionActivitySelectOptions(
                                      props.values.campuses !== undefined ? props.values.campuses : null,
                                      props.values.week_of_day !== undefined ? props.values.week_of_day : null,
                                      values.lesson_count !== undefined ? values.lesson_count : null,
                                      moment(value[0]).format('HH:mm'));
                                    props.setFieldValue('classrooms', null);
                                  }}
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="start-time-datepicker">{T.t('gen_start_time')}</label>
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[1].value) ?
                            <div className="col-md-12">
                              <div className="col-md-7">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classrooms')}</label>
                                    <Select
                                      id='select_classroom'
                                      className="react-select"
                                      isMulti={false}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={
                                        this.props.settings.filter_classrooms_at_solution_update == true ? (this.props.solution_activity_classroom && this.props.solution_activity_classroom.classrooms ? GT.MakeSelectOptions(this.props.solution_activity_classroom.classrooms) : [])
                                          : (this.props.solutionActivityPage && this.props.solutionActivityPage.classrooms) ? this.props.solutionActivityPage.classrooms : []
                                      }
                                      placeholder={T.t('gen_select_classroom')}
                                      value={props.values.classrooms ? props.values.classrooms : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classrooms', list);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[2].value) ?
                            <div className="col-md-12">
                              <div className="col-md-8">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_week')}</label>
                                    <Select
                                      id='select_week'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={WeekSelectOptions}
                                      placeholder={T.t('gen_select_week')}
                                      value={weekOptionSelector ? props.values.weeks : weekOptions}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('weeks',
                                          list.map(item => item.value).includes(-1) ? Weeks : list
                                        );
                                        props.setFieldValue(
                                          'week',
                                          list.map(item => item.value).includes(-1) ? Weeks.map((item: any) => item.value).join(",") : list.map(item => item.value).join(",")
                                        );
                                        weekOptionSelector = true;
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_week')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> : null
                        }
                        {!this.state.isAvailableHours && (
                          <div className="error">{T.t('gen_error_start_hour').replace('{0}', this.state.maxHour).replace('{1}', this.props.examDates.end_hour).replace('{2}', values.lesson_count)}</div>
                        )}
                        {
                          props.values.selected_types.length !== 0 ?
                            <div className="col-12 pt-3 pb-3 pr-5">
                              <button
                                id='button_change'
                                type="button"
                                className="btn btn-green float-right"
                                disabled={this.disableEditButton(values)}
                                onClick={() => {
                                  props.handleSubmit()
                                  saveButtonClicked = true
                                }}
                              >
                                {T.t('gen_change')}
                              </button>
                            </div> : null
                        }
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    term_id: store.state.term_id,
    settings: store.state.settings,
    solutionActivityPage: store.state.select_options && store.state.select_options.solutionActivityPage,
    examDates: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    solution_activity_classroom: store.state.select_options && store.state.select_options.solutionActivityClassroomSelectOption,
    results_term_week_dates: store.state && store.state.examPeriodModal && store.state.examPeriodModal.resultsTermWeekDates,
    activityDetails: store.state && store.state.solution_page && store.state.solution_page.activityDetails,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionEditModal);

export default container;