export const actions = {
  CORE_NOTIFICATION: 'CORE:NOTIFICATION',
  CORE_NAVIGATION: 'CORE:NAVIGATION',
  CORE_SHOW_MODAL: 'CORE:SHOW_MODAL',
  CORE_WELL_KNOWN_UNITS: 'CORE:WELL_KNOWN_UNITS',
  CORE_AUTH_USER: 'CORE:AUTH_USER',
  CORE_API_REQUEST: 'CORE:API_REQUEST',
  CORE_CHANGE_LANGUAGE: 'CORE:CHANGE_LANGUAGE',
  CORE_TRANSLATOR_READY: 'CORE:TRANSLATOR_READY',
  CORE_PERSISTOR_READY: 'CORE:PERSISTOR_READY',
  CORE_APP_READY: 'CORE:APP_READY',
  CORE_RELOAD_STATE: 'CORE:RELOAD_STATE',
  CORE_SET_TERM_INFO: 'CORE:SET_TERMID',
  CORE_SET_STATUS: 'CORE:SET_STATUS'
};

export enum TermType {
  UNSELECTED = -1,
  COURSE = 0,
  EXAM = 1,
  EVENT = 2,
}