import { constants } from '../constants/event-period-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const eventPeriodReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
    switch (action.type) {
        case constants.EVENT_PERIOD_LIST_SEARCH: {
            const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
            if (result && result.content) {
                return {
                    ...state,
                    event_period_page: {
                        ...state.event_period_page,
                        results: result.content ? result.content.data : [],
                        filters: result.content.filters
                    }
                };
            }
        }
        case constants.EVENT_PERIOD_GET_SELECT_OPTIONS: {
            const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
            if (result && result.content) {
                state = Object.assign({}, state, {
                    select_options: {
                        ...state.select_options,
                        solutionEventPage: result.content
                    }
                });
            }
            return state;
        }
        case constants.EVENT_PERIOD_SOLUTION_SEARCH: {
            const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
            if (result && result.content) {
                return {
                    ...state,
                    event_period_page: {
                        ...state.event_period_page,
                        solutionPage: result.content,
                        filters: result.content.filters
                    }
                };
            }
        }
        case constants.EVENT_PERIOD_SEARCH_AVAILABLE_CLASSROOMS: {
            const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
            if (result && result.content) {
                return {
                    ...state,
                    event_period_page: {
                        ...state.event_period_page,
                        availableClassrooms: result.content ? result.content.data : [],
                        availableClassroomFilters: result.content.filters,
                    }
                };
            }
            return state;
        }
        case constants.EVENT_PERIOD_GET_BY_ID: {
            const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
            if (result && result.content) {
                state = Object.assign({}, state, {
                    event_period_page: {
                        ...state.event_period_page,
                        form: result.content.data
                    }
                });
            }
            return state;
        }
        case constants.EVENT_PERIOD_GET_LIST: {
            const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
            if (result && result.content) {
                state = Object.assign({}, state, {
                    term_list: result.content ? result.content.data : []
                });
            }
            return state;
        }
        default: {
            return state;
        }
    }
};

export default eventPeriodReducer;
