import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { Modal } from 'reactstrap';
import Spinner from '../../components/templates/spinner';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

const equal = require('deep-equal');

const T = Translator.create();

const L = Log.create('CampusMatrixAddModal');

let keys: any = [];

class CampusMatrix extends Component<Types.ICampusMatrixProps, Types.ICampusMatrixState> {
  state: Types.ICampusMatrixState = {
    campusList: [],
    campusObjects: [],
    distances: [],
    matrixValues: {}
  };

  componentDidMount() { }

  getCampusDistances() {
    this.props.dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_DISTANCES, null, 'campus-list-spin'));
  }

  componentDidUpdate(prevProps: Types.ICampusMatrixProps) {
    if (!Object.keys(this.state.matrixValues).length || !equal(prevProps.distanceData, this.props.distanceData)) {
      let campus_list =
        this.props.distanceData && this.props.distanceData.campus_list ? this.props.distanceData.campus_list : [];
      let distances =
        this.props.distanceData && this.props.distanceData.distances ? this.props.distanceData.distances : [];
      if (distances.length && campus_list.length) {
        this.createDistanceObjects(campus_list, distances);
      }
    }
  }

  createDistanceObjects = (
    campus_list: Array<{ campus_id: number; name: string }>,
    distances: Array<{
      campus_id_x: number;
      campus_id_y: number;
      distance: number;
    }>
  ) => {
    let distanceObject: { [key: string]: number } = {};
    distances.map((distanceItem: { campus_id_x: number; campus_id_y: number; distance: number }) => {
      let campus_x = campus_list.find(
        (campusItem: { campus_id: number; name: string }) => distanceItem.campus_id_x === campusItem.campus_id
      );

      let name_x = campus_x && campus_x.name;

      let campus_y = campus_list.find(
        (campusItem: { campus_id: number; name: string }) => campusItem.campus_id === distanceItem.campus_id_y
      );
      let name_y = campus_y && campus_y.name;

      let matrixName = name_x + '-' + name_y;
      distanceObject[matrixName] = distanceItem.distance;
    });

    this.setState({
      ...this.state,
      matrixValues: distanceObject
    });
  };

  createDataModel = () => {
    let keys = Object.keys(this.state.matrixValues);
    let helperArray: any = [];

    keys.map((key: string) => {
      let [name_x, name_y] = key.split('-');
      let campus_x = this.state.campusObjects.find(
        (campusItem: { campus_id: number; name: string }) => name_x === campusItem.name
      );
      let campus_y = this.state.campusObjects.find(
        (campusItem: { campus_id: number; name: string }) => name_y === campusItem.name
      );
      helperArray.push({
        campus_id_x: campus_x && campus_x.campus_id,
        campus_id_y: campus_y && campus_y.campus_id,
        distance: this.state.matrixValues[key]
      });
    });

    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_distances_will_be_saved'),
        body: T.t('gen_distances_will_be_saved_question'),
        name: 'examPeriod_attach_classrooms',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (status == 200) {
              this.setClose();
              this.getCampusDistances();
            }
          };
          this.props.dispatch(
            Actions.ApiRequest(Constants.campus.CAMPUS_UPDATE_DISTANCES, helperArray, 'matrix-spin', resultCallback)
          );
        }
      })
    );
  };

  handleChange = (event: any) => {
    const regex = /^[1-5]$|^[1-5]$|^(5)$/;
    const value = event.target.value;
    if (value === '' || regex.test(value)) {
      this.setState({
        ...this.state,
        matrixValues: {
          ...this.state.matrixValues,
          [event.target.name]: event.target.value
        }
      });
    }
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    if (this.props.term_id && this.props.term_id == -1) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_distances_will_be_closed_without_saving'),
          body: T.t('gen_distances_will_be_closed_without_saving_body'),
          name: 'distances_will_be_closed_without_saving',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            this.setClose();
          }
        })
      );
    } else {
      this.setClose();
    }
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  static getDerivedStateFromProps(props: Types.ICampusMatrixProps, state: Types.ICampusMatrixState) {
    let hasNewState: boolean = false;
    if (props.distanceData && Object.keys(props.distanceData).length) {
      hasNewState = true;
      let arr: Array<string> = [];
      let distanceObject: any = {};
      let { campus_list } = props.distanceData;
      campus_list.map((campus: { campus_id: number; name: string }) => arr.push(campus.name));
      state.campusObjects = campus_list;
      state.campusList = arr;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let tableColumnHeads =
      this.state.campusList &&
      this.state.campusList.length &&
      this.state.campusList.map((campus, index: any) => (
        <th key={index} className="matrix-head" scope="col">
          <div style={{ textAlign: 'center' }}>
            {campus}
          </div>
        </th>
      ));

    let tableRows =
      this.state.campusList &&
      this.state.campusList.length &&
      this.state.campusList.map((campus, index) => {
        let disabledCount = this.state.campusList && this.state.campusList.length - index;
        let enabledBoxes = Array(index)
          .fill(undefined)
          .map((val, index) => {
            let name = campus + '-' + this.state.campusList[index];
            keys.push(name);
            return (
              <td key={index} className="matrix-data time-cell">
                <input
                  name={name}
                  className="matrix-data-input"
                  style={{ textAlign: 'center' }}
                  onChange={this.handleChange}
                  value={this.state.matrixValues && this.state.matrixValues[name]}
                  type="number"
                  onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  min={1}
                  max={5}
                  defaultValue={'1'}
                  required
                />
              </td>
            );
          });
        let disabledBoxes = Array(disabledCount)
          .fill(undefined)
          .map((_, index1) => (
            <td key={index1} className="matrix-data time-cell">
              <input
                className="matrix-data-input"
                style={{ backgroundColor: '#ffadad' }}
                type="text"
                step={600}
                disabled
              />
            </td>
          ));

        return (
          <tr key={campus} className="matrix-row">
            <td className="matrix-data" scope="row">
              <div style={{ textAlign: 'center' }}>
                {campus}
              </div>
            </td>
            {enabledBoxes}
            {disabledBoxes}
          </tr>
        );
      });

    return (
      <Modal className="pt-0" style={{ maxWidth: '100%', padding: '0 15px' }} isOpen={this.props.modalIsOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_campus_distances')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <Spinner name="matrix-spin" />
                <div className="col-12">
                  {this.state.campusList && this.state.campusList.length ? (
                    <table id="campus-matrix" className="aplan-table table sortable table-responsive-md">
                      <thead>
                        <tr id="campus-x">
                          <th scope="col" style={{ fontWeight: 'bold', color: '#dc3545', textAlign: 'center' }}># {T.t('gen_hour')}</th>
                          {tableColumnHeads}
                        </tr>
                      </thead>
                      <tbody id="campus-y">{tableRows}</tbody>
                    </table>
                  ) : (
                    <div className="text-center">
                      <h3>{T.t('gen_campus_distances_no_found')}</h3>
                    </div>
                  )}
                </div>
              </div>
              {this.props.term_id && this.props.term_id == -1 ? (
                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2 float-right"
                      onClick={this.createDataModel}
                    >
                      <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICampusPageProps): Types.ICampusPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.ICampusPageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    distanceData: store.state.campus_page && store.state.campus_page.distanceData
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.campus_page) {
    return !!equal(
      prev.state.campus_page && prev.state.campus_page.distanceData,
      next.state.campus_page && next.state.campus_page.distanceData
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CampusMatrix);

export default container;
