import React, { Component } from 'react';
import { Formik, FormikProps, FormikActions } from 'formik';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { Log } from 'ng2-logger';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';

import * as Types from '../../store/types';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ClassroomSearchInitialValues, ClassroomFeatureOptions } from '../../store/constants/classroom-const';

import Spinner from '../../components/templates/spinner';
import Paginate from '../../components/table/paginate';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import SortedColumn from '../../components/table/sorted-column';

import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';

const T = Translator.create();

const equal = require('deep-equal');
const L = Log.create('ClassroomImportForm');

class ClassroomImportForm extends Component<Types.IClassroomImportFormProps, Types.IClassroomImportFormState> {
  state: Types.IClassroomImportFormState = {
    filters: ClassroomSearchInitialValues,
    filterIsOpen: false,
    all_ids: [],
    selected_ids: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.init();
    document.addEventListener('keydown', this.handleKeyDown);
  }
  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setCloseForm();
    }
  };
  componentDidUpdate(prevProps: any, prevState: any) {
    if (!prevProps.formIsOpen && this.props.formIsOpen) {
      this.init();
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.init();
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  init() {
    this.state = {
      filters: Object.assign({}, ClassroomSearchInitialValues),
      filterIsOpen: false,
      all_ids: [],
      selected_ids: []
    };
    this.state.filters.page = 1;
    this.setState(this.state);
    this.searchClassrooms();
    this.getClassroomSelectOptions();
    this.getBuildingsByCampusesAtClassrooms([]);
  }

  searchClassrooms() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.classroom.CLASSROOM_IMPORT_LIST_SEARCH, this.state.filters, 'classroom-import-form-spin')
    );
  }

  getClassroomSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_IMPORT_GET_SELECT_OPTIONS, 'classroom-import-form-spin'));
  }

  getBuildingsByCampusesAtClassrooms = (campusIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_IMPORT_GET_BUILDINGS_BY_CAMPUSES, campusIds, 'classroom-import-form-spin'));
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchClassrooms();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchClassrooms();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ClassroomSearchInitialValues);
    this.setState(this.state);
    this.searchClassrooms();
  };

  onFilterClassroom(model: Types.IFilterClassroom, FormActions: FormikActions<Types.IFilterClassroom>) {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...model,
        page: 1
      },
      filterIsOpen: true,
      selected_ids: [],
    }))
    this.searchClassrooms();
    FormActions.setSubmitting(false);
  }

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.classroom.CLASSROOM_IMPORT_LIST_SEARCH,
            this.state.filters,
            'classroom-import-form-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onSelectClassroom = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onFormSave(props: Types.IClassroomImportFormState, FormActions: FormikActions<Types.IClassroomImportFormState>) {
    this.disableAllChildren(true);
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status === 200) {
        this.setClose(true);
      }
      this.disableAllChildren(false);
      FormActions.setSubmitting(false);
    };
    const classroom_ids = props.selected_ids;
    this.props.dispatch(
      Actions.ApiRequest(Constants.classroom.CLASSROOM_IMPORT_CREATE, classroom_ids, 'classroom-import-form-spin', resultCallback)
    );
  };
  disableAllChildren = (isDisabled: boolean) => {
    const disableOnLoading = document.getElementById('disableOnLoading');

    if (disableOnLoading) {
      disableOnLoading.querySelectorAll('input, button, textarea, select').forEach((element) => {
        if (element instanceof HTMLInputElement ||
          element instanceof HTMLButtonElement ||
          element instanceof HTMLTextAreaElement ||
          element instanceof HTMLSelectElement) {
          element.disabled = isDisabled;
        }
      });
    }
  };
  static getDerivedStateFromProps(props: Types.IClassroomImportFormProps, state: Types.IClassroomImportFormState) {
    let hasNewState: boolean = false;
    if (hasNewState) {
      return state;
    }
    else return null;
  }

  render() {
    let classroomList = this.props.results;

    let classroomFeatureOptions = ClassroomFeatureOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_features && this.props.selectOptions.additional_classroom_features.length > 0) {
      classroomFeatureOptions = [...ClassroomFeatureOptions(T), ...this.props.selectOptions.additional_classroom_features].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Formik
          enableReinitialize={true}
          initialValues={this.state}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
        >
          {(props: FormikProps<Types.IClassroomImportFormState>) => {
            const { handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div id="disableOnLoading">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <Spinner name="classroom-import-form-spin" />
                          <h5 className="modal-title">
                            <span>
                              <i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_classroom')}
                            </span>
                          </h5>
                          <button
                            id='button_close'
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.setCloseForm}
                          >
                            <i className="material-icons">close</i>
                            <span>ESC</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="white-container mt-4">
                            <div className="row align-items-center mb-1">
                              <div className="col-md-12">
                                <div className="col-md-12 text-right">
                                  <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                                    <button
                                      id='button_filter'
                                      type='button'
                                      className="category-tag-square tag-glass float-right ml-3 mr-3"
                                      style={{ margin: '5px' }}
                                      onClick={() => {
                                        this.state.filterIsOpen = !this.state.filterIsOpen;
                                        this.setState(this.state);
                                      }}
                                    >
                                      <i className="material-icons mr-2">filter_list</i>
                                      <span>{T.t('gen_filter')}</span>
                                    </button>
                                  </div>
                                </div>
                                {/* FILTER STARTS HERE  */}
                                <div
                                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                                  id="advance-search"
                                >
                                  <div className="advance-search d-block mt-3" style={{}}>
                                    <Formik
                                      initialValues={ClassroomSearchInitialValues}
                                      enableReinitialize={true}
                                      onSubmit={(values, actions) => {
                                        this.onFilterClassroom(values, actions);
                                      }}
                                      onReset={this.onFormReset}
                                    >
                                      {(props: FormikProps<Types.IFilterClassroom>) => {
                                        return (
                                          <form onSubmit={props.handleSubmit}>
                                            <div className="row">
                                              <div className="col-12">
                                                <h6>{T.t('gen_filter_parameters')}</h6>
                                              </div>
                                              <div className="col-md-4 mt-3">
                                                <div className="form-input form-group date-picker">
                                                  <input
                                                    id="classroom_code"
                                                    name="classroom_code"
                                                    value={props.values.classroom_code}
                                                    onChange={props.handleChange}
                                                    type="text"
                                                    required
                                                  />
                                                  <span className="highlight" />
                                                  <span className="bar" />
                                                  <label htmlFor="classroom_code">{T.t('gen_classroom_code')}</label>
                                                  <i className="material-icons">filter_1</i>
                                                </div>
                                              </div>
                                              <div className="col-md-4 mt-3">
                                                <div className="form-input form-group date-picker">
                                                  <input
                                                    id="name"
                                                    name="name"
                                                    value={props.values.name}
                                                    onChange={props.handleChange}
                                                    type="text"
                                                    required
                                                  />
                                                  <span className="highlight" />
                                                  <span className="bar" />
                                                  <label htmlFor="name">{T.t('gen_classroom_name')}</label>
                                                  <i className="material-icons">title</i>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="add-custom-tag mb-3">
                                                  <div className="react-select-container">
                                                    <label>{T.t('gen_classroom_type')}</label>
                                                    <Select
                                                      id='select_type'
                                                      className="react-select"
                                                      isMulti={true}
                                                      filterOption={(option: any, query: any) =>
                                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                      }
                                                      closeMenuOnSelect={false}
                                                      options={
                                                        this.props.selectOptions && this.props.selectOptions.classroom_types
                                                          ? this.props.selectOptions.classroom_types.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))
                                                          : []
                                                      }
                                                      placeholder={T.t('gen_select_type')}
                                                      value={props.values.classroom_types ? props.values.classroom_types : null}
                                                      onChange={(
                                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                      ) => {
                                                        const list: Array<Types.ISelectOption> = options
                                                          ? (options as Array<Types.ISelectOption>)
                                                          : [];
                                                        props.setFieldValue('classroom_types', list);
                                                        props.setFieldValue(
                                                          'classroom_type_ids',
                                                          list.map((item) => item.value)
                                                        );
                                                      }}
                                                      noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="add-custom-tag mb-3">
                                                  <div className="react-select-container">
                                                    <label>{T.t('gen_campus')}</label>
                                                    <Select
                                                      id='select_campus'
                                                      className="react-select"
                                                      isMulti={true}
                                                      filterOption={(option: any, query: any) =>
                                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                      }
                                                      closeMenuOnSelect={false}
                                                      options={
                                                        this.props.selectOptions && this.props.selectOptions.campuses
                                                          ? this.props.selectOptions.campuses
                                                          : []
                                                      }
                                                      placeholder={T.t('gen_select_campus')}
                                                      value={props.values.campuses ? props.values.campuses : null}
                                                      onChange={(
                                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                      ) => {
                                                        const list: Array<Types.ISelectOption> = options
                                                          ? (options as Array<Types.ISelectOption>)
                                                          : [];
                                                        props.setFieldValue('campuses', list);
                                                        props.setFieldValue('campus_ids', list.map((item) => item.value));
                                                        this.getBuildingsByCampusesAtClassrooms(list.map((item) => item.value));
                                                      }}
                                                      noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="add-custom-tag mb-3">
                                                  <div className="react-select-container">
                                                    <label>{T.t('gen_building')}</label>
                                                    <Select
                                                      id='select_building'
                                                      className="react-select"
                                                      isMulti={true}
                                                      filterOption={(option: any, query: any) =>
                                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                      }
                                                      closeMenuOnSelect={false}
                                                      options={props.values.campus_ids && props.values.campus_ids.length > 0 ?
                                                        (this.props.buildings_related_campuses ? this.props.buildings_related_campuses : []) :
                                                        (this.props.selectOptions && this.props.selectOptions.buildings ? this.props.selectOptions.buildings : [])}
                                                      placeholder={T.t('gen_select_building')}
                                                      value={props.values.buildings ? props.values.buildings : null}
                                                      onChange={(
                                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                      ) => {
                                                        const list: Array<Types.ISelectOption> = options
                                                          ? (options as Array<Types.ISelectOption>)
                                                          : [];
                                                        props.setFieldValue('buildings', list);
                                                        props.setFieldValue('building_ids', list.map((item) => item.value));
                                                      }}
                                                      noOptionsMessage={(): string => T.t('gen_select_no_building')}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="add-custom-tag mb-3">
                                                  <div className="react-select-container">
                                                    <label>{T.t('gen_classroom_features')}</label>
                                                    <Select
                                                      id='select_feature'
                                                      className="react-select"
                                                      isMulti={true}
                                                      filterOption={(option: any, query: any) =>
                                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                      }
                                                      closeMenuOnSelect={false}
                                                      options={classroomFeatureOptions}
                                                      placeholder={T.t('gen_select_feature')}
                                                      value={props.values.classroom_features}
                                                      onChange={(
                                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                      ) => {
                                                        const list: Array<Types.ISelectOption> = options
                                                          ? (options as Array<Types.ISelectOption>)
                                                          : [];
                                                        props.setFieldValue('classroom_features', list);
                                                        props.setFieldValue(
                                                          'classroom_feature_codes',
                                                          list.map((item) => item.value)
                                                        );
                                                      }}
                                                      noOptionsMessage={(): string => T.t('gen_select_no_feature')}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-2 mt-3">
                                                <div className="form-input form-group date-picker">
                                                  <input
                                                    id="building_floor"
                                                    name="building_floor"
                                                    value={props.values && props.values.building_floor}
                                                    onChange={props.handleChange}
                                                    type="number"
                                                    required
                                                  />
                                                  <span className="highlight" />
                                                  <span className="bar" />
                                                  <label htmlFor="building_floor">{T.t('gen_floor')}</label>
                                                </div>
                                              </div>
                                              <div className="col-md-2 mt-3">
                                                <div className="form-input form-group date-picker">
                                                  <input
                                                    id="door_order"
                                                    name="door_order"
                                                    value={props.values.door_order}
                                                    onChange={props.handleChange}
                                                    type="number"
                                                    required
                                                  />
                                                  <span className="highlight" />
                                                  <span className="bar" />
                                                  <label htmlFor="door_order">{T.t('gen_door_number')}</label>
                                                </div>
                                              </div>
                                              {this.props.term_type !== 0 ? (
                                                <div className="col-md-4 mt-3">
                                                  <div className="form-input form-group date-picker">
                                                    <input
                                                      id="exam_capacity"
                                                      name="exam_capacity"
                                                      value={props.values.exam_capacity}
                                                      onChange={props.handleChange}
                                                      type="number"
                                                      required
                                                    />
                                                    <span className="highlight" />
                                                    <span className="bar" />
                                                    <label htmlFor="exam_capacity">{T.t('gen_exam_capacity')}</label>
                                                  </div>
                                                </div>
                                              ) : null}
                                              <div className="col-md-4 mt-3">
                                                <div className="form-input form-group date-picker">
                                                  <input
                                                    id="lecture_capacity"
                                                    name="lecture_capacity"
                                                    value={props.values.lecture_capacity}
                                                    onChange={props.handleChange}
                                                    type="number"
                                                    required
                                                  />
                                                  <span className="highlight" />
                                                  <span className="bar" />
                                                  <label htmlFor="lecture_capacity">{T.t('gen_lecture_capacity')}</label>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row mt-3">
                                              <div className="col-md-6">
                                                <div className="text-left">
                                                  <h6>{T.t('gen_status')}</h6>
                                                  <div className="tick-radio position-relative mb-3 d-inline-block">
                                                    <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                                  </div>
                                                  <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                                    <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr />
                                            <div className="row mt-3">
                                              <div className="col-6">
                                                <button
                                                  id='button_arrow_upward'
                                                  type="button"
                                                  onClick={() => {
                                                    this.state.filterIsOpen = false;
                                                    this.setState(this.state);
                                                  }}
                                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                                >
                                                  <i className="material-icons">arrow_upward</i>
                                                </button>
                                                <button
                                                  id='button_delete_sweep'
                                                  type="reset"
                                                  onClick={props.handleReset}
                                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                                >
                                                  <i className="material-icons">delete_sweep</i>
                                                </button>
                                              </div>
                                              <div className="col-6 text-right">
                                                <button
                                                  id='button_search'
                                                  type="button"
                                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                                  onClick={() => props.handleSubmit()}
                                                  disabled={props.isSubmitting}
                                                >
                                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                                </button>
                                              </div>
                                            </div>
                                          </form>
                                        );
                                      }}
                                    </Formik>
                                  </div>
                                </div>
                                <div className="row tw-overflow-x-auto">
                                  <div className="col-12">
                                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                                      <thead>
                                        <tr>
                                          <th data-cell="select">
                                            <div className="tick-radio position-relative">
                                              <input
                                                id='select_all'
                                                type="checkbox"
                                                className="form-radio"
                                                checked={this.checkAllIdsSelected()}
                                                onChange={this.onSelectAll}
                                              />
                                            </div>
                                          </th>
                                          <SortedColumn
                                            datacell="classroom_code"
                                            title={T.t('gen_code')}
                                            sortkey="classroom_code"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                          <SortedColumn
                                            datacell="name"
                                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                            title={T.t('gen_name')}
                                            sortkey="name"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                          <SortedColumn
                                            datacell="building_name"
                                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                            title={T.t('gen_campus_and_building')}
                                            sortkey="building_name"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                          <SortedColumn
                                            datacell="building_floor"
                                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                            title={T.t('gen_floor')}
                                            sortkey="building_floor"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                          <SortedColumn
                                            datacell="door_order"
                                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                            title={T.t('gen_door_number')}
                                            sortkey="door_order"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                          {this.props.term_type !== 0 ? (
                                            <SortedColumn
                                              datacell="exam_capacity"
                                              className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                              title={T.t('gen_exam_capacity')}
                                              sortkey="exam_capacity"
                                              sortedcolumn={this.state.filters.order_by}
                                              sort={this.sort}
                                            />
                                          ) : null}
                                          <SortedColumn
                                            datacell="lecture_capacity"
                                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                            title={T.t('gen_lecture_capacity')}
                                            sortkey="lecture_capacity"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                          {this.props.term_type !== 0 ? (
                                            <SortedColumn
                                              datacell="invigilator_count"
                                              className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                              title={T.t('gen_invigilator_count')}
                                              sortkey="invigilator_count"
                                              sortedcolumn={this.state.filters.order_by}
                                              sort={this.sort}
                                            />
                                          ) : null}
                                          <SortedColumn
                                            datacell="classroom_type"
                                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                            title={T.t('gen_type')}
                                            sortkey="classroom_type"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                          <th scope="col" className="text-center">
                                            {T.t('gen_features')}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {classroomList && classroomList.length
                                          ? classroomList.map((item: Types.IClassroomItem) => {

                                            let classroomFeatures: any = [];
                                            item.feature_codes &&
                                              item.feature_codes.length &&
                                              item.feature_codes.map((featureCode: string) => {
                                                let feature = classroomFeatureOptions.find(
                                                  (item: Types.ISelectOption) => item.value == featureCode
                                                );
                                                if (!feature) {
                                                  feature = GT.GetUnknownSelectOption(featureCode);
                                                }
                                                classroomFeatures.push(feature);
                                              });

                                            return (
                                              <tr key={'classroom-' + item.classroom_id} data-title={item.name}>
                                                <td data-cell="select">
                                                  <div className="tick-radio position-relative">
                                                    <input
                                                      id='select_classroom'
                                                      type="checkbox"
                                                      className="form-radio"
                                                      checked={
                                                        this.state.selected_ids &&
                                                        this.state.selected_ids.indexOf(item.classroom_id ? item.classroom_id : -1) >
                                                        -1
                                                      }
                                                      data-id={item.classroom_id}
                                                      onChange={this.onSelectClassroom}
                                                    />
                                                  </div>
                                                </td>
                                                <td scope="row" data-label={T.t('gen_code')}>
                                                  {item.classroom_code}
                                                </td>
                                                <td data-label={T.t('gen_name')} className="text-center">
                                                  {item.name}
                                                </td>
                                                <td data-label={T.t('gen_campus_and_building')} className="text-center">
                                                  {item.building_name}
                                                </td>
                                                <td data-label={T.t('gen_floor')} className="text-center">
                                                  {item.building_floor}
                                                </td>
                                                <td data-label={T.t('gen_door_number')} className="text-center">
                                                  {item.door_order}
                                                </td>
                                                {this.props.term_type !== 0 ? (
                                                  <td data-label={T.t('gen_exam_capacity')} className="text-center">
                                                    {item.exam_capacity}
                                                  </td>
                                                ) : null}
                                                <td data-label={T.t('gen_lecture_capacity')} className="text-center">
                                                  {item.lecture_capacity}
                                                </td>
                                                {this.props.term_type !== 0 ? (
                                                  <td data-label={T.t('gen_invigilator_count')} className="text-center">
                                                    {item.invigilator_count}
                                                  </td>
                                                ) : null}
                                                <td data-label={T.t('gen_type')} className="text-center">
                                                  {item.classroom_type}
                                                </td>
                                                <td className="text-center">
                                                  {classroomFeatures && classroomFeatures.length > 0 ?
                                                    <div className="table-scrollable-td">{classroomFeatures && classroomFeatures.map((i: any, index: any) => (index == classroomFeatures.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                                  }
                                                </td>
                                              </tr>
                                            );
                                          })
                                          : null}
                                      </tbody>
                                    </table>
                                    <div className="row-options justify-content-end">
                                      <div
                                        className="page-sorting d-flex align-items-center justify-content-center"
                                        style={{ marginTop: '5px' }}
                                      >
                                        {this.props.results && this.props.results.length > 0 && (
                                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Spinner name="classroom-import-form-spin" />
                        <div className="modal-footer d-block">
                          <div className="row">
                            <div className="col-md">
                              <button
                                id='button_cancel'
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-gray min-auto"
                                aria-label="Close"
                                onClick={this.setCloseForm}
                              >
                                {T.t('gen_cancel')}
                              </button>
                            </div>
                            <div className="col-md text-md-right">
                              <button
                                id='button_save'
                                type="button"
                                data-dismiss="modal"
                                aria-label="alert-success"
                                disabled={(this.state.selected_ids && this.state.selected_ids.length < 1) || props.isSubmitting}
                                onClick={() => props.handleSubmit()}
                                className="btn btn-green"
                              >
                                <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IClassroomImportFormProps): Types.IClassroomImportFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IClassroomImportFormProps = Object.assign({}, ownProps, {
    user: store.state.user,
    results: store.state.classroom_import_form && store.state.classroom_import_form.results,
    filters: store.state.classroom_import_form && store.state.classroom_import_form.filters,
    selectOptions: store.state.select_options && store.state.select_options.classroomImportForm,
    buildings_related_campuses: store.state.select_options && store.state.select_options.buildings_related_campuses,
    term_type: store.state.term_type,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.classroom_import_form) {
    return (
      !!equal(
        prev.state.user,
        next.state.user
      ) &&
      !!equal(
        prev.state.classroom_import_form && prev.state.classroom_import_form.results,
        next.state.classroom_import_form && next.state.classroom_import_form.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.classroomImportForm,
        next.state.select_options && next.state.select_options.classroomImportForm
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.buildings_related_campuses,
        next.state.select_options && next.state.select_options.buildings_related_campuses
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(ClassroomImportForm);

export default container;
