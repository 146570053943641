import moment from 'moment';
import 'moment/locale/tr';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Announcement from '../../assets_aplan/images/announcement.svg';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  CalendarModalSearchInitialValues,
  CourseHoursDataModelValues,
  CourseHoursInitialValues
} from '../../store/constants/exam-period-const';
import * as Types from '../../store/types';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { StaffTypes, StaffApplyType, Titles } from '../../store/constants/instructor-const';
import { Days } from '../../store/constants/course-period-const';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const Logger = Log.create('CalendarSettings');

function getInitialState(): any {
  const initialValues: any = {
    model: Object.assign({}, CourseHoursInitialValues),
    dataModel: Object.assign({}, CourseHoursDataModelValues),
    filters: Object.assign({}, CalendarModalSearchInitialValues),
    settings: {
      apply_type: -1,
      staff_types: [],
      title_list: []
    }
  };
  return Object.assign({}, initialValues);
}

class CalendarSettings extends Component<any, any> {
  state: any = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.model.term_id = id;
    this.state.filters.term_id = id;
    this.setState(this.state);
    this.convertDataToSchedule();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_SETTINGS, 'course-list-spin'));
  }

  createModifiedSchedule() {
    let scheduleClone: any = Object.assign({}, this.state.model.calendar.schedule);
    let daysClone: any = Object.assign([], this.state.model.calendar.days);
    let test_schedule: any = {};

    daysClone.map((day: any) => {
      let newArray: any = [];
      scheduleClone[day].map((item: any) => {
        let obj = {
          hour: item.hour,
          status: item.isActive == true ? 'empty' : 'disabled'
        };
        newArray.push(obj);
      });
      test_schedule[day] = newArray;
    });
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        calendar: {
          ...this.state.model.calendar,
          schedule: test_schedule
        },
        initialSchedule: test_schedule,
        is_calendar_created: true
      }
    });
  }

  convertDataToSchedule() {
    let data_time_slots: any =
      this.props.settings && this.props.settings.time_slots
        ? this.props.settings.time_slots
        : {};

    let timeSlotsDays = Object.keys(data_time_slots);
    let dateObject_timeSlots: any = {};
    let result_timeSlots: any = {};
    let scheduleClone: any = this.state.model.calendar.schedule;

    if (timeSlotsDays && timeSlotsDays.length) {
      timeSlotsDays.map((day: string) => {
        dateObject_timeSlots = scheduleClone[day];
        data_time_slots[day].map((dataHour: string) => {
          dateObject_timeSlots = dateObject_timeSlots.map((item: { hour: string; status: any }) =>
            item.hour == dataHour ? { hour: item.hour, status: 'course' } : item
          );
        });
        result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
          [day]: dateObject_timeSlots
        });
      });

      this.setState({
        ...this.state,
        model: {
          ...this.state.model,
          calendar: {
            ...this.state.model.calendar,
            schedule: result_timeSlots
          }
        }
      });
    }

  }

  saveSettings(addType: string) {
    let scheduleClone: any = this.state.model.calendar.schedule;
    let timeSlotsArray: any = [];
    let timesHelperObject: any = {};
    let result_timeSlots: any = {};

    this.state &&
      this.state.model.calendar.days.map((day: string) => {
        scheduleClone[day].map((item: { hour: string; status: string }) => {
          if (item.status == 'course') {
            timeSlotsArray.push(item.hour);
          }
        });
        result_timeSlots = Object.assign(timesHelperObject, timeSlotsArray.length ? { [day]: timeSlotsArray } : {});
        timeSlotsArray = [];
      });

    const model = {
      ...this.props.otherSettings,
      staff_types: this.state.settings.staff_types ? this.state.settings.staff_types.map((item: any) => item && item.value) : null,
      title_list: this.state.settings.title_list ? this.state.settings.title_list.map((item: any) => item && item.value) : null,
      apply_type: this.state.settings.apply_type && this.state.settings.apply_type.value,
      time_slots: result_timeSlots
    };

    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('settings_save_title'),
        body: T.t('settings_save_question'),
        name: 'courseHours_save',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_save'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (status == 200) {
              this.state.model.checked_list = [];
              this.getSettings();
              this.setState(this.state);
            }
          };
          this.props.dispatch(
            Actions.ApiRequest(Constants.setting.UPDATE_SETTINGS, model, 'exam-period-modal-tab-spin', resultCallback)
          );
        }
      })
    );
  }

  chooseHour = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      const hour: string = e.currentTarget.dataset.hour || '';
      const day: string = e.currentTarget.dataset.day || '';
      let newSchedule = scheduleClone[day].map((item: any) =>
        item.hour == hour && item.status != 'disabled'
          ? {
            ...item,
            status: item.status == this.state.model.addType ? 'empty' : this.state.model.addType
          }
          : item
      );
      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, {
        [day]: newSchedule
      });
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  chooseAllHoursOfDay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      const day: string = e.currentTarget.dataset.day || '';

      var checkArray = scheduleClone[day].map((item: any) => item.status);

      let allEmptySlotsAreSelected: boolean = checkArray.includes('course') && checkArray.indexOf('empty') == -1;

      let newSchedule = scheduleClone[day].map((item: any) => {
        if (item.status != 'disabled') {
          if (allEmptySlotsAreSelected) {
            return { ...item, status: 'empty' };
          } else {
            return { ...item, status: 'course' };
          }
        } else return item;
      });
      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, {
        [day]: newSchedule
      });
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  chooseAllDaysofHour = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget) {
      let newSchedule = {};
      let scheduleClone: any = this.state.model.calendar.schedule;
      let daysClone: any = this.state.model.calendar.days;
      const time: string = e.currentTarget.dataset.time || '';

      let checkArray: any = [];
      daysClone.map((day: string) =>
        scheduleClone[day].map((item: any) => (item.hour == time ? checkArray.push(item.status) : null))
      );

      let allEmptySlotsAreSelected: boolean = checkArray.includes('course') && checkArray.indexOf('empty') == -1;

      daysClone.map((day: string) => {
        let dayObject = scheduleClone[day].map((item: any) => {
          if (item.hour == time) {
            if (allEmptySlotsAreSelected) {
              return item.status != 'disabled' ? { ...item, status: 'empty' } : item;
            } else {
              return item.status != 'disabled' ? { ...item, status: 'course' } : item;
            }
          } else return item;
        });
        newSchedule = Object.assign(newSchedule, { [day]: dayObject });
      });
      this.state.model.calendar.schedule = JSON.parse(JSON.stringify(newSchedule));
      this.setState(this.state);
    }
  };

  checkScheduleAndSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    let addType: string = e.currentTarget.dataset.addtype || '';
    let dayHasAnyTrueValues: any = [];
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      let daysClone: any = this.state.model.calendar.days;
      dayHasAnyTrueValues = daysClone.map((day: string) => {
        return scheduleClone[day].map((item: any) => (item.status == 'course' ? 1 : 0)).includes(1);
      });
      if (!dayHasAnyTrueValues.includes(true)) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Saat seçmediniz!',
            body: T.t('gen_select_hours_before_saving'),
            name: 'examPeriod_delete',
            icon: 'error_outline',
            iconColor: 'red',
            cancel: T.t('gen_close')
          })
        );
      } else if (dayHasAnyTrueValues.includes(true)) {
        this.saveSettings(addType);
      }
    }
  };

  selectActiveTab = (tabName: string, filters: any = undefined) => {
    let tabs =
      this.state.model.tabManager &&
      this.state.model.tabManager.map((tab: any) => {
        return tab.key == tabName ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });

    this.state.model.tabManager = tabs;
    this.state.filters.course_id =
      this.state.model.selected_Course && this.state.model.selected_Course.course_id
        ? this.state.model.selected_Course.course_id
        : -1;
    this.setState(this.state);
  };

  isActiveTab = (tabName: string) => {
    let activeTab = this.state.model.tabManager && this.state.model.tabManager.find((item: any) => item.key == tabName);
    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  //TODO: getDerivedStateFromProps
  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;
    if (
      props.storedCalendar &&
      props.storedCalendar.times &&
      props.storedCalendar.days &&
      Object.keys(props.storedCalendar.schedule).length &&
      !state.model.is_calendar_created
    ) {
      hasNewState = true;
      state.model.calendar = props.storedCalendar;
    }

    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.model.all_ids = props.all_ids;
    }

    if (props.settings && state.settings.apply_type === -1) {
      hasNewState = true;

      const staffTypes = props.settings.staff_types != null ? props.settings.staff_types.map((type: any) =>
        StaffTypes(T).find((item) => type == item.value)
      ) : null;
      const titleList = props.settings.title_list != null ? props.settings.title_list.map((title: any) =>
        Titles(T).find((item) => title == item.value)
      ) : null;
      const staffApplyType = StaffApplyType(T).find((item) => item.value == props.settings.apply_type);

      state.settings.staff_types = staffTypes;
      state.settings.title_list = titleList;
      state.settings.apply_type = staffApplyType;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  toggleCompactTable = () => {
    this.state.model.showAsCompactTable = !this.state.model.showAsCompactTable;
    this.setState(this.state);
  };

  toggleAddModal = () => {
    this.state.model.addModalIsOpen = !this.state.model.addModalIsOpen;
    this.setState(this.state);
  };

  clearCheckedItems = () => {
    this.state.model.checked_list = [];
    this.setState(this.state);
  };

  render() {
    const appLang = localStorage.getItem('langid');
    const lang = appLang ? appLang.split('-')[0] : 'tr';
    moment.locale(lang);

    let { course_list, checked_list, selected_Course } = this.state.model;
    let { times, days } = this.state.model.calendar;

    let course_list_ids = course_list.map((person: any) => person.course_id);
    let checkAllChecked: boolean = course_list_ids.every((id: number) => checked_list.includes(id));
    let tableColumnHeads = days.map((day: any) => (
      <th key={day}>
        <div data-day={day} onClick={this.chooseAllHoursOfDay}>
          {this.props.term_type === 1 ? moment(day).format('D MMM ddd') : Days.map(item => item.value === day ? T.t(item.label) : '')}
        </div>
      </th>
    ));

    let timeTableRows = null;
    timeTableRows = times.map((time: string, time_index: number) => {
      let boxes = Array(days.length)
        .fill(undefined)
        .map((val, index) => {
          let day: string = days[index];
          let scheduleClone: any = this.state && this.state.model.calendar && this.state.model.calendar.schedule;
          return (
            <td key={index}>
              <div
                id='choose_hour'
                data-day={day}
                data-hour={time}
                onClick={this.chooseHour}
                className={
                  scheduleClone[day].find((item: any) => item.hour == time).status == 'empty'
                    ? ''
                    : scheduleClone[day].find((item: any) => item.hour == time).status == 'course'
                      ? 'admin_force_on'
                      : 'unavailable'
                }
              ></div>
            </td>
          );
        });

      return (
        <tr key={time_index}>
          <td className="aplan-tm-sticky-col">
            <div id='choose_all_days' data-time={time} onClick={this.chooseAllDaysofHour}>
              {time}
            </div>
          </td>
          {boxes}
        </tr>
      );
    });

    let BottomButtons =
      this.state.model.checked_list && this.state.model.checked_list.length > 1 ? (
        this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') && this.props.term_type != -1 ? (
          <button
            id='button_save_all'
            type="button"
            className="btn btn-blue mt-2 mb-2 float-right"
            data-addtype="multiple"
            onClick={this.checkScheduleAndSave}
          >
            <i className="material-icons mr-2 text-left">done_all</i>
            <span>{T.t('gen_save_multi')}</span>
          </button>
        ) : null
      ) : (
        this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') && this.props.term_type != -1 ? (
          <button
            id='button_save'
            type="button"
            className="btn btn-green mt-2 mb-2 mr-2 float-right"
            data-addtype="single"
            onClick={this.checkScheduleAndSave}
          >
            <i className="material-icons mr-2 text-left">done</i>
            <span>{T.t('gen_save')}</span>
          </button>
        ) : null
      );

    let CoursePage = (
      <React.Fragment>
        <div className="row mb-5">
          <div className="col-12">
            <div className="alert alert-info" role="alert" style={{ maxWidth: 'none' }}>
              {T.t('settings_calendar_info_text')}
            </div>
          </div>

          <div className="col-4 col-md-4 col-sm-4 mt-1 settings-dropdown-110">
            <div className="react-select-container">
              <label>{T.t('settings_calendar_apply_type')}</label>
              <Select
                id='select_type'
                className="react-select"
                isMulti={false}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                closeMenuOnSelect={true}
                options={StaffApplyType(T)}
                placeholder={T.t('gen_select_type')}
                value={this.state.settings.apply_type}
                onChange={(option: any) => {
                  this.state.settings.apply_type = option;
                  this.setState(this.state);
                }}
                noOptionsMessage={(): string => T.t('gen_select_no_type')}
              />
            </div>
          </div>

          {this.state.settings.apply_type && this.state.settings.apply_type.value === 0 ? (
            <React.Fragment>
              <div className="col-8 col-md-8 col-sm-8 mt-1 settings-dropdown-120">
                <div className="react-select-container">
                  <label>{T.t('gen_title')}</label>
                  <Select
                    id='select_title'
                    className="react-select"
                    isMulti={true}
                    filterOption={(option: any, query: any) =>
                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                    }
                    closeMenuOnSelect={false}
                    options={Titles(T)}
                    placeholder={T.t('gen_select_title')}
                    value={this.state.settings.title_list && (this.state.settings.title_list.length ? this.state.settings.title_list : null)}
                    onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                      const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                      this.state.settings.title_list = list;
                      this.setState(this.state);
                    }}
                    noOptionsMessage={(): string => T.t('gen_select_no_title')}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : this.state.settings.apply_type && this.state.settings.apply_type.value === 1 ? (
            <div className="col-8 col-md-8 col-sm-8 mt-1 settings-dropdown-100">
              <div className="react-select-container">
                <label>{T.t('gen_staff')}</label>
                <Select
                  id='select_staff_type'
                  className="react-select"
                  isMulti={true}
                  filterOption={(option: any, query: any) =>
                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                  }
                  closeMenuOnSelect={false}
                  options={StaffTypes(T)}
                  placeholder={T.t('gen_select_staff_type')}
                  value={this.state.settings.staff_types != null ? this.state.settings.staff_types : null}
                  onChange={(options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                    const list: Array<Types.ISelectOption> = options ? (options as Array<Types.ISelectOption>) : [];
                    this.state.settings.staff_types = list;
                    this.setState(this.state);
                  }}
                  noOptionsMessage={(): string => T.t('gen_select_no_staff_type')}
                />
              </div>
            </div>
          ) : null}

          <div className={'col-xl-12 col-lg-12 col-md-12 settings-dropdown-90'}>
            <div className="generic-wrapper">
              {this.state.model.calendar.times.length &&
                this.state.model.calendar.days.length &&
                Object.keys(this.state.model.calendar.schedule).length !== 0 ? (
                <React.Fragment>
                  <div className="tab-content" id="nav-tabContent2">
                    <div
                      className={this.isActiveTab('hours') ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="course-hours"
                      role="tabpanel"
                      aria-labelledby="course-hours-tab"
                    >
                      <div className="row mb-4">
                        <div className="col-12">
                          <div className="aplan-table-matrix-wrapper">
                            <div
                              className="aplan-table-matrix-scroller"
                              style={this.state.model.isExpanded ? { height: '100%' } : { height: '400px' }}
                            >
                              <table className="table table-borderless table-striped table-hover aplan-table-matrix course-table mb-0">
                                <thead>
                                  <tr>
                                    <th className="aplan-tm-sticky-col">
                                      <div>#</div>
                                    </th>
                                    {tableColumnHeads}
                                  </tr>
                                </thead>
                                <tbody>{timeTableRows}</tbody>
                              </table>
                            </div>
                          </div>
                          {BottomButtons}
                          <button
                            id='button_expand'
                            type="button"
                            className="btn btn-gray mt-2 mb-2 float-none"
                            onClick={() => {
                              this.state.model.isExpanded = !this.state.model.isExpanded;
                              this.setState(this.state);
                            }}
                          >
                            <i className="material-icons mr-2 text-left">
                              {this.state.model.isExpanded ? 'remove' : 'add'}
                            </i>
                            <span>{this.state.model.isExpanded ? T.t('gen_collapse') : T.t('gen_expand')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  <img
                    id='course_hours_area_info_text'
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '40%'
                    }}
                    src={Announcement}
                  />
                  <br />
                  <p style={{ textAlign: 'center' }}>
                    {T.t('gen_course_hours_area_info_text')} <br /> {T.t('gen_remember_to_save_hours')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    return <React.Fragment>{CoursePage}</React.Fragment>;
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    storedCalendar: store.state.examPeriodModal && store.state.examPeriodModal.storedCalendar,
    filters: store.state.examPeriodModal && store.state.examPeriodModal.filters,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.course_hours &&
      store.state.examPeriodModal.course_hours.all_ids,
    user: store.state.user,
    term_type: store.state.term_type,
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    settings: store.state.settings
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(prev.state.examPeriodModal, next.state.examPeriodModal) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.course_hours,
        next.state.examPeriodModal && next.state.examPeriodModal.course_hours
      ) &&
      !!equal(
        prev.state && prev.state.settings,
        next.state && next.state.settings
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CalendarSettings);

export default container;
