import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import * as Actions from '../../store/actions/general';
import Announce from '../../components/templates/announce';
import * as Constants from '../../store/constants/all';
import { ClassroomSearchInitialValues, ClassroomFeatureOptions } from '../../store/constants/classroom-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';

const T = Translator.create();
const L = Log.create('ClassroomAddModal');

class ClassroomAddModal extends Component<Types.IClassroomPageProps, Types.IClassroomPageState> {
  state: Types.IClassroomPageState = {
    filters: Object.assign({}, ClassroomSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    classroomFormIsOpen: false,
    classroomId: undefined
  };

  componentDidMount() {
    this.state.filters.term_id = this.props.term_id;
    this.setState(this.state);
    this.getClassroomSelectOptions();
    this.getBuildingsByCampusesAtClassrooms([]);
  }

  getClassroomHours() {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(this.state.filters);
    } else {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS,
          this.state.filters,
          'ep-classroom-list'
        )
      );
    }
  }

  getCourseHours() {
    let model = {
      page: 1,
      size: 10,
      status: [1],
      term_id: this.state.filters.term_id,
      total: -1
    };

    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS, model, 'ep-course-list')
    );
  }

  getClassroomSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_SELECT_OPTIONS, 'classroom-list-spin'));
  }

  getBuildingsByCampusesAtClassrooms = (campusIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_BUILDINGS_BY_CAMPUSES, campusIds, 'classroom-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onShowModal) {
      this.props.onShowModal(refresh);
    }
  };

  onClickClose = () => {
    this.setClose();
  };

  setShowAddModal = (refresh: boolean = false) => {
    if (this.props.onShowClassroomAddModal) {
      this.props.onShowClassroomAddModal(refresh);
    }
  };

  onShowAddModal = () => {
    this.setShowAddModal();
  };

  onUpdateAttachedClassroomList = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList();
    }
  };

  switchDisplay = (refresh: boolean = false) => {
    if (this.props.onSwitch) {
      this.props.onSwitch(refresh);
    }
  };

  onClickSwitch = () => {
    this.switchDisplay();
  };

  showClassroomProgram = (e?: React.MouseEvent<HTMLButtonElement>, id?: number) => {
    if (this.props.onSelect) {
      this.props.onSelect(e, id);
    }
  };

  onShowClassroomProgram = (e?: React.MouseEvent<HTMLButtonElement>) => {
    let numID: number = -1;
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      numID = parseInt(id, 10);
    }
    this.showClassroomProgram(e, numID);
    this.switchDisplay();
  };

  onSelectClassroom = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  deleteClassrooms = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      if (this.props.calledFromCourseTab && this.props.calledFrom == 'course-tab') {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Dersten Silinecek',
            body: `Seçtiğiniz derslikler bu dersten silinecektir. Devam etmek istiyor musunuz?`,
            name: 'examPeriod_delete_attached_classrooms',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                if (status == 200) {
                  this.state.selected_ids = [];
                  this.setState(this.state);
                  this.onUpdateAttachedClassroomList();
                  this.getCourseHours();
                }
              };

              /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
              let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
              var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
              let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
              this.state.filters.term_id = id;
              this.state.filters.delete_list = this.state.selected_ids;
              this.state.filters.course_id = this.state.selected_course_id;
              this.state.filters.course_ids = this.state && this.state.selected_course_id ? [this.state && this.state.selected_course_id] : [];

              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.exam_period.EXAM_PERIOD_DELETE_ATTACHED_CLASSROOMS,
                  this.state.filters,
                  'course-tab-attached-classrooms',
                  resultCallback
                )
              );
            }
          })
        );
      } else {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_classrooms_remove_planning'),
            body: T.t('gen_remove_classrooms_from_course_schedule_question'),
            name: 'examPeriod_classrooms_delete',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                if (status == 200) {
                  this.state.selected_ids = [];
                  this.setState(this.state);
                  this.getClassroomHours();
                }
              };
              this.state.filters.delete_list = this.state.selected_ids;

              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.exam_period.EXAM_PERIOD_DELETE_CLASSROOMS_FROM_PERIOD,
                  this.state.filters,
                  'ep-classroom-list',
                  resultCallback
                )
              );
            }
          })
        );
      }
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.state.filters.course_id = this.state.selected_course_id;
        this.state.filters.course_ids = this.state && this.state.selected_course_id ? [this.state && this.state.selected_course_id] : [];
        this.setState(this.state);

        this.props.dispatch(
          Actions.ApiRequest(
            Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS,
            this.state.filters,
            'ep-classroom-list',
            (response: any) =>
              this.setState({
                ...this.state,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getClassroomHours();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.getClassroomHours();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ClassroomSearchInitialValues);
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.setState(this.state);
    this.getClassroomHours();
  };

  onFilterClassroom(model: Types.IFilterClassroom, FormActions: FormikActions<Types.IFilterClassroom>) {
    this.setState(prev => ({
      ...prev,
      selected_ids: [],
      filters: {
        ...model,
        page: 1,
      },
      filterIsOpen: true,
    }));
    this.getClassroomHours();
    FormActions.setSubmitting(false);
  }

  checkAllSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.IClassroomPageState) {
    let hasNewState: boolean = false;
    if (props.classroomList) {
      hasNewState = true;
      state.classroom_list = props.classroomList;
    }

    if (props.calledFromCourseTab && props.attached_classrooms) {
      hasNewState = true;
      state.classroom_list = props.attached_classrooms;
    }

    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.all_ids = props.all_ids;
    }

    if (props.selectedCourseID && props.selectedCourseID != 0 && state.selected_course_id != props.selectedCourseID) {
      hasNewState = true;
      state.filters.term_id = props.term_id;
      state.all_ids = [];
      state.selected_ids = [];
      state.selected_course_id = props.selectedCourseID;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let classroomList = this.state.classroom_list;

    let classroomFeatureOptions = ClassroomFeatureOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_features && this.props.selectOptions.additional_classroom_features.length > 0) {
      classroomFeatureOptions = [...ClassroomFeatureOptions(T), ...this.props.selectOptions.additional_classroom_features].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    let Table = (
      <div className="row">
        <div className="col-12">
          <div className="quick-actions">
            <button
              id='button_filter'
              className="category-tag-square tag-glass float-right ml-3 mr-3"
              style={{ margin: '5px' }}
              onClick={() => {
                this.state.filterIsOpen = !this.state.filterIsOpen;
                this.setState(this.state);
              }}
            >
              <i className="material-icons mr-2">filter_list</i>
              <span>{T.t('gen_filter')}</span>
            </button>
            {this.props.calledFrom && this.props.calledFrom == 'course-tab' ? (
              this.state.selected_ids && this.state.selected_ids.length ? (
                this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (
                  <React.Fragment>
                    <button
                      id='button_cancel_selection'
                      className="category-tag-square tag-gray float-left pr-2 pl-2"
                      style={{ margin: '5px' }}
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          selected_ids: []
                        });
                      }}
                    >
                      <i className="material-icons mr-2">close</i>
                      <span>
                        {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                      </span>
                    </button>
                    {this.props.user && (this.props.user.role === 's') ? (
                      <button
                        id='button_delete_outline'
                        className="category-tag-square tag-red float-left pr-2 pl-2"
                        style={{ margin: '5px' }}
                        onClick={this.deleteClassrooms}
                      >
                        <i className="material-icons mr-2">delete_outline</i>
                        <span> {T.t('gen_delete_selected')}</span>
                      </button>
                    ) : null}
                  </React.Fragment>
                ) : null
              ) : null
            ) : this.state.selected_ids && this.state.selected_ids.length ? (
              <React.Fragment>
                <button
                  id='button_close'
                  className="category-tag-square tag-gray float-left pr-2 pl-2"
                  style={{ margin: '5px' }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      selected_ids: []
                    });
                  }}
                >
                  <i className="material-icons mr-2">close</i>
                  <span>
                    {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                  </span>
                </button>
                {this.props.user && this.props.user.role === 's' ? (
                  <button
                    id='button_delete_selected'
                    className="category-tag-square tag-red float-left pr-2 pl-2"
                    style={{ margin: '5px' }}
                    onClick={this.deleteClassrooms}
                  >
                    <i className="material-icons mr-2">delete_outline</i>
                    <span> {T.t('gen_delete_selected')}</span>
                  </button>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button
                  id='button_view_program'
                  className="category-tag-square tag-gray float-left pr-2 pl-2"
                  style={{ margin: '5px' }}
                  onClick={this.onClickSwitch}
                >
                  <i className="material-icons mr-2">grid_on</i>
                  <span>{T.t('gen_view_program')}</span>
                </button>
                {this.props.user && (this.props.user.role === 's') ? (
                  <button
                    id='button_playlist_add'
                    className="category-tag-square tag-green float-left pr-2 pl-2"
                    style={{ margin: '5px' }}
                    onClick={this.onClickClose}
                  >
                    <i className="material-icons mr-2">playlist_add</i>
                    <span>{T.t('gen_add')}</span>
                  </button>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)} id="advance-search">
          <div className="advance-search d-block mt-3">
            <Formik
              initialValues={ClassroomSearchInitialValues}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                values.term_id = this.state.filters.term_id;
                this.onFilterClassroom(values, actions);
              }}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterClassroom>) => {
                return (
                  <form onSubmit={props.handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        props.handleSubmit();
                      }
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <h6>{T.t('gen_filter_parameters')}</h6>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="classroom_code"
                            name="classroom_code"
                            value={props.values.classroom_code}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="classroom_code">{T.t('gen_classroom_code')}</label>
                          <i className="material-icons">filter_1</i>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="name"
                            name="name"
                            value={props.values.name}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="name">{T.t('gen_classroom_name')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_campus')}</label>
                            <Select
                              id='select_campus'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.campuses
                                  ? this.props.selectOptions.campuses
                                  : []
                              }
                              placeholder={T.t('gen_select_campus')}
                              value={props.values.campuses ? props.values.campuses : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('campuses', list);
                                props.setFieldValue('campus_ids', list.map((item) => item.value));
                                this.getBuildingsByCampusesAtClassrooms(list.map((item) => item.value));
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_building')}</label>
                            <Select
                              id='select_building'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={this.props.buildings_related_campuses ? this.props.buildings_related_campuses : []}
                              placeholder={T.t('gen_select_building')}
                              value={props.values.buildings ? props.values.buildings : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('buildings', list);
                                props.setFieldValue('building_ids', list.map((item) => item.value));
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_building')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_classroom_features')}</label>
                            <Select
                              id='select_feature'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={classroomFeatureOptions}
                              placeholder={T.t('gen_select_feature')}
                              value={props.values.classroom_features}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('classroom_features', list);
                                props.setFieldValue(
                                  'classroom_feature_codes',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_feature')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_classroom_type')}</label>
                            <Select
                              id='select_type'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.classroom_types
                                  ? this.props.selectOptions.classroom_types.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))
                                  : []
                              }
                              placeholder={T.t('gen_select_type')}
                              value={props.values.classroom_types ? props.values.classroom_types : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('classroom_types', list);
                                props.setFieldValue(
                                  'classroom_type_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_type')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="building_floor"
                            name="building_floor"
                            value={props.values && props.values.building_floor}
                            onChange={props.handleChange}
                            type="number"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="building_floor">{T.t('gen_floor')}</label>
                        </div>
                      </div>
                      <div className="col-md-2 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="door_order"
                            name="door_order"
                            value={props.values.door_order}
                            onChange={props.handleChange}
                            type="number"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="door_order">{T.t('gen_door_number')}</label>
                        </div>
                      </div>
                      <div className="col-md-2 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="lecture_capacity"
                            name="lecture_capacity"
                            value={props.values.lecture_capacity}
                            onChange={props.handleChange}
                            type="number"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="lecture_capacity">{T.t('gen_lecture_capacity')}</label>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          id='button_arrow_upward'
                          type="button"
                          onClick={() => {
                            this.state.filterIsOpen = false;
                            this.setState(this.state);
                          }}
                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                        >
                          <i className="material-icons">arrow_upward</i>
                        </button>
                        <button
                          id='button_delete_sweep'
                          type="reset"
                          onClick={props.handleReset}
                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                        >
                          <i className="material-icons">delete_sweep</i>
                        </button>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          id='button_search'
                          type="button"
                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                          onClick={() => props.handleSubmit()}
                          disabled={props.isSubmitting}
                        >
                          <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="col-12">

          {classroomList && classroomList.length ? (
            <div style={{ overflowX: "auto", overflowY: "auto" }}>
              <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                <thead>
                  <tr>
                    <th data-cell="select">
                      {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (
                        <div className="tick-radio position-relative">
                          <input
                            id='check_all'
                            type="checkbox"
                            className="form-radio"
                            checked={this.checkAllSelected()}
                            onChange={this.onSelectAll}
                          />
                        </div>
                      ) : null}
                    </th>
                    <SortedColumn
                      datacell="status"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_status')}
                      sortkey="status"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="hours"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_hours')}
                      sortkey="hours"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <th scope="col" className="text-center">
                      {T.t('gen_course_code')}
                    </th>
                    <SortedColumn
                      datacell="classroom_code"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_code')}
                      sortkey="classroom_code"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="name"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_name')}
                      sortkey="name"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="building_name"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_campus_and_building')}
                      sortkey="building_name"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="building_floor"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_floor')}
                      sortkey="building_floor"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="door_order"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_door_number')}
                      sortkey="door_order"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="lecture_capacity"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_lecture_capacity')}
                      sortkey="lecture_capacity"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="classroom_type"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_type')}
                      sortkey="classroom_type"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <th scope="col" className="text-center">
                      {T.t('gen_features')}
                    </th>
                    <th scope="col" className="text-center">
                      {T.t('gen_description')}
                    </th>
                    {!this.props.calledFromCourseTab && (
                      <th scope="col" className="text-right">
                        <span className="text-right">{T.t('gen_actions')}</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {
                    classroomList.map((item: Types.IExamPeriod_ClassroomItem, index) => {
                      let classroomFeatures: any = [];

                      if (item.classroomInfo && item.classroomInfo.feature_codes) {
                        item.classroomInfo.feature_codes.map((featureCode: string, index) => {
                          const feature = classroomFeatureOptions.find(
                            (item: Types.ISelectOption) => item.value && item.value === featureCode
                          );
                          if (feature) {
                            classroomFeatures.push(feature);
                          }
                        });
                      }
                      let keyId = 'classroom-' + item.classroom_id + (Math.random() * 1000)
                      return (
                        <tr
                          key={keyId}
                          data-title={item.classroomInfo && item.classroomInfo.name}
                        >
                          <td data-cell="select">
                            {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (
                              <div className="tick-radio position-relative">
                                <input
                                  id='check_classroom'
                                  type="checkbox"
                                  className="form-radio"
                                  checked={
                                    this.state.selected_ids &&
                                    this.state.selected_ids.indexOf(item.classroom_id ? item.classroom_id : -1) > -1
                                  }
                                  data-id={item.classroom_id}
                                  onChange={this.onSelectClassroom}
                                />
                              </div>
                            ) : null}
                          </td>
                          <td scope="row" data-label={T.t('gen_status')} className="text-center">
                            <div className="tags ml-1 mr-4">
                              <button
                                id='button_status'
                                className={
                                  `small-tag text-uppercase` +
                                  (item.classroomInfo && item.classroomInfo.status == 1 ? ` tag-green` : ` tag-red`)
                                }
                              >
                                {GT.GetActiveStatus(item.classroomInfo && item.classroomInfo.status)}
                              </button>
                            </div>
                          </td>
                          <td data-label={T.t('gen_hours')} className="text-center">
                            {item.status == 1 ? (
                              <span className="badge badge-success" style={{ fontSize: '95%', textAlign: 'center' }}>
                                {T.t('gen_badge_assigned')}
                              </span>
                            ) : (
                              <span className="badge badge-warning" style={{ fontSize: '95%', textAlign: 'center' }}>
                                {T.t('gen_badge_unassigned')}
                              </span>
                            )}
                          </td>
                          <td data-label={T.t('gen_course_code')} className="text-center">
                            {item.course_code ? item.course_code : '-'}
                          </td>
                          <td data-label={T.t('gen_code')} className="text-center">
                            {item.classroomInfo && item.classroomInfo.classroom_code}
                          </td>
                          <td data-label={T.t('gen_name')} className="text-center">
                            {item.classroomInfo && item.classroomInfo.name}
                          </td>
                          <td data-label={T.t('gen_campus_and_building')} className="text-center">
                            {item.classroomInfo && item.classroomInfo.building_name}
                          </td>
                          <td data-label={T.t('gen_floor')} className="text-center">
                            {item.classroomInfo && item.classroomInfo.building_floor}
                          </td>
                          <td data-label={T.t('gen_door_number')} className="text-center">
                            {item.classroomInfo && item.classroomInfo.door_order}
                          </td>
                          <td data-label={T.t('gen_lecture_capacity')} className="text-center">
                            {item.classroomInfo && item.classroomInfo.lecture_capacity}
                          </td>
                          <td data-label={T.t('gen_type')} className="text-center">
                            {item.classroomInfo && item.classroomInfo.classroom_type}
                          </td>
                          <td className="text-center">
                            {classroomFeatures && classroomFeatures.length > 0 ?
                              <div className="table-scrollable-td">{classroomFeatures && classroomFeatures.map((i: any, index: any) => (index == classroomFeatures.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                            }
                          </td>
                          <td data-label={T.t('gen_description')} className="text-center">
                            {item.classroomInfo && item.classroomInfo.description && item.classroomInfo.description.length ?
                              <div className="table-scrollable-td">{item.classroomInfo.description}</div> : '-'}
                          </td>
                          {!this.props.calledFromCourseTab && (
                            <td data-label={T.t('gen_actions')} className="table-buttons">
                              <div className="table-buttons-wrapper">
                                <button
                                  id='button_access_time'
                                  data-toggle="tooltip"
                                  data-id={item.classroom_id}
                                  onClick={this.onShowClassroomProgram}
                                  title={T.t('gen_edit_hours')}
                                  className="btn btn-light btn-sm table-button"
                                >
                                  <span className="d-block" data-toggle="modal" data-target="#addNew">
                                    <i className="material-icons">access_time</i>
                                  </span>
                                </button>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>) : (
            <div className="text-center">
              <Announce title={T.t('gen_no_records_found')} />
            </div>
          )
          }
          <div className="row-options justify-content-end">
            <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
              {this.state.classroom_list && this.state.classroom_list.length ? (
                <Paginate filters={(this.props.calledFrom && this.props.calledFrom == 'course-tab') ?
                  this.props.attached_classroom_filters :
                  this.props.filters}
                  onPageChange={this.onPageChange}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="container-fluid p-0">
        {Table}
      </div>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IClassroomPageProps
): Types.IClassroomPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IClassroomPageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    classroomList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.classroom_hours &&
      store.state.examPeriodModal.classroom_hours.classroom_list,
    attached_classrooms:
      store.state.course_period_page &&
      store.state.course_period_page.attached_classrooms,
    results: store.state.classroom_page && store.state.classroom_page.results,
    filters:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.classroom_hours &&
      store.state.examPeriodModal.classroom_hours.classroom_hours_filters,
    attached_classroom_filters:
      store.state.course_period_page &&
      store.state.course_period_page.attached_classrooms_filters,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.classroom_hours &&
      store.state.examPeriodModal.classroom_hours.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.classroomPage,
    buildings_related_campuses: store.state.select_options && store.state.select_options.buildings_related_campuses,
    term_id: store.state.term_id,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(prev.state.examPeriodModal, next.state.examPeriodModal
      ) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.classroom_hours,
        next.state.examPeriodModal && next.state.examPeriodModal.classroom_hours
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.buildings_related_campuses,
        next.state.select_options && next.state.select_options.buildings_related_campuses
      ) &&
      !!equal(prev.state.course_period_page, next.state.course_period_page
      ) &&
      !!equal(
        prev.state.user && prev.state.user,
        next.state.user && next.state.user
      ) &&
      !!equal(
        prev.state.term_id,
        next.state.term_id
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomAddModal);

export default container;
