import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { Log } from 'ng2-logger';
import { IAppSettings } from '../store/types';
import { EventEmitter } from 'events';
import * as actions from '../store/constants/all';
import * as Types from '../store/types';

const L = Log.create('Translator');

declare var appSettings: IAppSettings;

let _instance: Translator;
export default class Translator extends EventEmitter {
  i18n: any = {};
  i18next: any;
  listeners: any = {};
  excludedDefaults = ['lobby_.+'];
  ls_key: string = 'langid';

  constructor() {
    super();
    if (_instance) {
      return _instance;
    } else {
      this.init();
      _instance = this;
    }
  }

  static create(): Translator {
    this.defaultMaxListeners = 100;
    return new Translator();
  }

  init = (): void => {
    const backedOptions = {
      loadPath:
        appSettings && appSettings.RESOURCE_PATH
          ? appSettings.RESOURCE_PATH + '/{{lng}}/{{ns}}.json'
          : '/app/resources/{{lng}}/{{ns}}.json',
      parse: (data: any) => {
        try {
          data = data.trim();
          if (data && data.startsWith('{') && data.endsWith('}')) {
            return JSON.parse(data);
          } else {
            return null;
          }
        } catch {
          return null;
        }
      },
      crossDomain: false,
      withCredentials: false,
      allowMultiLoading: false
    };

    const detectionOptions = {
      order: ['querystring', 'localStorage'],
      // keys or params to lookup language from
      lookupQuerystring: this.ls_key,
      lookupLocalStorage: this.ls_key,

      // cache user language on
      caches: ['localStorage']
    };

    const options = {
      backend: backedOptions,
      detection: detectionOptions,
      interpolation: {
        escapeValue: false
      },
      fallbackLng: 'tr-TR',
      debug: true,
      ns: ['translations'],
      defaultNS: 'translations',
      // resources : translations,
      react: {
        wait: true
      },
      saveMissing: false
    };

    const onInit = (err: any, t: any) => {
      if (err) {
        L.error('error in onInit', err);
      } else {
        this.i18n.t = t;
        this.t = t;
        this.emit(actions.gen.CORE_TRANSLATOR_READY, t);
      }
    };

    this.i18next = i18next;

    i18next.on('languageChanged', () => {
    });
    i18next.on('missingKey', (lngs, namespace, key, res) => {
    });
    this.i18n = i18next.use(LanguageDetector).use(XHR).init(options, onInit);
  };

  t = (key: any, opts: any = null): any => {
    return typeof key === 'string' ? this.translateString(key) : i18next.t(key, opts);
  };

  t_options = (itemArray: Array<Types.ISelectOption>, sortDir = 'asc') => {
    let newItems: Array<Types.ISelectOption> = [];
    itemArray.forEach(e => newItems.push({ label: this.t(e.label), value: this.t(e.value) }));
    if (sortDir !== 'null') {
      itemArray.sort((a, b) => {
        // return -1 to get a first
        if (sortDir === 'desc') {
          return String(a.value).localeCompare(String(b.value), i18next.language, { ignorePunctuation: true }) >= 0 ? -1 : 1;
        } else { // default asc
          return String(a.value).localeCompare(String(b.value), i18next.language, { ignorePunctuation: true }) <= 0 ? -1 : 1;
        }
      });
    }
    return newItems;
  };

  translateString = (key: string) => {
    // strip res: if it exits. In some places, we need to put res: in front to determine whether the string is a resource key.
    if (key.indexOf('res:') === 0) {
      key = key.split('res:')[1];
    }
    let val = i18next.t(key);
    if (!val || val.length === 0 || val === key) {
      let setKeyAsValue = true;
      for (let i = 0; i < this.excludedDefaults.length; i++) {
        let pattern: any = this.excludedDefaults[i];
        if (typeof pattern === 'string') {
          pattern = new RegExp(pattern);
        }
        if (pattern.test(key)) {
          setKeyAsValue = false;
          break;
        }
      }
      val = setKeyAsValue ? key : '';
    }
    return val;
  };

  private setLanguage = (lang: string) => {
    i18next.changeLanguage(lang);
    localStorage.setItem(this.ls_key, lang);
  };

  changeLanguage = (langid: string, forceChange: boolean = false) => {
    if (!forceChange) {
      if (sessionStorage && sessionStorage.getItem(this.ls_key)) {
        langid = sessionStorage.getItem(this.ls_key) as string;
      }

      if ('URLSearchParams' in window) {
        const searchParams = new URLSearchParams(window.location.search);
        let qsLang = searchParams.get(this.ls_key);
        if (qsLang && (qsLang.toLowerCase() === 'tr-tr' || qsLang.toLowerCase() === 'en-us')) {
          langid = qsLang;
          if (sessionStorage) {
            sessionStorage.setItem(this.ls_key, langid);
          }
        }
      }
    } else if (sessionStorage) {
      sessionStorage.removeItem(this.ls_key);
    }

    this.setLanguage(langid);
    setTimeout(() => this.emit(actions.gen.CORE_CHANGE_LANGUAGE, langid), 300);
    return langid;
  };

  getSelectedLanguage = () => {
    let langid = '';
    if (i18next.language.toLowerCase() === 'tr-tr') {
      langid = 'tr-TR';
    } else {
      langid = 'en-US';
    }
    return langid;
  };

  addListenerWithKey(key: string, listener: any) {
    if (!this.listeners.hasOwnProperty(key)) {
      this.listeners[key] = listener;
      this.addListener(actions.gen.CORE_CHANGE_LANGUAGE, listener);
    }
  }

  removeListenerWithKey(key: string) {
    if (this.listeners.hasOwnProperty(key)) {
      this.removeListener(actions.gen.CORE_CHANGE_LANGUAGE, this.listeners[key]);
    }
  }

  addComponentListener(componentName: string, instance: any) {
    this.addListenerWithKey('component_' + componentName, () => setTimeout(() => instance.forceUpdate(), 1000));
  }

  removeComponentListener(componentName: string) {
    this.removeListenerWithKey('component_' + componentName);
  }
}
