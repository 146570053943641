import * as Types from '../types';
import * as actions from '../constants/all';
import * as GT from '../../tools/general-tools';
import { Log } from 'ng2-logger';
const L = Log.create('DashboardReducer');

const DashboardReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case actions.dasboard.DASHBOARD_GET_SESSIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          dashboard_pages: {
            ...state.dashboard_pages,
            sessions: result.content ? result.content : []
          }
        });
      }
      return state;
    }
    case actions.dasboard.DASHBOARD_GET_METABASE_REPORT: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          dashboard_page: {
            ...state.dashboard_page,
            model: result.content ? result.content : []
          }
        });
      }
      return state;
    }
    default:
      return state;
  }
}

export default DashboardReducer;