import { Image } from "antd";
import React from "react";

interface ImagePopupProps {
    open: boolean,
    setClose: Function,
    imageArray: string[]
}

const ImagePopup: React.FC<ImagePopupProps> = ({ open, setClose, imageArray }) => {
    return (
        <Image.PreviewGroup preview={{ visible: open, onVisibleChange: () => { setClose(false) } }}>
            {
                imageArray.map(
                    it => <Image
                        key={it}
                        preview={{
                            width: 200,
                            src: it
                        }}
                    />
                )
            }
        </Image.PreviewGroup>
    );
}

export default ImagePopup