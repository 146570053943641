import * as Types from '../types';
import * as actions from '../constants/all';
import AppFactory from '../../services/app-factory';
import * as Enums from '../constants/enums';
import { Log } from 'ng2-logger';
import * as GT from '../../tools/general-tools';
const L = Log.create('CoreReducer');
const AF = AppFactory.create();

function CoreReducer(state: Types.IAppState, action: Types.IAction): Types.IAppState {
  if (action.type === actions.gen.CORE_WELL_KNOWN_UNITS) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, { account: result.content.account, definitions: result.content.definitions });
      AF.setAppModuleReady(actions.gen.CORE_WELL_KNOWN_UNITS);
    }
  } else if (action.type === actions.gen.CORE_AUTH_USER) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, { permissions: result.content.permissions, user: result.content });
      state = AF.setStateFunctions(state);
      AF.setAppModuleReady(actions.gen.CORE_AUTH_USER);
    }
  } else if (action.type === actions.gen.CORE_RELOAD_STATE) {
    state = Object.assign({}, GT.GetInitialState(), { account: state.account, definitions: state.definitions });
  } else if (action.type === actions.gen.CORE_SET_TERM_INFO) {
    state.term_id = (action as Types.ISetTermInfoAction).termId;
    state.term_type = (action as Types.ISetTermInfoAction).termType;
  }
  return state;
}

export default CoreReducer;
