import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { Modal } from 'reactstrap';
import Select from 'react-select';
import Announce from '../../components/templates/announce';
import Spinner from '../../components/templates/spinner';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

const equal = require('deep-equal');

const T = Translator.create();

const L = Log.create('BuildingMatrixAddModal');

let keys: any = [];

class BuildingMatrix extends Component<Types.IBuildingMatrixProps, Types.IBuildingMatrixState> {
  state: Types.IBuildingMatrixState = {
    campusOptions: [],
    campusList: [],
    campusObjects: [],
    buildingList: [],
    buildingObjects: [],
    distances: [],
    matrixValues: {},
  };

  componentDidMount() { }

  getBuildingDistances() {
    this.props.dispatch(Actions.ApiRequest(Constants.building.BUILDING_GET_DISTANCES, null, 'building-list-spin'));
  }

  componentDidUpdate(prevProps: Types.IBuildingMatrixProps) {
    if (!Object.keys(this.state.matrixValues).length || !equal(prevProps.buildingDistanceData, this.props.buildingDistanceData)) {
      let campus_list =
        this.props.buildingDistanceData && this.props.buildingDistanceData.campus_list ? this.props.buildingDistanceData.campus_list : [];
      let building_list =
        this.props.buildingDistanceData && this.props.buildingDistanceData.building_list ? this.props.buildingDistanceData.building_list : [];
      let distances =
        this.props.buildingDistanceData && this.props.buildingDistanceData.distances ? this.props.buildingDistanceData.distances : [];
      if (distances.length && campus_list.length) {
        this.createDistanceObjects(campus_list, building_list, distances);
      }
    }
  }

  createDistanceObjects = (
    campus_list: Array<{ campus_id: number; name: string }>,
    building_list: Array<{ building_id: number; name: string }>,
    distances: Array<{
      building_id_x: number;
      building_id_y: number;
      campus_id: number;
      distance: number;
    }>
  ) => {
    let distanceObject: { [key: string]: number } = {};
    distances.map((distanceItem: { building_id_x: number; building_id_y: number; campus_id: number; distance: number }) => {
      let building_x = building_list.find(
        (buildingItem: { building_id: number; name: string }) => distanceItem.building_id_x === buildingItem.building_id
      );

      let name_x = building_x && building_x.name;

      let building_y = building_list.find(
        (buildingItem: { building_id: number; name: string }) => buildingItem.building_id === distanceItem.building_id_y
      );
      let name_y = building_y && building_y.name;

      let matrixName = name_x + '-' + name_y;
      distanceObject[matrixName] = distanceItem.distance;
    });

    this.setState({
      ...this.state,
      matrixValues: distanceObject
    });
  };

  createDataModel = () => {
    let keys = Object.keys(this.state.matrixValues);
    let helperArray: any = [];

    keys.map((key: string) => {
      let [name_x, name_y] = key.split('-');
      let building_x = this.state.buildingObjects.find(
        (buildingItem: { building_id: number; name: string }) => name_x === buildingItem.name
      );
      let building_y = this.state.buildingObjects.find(
        (buildingItem: { building_id: number; name: string }) => name_y === buildingItem.name
      );
      helperArray.push({
        building_id_x: building_x && building_x.building_id,
        building_id_y: building_y && building_y.building_id,
        campus_id: building_x && building_x.campus_id,
        distance: this.state.matrixValues[key]
      });
    });

    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_distances_will_be_saved'),
        body: T.t('gen_distances_will_be_saved_question'),
        name: 'examPeriod_attach_classrooms',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (status == 200) {
              this.setClose();
              this.getBuildingDistances();
            }
          };
          this.props.dispatch(
            Actions.ApiRequest(Constants.building.BUILDING_UPDATE_DISTANCES, helperArray, 'matrix-spin', resultCallback)
          );
          this.state.campus_select = undefined;
          this.setState(this.state);
        }
      })
    );
  };

  handleChange = (event: any) => {
    const regex = /^[0-4]$|^[0-4]$|^(4)$/;
    const value = event.target.value;

    if (event.target.value && event.target.value > 4) event.target.value = 4;
    else if (event.target.value && event.target.value < 0) event.target.value = 0;

    if (value === '' || regex.test(value)) {
      this.setState({
        ...this.state,
        matrixValues: {
          ...this.state.matrixValues,
          [event.target.name]: event.target.value
        }
      });
    }
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    if (this.props.term_id && this.props.term_id == -1) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_distances_will_be_closed_without_saving'),
          body: T.t('gen_distances_will_be_closed_without_saving_body'),
          name: 'distances_will_be_closed_without_saving',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            this.state.campus_select = undefined;
            this.setState(this.state);
            this.setClose();
          }
        })
      );
    } else {
      this.setClose();
    }
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  static getDerivedStateFromProps(props: Types.IBuildingMatrixProps, state: Types.IBuildingMatrixState) { //TODO
    let hasNewState: boolean = false;
    if (props.buildingDistanceData && Object.keys(props.buildingDistanceData).length) {
      hasNewState = true;
      let arrCampus: Array<string> = [];
      let arrBuilding: Array<string> = [];
      let distanceObject: any = {};
      let { campus_list } = props.buildingDistanceData;
      campus_list.map((campus: { campus_id: number; campus_code: string, name: string }) => arrCampus.push(campus.name));
      state.campusObjects = campus_list;
      state.campusList = arrCampus;
      let { building_list } = props.buildingDistanceData;
      building_list.map((building: { building_id: number; name: string }) => arrBuilding.push(building.name));
      state.buildingObjects = building_list;
      state.buildingList = arrBuilding;
      state.campusOptions = props.buildingDistanceData.campus_options;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let buildingList: Array<any> = [];
    if (this.state.campus_select != undefined) {
      this.state.buildingObjects.filter(item => item.campus_id === this.state.campus_select!.value).map((building: { building_id: number; name: string }) => buildingList.push(building.name))
    }

    let tableColumnHeads =
      buildingList &&
      buildingList.length &&
      buildingList.map((building, index: any) => (
        <th key={index} className="matrix-head" scope="col">
          <div className="table-cell-scrollable-td">
            {building}
          </div>
        </th>
      ));

    let tableRows =
      buildingList &&
      buildingList.length &&
      buildingList.map((building, index) => {
        let disabledCount = buildingList && buildingList.length - index;
        let enabledBoxes = Array(index)
          .fill(undefined)
          .map((val, index) => {
            let name = building + '-' + buildingList[index];
            keys.push(name);
            return (
              <td key={index} className="matrix-data time-cell">
                <input
                  name={name}
                  className="matrix-data-input"
                  style={{ textAlign: 'center' }}
                  onChange={this.handleChange}
                  value={this.state.matrixValues && this.state.matrixValues[name]}
                  type="number"
                  onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  step={1}
                  min={0}
                  max={4}
                  defaultValue={'0'}
                  required
                />
              </td>
            );
          });
        let disabledBoxes = Array(disabledCount)
          .fill(undefined)
          .map((_, index1) => (
            <td key={index1} className="matrix-data time-cell">
              <input
                className="matrix-data-input"
                style={{ backgroundColor: '#ffadad' }}
                type="text"
                step={600}
                disabled
              />
            </td>
          ));

        return (
          <tr key={building} className="matrix-row">
            <td className="matrix-data" scope="row">
              <div className="table-cell-scrollable-td">
                {building}
              </div>
            </td>
            {enabledBoxes}
            {disabledBoxes}
          </tr>
        );
      });

    return (
      <Modal className="pt-0" style={{ maxWidth: '100%', padding: '0 15px' }} isOpen={this.props.modalIsOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_building_distances')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <Spinner name="matrix-spin" />
                <div className="col-md-12">
                  <div className="add-custom-tag mb-3">
                    <div className="react-select-container">
                      <label>{T.t('gen_campus')}</label>
                      <Select
                        id='select_campus'
                        className="react-select"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={
                          this.state.campusOptions
                            ? this.state.campusOptions
                            : []
                        }
                        placeholder={T.t('gen_select_campus')}
                        value={this.state.campus_select}
                        onChange={(option: any) => {
                          this.state.campus_select = option;
                          this.setState(this.state);
                        }}
                        noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {this.state.campus_select && buildingList && buildingList.length ? (
                    <React.Fragment>
                      <div className="row" style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <table id="building-matrix" className="aplan-table table sortable table-responsive-md">
                          <thead>
                            <tr id="building-x">
                              <th scope="col" style={{ fontWeight: 'bold', color: '#dc3545', textAlign: 'center' }}># {T.t('gen_hour')}</th>
                              {tableColumnHeads}
                            </tr>
                          </thead>
                          <tbody id="building-y">{tableRows}</tbody>
                        </table>
                      </div>
                      {this.props.term_id && this.props.term_id == -1 ? (
                        <div className="row">
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2 float-right"
                              onClick={this.createDataModel}
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </React.Fragment>
                  ) : (this.state.campus_select && buildingList && buildingList.length == 0 ? (
                    <div className="text-center">
                      <h4>{T.t('gen_building_distances_no_found')}</h4>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-md-12 col-xs-12 text-center">
                        <Announce title={T.t('gen_notification_campus_for_building_distances_not_selected')} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IBuildingPageProps): Types.IBuildingPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IBuildingPageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    buildingDistanceData: store.state.building_page && store.state.building_page.buildingDistanceData,
    select_options: []
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.building_page) {
    return !!equal(
      prev.state.building_page && prev.state.building_page.buildingDistanceData,
      next.state.building_page && next.state.building_page.buildingDistanceData
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(BuildingMatrix);

export default container;
