import React, { InputHTMLAttributes } from 'react';
import cn from '../ui/Tailwind';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string,
    icon?: string,
    className?: string
}

export default function Input({ className, label, icon, ...props }: Readonly<InputProps>) {
    return (
        <>
            <input
                className={cn(
                    className
                )}
                {...props}
            />
            {
                label && <>
                    <span className="highlight" />
                    <span className="bar" />
                    <label htmlFor={props.id}>{label}</label>
                </>
            }
            {
                icon &&
                <i className="material-icons">{icon}</i>
            }
        </>
    );
}
