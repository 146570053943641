import * as React from 'react';
import { useEffect, useState } from 'react';
import Translator from '../../services/translate-factory';

const T = Translator.create();

interface State {
  time: number;
  secounds: number;
  minutes: number;
  startDates: Date;
}
interface Props {
  startDate: string;
  completionDate: string;
}
let createdDate: Date;
let endDate: Date;

const Timer: React.FC<Props> = ({ startDate, completionDate }) => {
  const [partyTime, setPartyTime] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const now1 = new Date();

  const hoursText = (hours < 10 && hours >= 0) ? ('0' + hours) : hours;
  const minutesText = (minutes < 10 && minutes >= 0) ? ('0' + minutes) : minutes;
  const secondsText = (seconds < 10 && seconds >= 0) ? ('0' + seconds) : seconds;

  createdDate = new Date(now1.getTime());

  if (startDate != '' && startDate != undefined) {
    createdDate = new Date(startDate);
  }

  const now = new Date();
  endDate = new Date(completionDate != '' && completionDate != undefined ? completionDate : now);

  useEffect(() => {
    const interval = setInterval(() => {
      const difference = (endDate.getTime() - createdDate.getTime());

      const d = Math.floor(difference / (1000 * 60 * 60 * 24));

      const h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

      const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

      const s = Math.floor((difference % (1000 * 60)) / 1000);

      setDays(d);

      if (h == -1 && m <= 0 && s <= 0) {
        setHours(0);
        setMinutes(0);
        setSeconds(0);
      }
      else {
        setHours(h);
        setMinutes(m);
        setSeconds(s);
      }

      if (d <= 0 && h <= 0 && m <= 0 && s <= 0) {
        setPartyTime(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <span style={{ fontSize: '1.3rem', marginRight: '8px' }}>{startDate != '' && startDate != undefined ? `${hoursText}: ${minutesText}: ${secondsText}` : T.t('gen_distribution_start_info')}</span>
  );
};

export default Timer;