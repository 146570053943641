import React from 'react';
import { IAppState, IPageProps, IAction } from '../../store/types';
import * as Actions from '../../store/actions/general';
import AlertBox from '../../components/notifications/alert-box';
import ModalBox from '../../components/modals/modal-box';
import DropzoneUploader from '../../components/upload/dropzone-uploader';
import { Log } from 'ng2-logger';
const L = Log.create('App');

export default class MainLayout extends React.Component<any> {
  render() {
    return (
      <React.Fragment>
        {this.props.header}
        {this.props.children || this.props.content}
        <AlertBox />
        <ModalBox />
        <DropzoneUploader dispatch={this.props.dispatch}/>
      </React.Fragment>
    );
  }
}
