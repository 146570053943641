import * as Types from '../types';
import DropzoneUploader from '../../components/upload/dropzone-uploader';
import * as Consts from '../constants/all';

import { Log } from 'ng2-logger';
const L = Log.create('UploadMiddleware');
const UploadMiddleware = (store: any) => (next: any)=> (action: Types.IAction) => {
    let result = next(action);
    if (action.type === Consts.file.FILE_UPLOAD) {
        const dzone = DropzoneUploader.getInstance();
        if (dzone) {
            dzone.onRequest(<Types.IFileUploadRequestAction> action);             
        } 
    }
    return result;
};

export default UploadMiddleware;