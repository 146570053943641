import { Redirect } from 'react-router-dom';
import { Grades } from './course-const';
import { escapeLeadingUnderscores, Type } from 'typescript';
import * as Types from '../types';
import Translator from '../../services/translate-factory';

export const constants = {
  DISTRIBUTOR_GET_DISTRIBUTION_LIST: 'DISTRIBUTOR:GET_DISTRIBUTION_LIST',
  DISTRIBUTOR_GET_STATUS_HISTORY: 'DISTRIBUTOR:GET_STATUS_HISTORY',
  DISTRIBUTOR_CREATE_DISTRIBUTION: 'DISTRIBUTOR:CREATE_DISTRIBUTION',
  DISTRIBUTOR_CONFIRM_DISTRIBUTION: 'DISTRIBUTOR_CONFIRM_DISTRIBUTION',
  DISTRIBUTOR_DOWNLOAD_HISTORY: 'DISTRIBUTOR_DOWNLOAD_HISTORY',
  DISTRIBUTOR_STOP_CALCULATION: 'DISTRIBUTOR_STOP_CALCULATION',
  DISTRIBUTOR_RESET_MAX_NUMBER_OF_ATTEMPTS: 'DISTRIBUTOR_RESET_MAX_NUMBER_OF_ATTEMPTS',
  DISTRIBUTOR_REASSIGN_INVIGILATORS_AUTO: 'DISTRIBUTOR_REASSIGN_INVIGILATORS_AUTO',
  DISTRIBUTOR_CANCEL_DISTRIBUTION: 'DISTRIBUTOR:CANCEL_DISTRIBUTION',
  GET_DISTRIBUTION_IN_PROGRESS_STATUS: 'DISTRIBUTOR:GET_DISTRIBUTION_IN_PROGRESS_STATUS',
  DISTRIBUTOR_GET_INFO_SOLUTION_CHANGE: 'DISTRIBUTOR:GET_INFO_SOLUTION_CHANGE',
  DISTRIBUTOR_SOLUTION_CHANGE_GET_SELECT_OPTIONS: 'DISTRIBUTOR:SOLUTION_CHANGE_GET_SELECT_OPTIONS',
  DISTRIBUTOR_SOLUTION_CHANGE_SET_COURSE_TIMESLOT: 'DISTRIBUTOR:SOLUTION_CHANGE_SET_COURSE_TIMESLOT',
  DISTRIBUTOR_SOLUTION_CHANGE: 'DISTRIBUTOR:SOLUTION_CHANGE',
  DISTRIBUTOR_GET_INFO_INVIGILATOR_CHANGE: 'DISTRIBUTOR:GET_INFO_INVIGILATOR_CHANGE',
  DISTRIBUTOR_INVIGILATOR_CHANGE_GET_SELECT_OPTIONS: 'DISTRIBUTOR:INVIGILATOR_CHANGE_GET_SELECT_OPTIONS',
  DISTRIBUTOR_INVIGILATOR_CHANGE_SET_EXAM_INVIGILATOR: 'DISTRIBUTOR:INVIGILATOR_CHANGE_SET_EXAM_INVIGILATOR',
  DISTRIBUTOR_INVIGILATOR_CHANGE: 'DISTRIBUTOR:INVIGILATOR_CHANGE',
  DISTRIBUTOR_SINGLE_EXAM_CHANGE_GET_SELECT_OPTIONS: 'DISTRIBUTOR:SINGLE_EXAM_CHANGE_GET_SELECT_OPTIONS',
  DISTRIBUTOR_GET_INFO_SINGLE_EXAM_CHANGE: 'DISTRIBUTOR:GET_INFO_SINGLE_EXAM_CHANGE',
  DISTRIBUTOR_SINGLE_EXAM_CHANGE: 'DISTRIBUTOR:SINGLE_EXAM_CHANGE',
  DISTRIBUTOR_SINGLE_COURSE_CHANGE_GET_SELECT_OPTIONS: 'DISTRIBUTOR:SINGLE_COURSE_CHANGE_GET_SELECT_OPTIONS',
  DISTRIBUTOR_GET_INFO_SINGLE_COURSE_CHANGE: 'DISTRIBUTOR:GET_INFO_SINGLE_COURSE_CHANGE',
  DISTRIBUTOR_SINGLE_COURSE_CHANGE: 'DISTRIBUTOR:SINGLE_COURSE_CHANGE',
  DISTRIBUTOR_GET_INFO_CLASSROOM_CHANGE: 'DISTRIBUTOR:GET_INFO_CLASSROOM_CHANGE',
  DISTRIBUTOR_CLASSROOM_CHANGE_GET_SELECT_OPTIONS: 'DISTRIBUTOR:CLASSROOM_CHANGE_GET_SELECT_OPTIONS',
  DISTRIBUTOR_CLASSROOM_CHANGE_SET_COURSE_CLASSROOM: 'DISTRIBUTOR:CLASSROOM_CHANGE_SET_COURSE_CLASSROOM',
  DISTRIBUTOR_CLASSROOM_CHANGE: 'DISTRIBUTOR:CLASSROOM_CHANGE',
  DISTRIBUTOR_SUBMIT_SOLUTION: 'DISTRIBUTOR_SUBMIT_SOLUTION',
};

export const DistributorActions: any = {
  Stop: 1,
  Redirection: 2
}

export const ErrorCodeExam: any = {
  None: 0,

  // 101 to 200 : Fatal
  No_Active_Time_Slots: 101,
  Classrooms_No_Available_Time_Slots: 102,
  Classrooms_Not_Selected: 103,
  Instructors_No_Available_Time_Slots: 104,
  Courses_With_Missing_Data: 105,
  Course_Instructors_Not_in_List_of_Instructors: 106,
  Numbers_of_Programs_and_Grades_Mismatch: 107,
  Numbers_of_Programs_and_Instructors_Mismatch: 108,
  Numbers_of_Programs_and_Course_Codes_Mismatch: 109,
  Course_Not_Selected: 110,
  Inadequacy_of_Campus_Durations_and_Campuses: 111,
  Campus_Codes_in_Exam_List_Not_in_Campus_List: 112,
  Campus_Durations_Error: 113,
  Faculty_Codes_in_Exam_List_Not_in_List_of_Faculties: 114,
  Inadequacy_of_Classroom_Capacities: 115,
  Inadequacy_of_Time_Slots_for_Courses_With_Common_Classroom: 116,
  Inadequacy_of_Consecutive_Time_Slots_for_Exam: 117,
  Inadequacy_of_Available_Hours_for_Instructor: 118,
  Invigilation_Location_of_Instructor_Not_Selected: 119,
  Inadequacy_of_Available_Hours_for_Program: 120,
  Risk_of_Inadequacy_of_Available_Hours_for_Program: 121,
  Inadequacy_of_Available_Hours_for_Student: 122,
  Triple_Inadequacy_of_Available_Hours: 123,

  // 200+ : Ignorable
  Student_Numbers_of_Exam_List_and_Student_List_Mismatch: 201,
  Course_in_Course_List_Not_in_Student_List: 202,
  Course_in_Student_List_Not_in_Course_List: 203
}

export const StatusSelectOptions = (T: Translator) => [
  { label: T.t('gen_cancelled'), value: -2 },
  { label: T.t('gen_failed'), value: -1 },
  { label: T.t('gen_in_progress'), value: 0 },
  { label: T.t('gen_ready_to_publish'), value: 1 },
  { label: T.t('gen_published'), value: 2 },
];

export function GetDistributionStatusInfo(T: Translator, termType: number, status: number, isErrorFatal: boolean): string {
  switch (status) {
    case DistributorJobStatus.Stopped:
      return T.t('gen_cancelled_uppercase');

    case DistributorJobStatus.UnexpectedError:
    case DistributorJobStatus.DataPreparation_Error:
    case DistributorJobStatus.Calculations_Error:
    case DistributorJobStatus.ClassroomAssignment_Error:
    case DistributorJobStatus.InvigilatorAssignment_Error:
    case DistributorJobStatus.SubmitSolution_Error:
      return T.t('gen_failed');

    case DistributorJobStatus.DataPreparation_Pending:
    case DistributorJobStatus.DataPreparation_Ready:
    case DistributorJobStatus.ImpossibilityTest_Pending:
    case DistributorJobStatus.ImpossibilityTest_Ready:
    case DistributorJobStatus.Calculations_Pending:
    case DistributorJobStatus.Calculations_Ready:
    case DistributorJobStatus.ClassroomAssignment_Pending:
    case DistributorJobStatus.InvigilatorAssignment_Pending:
    case DistributorJobStatus.SubmitSolution_Pending:
      return T.t('gen_in_progress');

    case DistributorJobStatus.InvigilatorAssignment_Ready:
      return T.t('gen_ready_to_publish');

    case DistributorJobStatus.ImpossibilityTest_Error:
      if (termType == 1) {
        if (isErrorFatal) return T.t('gen_failed');
        else return T.t('gen_awaiting_approval_uppercase');
      }
      else if (termType == 0) return T.t('gen_failed');
      else return '';

    case DistributorJobStatus.ClassroomAssignment_Ready:
      if (termType == 0) return T.t('gen_ready_to_publish');
      else if (termType == 1) return T.t('gen_in_progress');
      else return '';

    case DistributorJobStatus.Completed_Successfully:
      return T.t('gen_published');

    default:
      return '';
  }
}

export function GetDistributionStatusColor(T: Translator, termType: number, status: number, isErrorFatal: boolean): string {
  switch (status) {
    case DistributorJobStatus.Stopped:
    case DistributorJobStatus.UnexpectedError:
    case DistributorJobStatus.DataPreparation_Error:
    case DistributorJobStatus.Calculations_Error:
    case DistributorJobStatus.ClassroomAssignment_Error:
    case DistributorJobStatus.InvigilatorAssignment_Error:
    case DistributorJobStatus.SubmitSolution_Error:
      return 'red';

    case DistributorJobStatus.DataPreparation_Pending:
    case DistributorJobStatus.DataPreparation_Ready:
    case DistributorJobStatus.ImpossibilityTest_Pending:
    case DistributorJobStatus.ImpossibilityTest_Ready:
    case DistributorJobStatus.Calculations_Pending:
    case DistributorJobStatus.Calculations_Ready:
    case DistributorJobStatus.ClassroomAssignment_Pending:
    case DistributorJobStatus.InvigilatorAssignment_Pending:
    case DistributorJobStatus.SubmitSolution_Pending:
      return 'gray';

    case DistributorJobStatus.InvigilatorAssignment_Ready:
      return 'orange';

    case DistributorJobStatus.ImpossibilityTest_Error:
      if (termType == 1) {
        if (isErrorFatal) return 'red';
        else return 'orange';
      }
      else if (termType == 0) return 'red';
      else return '';

    case DistributorJobStatus.ClassroomAssignment_Ready:
      if (termType == 0) return 'orange';
      else if (termType == 1) return 'gray';
      else return '';

    case DistributorJobStatus.Completed_Successfully:
      return 'green';

    default:
      return '';
  }
}

export const DistributorModalSearchInitialValues: Types.IFilterDistributorModal = {
  order_by: '',
  page: 1,
  total: -1,
  size: 10,
};

export const DistributorSearchInitialValues: Types.IFilterDistributor = {
  order_by: '',
  page: 1,
  total: -1,
  size: 10,
};

export const DistributorJobStatus: Types.IDistributorJobStatus = {
  Stopped: -2,
  UnexpectedError: -1,

  // Create dll XMLs
  DataPreparation_Pending: 0,
  DataPreparation_Ready: 1,
  DataPreparation_Error: 2,

  // Check data
  ImpossibilityTest_Pending: 3,
  ImpossibilityTest_Ready: 4,
  ImpossibilityTest_Error: 5,

  // Calculations
  Calculations_Pending: 6,
  Calculations_Ready: 7,
  Calculations_Error: 8,

  // Classroom Assignment
  ClassroomAssignment_Pending: 9,
  ClassroomAssignment_Ready: 10,
  ClassroomAssignment_Error: 11,

  // Invigilator Assignment (Exam)
  InvigilatorAssignment_Pending: 12,
  InvigilatorAssignment_Ready: 13,
  InvigilatorAssignment_Error: 14,

  // Submit Solution
  SubmitSolution_Pending: 98,
  SubmitSolution_Error: 99,

  Completed_Successfully: 100,
}

export const CourseTimeSlotEditModel: Types.IDistributorCourseTimeSlotEditModel = {
  slot_id: 0,
  time_slot: '',
  slot_capacity: 0,
  courses: [{
    course_detail: undefined,
    exam_detail: undefined,
    selected_time_slot: { label: '', value: 0 },
    available_time_slots: []
  }]
};

export const CourseTimeSlotSearchInitialValues: Types.IFilterDistributorCourseTimeSlot = {
  time_slots: [],
  time_slot_ids: [],
  course_ids: [],
  course_codes: [],
  course_ids_select: [],
  course_codes_select: [],
  programs: [],
  program_ids: [],
  class: [],
  class_ids: [],
  instructors: [],
  instructor_ids: [],
  course_id_for_overlaps: 0,
  order_by: '',
  page: 1,
  total: -1,
  size: 10
};

export const CourseClassroomSearchInitialValues: Types.IFilterDistributorCourseClassroom = {
  room_ids: [],
  rooms_select: [],
  course_ids: [],
  course_codes: [],
  course_ids_select: [],
  course_codes_select: [],
  programs: [],
  program_ids: [],
  class: [],
  class_ids: [],
  instructors: [],
  instructor_ids: [],
  course_id_for_overlaps: 0,
  order_by: '',
  page: 1,
  total: -1,
  size: 10
};

export const DistributorInvigilatorEditModel: Types.IDistributorCourseInvigilatorEditModel = {
  exam_id: 0,
  time_slot_id: 0,
  course_info: '',
  exam_info: '',
  exam_classrooms: [{
    dll_invigilator_id: 0,
    invigilator_code: '',
    invigilator_name: '',
    dll_room_id: 0,
    classroom_code: '',
    classroom_name: '',
    time_slot_id: 0,
    date: '',
    available_invigilator_codes: []
  }]
};

export const DistributorInvigilatorSearchInitialValues: Types.IFilterDistributorInvigilator = {
  time_slots: [],
  time_slot_ids: [],
  course_ids: [],
  course_codes: [],
  course_ids_select: [],
  course_codes_select: [],
  course_name: '',
  faculties: [],
  faculty_ids: [],
  education_types: [],
  education_types_select: [],
  campuses: [],
  campus_ids: [],
  programs: [],
  program_ids: [],
  class: [],
  class_ids: [],
  instructors: [],
  instructor_ids: [],
  exam_type: [],
  exam_type_ids: [],
  classroom_types: [],
  classroom_type_ids: [],
  invigilators: [],
  invigilator_ids: [],
  invigilator_assignment_status: [],
  invigilator_assignment_status_ids: [],
  classrooms: [],
  classroom_ids: [],
  invigilator_status: [],
  invigilator_status_ids: [],
  order_by: '',
  page: 1,
  total: -1,
  size: 10
};

export const DistributorSingleExamEditModel: Types.IDistributorSingleExamEditModel = {
  job_id: -1,
  exam_id: -1,
  time_slot_id: -1,
  course_id: -1,
  dll_course_id: -1,
  assigned_time_slot_id: -1,
  time_slot: '',
  course_info: '',
  exam_info: '',
  exam_date: '',
  student_count: -1,
  classrooms_capacity: -1,
  exam_type: -1,
  validation: false,
  exam_classrooms: [{
    dll_invigilator_id: 0,
    invigilator_code: '',
    invigilator_name: '',
    dll_room_id: 0,
    classroom_code: '',
    classroom_name: '',
    time_slot_id: 0,
    date: '',
    available_invigilator_codes: []
  }],
  available_time_slots: [],
  available_rooms: []
};

export const DistributorSingleExamSearchInitialValues: Types.IFilterDistributorSingleExam = {
  course_id: -1,
  timeslot_id: -1,
  room_ids: [],
  invigilator_ids: [],
  order_by: '',
  page: 1,
  total: -1,
  size: 1
};

export const DistributorSingleCourseEditModel: Types.IDistributorSingleCourseEditModel = {
  job_id: -1,
  exam_id: -1,
  time_slot_id: -1,
  course_id: -1,
  dll_course_id: -1,
  assigned_time_slot_id: -1,
  time_slot: '',
  course_info: '',
  additional_info: '',
  course_date: '',
  student_count: -1,
  classrooms_capacity: -1,
  education_type: -1,
  validation: false,
  current_classroom: '',
  course_classroom: {
    dll_room_id: 0,
    classroom_code: '',
    classroom_name: '',
    time_slot_id: 0,
    date: ''
  },
  available_time_slots: [],
  available_rooms: []
};

export const DistributorSingleCourseSearchInitialValues: Types.IFilterDistributorSingleCourse = {
  course_id: -1,
  timeslot_id: -1,
  room_id: -2,
  order_by: '',
  page: 1,
  total: -1,
  size: 1
};