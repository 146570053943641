export const constants = {
  SKETCH_CAMPUS_LIST_SEARCH: 'SKETCH:CAMPUS_LIST_SEARCH',
  SKETCH_BUILDING_LIST_SEARCH: 'SKETCH:BUILDING_LIST_SEARCH',
  SKETCH_CAMPUS_GET_BY_ID: 'SKETCH:CAMPUS_GET_BY_ID',
  SKETCH_BUILDING_GET_BY_ID: 'SKETCH:BUILDING_GET_BY_ID',
  SKETCH_GET_SELECT_OPTIONS: 'SKETCH:GET_SELECT_OPTIONS',
  SKETCH_BUILDING_MARKER_UPDATE: 'SKETCH:BUILDING_MARKER_UPDATE',
  SKETCH_CLASSROOM_MARKER_UPDATE: 'SKETCH:CLASSROOM_MARKER_UPDATE',
  SKETCH_BULK_UPDATE: 'SKETCH:BULK_UPDATE',
};
