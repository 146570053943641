import * as Types from '../../store/types';
import AlertBox from '../../components/notifications/alert-box';
import ModalBox from '../../components/modals/modal-box';
import * as Consts from '../constants/all';

import { Log } from 'ng2-logger';
const L = Log.create('NotificationMiddleware');
const NotificationMiddleware = (store: any) => (next: any) => (action: Types.IAction) => {
  let result = next(action);
  if (action.type === Consts.gen.CORE_NOTIFICATION) {
    const Alert = AlertBox.getInstance();
    if (Alert) {
      Alert.setMessage(<Types.INotificationAction>action);
    }
  } else if (action.type === Consts.gen.CORE_SHOW_MODAL) {
    const Modal = ModalBox.getInstance();
    if (Modal) {
      Modal.setMessage(<Types.IModalAction>action, result.buttonPosition);
    }
  }
  return result;
};

export default NotificationMiddleware;
