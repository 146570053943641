import React from 'react';
import { Field } from 'formik';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
const T = Translator.create(); 

const Checkbox: React.SFC<Types.ICheckboxProps> = props => {
  return (
    <Field name={props.name}>
      {({ field, form }: any) => (
        <label>
          <input
            {...props}
            type="checkbox"
            id="radio-active"
            className="form-radio"
            checked={field.value === 1}
            onChange={() => form.setFieldValue(props.name, field.value === 0 ? 1 : 0)}
            defaultChecked={props.checked}
          />
          {field.value === 1 ? ' ' + T.t('gen_active') : field.value === 0 ? ' ' + T.t('gen_passive') : ' ' + T.t('gen_deleted')}
        </label>
      )}
    </Field>
  );
};

export default Checkbox;
