import React, { Component } from 'react'
import * as Types from '../../store/types';
import { DashboardExamPlanningInitialValues } from '../../store/constants/dashboard-const';
import { Log } from 'ng2-logger';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('MetabaseReport');

class MetabaseReport extends Component<Types.IMetabaseReportModel, Types.IMetabaseReportState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            data: Object.assign({}, DashboardExamPlanningInitialValues.model)
        };
    }

    hideSpinner = () => {
        this.setState({
            loading: false
        });
    };

    render() {
        return (
            <React.Fragment>
                {this.props.dashboard_id && this.props.dashboard_id >= 0
                    ?
                    <React.Fragment>
                        {this.state.loading ? (
                            <React.Fragment>
                                <div className="loader ml-3"></div>
                                <div className="row">
                                    <div className="col-10 ml-3" style={{ color: '#827c7c' }}>
                                        {T.t('gen_dashboard_loading')}
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : null}
                        <div >
                            <iframe
                                src={this.props.url}
                                title={this.props.report_name}
                                frameBorder="0"
                                width={this.props.frame_width}
                                height={this.props.frame_height}
                                allowTransparency
                                onLoad={this.hideSpinner}
                            />
                        </div>
                    </React.Fragment>
                    :
                    (this.props.url && this.props.url.length > 0 ?
                        <div className="row">
                            <div className="col-10 ml-3" style={{ color: 'red' }}>
                                {T.t('gen_dashboard_data_not_found')}
                            </div>
                        </div>
                        : null)
                }
            </React.Fragment>
        );
    }
}

export default MetabaseReport