import * as Types from '../types';

export const TermWeekDatesInitialValues: Types.ITermWeekDatesState = {
  start_date: "",
  end_date: "",
  total_weeks: 0,
  holiday_name: "",
  exclusion_name: "",
  term_week_no: 0,
  term_week_schedule: [{
    end_date: "",
    start_date: "",
    week_no: 0
  }],
  locale: '',
  term_week_start_end_date_range: [],
  term_week_schedule_start_values: "",
  term_week_schedule_end_values: "",
  term_week_schedule_values: [],
  holidays_start_date: "",
  holidays_end_date: "",
  holidays_start_end_date_range: [],
  holidays: [{
    id: 0,
    end_date: "",
    start_date: "",
    name: "",
    total_days: 0
  }],
  exclusions: [{
    id: 0,
    end_date: "",
    start_date: "",
    name: "",
    total_days: 0
  }],
  exclusions_start_end_date_range: [],
  date_exclution: undefined,
  date_exclution_value: [],
};
