import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Types from '../../../store/types';
import Translator from '../../../services/translate-factory';
import { ClassroomCombineTypes, ExamPeriodCourseTermLectureLocations, ExamTypes, ExamEnvironments, ExamOverlapTypes, InvigilatorStatuses } from '../../../store/constants/course-const';

const T = Translator.create();

function getInitialState(): any {
  const initialValues: any = {};
  return Object.assign({}, initialValues);
}

class SolutionCourseAddDetailTable extends Component<any, any> {
  state: {} = getInitialState();

  render() {
    let course = this.props.detail;
    let ClassroomCombineStatusObject: any = {};
    let combineStatus = course.classroom_combine_status;
    ClassroomCombineStatusObject = ClassroomCombineTypes(T).find(
      (object: any) => object.value == combineStatus
    );

    let examType = course.exam_type;
    let ExamTypesObject = ExamTypes(T).find(
      (object) => object.value == examType
    );

    let examEnvironment = course.exam_environment;
    let ExamEnvironmentsObject = ExamEnvironments(T).find(
      (object) => object.value == examEnvironment
    );

    let examOverlapType = course.exam_overlap_type;
    let ExamOverlapTypesObject = ExamOverlapTypes(T).find(
      (object) => object.value == examOverlapType
    );

    let invigilatorStatus = course.invigilator_status;
    let InvigilatorStatusesObject = InvigilatorStatuses(T).find(
      (object) => object.value == invigilatorStatus
    );

    return (
      <div className="col-12 table-scroller">
        <div className="col-12">
          <h6>{T.t('gen_solution-exam-will-be-added')}</h6>
        </div>
        <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_course_no')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_code')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_conjugate_course_code')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_name')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_student_count')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_total_student_count')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_grade')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_section')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_faculty')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_campus')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_exam_duration')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_session_count')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_classroom_combine_status')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_exam_type')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_exam_environment')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_exam_overlap_type')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_invigilator_status')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_connected_programs')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_instructors')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_assistants')}</span>
              </th>
              <th scope="col" className="text-center">
                <span className="text-center">{T.t('gen_description')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr key={'activity-' + course.activity_no} data-title={course.name}>
              <td scope="row" data-label={T.t('gen_course_no')} className="text-center">
                {course.course_id}
              </td>
              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                <div className="table-scrollable-td">{course.course_code ? course.course_code.split(';').join(', ') : '-'}</div>
              </td>
              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                <div className="table-scrollable-td">{course.conjugate_course_code ? course.conjugate_course_code.split(';').join(', ') : '-'}</div>
              </td>
              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                <div className="table-scrollable-td">{course.name ? course.name.split(';').join(', ') : '-'}</div>
              </td>
              <td className="text-center">
                {
                  course && course.student_counts && course.student_counts.length > 0 ?
                    <div className="table-scrollable-td">{course.student_counts && course.student_counts.map((i: any, index: any) => (index == course!.student_counts!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  course && course.student_counts && course.student_counts.length > 0 ?
                    <div className="table-scrollable-td">{course.student_count_total}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  course.classes && course.classes.length > 0 ?
                    <div className="table-scrollable-td">{course.classes && course.classes.map((i: any, index: any) => (index == course!.classes!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  course.sections && course.sections.length > 0 ?
                    <div className="table-scrollable-td">{course.sections && course.sections.map((i: any, index: any) => (index == course!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  course.faculties && course.faculties.length > 0 ?
                    <div className="table-scrollable-td">{course.faculties && course.faculties.map((i: any, index: any) => (index == course!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  course.campuses && course.campuses.length > 0 ?
                    <div className="table-scrollable-td">{course.campuses && course.campuses.map((i: any, index: any) => (index == course!.campuses!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td scope="row" data-label={T.t('gen_exam_duration')} className="text-center">
                {course.exam_duration}
              </td>
              <td data-label={T.t('gen_session_count')} className="text-center">
                {course.exam_session}
              </td>
              <td data-label={T.t('gen_classroom_combine_status')} className="text-center">
                {ClassroomCombineStatusObject && ClassroomCombineStatusObject.label}
              </td>
              <td data-label={T.t('gen_exam_type')} className="text-center">
                {ExamTypesObject && ExamTypesObject.label ? ExamTypesObject && ExamTypesObject.label : '-'}
              </td>
              <td data-label={T.t('gen_exam_environment')} className="text-center">
                {ExamEnvironmentsObject && ExamEnvironmentsObject.label ? ExamEnvironmentsObject && ExamEnvironmentsObject.label : '-'}
              </td>
              <td data-label={T.t('gen_exam_overlap_type')} className="text-center">
                {ExamOverlapTypesObject && ExamOverlapTypesObject.label ? ExamOverlapTypesObject && ExamOverlapTypesObject.label : '-'}
              </td>
              <td data-label={T.t('gen_invigilator_status')} className="text-center">
                {InvigilatorStatusesObject && InvigilatorStatusesObject.label ? InvigilatorStatusesObject && InvigilatorStatusesObject.label : '-'}
              </td>
              <td className="text-center">
                {
                  course.programs && course.programs.length > 0 ?
                    <div className="table-scrollable-td">{course.programs && course.programs.map((i: any, index: any) => (index == course!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  course.instructors && course.instructors.length > 0 ?
                    <div className="table-scrollable-td">{course.instructors && course.instructors.map((i: any, index: any) => (index == course!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  course.assistant_staff && course.assistant_staff.length > 0 ?
                    <div className="table-scrollable-td">{course.assistant_staff && course.assistant_staff.map((i: any, index: any) => (index == course!.assistant_staff!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td data-label={T.t('gen_description')} className="text-center">
                {course.description && course.description.length ?
                  <div className="table-scrollable-td">{course.description}</div> : '-'}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="row-options justify-content-end">
          <div
            className="page-sorting d-flex align-items-center justify-content-center"
            style={{ marginTop: '5px' }}
          ></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionCourseAddDetailTable);

export default container;
