import React, { ReactNode } from 'react';
import { Dropdown, MenuProps, Space } from 'antd';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Button from '../button';
import { IPageProps } from '../../store/types';
import * as Types from '../../store/types';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

export interface NavItem {
    key: number | string,
    danger?: boolean
    label?: string | ReactNode,
    icon?: any,
    leftIcon?: any,
    permissions?: string[],
    to?: string | (() => void),
    children?: NavItem[],
    show?: (props: IPageProps, state: Types.IHeaderState) => boolean,
    type?: any
}

export interface IDropdownElement {
    children?: NavItem[];
    title?: string | ReactNode;
    arrow?: boolean;
    trigger?: ("contextMenu" | "click" | "hover")[]
    permissions?: string[]
    show?: (props: IPageProps, state: Types.IHeaderState) => boolean
    to?: string | (() => void),
}

export interface DropdownElementProps extends RouteComponentProps, IDropdownElement {
    props: IPageProps,
    state: Types.IHeaderState,
    disablePadding?: boolean
}

const DropdownElement: React.FC<DropdownElementProps> = ({ show, disablePadding, props, state, children, title, arrow = true, to, history, permissions, trigger = ["hover"] }) => {
    const navItemToMenuProps = (items: NavItem[] | undefined): MenuProps['items'] => {
        if (!items)
            return undefined
        return items
            .filter(item => {
                if (!item.show)
                    return true
                return item.show(props, state)
            })
            .filter(item => {
                if (!item.permissions || (props.user && item.permissions.includes(props.user.role))) {
                    return true;
                }
                return false;
            })
            .map(_ => {
                if (_.type === 'divider') {
                    return {
                        type: 'divider',
                    }
                }
                const isString = typeof _.to === 'string';
                return {
                    ..._,
                    label: isString ? (
                        <Link to={_.to as string} className="hover:tw-no-underline tw-font-semibold">
                            {_.label}
                            {_.leftIcon}
                        </Link>
                    ) : (
                        <span onKeyDown={(e) => ['\r'].includes(e.key) && e.preventDefault()} onClick={_.to as () => void} className="hover:tw-no-underline tw-font-semibold">
                            {_.label}
                            {_.leftIcon}
                        </span>
                    ),
                    children: navItemToMenuProps(_.children),
                    icon: _.icon && <div className="tw-text-lg">
                        {_.icon}
                    </div>,
                }
            })
    }
    if (show && !show(props, state))
        return <></>

    if (permissions && (!props.user || (props.user && !permissions.includes(props.user.role)))) {
        return <></>;
    }

    const menuItems: MenuProps['items'] = navItemToMenuProps(children);

    if (to || !menuItems) {
        const isString = to && typeof to === 'string';

        return <a href={isString ? to : undefined} rel="noopener noreferrer">
            <Button
                onClick={() => {
                    if (!isString) {
                        (to as () => void)()
                    }

                }}
                size={disablePadding ? 'disable' : "md"} className="tw-font-semibold tw-text-white hover:tw-bg-white/10 tw-rounded-lg">
                <Space size={'small'}>
                    {title}
                    {arrow && <MdKeyboardArrowDown />}
                </Space>
            </Button>
        </a>
    }

    return (
        <Dropdown
            menu={{
                items: menuItems
                , style: { fontSize: '20px', padding: '8px' }
            }}
            trigger={trigger}
            className="tw-font-semibold"
        >
            <Button size={disablePadding ? 'disable' : "md"} className="tw-text-white hover:tw-bg-white/10 tw-rounded-lg tw-items-center tw-flex">
                <Space size={'small'}>
                    {title}
                    {arrow && <MdKeyboardArrowDown />}
                </Space>
            </Button>
        </Dropdown>
    );
};

export default withRouter(DropdownElement)
