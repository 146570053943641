import React from 'react';
import { Log } from 'ng2-logger';
import { IAppState, IPageProps, IAction } from '../../store/types';
import * as Actions from '../../store/actions/general';

const L = Log.create('App');

export default class SimplePage extends React.Component<any, any> {
  render() {
    return (
      <div className={'page-container ' + (this.props.name || '')}>
        <div className="page-content">
          <div>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
