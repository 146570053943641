import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
    EVENT_PERIOD_LIST_SEARCH: 'EVENT_PERIOD:LIST_SEARCH',
    EVENT_PERIOD_GET_SELECT_OPTIONS: 'EVENT_PERIOD:LIST_GET_SELECT_OPTIONS',
    EVENT_PERIOD_SOLUTION_SEARCH: 'EVENT_PERIOD:SOLUTION_SEARCH',
    EVENT_PERIOD_CREATE: 'EVENT_PERIOD:CREATE',
    EVENT_PERIOD_UPDATE: 'EVENT_PERIOD:UPDATE',
    EVENT_PERIOD_DELETE: 'EVENT_PERIOD:DELETE',
    EVENT_PERIOD_GET_BY_ID: 'EVENT_PERIOD:GET_BY_ID',
    EVENT_PERIOD_GET_LIST: 'EVENT_PERIOD_GET_LIST',
    EVENT_PERIOD_EVENT_CREATE: 'EVENT_PERIOD:EVENT_CREATE',
    EVENT_PERIOD_EVENT_UPDATE: 'EVENT_PERIOD:EVENT_UPDATE',
    EVENT_PERIOD_EVENT_DELETE: 'EVENT_PERIOD:EVENT_DELETE',
    EVENT_PERIOD_SEARCH_AVAILABLE_CLASSROOMS: 'EVENT_PERIOD:SEARCH_AVAILABLE_CLASSROOMS',
};

export const EventPeriodSearchInitialValues: Types.IFilterEventPeriod = {
    name: '',
    status: [],
    academic_terms: [],
    year: undefined,
    term_type: 2,
    page: 1,
    order_by: '',
    query: '',
    total: -1,
    size: 10,
    event_types: []
};

export const EventPeriodFormInitialValues: Types.ITermItem = {
    term_id: undefined,
    status: 1,
    name: '',
    academic_terms: [],
    term_type: 1,
    calendar_status: 0,
    description: '',
    year: undefined,
    time_range: undefined
};