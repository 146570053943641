import * as Constants from '../../../store/constants/all';
import { TableType } from './interfaces';

interface ConfigType {
    search: string;
    search_select_options?: string;
    spin: string;
    statePaths: {
        results: string;
        filters: string;
        selectOptions: string;
        distanceData?: string;
    };
}

export const getConstFromType: { [key in TableType]: ConfigType } = {
    [TableType.CAMPUS]: {
        search: Constants.campus.CAMPUS_LIST_SEARCH,
        spin: 'campus-list-spin',
        statePaths: {
            results: 'campus_page.results',
            filters: 'campus_page.filters',
            selectOptions: 'select_options.campusPage.buildings',
            distanceData: 'campus_page.distanceData',
        },
    },
    [TableType.BUILDING]: {
        search: Constants.building.BUILDING_LIST_SEARCH,
        spin: 'building-list-spin',
        statePaths: {
            results: 'building_page.results',
            filters: 'building_page.filters',
            selectOptions: 'select_options.buildingPage',
            distanceData: 'campus_page.distanceData',//evet campus_page olacak
        },
    },
    [TableType.SKETCH_CAMPUS]: {
        search: Constants.sketch.SKETCH_CAMPUS_LIST_SEARCH,
        search_select_options: Constants.sketch.SKETCH_GET_SELECT_OPTIONS,
        spin: 'sketch-list-spin',
        statePaths: {
            results: 'sketch_page.campus_results',
            filters: 'sketch_page.campus_filters',
            selectOptions: 'select_options.sketchPage.campus_buildings'
        },
    },
    [TableType.SKETCH_BUILDING]: {
        search: Constants.sketch.SKETCH_BUILDING_LIST_SEARCH,
        search_select_options: Constants.sketch.SKETCH_GET_SELECT_OPTIONS,
        spin: 'sketch-list-spin',
        statePaths: {
            results: 'sketch_page.building_results',
            filters: 'sketch_page.building_filters',
            selectOptions: 'select_options.sketchPage.building_campuses'
        },
    },
    [TableType.AVALIABLE_EVENT]: {
        search: Constants.event_period.EVENT_PERIOD_SEARCH_AVAILABLE_CLASSROOMS,
        spin: 'available-place-table',
        statePaths: {
            results: 'event_period_page.availableClassrooms',
            filters: 'event_period_page.availableClassroomFilters',
            selectOptions: 'select_options.solutionEventPage'
        },
    },
    // TODO : public sketch campus ve public sketch building icin statePaths ve search_select_options eklenmeli
    [TableType.PUBLIC_SKETCH_CAMPUS]: {
        search: 'Constants.sketch.SKETCH_PUBLIC_CAMPUS_LIST_SEARCH',
        spin: 'sketch-list-spin',
        statePaths: {
            results: 'sketch_page.public_campus_results',
            filters: 'sketch_page.public_campus_filters',
            selectOptions: 'select_options.sketchPage.public_campus_buildings'
        },
    },
    [TableType.PUBLIC_SKETCH_BUILDING]: {
        search: 'Constants.sketch.SKETCH_PUBLIC_BUILDING_LIST_SEARCH',
        spin: 'sketch-list-spin',
        statePaths: {
            results: 'sketch_page.public_building_results',
            filters: 'sketch_page.public_building_filters',
            selectOptions: 'select_options.sketchPage.public_building_campuses'
        },
    },
};