import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ActivityFormInitialValues,
  EducationTypeSelectOptions,
  ActivityTermLectureLocations,
  CourseTypes,
  ActivityTypes,
  CourseEnvironments
} from '../../store/constants/activity-const';
import * as Types from '../../store/types';
import { ActivityFormValidation } from './course/validations/course-form-val';
import MultiSelectionTable from './components/multi-selection-table';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';
import CourseDublicationModal from './course/course-dublication-modal';
import CourseSectionDublicationModal from './course/course-section-dublication-modal';
import { routes as Routes } from '../../store/constants/routes';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';

const T = Translator.create();
const Logger = Log.create('ClassroomForm');

let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;
let combined_values_selected: Array<any> = new Array<any>();

function getInitialState(): Types.IActivityFormState {
  const initialValues: Types.IActivityFormState = {
    model: Object.assign({}, ActivityFormInitialValues),
    isAdded: false,
    alreadyInTheList: false,
    week: ''
  };
  return Object.assign({}, initialValues);
}

class CourseHourForm extends Component<Types.IActivityHoursFormProps, Types.IActivityFormState> {
  state: Types.IActivityFormState = getInitialState();
  componentRef: any;

  constructor(props: any) {
    super(props);
    this.componentRef = React.createRef();
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
    this.getProgramsByFacultiesAtActivities([]);
    this.putToTable(this.createFieldValues(), false);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    combined_values_selected && combined_values_selected.splice(0, combined_values_selected.length);
  }

  getProgramsByFacultiesAtActivities = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'activity-list-spin'));
  }

  switchDublicateActivityModalStatus = () => {
    this.setState({
      ...this.state,
      dublicateActivityModalIsOpen: !this.state.dublicateActivityModalIsOpen
    });

    if (this.state.dublicateActivityModalIsOpen === false) {
      this.setCloseForm();
    }
  };

  onActivityIsRelated = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_IS_RELATED, [this.props.activity.activityInfo && this.props.activity.activityInfo.activity_no], 'activity-list-spin')
    );
    setTimeout(() => {
      if (this.props.is_related) {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_has_activity_relations'),
            body: T.t('gen_has_activity_relations_info'),
            name: 'course_delete',
            icon: 'warning',
            iconColor: 'red',
            cancel: T.t('gen_ok'),
            confirm: T.t('gen_go_to_activity_relations'),
            onConfirm: () => {
              this.props.dispatch(Actions.Navigation(GT.Route(Routes.ACTIVITY_RELATIONS)))
            }
          })
        );
      } else {
        this.switchDublicateActivityModalStatus();
      }
    }, 1000);
  };

  switchDublicateSectionModalStatus = () => {
    this.setState({
      ...this.state,
      dublicateSectionModalIsOpen: !this.state.dublicateSectionModalIsOpen
    });

    if (this.state.dublicateSectionModalIsOpen === false) {
      this.setCloseForm();
    }
  };

  onSectionIsRelated = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_IS_RELATED, [this.props.activity.activityInfo && this.props.activity.activityInfo.activity_no], 'activity-list-spin')
    );
    setTimeout(() => {
      if (this.props.is_related) {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_has_activity_relations'),
            body: T.t('gen_has_activity_relations_info'),
            name: 'course_delete',
            icon: 'warning',
            iconColor: 'red',
            cancel: T.t('gen_ok'),
            confirm: T.t('gen_go_to_activity_relations'),
            onConfirm: () => {
              this.props.dispatch(Actions.Navigation(GT.Route(Routes.ACTIVITY_RELATIONS)))
            }
          })
        );
      } else {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_dublicate_section'),
            body:
              <React.Fragment>
                <Formik
                  initialValues={{ sectionDublicationCount: 0 }}
                  enableReinitialize={true}
                  onSubmit={(values, actions) => {
                  }}
                >
                  {(props: FormikProps<Types.ISectionDublicationModalForm>) => {
                    const { values, handleChange, handleBlur, handleSubmit } = props;
                    return (
                      <div className="row">
                        {T.t('gen_section_dublication_question')}
                        <div className="col-md-12 col-sm-12 col-12 form-input form-group with-icon mt-3">
                          <input
                            id="section_dublicate_count"
                            name="section_dublicate_count"
                            value={values.sectionDublicationCount}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const newValue = e.target.value;
                              values.sectionDublicationCount = Number(newValue);
                              this.setState(
                                {
                                  ...this.state,
                                  sectionDublicationCount: Number(newValue),
                                  sectionDublicationCountIsConfirmed: Number(newValue) < 1 ? false : true
                                }
                              );
                            }}
                            type="number"
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="section_dublicate_count">{T.t('gen_section_dublicate_count')}</label>
                        </div>
                      </div>
                    )
                  }}
                </Formik>
              </React.Fragment>,
            name: 'section_dublicate',
            icon: 'info',
            iconColor: 'green',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              if (this.state.sectionDublicationCountIsConfirmed) {
                this.setState({
                  ...this.state,
                  dublicateSectionModalIsOpen: !this.state.dublicateSectionModalIsOpen
                });
              }
              else
                this.props.dispatch(Actions.Notification('notification_section_dublication', 'gen_warning', 'danger'));

            }
          })
        );
      }
    }, 500);
  };

  setClose = (refresh: boolean = false) => {
    this.setState(this.state);
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IActivityItem, FormActions: FormikActions<Types.IActivityItem>) => {
    if (saveButtonClicked) {
      const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
        if (result && result.code) {
          let errors: any = {};
          if (result.details) {
            const validations: Array<Types.IValidationResponse> = result.details;
            validations.forEach((m: Types.IValidationResponse) => {
              errors[m.field] = m.message[0];
            });
          }
          FormActions.setErrors(errors);
        }
        if (status === 200 || status === 201) {
          this.setClose(true);
        }
        else if (status === 406) {
          this.props.dispatch(
            Actions.ShowModal({
              title: T.t('gen_error'),
              body: result,
              name: 'failed',
              icon: 'warning',
              iconColor: 'red',
              cancel: T.t('gen_ok'),
            })
          );
          multiSelectedRow = false;
          this.setClose(true);
        }
      };

      this.props.activity.activityInfo = model;
      const dataModel = this.props.activity;

      let newModel;
      if (combined_values_selected == undefined || combined_values_selected.length == 0) {
        this.props.dispatch(Actions.Notification(T.t("gen_list_isnot_left_blank"), 'gen_warning', 'danger'))
      } else {

        newModel = {
          activityInfo: {
            activity_no: dataModel.activityInfo.activity_no,
            activity_programs: dataModel.activityInfo.activity_programs,
            activity_type: dataModel.activityInfo.activity_type,
            class: dataModel.activityInfo.class,
            classes: dataModel.activityInfo.classes,
            course_code: dataModel.activityInfo.course_code,
            course_name: dataModel.activityInfo.course_name,
            course_term_status: dataModel.activityInfo.course_term_status,
            course_type: dataModel.activityInfo.course_type,
            description: dataModel.activityInfo.description,
            educationType_object: dataModel.activityInfo.educationType_object,
            education_type: dataModel.activityInfo.education_type,
            label: dataModel.activityInfo.label,
            value: dataModel.activityInfo.value,
            faculty_id: dataModel.activityInfo.faculty_id,
            faculty_ids: combined_values_selected.map((item: any) => item.faculty_id),
            instructor_ids: dataModel.activityInfo.instructor_ids,
            instructors: dataModel.activityInfo.instructors,
            lecture_location: dataModel.activityInfo.lecture_location,
            lecture_location_id: dataModel.activityInfo.lecture_location_id,
            lesson_count: dataModel.activityInfo.lesson_count,
            program_id: dataModel.activityInfo.program_id,
            program_ids: combined_values_selected.map((item: any) => item.program_id),
            section: dataModel.activityInfo.section,
            sections: combined_values_selected.map((item: any) => item.section),
            status: dataModel.activityInfo.status,
            student_count: dataModel.activityInfo.student_count,
            term: dataModel.activityInfo.term,
            term_id: dataModel.activityInfo.term_id,
            week: dataModel.activityInfo.week,
            course_environment: model.course_environment,
            course_language: model.course_language_select ? model.course_language_select!.value : '',
            classroom_type: model.classroom_type_select ? model.classroom_type_select!.value : '',
          },
          activity_noes: dataModel.activity_noes,
          course_hour_id: dataModel.course_hour_id,
          course_id: dataModel.course_id,
          course_ids: dataModel.course_ids,
          status: dataModel.status,
          term_id: dataModel.term_id,
          time_slots: dataModel.time_slots
        }
        multiSelectedRow = false;

        this.props.dispatch(
          Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_COURSETERM_EDIT_COURSE, newModel, 'course-form-spin', resultCallback)
        );
        saveButtonClicked = false;
        FormActions.setSubmitting(false);
      }
    }
  };

  static getDerivedStateFromProps(props: Types.IActivityHoursFormProps, state: Types.IActivityFormState) {
    let hasNewState: boolean = false;

    if (props.activity && props.activity.course_hour_id) {
      state.model = props.activity.activityInfo;
      state.week = props.activity.activityInfo.week ? props.activity.activityInfo.week : '';
      const educationTypesArray = [props.activity.activityInfo.education_type];
      const educationTypeObject = EducationTypeSelectOptions(T).find((item) => educationTypesArray.includes(item.value));
      state.model.educationType_object = educationTypeObject;

      hasNewState = true;
    }

    if (hasNewState) {
      return state;
    } else return null;
  }

  putToTable = (values: any, plusButtonClicked: boolean) => {
    values.section = values.section == '' ? '1' : values.section;

    combined_values_selected = values.faculty_ids && values.faculty_ids.map((element: any, index: number) => (
      {
        faculty_id: values.faculty_ids[index],
        faculty: values.faculties[index],
        program_id: values.program_ids[index],
        program: values.programs[index],
        section: values.sections[index],
        index: [...Array(values.faculty_ids && values.faculty_ids.length)].map((element: any, index: number) => index + 1)[index],
      }));

    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = !plusButtonClicked;
  };

  multiSelecteds(
    combined_values: any
  ) {
    multiSelectedRow = true;
    combined_values_selected = combined_values
  }

  createFieldValues = (): Types.IActivityItem => {
    let fields: Types.IActivityItem = {
      course_code: '',
      activity_no: 0,
      name: '',
      student_count: -1,
      programs: [],
      program_ids: [],
      faculties: [],
      faculty_ids: [],
      campus: undefined,
      campus_id: 0,
      faculty_name: undefined,
      section: '',
      sections: [],
      description: '',
      program_id: 0,
      faculty_id: 0,
    };
    let courseInfo = this.props.activity.activityInfo ? this.props.activity.activityInfo : this.state.model;
    fields = {
      course_code: courseInfo && courseInfo.course_code ? courseInfo.course_code : '',
      activity_no: courseInfo && courseInfo.activity_no ? courseInfo.activity_no : 0,
      name: courseInfo && courseInfo.name ? courseInfo.name : '',
      campus: courseInfo && courseInfo.campus ? courseInfo.campus : undefined,
      campus_id: courseInfo && courseInfo.campus_id ? courseInfo.campus_id : undefined,
      student_count: courseInfo && courseInfo.student_count ? courseInfo.student_count : 0,
      programs: courseInfo && courseInfo.programs ? courseInfo.programs : [],
      program_ids: courseInfo && courseInfo.program_ids ? courseInfo.program_ids : [],
      faculties: courseInfo && courseInfo.faculties ? courseInfo.faculties : [],
      faculty_ids: courseInfo && courseInfo.faculty_ids ? courseInfo.faculty_ids : undefined,
      sections: courseInfo && courseInfo.sections ? courseInfo.sections : undefined,
      description: courseInfo && courseInfo.description ? courseInfo.description : undefined,
    };
    return fields;
  };

  render() {
    const campusOptions = this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));

    let activityTypes = ActivityTypes(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_activity_types && this.props.selectOptions.additional_activity_types.length > 0) {
      activityTypes = [...ActivityTypes(T), ...this.props.selectOptions.additional_activity_types].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    let Weeks: Types.ISelectOption[] = GT.convertNumberToSelectOptionType(this.props.results_term_week_dates.total_weeks);
    const WeekSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...Weeks];
    const weekOptions = this.state.week.split(",").map((item: any) => ({ label: item, value: item }));
    let weekOptionSelector = this.state.week.split(",")[0] === "" ? true : false;

    const scrollToTop = () => {
      this.componentRef.current.scrollIntoView({ block: 'nearest' });
    }

    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="faculty-form-spin" />
        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={ActivityFormValidation(T)}
        >
          {(props: FormikProps<Types.IActivityItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;

            const courseLocation = allSelectOptions.find((option) => option.value == props.values.lecture_location_id);

            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div ref={this.componentRef}></div>
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <i className="material-icons mr-2">add_circle_outline</i>
                          <span>{T.t('gen_update_course')}</span>
                        </h5>
                        <button
                          id='close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                        {this.state.model.activity_no ?
                          <>
                            <button
                              style={{ minHeight: '30px' }}
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              onClick={this.onSectionIsRelated}
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">library_add</i> {T.t('gen_dublicate_section')}
                            </button>
                            {this.props.activity.activityInfo && this.props.activity.activityInfo.lesson_count && this.props.activity.activityInfo.lesson_count > 1 ?
                              <button
                                style={{ minHeight: '30px' }}
                                id='button_save'
                                type="button"
                                data-dismiss="modal"
                                onClick={this.onActivityIsRelated}
                                className="btn btn-green"
                                aria-label="alert-success"
                              >
                                <i className="material-icons mr-2">library_add</i> {T.t('gen_dublicate_activity')}
                              </button>
                              : null}
                          </>
                          :
                          null}
                      </div>
                      {this.state.model.activity_no ?
                        <div className="row mt-3 mb-2">
                          <div className="col-md-12 ml-3">
                            {T.t('gen_course_opened_id')}:
                            <button className="multiple-info-tag small-tag group-ellipsis ml-1">
                              {this.props.activity.activityInfo && this.props.activity.activityInfo.course_opened_id ? this.props.activity.activityInfo.course_opened_id : "-"}
                            </button>
                          </div>
                          <div className="col-md-12 ml-3">
                            {T.t('gen_course_opened_section')}:
                            <button className="multiple-info-tag small-tag group-ellipsis ml-1">
                              {this.props.activity.activityInfo && this.props.activity.activityInfo.course_opened_sections ? this.props.activity.activityInfo.course_opened_sections.join("; ") : "-"}
                            </button>
                          </div>
                        </div> : null}
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="course_code"
                              name="course_code"
                              value={values.course_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="course_code">{T.t("gen_code")}</label>
                            {errors && errors.course_code && props.submitCount > 0 && (
                              <div className="error">{T.t(errors && errors.course_code)}</div>
                            )}
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="course_name"
                              name="course_name"
                              value={values.course_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">{T.t('gen_name')}</label>
                            {errors && errors.course_name && props.submitCount > 0 && (
                              <div className="error">{T.t(errors && errors.course_name)}</div>
                            )}
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="student_count"
                              name="student_count"
                              value={values.student_count}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                            {errors && errors.student_count && props.submitCount > 0 && (
                              <div className="error">{T.t(errors && errors.student_count)}</div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_course_type')}</label>
                                <Select
                                  id='select_course_type'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={CourseTypes(T)}
                                  placeholder={T.t('gen_select_course_type')}
                                  value={
                                    values.course_type != undefined && values.course_type != null
                                      ? CourseTypes(T).find((option) => option.value == values.course_type)
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('course_types', option);
                                    props.setFieldValue('course_type', option && option.value);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_course_type')}
                                />
                              </div>
                              {errors && errors.course_type && props.submitCount > 0 && (
                                <div className="error">{errors && errors.course_type}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_activity_type')}</label>
                                <Select
                                  id='select_activity'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={activityTypes}
                                  placeholder={T.t('gen_select_activity_type')}
                                  value={
                                    values.activity_type != undefined && values.activity_type != null
                                      ? activityTypes.find((option) => option.value == values.activity_type)
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('activity_type', option && option.value);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_activity_type')}
                                />
                              </div>
                              {errors && errors.activity_type && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.activity_type)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_course_language')}</label>
                                <Select
                                  id='select_course_language'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.course_languages_active
                                      ? this.props.selectOptions.course_languages_active
                                      : []
                                  }
                                  placeholder={T.t('gen_select_course_language')}
                                  value={props.values.course_language_select}
                                  onChange={(option: any) => {
                                    props.setFieldValue('course_language', option && option.value);
                                    props.setFieldValue('course_language_select', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_course_language')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_course_environment')}</label>
                                <Select
                                  id='select_course_environment'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={CourseEnvironments(T)}
                                  placeholder={T.t('gen_select_course_environment')}
                                  value={
                                    values.course_environment != undefined
                                      ? CourseEnvironments(T).find(
                                        (option) => option.value === values.course_environment
                                      )
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('course_environment', option && option.value)
                                    props.setFieldValue('course_environment_select', option);
                                    if (option && option.value == CourseEnvironments(T)[1].value) {
                                      values.lecture_location_id = ActivityTermLectureLocations(T)[2].value;
                                      values.lecture_location = ActivityTermLectureLocations(T)[2];
                                    } else {
                                      if (values.lecture_location_id == ActivityTermLectureLocations(T)[2].value) {
                                        values.lecture_location_id = undefined;
                                        values.lecture_location = undefined;
                                      }
                                    }
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_course_environment')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="lesson_count"
                              name="lesson_count"
                              value={values.lesson_count}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="lesson_count">{T.t('gen_lesson_count')}</label>
                            {errors && errors.lesson_count && props.submitCount > 0 && (
                              <div className="error">{T.t(errors && errors.lesson_count)}</div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_week')}</label>
                                <Select
                                  id='select_week'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={WeekSelectOptions}
                                  placeholder={T.t('gen_select_week')}
                                  value={weekOptionSelector ? props.values.weeks : weekOptions}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('weeks',
                                      list.map(item => item.value).includes(-1) ? Weeks : list
                                    );
                                    props.setFieldValue(
                                      'week',
                                      list.map(item => item.value).includes(-1) ? Weeks.map((item: any) => item.value).join(",") : list.map(item => item.value).join(",")
                                    );
                                    weekOptionSelector = true;
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_week')}
                                />
                              </div>
                              {errors && errors.week && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.week)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_education_type')}</label>
                                <Select
                                  id='select_education_type'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={EducationTypeSelectOptions(T)}
                                  placeholder={T.t('gen_select_education_type')}
                                  value={
                                    values.education_type != undefined && values.education_type != null
                                      ? T.t_options(EducationTypeSelectOptions(T)).find((option) => option.value == values.education_type)
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('education_type', option.value);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                />
                              </div>
                              {errors && errors.education_type && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.education_type)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_classroom_type')}</label>
                                <Select
                                  id='classroom_type'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.classroom_types_active
                                      ? this.props.selectOptions.classroom_types_active.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))
                                      : []
                                  }
                                  placeholder={T.t('gen_select_classroom_type')}
                                  value={props.values.classroom_type_select}
                                  onChange={(option: any) => {
                                    props.setFieldValue('classroom_type', option && option.value);
                                    props.setFieldValue('classroom_type_select', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_lecture_location')}</label>
                                <Select
                                  id='select_location'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={allSelectOptions}
                                  placeholder={T.t('gen_select_location')}
                                  value={
                                    props.values.lecture_location
                                      ? props.values.lecture_location
                                      : courseLocation
                                        ? courseLocation
                                        : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('lecture_location', option);
                                    props.setFieldValue('lecture_location_id', option && option.value);
                                    if (option.value == ActivityTermLectureLocations(T)[2].value) {
                                      values.course_environment = CourseEnvironments(T)[1].value;
                                      values.course_environment_select = CourseEnvironments(T)[1];
                                    } else {
                                      if (values.course_environment == CourseEnvironments(T)[1].value) {
                                        values.course_environment = undefined;
                                        values.course_environment_select = undefined;
                                      }
                                    }
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                              {errors && errors.lecture_location_id && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.lecture_location_id)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_instructors')}</label>
                                <Select
                                  id='select_instructor'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.instructors
                                      ? this.props.selectOptions.instructors
                                      : []
                                  }
                                  placeholder={T.t('gen_select_instructor')}
                                  value={props.values.instructors}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('instructors', list);
                                    props.setFieldValue(
                                      'instructor_ids',
                                      list.map((item) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                />
                              </div>
                              {errors && errors.instructor_ids && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.instructor_ids)}</div>
                              )}
                            </div>
                          </div>
                          <MultiSelectionTable
                            combined_values={combined_values_selected}
                            multiSelectionObjectFunction={this.multiSelecteds}
                          />
                        </div>
                        <div className="col-md-12 form-input form-group with-icon">
                          <textarea
                            name="description"
                            className="form-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows={2}
                            id="description"
                            value={values.description}
                            placeholder={T.t('gen_description')}
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="description" />
                          <ErrorMessage component="div" className="error" name="description" />
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_close'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              onClick={() => {
                                props.handleSubmit();
                                saveButtonClicked = true;
                                scrollToTop();
                              }}
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
        {this.state.dublicateActivityModalIsOpen ? (
          <CourseDublicationModal
            onPlanning={true}
            courseInfo={this.props.activity.activityInfo}
            modalIsOpen={this.state.dublicateActivityModalIsOpen}
            onClose={this.switchDublicateActivityModalStatus}
          />
        ) : null}
        {this.state.dublicateSectionModalIsOpen ? (
          <CourseSectionDublicationModal
            onPlanning={true}
            courseInfo={this.props.activity.activityInfo}
            sectionDublicationCount={this.state.sectionDublicationCount}
            modalIsOpen={this.state.dublicateSectionModalIsOpen}
            onClose={this.switchDublicateSectionModalStatus}
          />
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IActivityHoursFormProps
): Types.IActivityHoursFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IActivityHoursFormProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    is_related: store.state.activity_page && store.state.activity_page.is_related,
    term_id: store.state.term_id,
    results_term_week_dates: store.state && store.state.examPeriodModal && store.state.examPeriodModal.resultsTermWeekDates,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.select_options) {
    return (
      !!equal(
        prev.state.select_options && prev.state.select_options.activityPage,
        next.state.select_options && next.state.select_options.activityPage
      ) &&
      !!equal(
        prev.state.activity_page && prev.state.activity_page.is_related,
        next.state.activity_page && next.state.activity_page.is_related
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programs_related_faculty,
        next.state.select_options && next.state.select_options.programs_related_faculty
      ) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.resultsTermWeekDates,
        next.state.examPeriodModal && next.state.examPeriodModal.resultsTermWeekDates
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseHourForm);

export default container;
