import * as React from 'react';

import cn from "../ui/Tailwind";

interface WarningProps {
    show?: boolean
    children?: string
    classNames?: string
}

const Warning: React.FC<WarningProps> = ({ show, children, classNames }) => {
    return <div className={cn(
        "tw-text-yellow-800 tw-bg-yellow-500/50 tw-rounded-lg tw-w-full tw-p-2 tw-flex tw-justify-center tw-items-center",
        "tw-duration-150",
        { 'tw-opacity-100': show },
        { 'tw-opacity-0': !show },
        classNames)}>
        {children}
    </div>
}

export default Warning