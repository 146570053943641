import { put, takeEvery, all, take, select, cancel } from 'redux-saga/effects';
import * as actions from '../constants/all';
import { Log } from 'ng2-logger';
import PerculusApiClient from '../../services/perculus-api-client';
import * as Types from '../types';
import * as Actions from '../actions/general';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';
import AppFactory from '../../services/app-factory';
import MockAPIClient from '../../services/mock-api-client';

const T = Translator.create();
const L = Log.create('login-saga');
const AF = AppFactory.create();

function* setAuthToken(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.GetAuthToken(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status === 200) {
    if (content && content.access_token) {
      yield put(Actions.Notification('notification_user_validation_correct', 'gen_login'));
    } else {
      yield put(
        Actions.ShowModal({
          title: T.t("gen_login_failed"),
          body: T.t("gen_login_auth_error"),
          name: 'login_failed',
          icon: 'error_outline',
          iconColor: 'red'
        })
      );
    }
  } else {
    if (content && content.code) {
      yield put(
        Actions.ShowModal({
          title: T.t("gen_login_failed"),
          body: content.details,
          name: 'login_failed',
          icon: 'error_outline',
          iconColor: 'red'
        })
      );
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }

  yield put(Actions.ApiResponse(action, response, content));

  if (content && content.access_token) {
    MockAPI._accessToken = content.access_token;
    AF.setAppModuleReady(actions.gen.CORE_AUTH_USER, false);
    yield put(Actions.ApiRequest(actions.gen.CORE_AUTH_USER));
  }
}

function* sendForgotPassword(action: Types.IApiRequestAction): Generator<any, any, any> {
  const papi: PerculusApiClient = new PerculusApiClient();
  const response: Response = yield papi.GetResponse(papi.SendForgotPassword(action.body));
  let content: any = yield papi.GetContent(response);
  if (response && response.status === 200) {
    yield put(Actions.Navigation(GT.Route(Routes.LOGIN)));
    if (action.body.token) {
      yield put(
        Actions.ShowModal({
          title: T.t("gen_password_reset_success"),
          body: T.t("gen_password_reset_success_body"),
          name: 'login_pw_done',
          icon: 'done_outline',
          iconColor: '#39b27b'
        })
      );
    } else {
      yield put(
        Actions.ShowModal({
          title: T.t("gen_password_reset_request"),
          body: T.t("gen_password_reset_request_body"),
          name: 'login_forgot_pw',
          icon: 'done_outline',
          iconColor: '#39b27b'
        })
      );
    }
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }

  yield put(Actions.ApiResponse(action, response, content));
}

function* resetPassword(action: Types.IApiRequestAction): Generator<any, any, any> {
  const papi: PerculusApiClient = new PerculusApiClient();
  const response: Response = yield papi.GetResponse(papi.ResetPassword(action.body));
  let content: any = yield papi.GetContent(response);
  if (response && response.status === 200) {
    yield put(Actions.Navigation(GT.Route(Routes.LOGIN)));
    if (action.body.token) {
      yield put(
        Actions.ShowModal({
          title: T.t("gen_password_reset_success"),
          body: T.t("gen_password_reset_success_body"),
          name: 'login_pw_done',
          icon: 'done_outline',
          iconColor: '#39b27b'
        })
      );
    } else {
      yield put(
        Actions.ShowModal({
          title: T.t("gen_password_reset_success"),
          body: T.t("gen_password_reset_success_body"),
          name: 'login_pw_done',
          icon: 'done_outline',
          iconColor: '#39b27b'
        })
      );
    }
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }

  yield put(Actions.ApiResponse(action, response, content));
}

export function* getAuthToken() {
  yield takeEvery((action: any) => action.target === actions.login.LOGIN_AUTH_TOKEN, setAuthToken);
}

export function* getForgotPwResponse() {
  yield takeEvery((action: any) => action.target === actions.login.LOGIN_FORGOT_PASSWORD, sendForgotPassword);
}

export function* getResetPwResponse() {
  yield takeEvery((action: any) => action.target === actions.login.LOGIN_RESET_PASSWORD, resetPassword);
}

export default function* loginSagas() {
  yield all([getAuthToken(), getForgotPwResponse()]);
}
