import { Formik, FormikActions, FormikProps, ErrorMessage } from 'formik';
import { Log } from 'ng2-logger';
import React from 'react';
import * as Icons from 'react-icons/md';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ResetPasswordFormInitialValues } from '../../store/constants/setting-const';
import * as Types from '../../store/types';
import ResetPasswordFormValidation from './validations/reset-password-form-val';
import Spinner from '../../components/templates/spinner';
import Translator from '../../services/translate-factory';
const T = Translator.create();

const L = Log.create('ResetPasswordFrom');

class ResetPasswordFrom extends React.Component<Types.IResetPasswordFormProps, Types.IResetPasswordFormState> {
  state: Types.IResetPasswordFormState = {
    formIsOpen: false,
    model: ResetPasswordFormInitialValues
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  setClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  onFormSave = (model: Types.IResetPassword, FormActions: FormikActions<Types.IResetPassword>) => {

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      } else if (status === 200 || status === 204) {
        this.setClose();
      }
    };

    let resetPasswordModel: Types.IResetPasswordPost = {
      user_id: this.props.user_id,
      currentPassword: model.currentPassword,
      newPassword: model.newPassword,
      newPasswordConfirm: model.newPasswordConfirm
    };

    setTimeout(() => {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.setting.SETTING_RESET_PASSWORD,
          resetPasswordModel,
          'settings-reset-password-form-spin',
          resultCallback
        )
      );
      FormActions.setSubmitting(false);
    }, 500);
  };

  static getDerivedStateFromProps(props: Types.IResetPasswordFormProps, current_state: Types.IResetPasswordFormState) {
    if (current_state.formIsOpen !== props.formIsOpen) {
      current_state.formIsOpen = props.formIsOpen;
    }
    return current_state;
  }

  render() {
    return (
      <Modal modalClassName="modal-from-right" isOpen={this.state.formIsOpen}>
        <Spinner name="settings-reset-password-form-spin" />
        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          validationSchema={ResetPasswordFormValidation}
          onSubmit={this.onFormSave}
        >
          {(props: FormikProps<Types.IResetPassword>) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <Icons.MdEdit /> {T.t('gen_password_change')}
                  </h5>
                  <button id='button_close' type="button" className="close" onClick={this.setClose}>
                    <i className="material-icons">close</i>
                    <span>esc</span>
                  </button>
                </div>
                <div className="modal-body col-12 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <div className="form-input">
                          <input
                            id='current_password'
                            name="currentPassword"
                            type="password"
                            value={props.values.currentPassword}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>{T.t('gen_password_old')}</label>
                        </div>
                        <ErrorMessage component="div" className="error" name="currentPassword" />
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <div className="form-input">
                          <input
                            id="newPassword"
                            type="password"
                            value={props.values.newPassword}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>{T.t('gen_password_new')}</label>
                        </div>
                        <ErrorMessage component="div" className="error" name="newPassword" />
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <div className="form-input">
                          <input
                            id="newPasswordConfirm"
                            type="password"
                            value={props.values.newPasswordConfirm}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>{T.t('gen_password_new_confirm')}</label>
                        </div>
                        <ErrorMessage component="div" className="error" name="newPasswordConfirm" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-block">
                  <div className="row">
                    <div className="col-md">
                      <button id='cancel' type="button" className="btn btn-gray" onClick={this.setClose}>
                        {T.t('gen_cancel')}
                      </button>
                    </div>
                    <div className="col-md text-md-right">
                      <button id='save' type="submit" className="btn btn-green" onClick={() => props.handleSubmit()} disabled={props.isSubmitting}>
                        <i className="material-icons">done</i>&nbsp;{T.t('gen_save')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IResetPasswordFormProps): Types.IResetPasswordFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IResetPasswordFormProps = Object.assign({}, ownProps, {});
  return newProps;
};

const dispatchProps = (dispatch: any) => ({
  dispatch
});

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(ResetPasswordFrom);

export default container;
