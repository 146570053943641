import { put, takeEvery, all, take, select } from 'redux-saga/effects';
import * as actions from '../constants/all';
import { Log } from 'ng2-logger';
import AplanApiClient from '../../services/mock-api-client';
import * as Types from '../types';
import * as Actions from '../actions/general';
const L = Log.create('user-saga');

function* changePassword(action: Types.IApiRequestAction): Generator<any, any, any> {
  const aapi: AplanApiClient = new AplanApiClient();
  const response: Response = yield aapi.GetResponse(aapi.UserResetPassword(action.body));
  let content: any = yield aapi.GetContent(response);

  if (response && response.status === 200) {
    yield put(
      Actions.ShowModal({
        title: 'Şifre Değişikliği',
        body: 'Şifreniz Başarıyla Güncellenmiştir.',
        name: 'password_changed',
        icon: 'done',
        iconColor: 'green'
      })
    );
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSettings(action: Types.IApiRequestAction): Generator<any, any, any> {
  const AAPI: AplanApiClient = new AplanApiClient();
  const response: Response = yield AAPI.GetResponse(AAPI.GetSettings());
  let content: any = null;

  if (response && response.status === 200) {
    content = yield AAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getGeneralSettings(action: Types.IApiRequestAction): Generator<any, any, any> {
  const AAPI: AplanApiClient = new AplanApiClient();
  const response: Response = yield AAPI.GetResponse(AAPI.GetGeneralSettings());
  let content: any = null;

  if (response && response.status === 200) {
    content = yield AAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateSettings(action: Types.IApiRequestAction): Generator<any, any, any> {
  const AAPI: AplanApiClient = new AplanApiClient();
  const response: Response = yield AAPI.GetResponse(AAPI.UpdateSettings(action.body));
  let content: any = yield AAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_settings_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchGetSettings() {
  yield takeEvery((action: any) => action.target === actions.setting.GET_SETTINGS, getSettings);
}

export function* watchGetGeneralSettings() {
  yield takeEvery((action: any) => action.target === actions.setting.GET_GENERAL_SETTINGS, getGeneralSettings);
}

export function* watchUpdateSettings() {
  yield takeEvery((action: any) => action.target === actions.setting.UPDATE_SETTINGS, updateSettings);
}

export function* watchChangePassword() {
  yield takeEvery((action: any) => action.target === actions.setting.SETTING_RESET_PASSWORD, changePassword);
}
