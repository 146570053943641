import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, BuildingSearchInitialValues } from '../constants/building-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';
import { Log } from 'ng2-logger';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const Logger = Log.create('BuildingSaga');

function* getBuildings(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BuildingSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getBuildingData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BuildingGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateBuilding(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BuildingUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_building_updated', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createBuilding(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BuildingCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_building_created', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteBuilding(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BuildingDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_building_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.BUILDING_LIST_SEARCH, BuildingSearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else if (response && response.status === 409) {
    yield put(Actions.Notification('gen_relation_occurred_classrooms', 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getBuildingSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.BuildingGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createBuildingExcelBulk(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.BuildingCreateExcelBulk(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getBuildingDistances(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BuildingGetDistances());
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateBuildingDistances(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BuildingPostDistances(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_building_distances_saved', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getBuildingImport(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BuildingImportSearch(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getBuildingImportSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.BuildingImportGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createBuildingImport(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BuildingCreateImport(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_building_created', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* downloadAllData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DownloadAllData(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_download_request', 'gen_success'));
    content = yield MockAPI.GetContent(response);
  } else if (response && response.status === 409) {
    yield put(
      Actions.ShowModal({
        body: content,
        title: T.t('gen_pending'),
        name: 'gen_pending',
        icon: 'warning',
        iconColor: 'red'
      })
    );
  } else {
    yield put(Actions.Notification('notification_error_preparing_data', 'gen_warning', 'danger'));
  }
}

export function* watchBuildingSearch() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_LIST_SEARCH, getBuildings);
}

export function* watchBuildingGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_GET_SELECT_OPTIONS, getBuildingSelectOptions);
}

export function* watchBuildingGetById() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_GET_BY_ID, getBuildingData);
}

export function* watchBuildingUpdate() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_UPDATE, updateBuilding);
}

export function* watchBuildingCreate() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_CREATE, createBuilding);
}
export function* watchBuildingDelete() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_DELETE, deleteBuilding);
}
export function* watchCreateBuildingExcelBulk() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_CREATE_EXCEL_BULK, createBuildingExcelBulk);
}

export function* watchBuildingGetDistances() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_GET_DISTANCES, getBuildingDistances);
}

export function* watchBuildingUpdateDistances() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_UPDATE_DISTANCES, updateBuildingDistances);
}

export function* watchBuildingImportSearch() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_IMPORT_LIST_SEARCH, getBuildingImport);
}

export function* watchBuildingImportGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_IMPORT_GET_SELECT_OPTIONS, getBuildingImportSelectOptions);
}

export function* watchBuildingImportCreate() {
  yield takeEvery((action: any) => action.target === constants.BUILDING_IMPORT_CREATE, createBuildingImport);
}

export function* watchDownloadAllData() {
  yield takeEvery((action: any) => action.target === constants.DOWNLOAD_ALL_DATA, downloadAllData);
}
