import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';
import { specialCharacterRegex } from '../../../util/regex';

export const InstructorFormValidation = (T: Translator) => Yup.object().shape(instructorFormFields(T));

export const instructorFormFields = (T: Translator) => ({
  instructor_code: Yup.string().required(T.t('gen_cannot_leave_empty'))
    .matches(specialCharacterRegex,
      T.t('gen_cannot_enter_special_characters'),
    ),
  name: Yup.string().required(T.t('gen_cannot_leave_empty'))
    .matches(specialCharacterRegex,
      T.t('gen_cannot_enter_special_characters'),
    ),
  email: Yup.string().required(T.t('gen_cannot_leave_empty')).email(T.t('gen_invalid_email')),
  daily_max_class: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  max_invigilation_duty: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  max_time_bw_consecutive_invigilations: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  daily_max_duty: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  invigilator_level: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  title: Yup.string().required(T.t('gen_cannot_leave_empty')),
  staff_type: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  faculty_of_duty_id: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  program_of_duty_id: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  mobile: Yup.string().max(20, T.t('gen_can_be_up_to_n').replace('{0}', '20')),
})