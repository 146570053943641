import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Log } from 'ng2-logger';
import * as Types from '../../../store/types';
import * as Actions from '../../../store/actions/general';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { IInvigilationCountsProps } from '../../../store/types';
import { ISolutionModelInitialValues } from '../../../store/constants/solution-const';
import Select from 'react-select';
import * as Constants from '../../../store/constants/all';
import * as Icons from 'react-icons/md';
import Translator from '../../../services/translate-factory';
import Announce from '../../../components/templates/announce';

const T = Translator.create();
const Logger = Log.create('Invigilation Numbers');

function getInitialState(): any {
  let averagesRowInit = {
    fe_exams: -1,
    se_exams: -1,
    weekend_exams: -1,
    fe_invigilations: -1,
    se_invigilations: -1,
    weekend_invigilations: -1,
    fe_total: -1,
    se_total: -1,
    weekend_total: -1,
    all_exams: -1,
    all_invigilations: -1,
    all_total: -1
  };

  return Object.assign(
    {},
    {
      model: ISolutionModelInitialValues,
      result: {},
      averagesRow: averagesRowInit,
      selectOptions: [],
      selected_Options: [],
      filterResult: [],
      isFilterActive: false,
      orderBy: '',
      filters: {
        invigilators_count: []
      }
    }
  );
}
class InvigilationNumbers extends Component<IInvigilationCountsProps, any> {
  state: any = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    this.setState({ model: this.props.model! });

    if (this.props.solutionInvigilatorCounts) {
      this.createTable();
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  convertToSelectType = (arr: any): Types.ISelectOption[] =>
    arr.map((piece: { item1: string; item2: number }) => ({ label: piece.item1, value: piece.item2 }));


  createTable = () => {
    let filteredModel: any = this.props.solutionInvigilatorCounts;
    let tableValues: any = filteredModel;

    if (this.state.filters.invigilators_count.length) {
      const invigilators_count: any[] = this.state.filters.invigilators_count;
      const filteredTableValues = tableValues.filter((item: any) =>
        invigilators_count.some((instructorCode) => item.instructor_code === instructorCode)
      );

      tableValues = filteredTableValues;
    }
    else {
      tableValues = [];
    }

    if (this.props.solutionInvigilatorCounts && this.props.solutionInvigilatorCounts.length > 0 && this.state.averagesRow && this.state.averagesRow.all_total == -1) {
      this.state.averagesRow = this.props.solutionInvigilatorCounts.pop();
    }

    this.setState({
      ...this.state,
      filteredModel,
      tableValues
    });
  };

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;
    if (props && props.model && props.solutionInvigilatorCounts) {
      hasNewState = true;
      state.model = props.model;
      state.model.filteredModel = props.solutionInvigilatorCounts;
    }

    if (props && props.selectOptions) {
      hasNewState = true;
      const invigilators_count = props.selectOptions.invigilators_count.map((item: any) => ({ label: item.label, value: item.value }));

      state.selectOptions = {
        ...state.filters,
        invigilators_count,
      };
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  sortingIcon = (sortKey: string) => {
    if (this.state.orderBy.includes(sortKey)) {
      if (this.state.orderBy.includes('asc')) return <Icons.MdKeyboardArrowDown />;
      else return <Icons.MdKeyboardArrowUp />;
    }
    return <Icons.MdSort />;
  };

  sort = (sortKey: string) => {
    let invigilatorCounts = this.state.tableValues;

    let orderBy = this.state.orderBy;
    if (orderBy.includes(sortKey)) {
      if (orderBy.includes('desc')) {
        orderBy = sortKey + '_asc';
        invigilatorCounts.sort((a: any, b: any) => (a[sortKey] > b[sortKey] ? 1 : b[sortKey] > a[sortKey] ? -1 : 0));
      } else {
        orderBy = sortKey + '_desc';
        invigilatorCounts.sort((a: any, b: any) => (a[sortKey] > b[sortKey] ? -1 : b[sortKey] > a[sortKey] ? 1 : 0));
      }
    } else {
      orderBy = sortKey + '_desc';
      invigilatorCounts.sort((a: any, b: any) => (a[sortKey] > b[sortKey] ? -1 : b[sortKey] > a[sortKey] ? 1 : 0));
    }
    this.setState({
      tableValues: invigilatorCounts,
      orderBy
    });
  };

  render() {
    const averagesRow = this.state.averagesRow;
    const invigilatorCounts = this.state.tableValues;
    const selectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...this.state.selectOptions.invigilators_count];
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6">
            <div className="add-custom-tag">
              <div className="react-select-container">
                <label>{T.t('gen_instructor_filters')}</label>
                <Select
                  className="react-select"
                  isMulti={true}
                  closeMenuOnSelect={false}
                  options={selectOptions}
                  placeholder={T.t('gen_select_instructor')}
                  onChange={(options: any) => {
                    if (options && options.length && options.map((option: Types.ISelectOption) => option.value).includes(-1)) {
                      options = this.state.selectOptions.invigilators_count;
                    }

                    const ids = options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                    if (this.props.solutionInvigilatorCounts && this.props.solutionInvigilatorCounts.length > 0) {
                      this.setState(
                        {
                          ...this.state,
                          filters: {
                            ...this.state.filters,
                            invigilators_count: ids
                          },
                          selected_Options: {
                            ...this.state.selected_Options,
                            invigilators_count: options
                          }
                        },
                        () => this.createTable()
                      );
                    }
                  }}
                  value={this.state.selected_Options.invigilators_count}
                  noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="white-container">
          <div className="row">
            <div className="col-12">
              {this.props.solutionInvigilatorCounts ? (
                <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        #{' '}
                      </th>

                      <th scope="col" className="text-center" onClick={() => this.sort('instructor_name')}>
                        {T.t('gen_instructor')}&nbsp;{this.sortingIcon('instructor_name')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('fe_exams')}>
                        {T.t('gen_exams')} ({T.t('gen_short_formal_education')})&nbsp;{this.sortingIcon('fe_exams')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('fe_invigilations')}>
                        {T.t('gen_invigilatings')} ({T.t('gen_short_formal_education')})&nbsp;{this.sortingIcon('fe_invigilations')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('fe_total')}>
                        {T.t('gen_exams')} + {T.t('gen_invigilatings')} ({T.t('gen_short_formal_education')})&nbsp;{this.sortingIcon('fe_total')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('se_exams')}>
                        {T.t('gen_exams')} ({T.t('gen_short_secondary_education')})&nbsp;{this.sortingIcon('se_exams')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('se_invigilations')}>
                        {T.t('gen_invigilatings')} ({T.t('gen_short_secondary_education')})&nbsp;{this.sortingIcon('se_invigilations')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('se_total')}>
                        {T.t('gen_exams')} + {T.t('gen_invigilatings')} ({T.t('gen_short_secondary_education')})&nbsp;{this.sortingIcon('se_total')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('weekend_exams')}>
                        {T.t('gen_exams')} ({T.t('gen_weekend')})&nbsp;{this.sortingIcon('weekend_exams')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('weekend_invigilations')}>
                        {T.t('gen_invigilatings')} ({T.t('gen_weekend')})&nbsp;{this.sortingIcon('weekend_invigilations')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('weekend_total')}>
                        {T.t('gen_exams')} + {T.t('gen_invigilatings')} ({T.t('gen_weekend')})&nbsp;{this.sortingIcon('weekend_total')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('all_exams')}>
                        {T.t('gen_total')} {T.t('gen_exams')}&nbsp;{this.sortingIcon('all_exams')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('all_invigilations')}>
                        {T.t('gen_total')} {T.t('gen_invigilatings')}&nbsp;{this.sortingIcon('all_invigilations')}
                      </th>
                      <th scope="col" className="text-center" onClick={() => this.sort('all_total')}>
                        {T.t('gen_exams')} + {T.t('gen_invigilatings')} ( {T.t('gen_total')})&nbsp;
                        {this.sortingIcon('all_total')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invigilatorCounts && invigilatorCounts.length
                      ? invigilatorCounts.map((item: any, index: number) => (
                        <tr key={'solution-number-' + item.instructor_code} data-title={item.instructor_name}>
                          <td scope="row" data-label={T.t('gen_door_number')} style={{ textAlign: 'center' }}>
                            {index + 1}
                          </td>
                          <td scope="row" data-label="instructor" style={{ textAlign: 'center' }}>
                            {item.instructor_title + ' ' + item.instructor_name}
                          </td>
                          <td scope="row" data-label="exams (FE)" style={{ textAlign: 'center' }}>
                            {item.fe_exams}
                          </td>
                          <td scope="row" data-label="invigilatings (FE)" style={{ textAlign: 'center' }}>
                            {item.fe_invigilations}
                          </td>
                          <td scope="row" data-label="exam + invigilating (FE)" style={{ textAlign: 'center' }}>
                            {item.fe_total}
                          </td>
                          <td scope="row" data-label="exams (SE)" style={{ textAlign: 'center' }}>
                            {item.se_exams}
                          </td>
                          <td scope="row" data-label="invigilatings (SE)" style={{ textAlign: 'center' }}>
                            {item.se_invigilations}
                          </td>
                          <td scope="row" data-label="exam + invigilating (SE)" style={{ textAlign: 'center' }}>
                            {item.se_total}
                          </td>
                          <td scope="row" data-label="exams (Weekend)" style={{ textAlign: 'center' }}>
                            {item.weekend_exams}
                          </td>
                          <td scope="row" data-label="invigilatings (Weekend)" style={{ textAlign: 'center' }}>
                            {item.weekend_invigilations}
                          </td>
                          <td scope="row" data-label="exam + invigilating (Weekend)" style={{ textAlign: 'center' }}>
                            {item.weekend_total}
                          </td>
                          <td scope="row" data-label="total exams" style={{ textAlign: 'center' }}>
                            {item.all_exams}
                          </td>
                          <td scope="row" data-label="total invigilatings" style={{ textAlign: 'center' }}>
                            {item.all_invigilations}
                          </td>
                          <td scope="row" data-label="exam + invigilating (total)" style={{ textAlign: 'center' }}>
                            {item.all_total}
                          </td>
                        </tr>
                      ))
                      : null}
                    {invigilatorCounts && invigilatorCounts.length && averagesRow && averagesRow.all_total !== -1
                      ?
                      <tr key={'solution-number-total'} data-title={'totals'}>
                        <td
                          scope="row"
                          data-label={T.t('gen_door_number')}
                          style={{ textAlign: 'center', fontWeight: 700 }}
                        >
                          -
                        </td>
                        <td scope="row" data-label="instructor" style={{ textAlign: 'center', fontWeight: 700 }}>
                          {T.t('gen_average')}
                        </td>
                        <td scope="row" data-label="exams (FE)" style={{ textAlign: 'center', fontWeight: 700 }}>
                          {averagesRow.fe_exams}
                        </td>
                        <td scope="row" data-label="invigilatings (FE)" style={{ textAlign: 'center', fontWeight: 700 }}>
                          {averagesRow.fe_invigilations}
                        </td>
                        <td
                          scope="row"
                          data-label="exam + invigilating (FE)"
                          style={{ textAlign: 'center', fontWeight: 700 }}
                        >
                          {averagesRow.fe_total}
                        </td>
                        <td scope="row" data-label="exams (SE)" style={{ textAlign: 'center', fontWeight: 700 }}>
                          {averagesRow.se_exams}
                        </td>
                        <td scope="row" data-label="invigilatings (SE)" style={{ textAlign: 'center', fontWeight: 700 }}>
                          {averagesRow.se_invigilations}
                        </td>
                        <td
                          scope="row"
                          data-label="exam + invigilating (SE)"
                          style={{ textAlign: 'center', fontWeight: 700 }}
                        >
                          {averagesRow.se_total}
                        </td>
                        <td scope="row" data-label="exams (Weekend)" style={{ textAlign: 'center', fontWeight: 700 }}>
                          {averagesRow.weekend_exams}
                        </td>
                        <td scope="row" data-label="invigilatings (Weekend)" style={{ textAlign: 'center', fontWeight: 700 }}>
                          {averagesRow.weekend_invigilations}
                        </td>
                        <td
                          scope="row"
                          data-label="exam + invigilating (Weekend)"
                          style={{ textAlign: 'center', fontWeight: 700 }}
                        >
                          {averagesRow.weekend_total}
                        </td>
                        <td scope="row" data-label="total exams" style={{ textAlign: 'center', fontWeight: 700 }}>
                          {averagesRow.all_exams}
                        </td>
                        <td scope="row" data-label="total invigilatings" style={{ textAlign: 'center', fontWeight: 700 }}>
                          {averagesRow.all_invigilations}
                        </td>
                        <td
                          scope="row"
                          data-label="exam + invigilating (total)"
                          style={{ textAlign: 'center', fontWeight: 700 }}
                        >
                          {averagesRow.all_total}
                        </td>
                      </tr>
                      : null}
                  </tbody>
                </table>
              ) :
                <div className='d-flex align-items-center justify-content-center'>
                  <Announce title={T.t('gen_no_records_found')} />
                </div>
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,

  ownProps: IInvigilationCountsProps
): IInvigilationCountsProps => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: IInvigilationCountsProps = Object.assign({}, ownProps, {
    model: store.state.solution_page && store.state.solution_page.solution,
    selectOptions: store.state.select_options && store.state.select_options.solutionCoursePage,
    term_id: store.state.term_id
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.solution_page) {
    return (
      !!equal(
        prev.state.term_id,
        next.state.term_id
      ) &&
      !!equal(
        prev.state.solution_page && prev.state.solution_page.solution,
        next.state.solution_page && next.state.solution_page.solution
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.solutionCoursePage,
        next.state.select_options && next.state.select_options.solutionCoursePage
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(InvigilationNumbers);

export default container;
