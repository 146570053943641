import Translator from '../../services/translate-factory';

export const constants = {
  PLANNING_HOURS_CLASSROOM_CREATE_EXCEL_BULK: 'PLANNING_HOURS_CLASSROOM:CREATE_EXCEL_BULK',
  PLANNING_HOURS_INSTRUCTOR_CREATE_EXCEL_BULK: 'PLANNING_HOURS_INSTRUCTOR:CREATE_EXCEL_BULK',
  PLANNING_HOURS_COURSE_CREATE_EXCEL_BULK: 'PLANNING_HOURS_COURSE:CREATE_EXCEL_BULK',
};

export const ImportingKeySelectOptions = (T: Translator) => [
  { label: T.t('gen_hours_availabilities'), value: 'AVAILABLE' },
  { label: T.t('gen_hours_non_availabilities'), value: 'NON-AVAILABLE' }
];