import * as Types from '../types';
import * as Consts from '../constants/all';
import Spinner from '../../components/templates/spinner';

import { Log } from 'ng2-logger';
const L = Log.create('SpinnerMiddleware');
const spinner: Spinner = Spinner.getInstance();

const SpinnerMiddleware = (store: any) => (next: any) => (action: Types.IAction) => {
  let result = next(action);
  if (action.type === Consts.gen.CORE_API_REQUEST) {
    const request: Types.IApiRequestAction = action as Types.IApiRequestAction;
    if (request && request.spin) {
      setTimeout(() => spinner.show(request.spin), 0);
    }
  } else {
    const response: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (response && response.request && response.request.spin) {
      setTimeout(() => spinner.hide(response.request.spin), 0);
    }
  }
  return result;
};

export default SpinnerMiddleware;
