import React from 'react';
import { INotificationAction, IAlertBoxState } from '../../store/types';
import { Log } from 'ng2-logger';
import { Alert } from 'reactstrap';
import Translator from '../../services/translate-factory';
import * as Constants from '../../store/constants/all';
const T = Translator.create();
const L = Log.create('AlertBox');
let _instance: AlertBox;
class AlertBox extends React.Component<any, IAlertBoxState> {
  _timer: any;

  state: IAlertBoxState = {
    alerts: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  constructor(props: any) {
    super(props);
    if (_instance) {
      this.state = _instance.state;
      this.setCleanerTimer();
    }
    _instance = this;
  }

  static getInstance = (): AlertBox => {
    return _instance;
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    if (this._timer) {
      clearInterval(this._timer);
    }
  }
  setMessage = (action: INotificationAction) => {
    action.timestamp = Date.now();
    this.state.alerts.push(action);
    this.setCleanerTimer();
    this.forceUpdate();
  };

  setCleanerTimer = () => {
    const alertCleaner = () => {
      if (this.state.alerts.length > 0) {
        this.state.alerts
          .filter(a => a.visible)
          .forEach(a => {
            if (a.timestamp) {
              a.visible = Date.now() - a.timestamp < (a.timeout || 3000);
            } else {
              a.visible = false;
            }
          });
        this.state.alerts = this.state.alerts.filter(a => a.visible);
        this.forceUpdate();
      } else if (this._timer) {
        clearInterval(this._timer);
        this._timer = null;
      }
    };

    if (!this._timer) {
      this._timer = setInterval(alertCleaner, 1000);
    }
  };

  render() {
    const boxes = this.state.alerts.map(action => {
      return (
        <Alert key={'a-' + action.timestamp} color={action.color} isOpen={action.visible}>
          <div className="media" onClick={action.onClick}>
            <i className="material-icons">{action.color == 'warning' || action.color == 'danger' ? 'error_outline' : 'done'}</i>
            <div className="ml-3 media-body">
              <span>{T.t(action.title)}</span>
              <br />
              {T.t(action.text)}
            </div>
            {/* 
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={action.onClick}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            */}
          </div>
        </Alert>
      );
    });
    return (
      <div className="alert-container" style={{ position: 'fixed' }}>
        {boxes}
      </div>
    );
  }
}

export default AlertBox;
