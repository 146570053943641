import * as React from 'react';
import { FaPlus } from 'react-icons/fa';
import { MdEdit, MdLocationOn, MdLocationOff, MdDelete, MdSave, MdClose, MdMyLocation } from "react-icons/md";
import * as Types from '../../../store/types';
import { Button, } from 'antd';
import { BuildingRoom, MarkerNamed, } from '../tools/interfaces';
import cn, { flexCenter } from '../../../components/ui/Tailwind';
import Translator from '../../../services/translate-factory';
import AppFactory from '../../../services/app-factory';
import { UserRole } from '../../../store/constants/enums';

const AF = AppFactory.create()

const T = Translator.create();

export type MarkerRow = BuildingRoom | Types.IBuildingItem

interface ActionButtonsProps {
    row: MarkerRow;
    selectedRow: MarkerRow | undefined;
    setStateSelected: Function;
    editObj: MarkerRow | undefined;
    setEditObj: Function;
    form: any;
    isEmptyPhoto: boolean;
    setShowMarkers: Function;
    handleDelete: (item: Types.IBuildingItem | BuildingRoom) => void
    handleEditSave: (item: Types.IBuildingItem | BuildingRoom) => void
}

function getAllMarkers(list: Types.IBuildingItem[] | BuildingRoom[]) {
    return list.map(item => {
        if (item.marker) {
            return {
                ...item.marker,
                name: item.name
            };
        }
    }).filter(marker => marker !== undefined) as MarkerNamed[];
}

const isAdmin = () => {
    return AF.inRole([UserRole.SYSTEM])
}

const ActionButtons = ({ handleDelete, handleEditSave, row, setStateSelected, selectedRow, editObj, setEditObj, form, setShowMarkers, isEmptyPhoto }: ActionButtonsProps) => {
    const handleLocationClick = () => {
        if (selectedRow !== row) {
            setStateSelected(row);
        } else {
            setStateSelected(undefined);
            if (form && "rooms" in form) {
                setShowMarkers(getAllMarkers(form.rooms));
            }
        }
    };

    const renderLocationButton = () => (
        <Button
            onClick={handleLocationClick}
            disabled={isEmptyPhoto || !(row.marker) || !!editObj}
            type="default"
            shape="circle"
            title={row.name}
            className={getLocationClassName()}
            icon={getLocationIcon()}
        />
    );

    const getLocationIcon = () => {

        if (!selectedRow) {
            return <MdLocationOn />
        }

        return selectedRow === row ? <MdMyLocation /> : <MdLocationOff />
    }
    const getLocationClassName = () => (
        selectedRow === row ? 'tw-text-green-500 tw-border-green-500' : ''
    );

    const getClassName = () => (
        !(isEmptyPhoto || isDisabled()) && (row.marker ? 'hover:tw-text-yellow-500 hover:tw-border-yellow-500' : 'hover:tw-text-green-500 hover:tw-border-green-500') || ""
    );

    const getButtonType = () => (
        editObj === row ? "dashed" : "default"
    );

    const getTitle = () => (
        editObj !== row ? T.t("gen_edit") : T.t("gen_cancel")
    );

    const getIcon = () => (
        row.marker ? (editObj === row ? <MdClose /> : <MdEdit />) : (editObj === row ? <MdClose /> : <FaPlus />)
    );

    const isDisabled = () => (
        editObj && editObj !== row
    );

    const getEditClassname = () => {
        return editObj === row ? "tw-text-green-500 tw-border-green-500 hover:tw-opacity-60" : "tw-hidden"
    }

    const renderAdminButtons = () => (
        <>
            <Button
                className={getClassName()}
                type={getButtonType()}
                shape="circle"
                title={getTitle()}
                icon={getIcon()}
                onClick={() => setEditObj(editObj !== row ? row : undefined)}
                disabled={isEmptyPhoto || isDisabled()}
            />
            <Button
                danger
                disabled={isEmptyPhoto || (!(row.marker) || isDisabled())}
                type="default"
                shape="circle"
                title={T.t("gen_delete")}
                onClick={() => handleDelete(row)}
                icon={<MdDelete />}
            />
            <Button
                className={getEditClassname()}
                type="default"
                shape="circle"
                title={T.t("gen_save")}
                onClick={() => handleEditSave(editObj || row)}
                icon={<MdSave />}
                disabled={isEmptyPhoto}
            />
        </>
    );


    return (
        <div className={cn(flexCenter, 'tw-gap-1')}>
            {renderLocationButton()}
            {isAdmin() && renderAdminButtons()}
        </div>
    );
};

export default ActionButtons;
