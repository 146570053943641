import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment, { duration } from 'moment';
import { ISolutionGridInitialValues, formatsValues, examMessagesValues, ExamSolutionSearchInitialValues } from '../../../store/constants/solution-const';
import { Log } from 'ng2-logger';
import * as Types from '../../../store/types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ISolutionGridState } from '../../../store/types';
import Select from 'react-select';
import * as Actions from '../../../store/actions/general';
import DisplayEventModal from './display-event-modal';
import * as Constants from '../../../store/constants/all';
import { constants } from '../../../store/constants/solution-const';
import Translator from '../../../services/translate-factory';
import { jsPDF } from 'jspdf';
import h2c from "html2canvas";
import SolutionCourseAddModal from './solution-course-add-modal';
import SolutionBatchChangeExamDatesModal from './solution-batch-change-exam-dates-modal';
import ReportsDownloadModal from '../../planning/reports-download-modal';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import Announcement from '../../../assets//images/announcement.svg';
import { UncontrolledTooltip } from 'reactstrap';
import { SectionTypes } from '../../../store/constants/enums';
import { getLocalizedDateLocalizer } from '../../../util/language';

const T = Translator.create();
const Logger = Log.create('SolutionGrid');

let solutionStudents: Array<any> = [];
let classroomsFilteredModel: Array<any> = [];
let courseIds: Array<number> = new Array<number>();

function getInitialState(): Types.ISolutionGridState {
  return Object.assign({}, ISolutionGridInitialValues);
}

class SolutionGrid extends Component<any, ISolutionGridState> {
  state: Types.ISolutionGridState = getInitialState();
  localizer = getLocalizedDateLocalizer(T);

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
        this.state.filters = Object.assign({}, ExamSolutionSearchInitialValues);
        this.localizer = getLocalizedDateLocalizer(T);
        this.resetScheduleProps();
        this.resetFormikForm();
        this.setState(this.state);
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };


  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    this.getSelectOptions();
    this.getProgramsByFacultiesAtSolution([]);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchExamSolution() {
    this.state.filters.solution_id = this.props.solutionId;
    this.state.filters.term_id = this.props.term_id;

    if (this.state.filters.term_id === -1) {
      this.state.filters.term_id = this.props.termId === undefined ? -1 : this.props.termId;
    }

    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.SOLUTION_LIST_SEARCH, this.state.filters, 'solution-schedule-spinner')
    );
  }

  getSelectOptions() {
    let term_id = this.props.termId === undefined ? this.props.term_id : this.props.termId;
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_COURSE_SELECT_OPTIONS, term_id, 'course-list-spin'));
  }

  getProgramsByFacultiesAtSolution = (facultyIds: any) => {
    let model = {
      faculty_ids: facultyIds,
      term_id: this.state.termId! > 0 && this.state.termId != undefined ? this.state.termId! : this.props.termId
    };

    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_GET_PROGRAMS_BY_FACULTIES, model, 'solution-list-spin'));
  }

  resetScheduleProps = () => {
    let model: Types.IFilterSolutionModel = {
      term_id: this.props.examDates.term_id,
      active_tab: '0',
      page: 1,
      total: -1,
      size: 10,
      solution_id: this.props.solutionId
    };

    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.SOLUTION_GET_BY_TERM, model, 'solution-schedule-spinner')
    );
    this.getSelectOptions();
    this.getProgramsByFacultiesAtSolution([]);

    let searchModel: Types.IFilterExamSolution = {
      term_id: this.props.term_id,
      solution_id: this.props.solutionId,
      courses: [],
      course_ids: courseIds,
      instructors: [],
      faculties: [],
      programs: [],
      grades: [],
      sections: [],
      invigilators: [],
      classrooms: [],
      instructor_ids: [],
      faculty_ids: [],
      program_ids: [],
      grade_ids: [],
      section_ids: [],
      invigilator_ids: [],
      classroom_ids: [],
      student_number: '',
      unique_number: '',
      dates: [],
      date_ids: []
    };

    if (searchModel.course_ids.length != 0) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.solution.SOLUTION_LIST_SEARCH, searchModel, 'solution-schedule-spinner')
      );
    }
  }

  switchSolutionCourseAddModalStatus = () => {
    this.setState({
      ...this.state,
      courseAddModalIsOpen: !this.state.courseAddModalIsOpen
    });

    if (this.state.courseAddModalIsOpen === false) {
      this.resetScheduleProps();
    }
  };

  switchSolutionBatchChangeExamDatesModalStatus = () => {
    this.setState({
      ...this.state,
      batchChangeExamDatesModalIsOpen: !this.state.batchChangeExamDatesModalIsOpen
    });

    if (this.state.batchChangeExamDatesModalIsOpen === false) {
      this.resetScheduleProps();
    }
  };

  resultSearch(
    course_ids: Array<number>
  ) {
    courseIds = course_ids;
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;
    if (props && props.examDates) {
      hasNewState = true;
      const startDate = moment(props.examDates.start_date).toDate();
      const endDate = moment(props.examDates.end_date).toDate();
      const startHour = moment(props.examDates.start_hour, 'HH:mm').format('H');
      const endHour = moment(props.examDates.end_hour, 'HH:mm').format('H');
      const min = parseInt(startHour, 10);
      const min_minute = parseInt(props.examDates.start_hour ? props.examDates.start_hour.split(':')[1] : '', 10);
      const max = parseInt(endHour, 10);
      const max_minute = parseInt(props.examDates.end_hour ? props.examDates.end_hour.split(':')[1] : '', 10);

      const duration_hour = moment(props.examDates.slot_duration, 'HH:mm').format('H');
      const duration_mins = moment(props.examDates.slot_duration, 'HH:mm').format('m');

      const duration_in_minutes = parseInt(duration_hour, 10) * 60 + parseInt(duration_mins, 10);
      const step = 15;
      const slotDuration = Math.floor(duration_in_minutes / step);

      const isScheduleCreated = props.examDates.start_date && props.examDates.start_date;

      state.scheduleData.startDate = startDate;
      state.scheduleData.min = min;
      state.scheduleData.max = max;
      state.scheduleData.max_minute = max_minute;
      state.scheduleData.min_minute = min_minute;
      state.scheduleData.slotDuration = slotDuration;
      state.scheduleData.isScheduleCreated = isScheduleCreated;

      state.minDateAddModal = moment(startDate).format('YYYY-DD-MM') + ' ' + props.examDates.start_hour;
      const maxHour = moment(max - Number(duration_hour), 'H').format('HH:mm');
      state.maxDateAddModal = moment(endDate).format('YYYY-DD-MM') + ' ' + maxHour;
      state.examDates = props.examDates;

      if (props.settings != undefined) state.settings = props.settings;
    }

    let term_id: number = props.examDates != undefined ? props.examDates.term_id : state.termId;

    if (props.model && props.model.createDate) {
      state.termId = term_id;
      state.dataModel = props.model;
      state.initialModel = props.model.solutionCourses;
    }

    let slot_duration = props.examDates != undefined ? (props.examDates.slot_duration ? props.examDates.slot_duration.split(':') : '') : (state.examDates && state.examDates.slot_duration ? state.examDates.slot_duration.split(':') : '')

    if (state.initialModel) {
      state.resultModel = state.initialModel.map((exam: any) => {
        const slotDuration = slot_duration;
        const durationMinutes = slotDuration[0] * 60 + parseInt(slotDuration[1], 10);
        const startDate = moment(exam.startDate);
        const endDate = startDate.clone().add(durationMinutes * exam.examDuration, 'm');

        let solutionFaculties: any;
        if (state.dataModel.solutionFaculties) {
          solutionFaculties = state.dataModel.solutionFaculties.filter((item: any) =>
            exam.courseId === item.courseId
          );
        }

        let solutionPrograms: any;
        if (state.dataModel.solutionPrograms) {
          solutionPrograms = state.dataModel.solutionPrograms.filter((item: any) =>
            exam.courseId === item.courseId
          );
        }

        let solutionInstructors: any;
        if (state.dataModel.solutionInstructors) {
          solutionInstructors = state.dataModel.solutionInstructors.filter((item: any) =>
            exam.courseId === item.courseId
          );
        }

        let solutionClassrooms: any;
        let solutionInvigilators: any;
        if (state.dataModel.solutionClassrooms) {
          solutionClassrooms = state.dataModel.solutionClassrooms.filter((item: any) =>
            exam.courseId === item.courseId
          );

          if (state.filters.student_number != '' && state.filters.student_number != undefined) {
            solutionStudents = state.dataModel.solutionStudents.filter((item: any) => item.studentNumber == state.filters.student_number);

            if (solutionStudents.length) {
              classroomsFilteredModel = state.dataModel.solutionClassrooms.filter((item: any) =>
                solutionStudents.some((students) => item.classroomCode === students.classroomCode && item.courseId === students.courseId)
              );
            }

            if (classroomsFilteredModel.length) {
              let tempClassroomsFilteredModel = classroomsFilteredModel.filter((item: any) =>
                exam.courseId === item.courseId
              );
              if (tempClassroomsFilteredModel.length) solutionClassrooms = tempClassroomsFilteredModel;
            }
          } else {
            solutionStudents = [];
          }

          solutionInvigilators = solutionClassrooms.invigilators ? solutionClassrooms.filter((item: any) =>
            exam.courseId === item.courseId
          ).map((item: any) => item.invigilators) : null;
        }

        let solutionCampuses: any;
        if (state.dataModel.solutionCampuses) {
          solutionCampuses = state.dataModel.solutionCampuses.filter((item: any) =>
            exam.courseId === item.courseId
          );
        }

        let solutionBuildings: any;
        if (state.dataModel.solutionBuildings) {
          solutionBuildings = state.dataModel.solutionBuildings.filter((item: any) =>
            exam.courseId === item.courseId
          );
        }

        const examInfo = {
          date: moment(exam.startDate).format('DD MMMM YYYY dddd, HH:mm'),
          id: exam.courseId,
          view_id: exam.solutionCourseId,
          term_id: exam.termId,
          solution_id: props.solutionId,
          code: exam.courseCode,
          name: exam.courseName,
          campuses: solutionCampuses,
          buildings: solutionBuildings,
          faculties: solutionFaculties,
          programs: solutionPrograms,
          instructors: exam.instructorName,
          classrooms: solutionClassrooms,
          students: solutionStudents,
          invigilators: solutionInvigilators,
          studentCount: exam.studentCount,
          duration: durationMinutes * exam.examDuration,
          durationHour: exam.examDuration,
          sessionCount: exam.sessionCount,
          grade: exam.grade,
          branch: exam.branch,
          courseOpenedSection: exam.courseOpenedSection,
          examType: exam.examType,
          solutionPublishmentInfo: props.solutionPublishmentInfo
        };

        let calendar_classroom_display_type: number = props.settings != undefined ? props.settings.calendar_classroom_display_type :
          (state.setting != undefined ? state.settings.calendar_classroom_display_type : 2);

        let settings_solution_show_course_opened_sections: boolean = props.settings != undefined ? props.settings.solution_show_course_opened_sections :
          (state.setting != undefined ? state.settings.solution_show_course_opened_sections : true);

        let solutionStudent: any;
        let classroomStudent: any;
        if (state.filters.student_number != '' && state.filters.student_number != undefined) {
          solutionStudent = state.dataModel.solutionStudents.find((x: any) => x.courseId === exam.courseId);
          if (solutionStudent !== undefined) classroomStudent = solutionClassrooms.find((y: any) => y.classroomCode.replace(/\s/g, '') === solutionStudent.classroomCode.replace(/\s/g, ''));
        }

        let classroomsInvigilator: any;
        if (state.filters.invigilators && state.filters.invigilators.length > 0 && state.filters.invigilators.some((x: any) => examInfo.instructors.indexOf(x.label) === -1)) {
          classroomsInvigilator = solutionClassrooms.filter((item: any) =>
            item.invigilators.map(function (v: any) { return v.id; }).filter((id: any) => state.filters.invigilator_ids.includes(id)).length > 0
          );

          state.dataModel.solutionStudents.find((x: any) => x.courseId === exam.courseId);
          if (solutionStudent !== undefined) classroomStudent = solutionClassrooms.find((y: any) => y.classroomCode.replace(/\s/g, '') === solutionStudent.classroomCode.replace(/\s/g, ''));
        }

        return {
          id: exam.courseId,

          title:
            (exam.courseCode ? exam.courseCode : '-') + ' - ' +
            (exam.courseName ? exam.courseName : '-') + ' - ' +
            T.t('gen_grade') + ': ' + (exam.grade ? exam.grade : '-') + ' - ' +
            T.t('gen_section') + ': ' + ((settings_solution_show_course_opened_sections && exam.courseOpenedSection != undefined) ? exam.courseOpenedSection : (exam.branch ? exam.branch : '-')) + ' - ' +
            Array.from(new Set(solutionInstructors.map((ins: { title: string; instructorName: string; }) => (ins.title + ' ' + ins.instructorName)))) + ' - ' +
            T.t('gen_classroom') + ': ' + (examInfo.campuses.some((x: any) => x.campusCode == "Uzaktan Sınav") ? T.t('gen_remote_exam') :

              (state.filters.student_number && state.filters.student_number.length > 0 && classroomStudent !== undefined ?
                classroomStudent.classroomName + ' (' + T.t('gen_session') + ': ' + solutionStudent.examSession +
                (solutionStudent.sessionStartDate && solutionStudent.sessionStartDate.split('T').length === 2 && solutionStudent.sessionStartDate.split('T')[1].length >= 5 ?
                  ' - ' + T.t('gen_hour') + ': ' + solutionStudent.sessionStartDate.split('T')[1].substring(0, 5) :
                  '') + ')'
                :
                (state.filters.invigilators && state.filters.invigilators.length > 0 && classroomsInvigilator !== undefined && classroomsInvigilator.length > 0 ?
                  classroomsInvigilator.map((classroom: { session: string; classroomName: string; }) =>
                    (' ' + classroom.classroomName + ' (' + T.t('gen_session') + ': ' + classroom.session + ')'))
                  : Array.from(new Set((solutionClassrooms.map((classroom: { classroomName: string; }) => (classroom.classroomName)) ?
                    solutionClassrooms.map(
                      (classroom: { classroomName: string; classroomCode: string; }) =>
                      (calendar_classroom_display_type == 0 ?
                        ((classroom.classroomName + ' (' + classroom.classroomCode + ')')) :
                        (calendar_classroom_display_type == 1 ?
                          (classroom.classroomCode ? classroom.classroomCode : '-') :
                          (classroom.classroomName ? classroom.classroomName : '-'))
                      )
                    )
                    : '-')))
                )
              )
            ),
          monthTitle: (exam.courseCode ? exam.courseCode : '-') + ' - ' + (exam.courseName ? exam.courseName : '-'),
          start: startDate.toDate(),
          end: endDate.toDate(),
          desc: JSON.stringify(examInfo),
          type: exam.type ? exam.type : ''
        };
      });
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  printDocument() {
    const input = document.getElementById('printed-component');
    h2c(input!)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const pdf = new jsPDF("l", "mm", "a4");
        var doc = new jsPDF("l", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'PNG', 15, 3, 265, 200, '', 'MEDIUM', 0);
        pdf.save("Çozüm.pdf");
      })
      ;
  }

  showInfoModalExaminationSchedulePdf = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_examination_schedule_download_title'),
          body: T.t('gen_examination_schedule_download_body'),
          name: 'examination_schedule_download',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const model = {
              termId: this.props.examDates.term_id,
              sectionType: SectionTypes.Examination_Schedule,
              sectionId: this.props.solutionId
            };
            this.props.dispatch(Actions.ApiRequest(Constants.building.DOWNLOAD_ALL_DATA, model, ''));
          }
        })
      );
    }
  };

  switchReportsDownloadModal = () => {
    this.setState({
      ...this.state,
      reportsDownloadModalIsOpen: !this.state.reportsDownloadModalIsOpen
    });
  };

  switchEventModalStatus = () => {
    this.setState({
      ...this.state,
      eventModalIsOpen: !this.state.eventModalIsOpen
    })

    if (this.state.eventModalIsOpen === false) {
      let model: Types.IFilterSolutionModel = {
        term_id: this.props.examDates.term_id,
        active_tab: '0',
        page: 1,
        total: -1,
        size: 10,
        solution_id: this.props.solutionId
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.solution.SOLUTION_GET_BY_TERM, model, 'solution-schedule-spinner')
      );

      this.searchExamSolution();
    }
  };

  openLinkInNewTab(url: string) {
    var win = window.open(url, '_blank');
    win && win.focus();
  }

  showInfoModalInstructorExamList() {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_instructors_download_exam_list_title'),
        body: T.t('gen_continue'),
        name: 'instructor_exam_list_download',
        icon: 'info',
        iconColor: 'blue',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const model = {
            solutionId: this.props.solutionId
          };
          this.props.dispatch(
            Actions.ApiRequest(Constants.solution.INSTRUCTOR_DOWNLOAD_EXAM_LIST, model, '',
              (result: string, status: number) => {
                if (status === 200) {
                  this.openLinkInNewTab(result);
                }
              })
          );
        }
      })
    );
  };

  showInfoModalStudentExamList() {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_students_download_exam_list_title'),
        body: T.t('gen_continue'),
        name: 'student_exam_list_download',
        icon: 'info',
        iconColor: 'blue',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const model = {
            solutionId: this.props.solutionId,
            student_id: this.state.filters.student_number
          };
          this.props.dispatch(
            Actions.ApiRequest(Constants.solution.STUDENT_DOWNLOAD_EXAM_LIST, model, '',
              (result: string, status: number) => {
                if (status === 200) {
                  this.openLinkInNewTab(result);
                }
              })
          );
        }
      })
    );
  };

  showInfoModalSolutionRawFiles() {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_solution_download_raw_files_title'),
        body: T.t('gen_continue'),
        name: 'solution_download_raw_files',
        icon: 'info',
        iconColor: 'blue',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const model = {
            solutionId: this.props.solutionId
          };
          this.props.dispatch(
            Actions.ApiRequest(Constants.solution.SOLUTION_DOWNLOAD_RAW_FILES, model, '',
              (result: string, status: number) => {
                if (status === 200) {
                  this.openLinkInNewTab(result);
                }
              })
          );
        }
      })
    );
  };

  onFilterExamSolution(model: Types.IFilterExamSolution, FormActions: FormikActions<Types.IFilterExamSolution>) {
    if (this.state.isSearchRequest) {
      this.state.filters = model;
      this.setState(this.state);
      this.searchExamSolution();
      FormActions.setSubmitting(false);
      this.setState(
        {
          ...this.state,
          isSearchRequest: false
        }
      );
    }
  }

  onFormReset = () => {
    this.state.filters = Object.assign({}, ExamSolutionSearchInitialValues);
    this.setState(this.state);
    this.searchExamSolution();
  };

  filterOption = (option: Types.ISelectOption, inputValue: string) => {
    if (option.value === -1) {
      return true;
    } else if (inputValue) {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    } else {
      return true;
    }
  };

  onInputChange = (inputValue: string, action: any) => {
    if (action.action === "input-change") {
      this.state.courses_filtered = this.props.selectOptions && this.props.selectOptions.courses_exist.filter((item: any) =>
        item.label.toLowerCase().includes(inputValue.toLowerCase()) || item.value.toString().toLowerCase().includes(inputValue.toLowerCase()));
    }

    if (action.action === "set-value" && this.state.courses_filtered == undefined) {
      this.state.courses_filtered = this.props.selectOptions && this.props.selectOptions.courses_exist;
    }

    if (action.action === "menu-close" && this.state.courses_filtered != undefined) {
      this.state.courses_filtered = undefined;
    }
  };

  resetFormikForm() {
    if (this.state.formikRef && this.state.formikRef.current) {
      this.state.formikRef.current.resetForm();
    }
  }

  componentDidUpdate(prevProps: any) {

    if (prevProps.activeTab !== this.props.activeTab) {
      this.resetFormikForm()
    }
  }

  constructor(props: any) {
    super(props);
    this.state.formikRef = React.createRef();
  }

  render() {
    const courseOptions = this.props.selectOptions && this.props.selectOptions.courses_exist ? this.props.selectOptions.courses_exist : []
    const courseSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...courseOptions];

    return (
      <Formik
        initialValues={ExamSolutionSearchInitialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this.onFilterExamSolution(values, actions);
        }}
        onReset={this.onFormReset}
        ref={this.state.formikRef}
      >
        {(props: FormikProps<Types.IFilterExamSolution>) => {
          if (props.values.faculties.length == 0) {
            props.values.programs = [];
            props.values.program_ids = [];
          }
          if (props.values.programs.length == 0) {
            props.values.sections = [];
            props.values.section_ids = [];
            props.values.grades = [];
            props.values.grade_ids = [];
          }
          return (
            <form onSubmit={props.handleSubmit}>
              <div id="printed-component">
                <div className="row solution-row">
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_faculty')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={true}
                              options={
                                this.props.selectOptions && this.props.selectOptions.faculties
                                  ? this.props.selectOptions.faculties
                                  : []
                              }
                              placeholder={T.t('gen_select_faculty')}
                              value={props.values.faculties ? props.values.faculties : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('faculties', list);
                                props.setFieldValue(
                                  'faculty_ids',
                                  list.map((item) => item.value)
                                );
                                this.getProgramsByFacultiesAtSolution(list.map(item => item.value))
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS_EX_INVIGILATORS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            {props.values.faculty_ids == undefined || props.values.faculty_ids.length == 0 ? (
                              <><label id="select_faculty_filter_program">{T.t('gen_program')} <i className="material-icons">info_outlined</i></label>
                                <UncontrolledTooltip placement="bottom" target="select_faculty_filter_program">
                                  {T.t('gen_select_faculty_filter_program')}
                                </UncontrolledTooltip></>
                            ) : (<><label>{T.t('gen_program')}</label></>)}
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={true}
                              isDisabled={props.values.faculty_ids == undefined || props.values.faculty_ids.length == 0 ? true : false}
                              options={this.props.programs_related_faculty}
                              placeholder={T.t('gen_select_program')}
                              value={props.values.programs ? props.values.programs : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('programs', list);
                                props.setFieldValue(
                                  'program_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            {props.values.program_ids == undefined || props.values.program_ids.length == 0 ? (
                              <><label id="select_program_filter_grade">{T.t('gen_grade')} <i className="material-icons">info_outlined</i></label>
                                <UncontrolledTooltip placement="bottom" target="select_program_filter_grade">
                                  {T.t('gen_select_program_filter_grade')}
                                </UncontrolledTooltip></>
                            ) : (<><label>{T.t('gen_grade')}</label></>)}
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={true}
                              isDisabled={props.values.program_ids == undefined || props.values.program_ids.length == 0 ? true : false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.grades
                                  ? this.props.selectOptions.grades
                                  : []
                              }
                              placeholder={T.t('gen_select_grade')}
                              value={props.values.grades ? props.values.grades : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('grades', list);
                                props.setFieldValue(
                                  'grade_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3 ml-3 mr-3">
                          <div className="react-select-container">
                            {props.values.program_ids == undefined || props.values.program_ids.length == 0 ? (
                              <><label id="select_program_filter_branch">{T.t('gen_section')} <i className="material-icons">info_outlined</i></label>
                                <UncontrolledTooltip placement="bottom" target="select_program_filter_branch">
                                  {T.t('gen_select_program_filter_branch')}
                                </UncontrolledTooltip></>
                            ) : (<><label>{T.t('gen_section')}</label></>)}
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={true}
                              isDisabled={props.values.program_ids == undefined || props.values.program_ids.length == 0 ? true : false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.sections
                                  ? this.props.selectOptions.sections
                                  : []
                              }
                              placeholder={T.t('gen_select_branch')}
                              value={props.values.sections ? props.values.sections : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('sections', list);
                                props.setFieldValue(
                                  'section_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_branch')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_INSTRUCTORS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_instructor')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.instructors
                                  ? this.props.selectOptions.instructors
                                  : []
                              }
                              placeholder={T.t('gen_select_instructor')}
                              value={props.values.instructors ? props.values.instructors : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('instructors', list);
                                props.setFieldValue(
                                  'instructor_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_classroom')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.classrooms
                                  ? this.props.selectOptions.classrooms
                                  : []
                              }
                              placeholder={T.t('gen_select_classroom')}
                              value={props.values.classrooms ? props.values.classrooms : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('classrooms', list);
                                props.setFieldValue(
                                  'classroom_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_course')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={courseSelectOptions}
                              placeholder={T.t('gen_select_course')}
                              value={props.values.courses ? props.values.courses : null}
                              filterOption={this.filterOption}
                              onInputChange={this.onInputChange}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('courses', list.map(item => item.value).includes(-1) ? this.state.courses_filtered! : list);
                                props.setFieldValue(
                                  'course_ids',
                                  list.map(item => item.value).includes(-1) ? this.state.courses_filtered!.map((item: any) => item.value) : list.map(item => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_course')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_INVIGILATORS) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_invigilator')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.instructors
                                  ? this.props.selectOptions.instructors
                                  : []
                              }
                              placeholder={T.t('gen_select_invigilator')}
                              value={props.values.invigilators ? props.values.invigilators : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('invigilators', list);
                                props.setFieldValue(
                                  'invigilator_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES) && (
                      <div className="col-md-3 col-xs-12">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_date')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.dates
                                  ? this.props.selectOptions.dates
                                  : []
                              }
                              placeholder={T.t('gen_select_date')}
                              value={props.values.dates ? props.values.dates : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('dates', list);
                                props.setFieldValue(
                                  'date_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_no_options_available')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-3 col-xs-12 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="student_number"
                            name="student_number"
                            value={props.values.student_number}
                            onChange={props.handleChange}
                            type="text"
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="student_number"><h6>{T.t('gen_enter_student_number')}</h6></label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS
                  ) && (
                      <div className="col-md-3 col-xs-12 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="unique_number"
                            name="unique_number"
                            value={props.values.unique_number}
                            onChange={props.handleChange}
                            type="text"
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="unique_number"><h6>{T.t('gen_enter_unique_number')}</h6></label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES ||
                    this.props.activeTab === constants.SOLUTION_GET_BY_STUDENTS) && (
                      <div className="col-md-3 col-ml-3"></div>
                    )}
                  {(this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS) && (
                    <div className="col-md-6 col-ml-6"></div>
                  )}
                  {this.props.activeTab == constants.SOLUTION_GET_BY_PROGRAMS ? (<div className="col-md-3 col-ml-3"></div>) : null}
                  {solutionStudents.length && props.values.student_number != undefined && props.values.student_number != '' && props.values.student_number != null ? (
                    <div className="col-md-12">
                      <div
                        style={{ display: 'flex', alignItems: 'left', marginBottom: '-50px' }}
                        className="mb-1"
                      >
                        <span className="ml-3 mr-1 tag-sms"><h6>{solutionStudents[0].name}</h6></span>
                      </div>
                      <div
                        style={{ display: 'flex', alignItems: 'left', marginBottom: '-50px' }}
                      >
                        <span className="alert alert-warning h-75" role="alert" style={{ maxWidth: 'none', textAlign: 'center' }}>{T.t('notification_see_all_exams_program_classes')}</span>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-12 text-right">
                    <button
                      type="button"
                      className="btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                      onClick={() => this.resetFormikForm()}
                    >
                      {T.t('reset')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2 ml-3"
                      disabled={
                        (props.values.faculties != undefined && props.values.faculties.length != 0) ||
                          (props.values.programs != undefined && props.values.programs.length != 0) ||
                          (props.values.classrooms != undefined && props.values.classrooms.length != 0) ||
                          (props.values.courses != undefined && props.values.courses.length != 0) ||
                          (props.values.invigilators != undefined && props.values.invigilators.length != 0) ||
                          (props.values.instructors != undefined && props.values.instructors.length != 0) ||
                          (props.values.dates != undefined && props.values.dates.length != 0) ||
                          (props.values.student_number != undefined && props.values.student_number != '' && props.values.student_number != null) ||
                          (props.values.unique_number != undefined && props.values.unique_number != '' && props.values.unique_number != null)
                          ? false : true
                      }
                      onClick={() => {
                        props.handleSubmit();
                        this.setState(
                          {
                            ...this.state,
                            isSearchRequest: true
                          }
                        );
                      }}
                    >
                      <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                    </button>
                    <hr />
                  </div>
                  <div className="col-md-12">
                    {
                      this.props.user && this.props.user.role === 's' ? (
                        <div className="solution-add-button">
                          <button
                            id='button_solution_add'
                            className="category-tag-square tag-green solution-add-button"
                            style={{ color: '#fff' }}
                            onClick={() => {
                              if (this.props.solutionPublishmentInfo.isPublished) {
                                this.props.dispatch(
                                  Actions.ShowModal({
                                    title: T.t('gen_add_exam'),
                                    body: T.t("gen_solution_process_info"),
                                    name: 'add_exam',
                                    icon: 'error_outline',
                                    iconColor: 'red',
                                    cancel: T.t('gen_close')
                                  })
                                );
                              } else {
                                this.getSelectOptions();
                                this.setState({ courseAddModalIsOpen: !this.state.courseAddModalIsOpen });
                              }
                            }}>
                            <i className="material-icons mr-2">add</i>{T.t('gen_add_exam')}
                          </button>
                          <button
                            id='button_solution_batch_change_dates'
                            className="category-tag-square tag-green solution-add-button"
                            style={{ color: '#fff' }}
                            onClick={() => {
                              if (this.props.solutionPublishmentInfo.isPublished) {
                                this.props.dispatch(
                                  Actions.ShowModal({
                                    title: T.t('gen_batch_change_exam_dates'),
                                    body: T.t("gen_solution_process_info"),
                                    name: 'add_exam',
                                    icon: 'error_outline',
                                    iconColor: 'red',
                                    cancel: T.t('gen_close')
                                  })
                                );
                              } else {
                                this.setState({ batchChangeExamDatesModalIsOpen: !this.state.batchChangeExamDatesModalIsOpen });
                              }
                            }}>
                            <i className="material-icons mr-2">sync</i>{T.t('gen_batch_change_exam_dates')}
                          </button>
                        </div>
                      ) : null}
                    <div className="solution-button">
                      {this.props.user && this.props.user.role === 's' && this.props.user.name === 'system' ? (
                        <div className="add-custom-tag">
                          <button className="category-tag-square tag-blue" onClick={() => { this.showInfoModalSolutionRawFiles() }}>
                            <i className="material-icons mr-2">manage_search</i>
                            <span>{T.t('gen_solution_download_raw_files')}</span>
                          </button>
                        </div>
                      ) : null}
                      <div className="add-custom-tag">
                        <button className="category-tag-square tag-blue ml-1" onClick={this.printDocument}>
                          <i className="material-icons mr-2">picture_as_pdf</i>
                          {T.t('gen_print')}
                        </button>
                      </div>
                      {['s', 'a', 'b', 'e', 'd', 'p', 'r'].includes(this.props.user.role) ? (
                        <div className="add-custom-tag">
                          <button className="category-tag-square tag-blue ml-1" onClick={this.showInfoModalExaminationSchedulePdf}>
                            <i className="material-icons mr-2">picture_as_pdf</i>
                            {T.t('gen_examination_schedule_download')}
                          </button>
                        </div>
                      ) : null}
                      {['s', 'a', 'b'].includes(this.props.user.role) ? (
                        <div className="add-custom-tag">
                          <button
                            id='button_cloud_solution_download'
                            className="category-tag-square tag-blue ml-1"
                            onClick={this.switchReportsDownloadModal}
                          >
                            <i className="material-icons mr-2">cloud_download</i>
                            <span>{T.t('solution_download')}</span>
                          </button>
                        </div>
                      ) : null}
                      {['s', 'a', 'b', 'e', 'd', 'p', 'r'].includes(this.props.user.role) ? (
                        <div className="add-custom-tag">
                          <button className="category-tag-square tag-blue ml-1" onClick={() => {
                            if (this.props.solutionPublishmentInfo.isPublished) {
                              this.showInfoModalInstructorExamList();
                            } else {
                              this.props.dispatch(
                                Actions.ShowModal({
                                  title: T.t('gen_instructors_download_exam_list'),
                                  body: T.t("gen_solution_exam_list_info"),
                                  name: 'instructors_download_exam_list',
                                  icon: 'error_outline',
                                  iconColor: 'red',
                                  cancel: T.t('gen_close')
                                })
                              );
                            }
                          }}>
                            <i className="material-icons mr-2">cloud_download</i>
                            <span>{T.t('gen_instructors_download_exam_list')}</span>
                          </button>
                        </div>
                      ) : null}
                      {solutionStudents.length && props.values.student_number != undefined && props.values.student_number != '' && props.values.student_number != null ? (
                        <div className="add-custom-tag">
                          <button className="category-tag-square tag-blue ml-1" onClick={() => {
                            if (this.props.solutionPublishmentInfo.isPublished || this.props.solutionPublishmentInfo.publicSolution[0].isPublished) {
                              this.showInfoModalStudentExamList();
                            } else {
                              this.props.dispatch(
                                Actions.ShowModal({
                                  title: T.t('gen_students_download_exam_list'),
                                  body: T.t("gen_solution_exam_list_info"),
                                  name: 'students_download_exam_list',
                                  icon: 'error_outline',
                                  iconColor: 'red',
                                  cancel: T.t('gen_close')
                                })
                              );
                            }
                          }}>
                            <i className="material-icons mr-2">cloud_download</i>
                            <span>{T.t('gen_students_download_exam_list')}</span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {
                    this.props.activeTab === constants.SOLUTION_GET_BY_INVIGILATORS && (
                      <div
                        style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                        className="col-md-9 mb-2"
                      >
                        <div style={{ height: '20px', width: '20px', backgroundColor: '#265985', borderRadius: '3px' }} />
                        <span className="ml-1 mr-2">{T.t('gen_own_exams')}</span>
                        <div style={{ height: '20px', width: '20px', backgroundColor: '#f64332', borderRadius: '3px' }} />
                        <span className="ml-1 mr-1">{T.t('gen_invigilatings')}</span>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} />
                      </div>
                    )
                  }
                </div >
                <div className="generic-wrapper" style={{ height: '100vh' }}>
                  {this.state.scheduleData.isScheduleCreated && this.state.resultModel && this.state.resultModel.length !== 0 ? (
                    <Calendar
                      events={this.state.resultModel}
                      min={new Date(0, 0, 0, this.state.scheduleData.min, this.state.scheduleData.min_minute, 0)}
                      max={new Date(0, 0, 0, this.state.scheduleData.max, this.state.scheduleData.max_minute, 0)}
                      formats={formatsValues(T)}
                      step={this.state.scheduleData.step}
                      timeslots={this.state.scheduleData.slotDuration}
                      defaultDate={this.state.scheduleData.startDate}
                      localizer={this.localizer}
                      defaultView={'agenda'}
                      messages={examMessagesValues(T)}
                      onSelectEvent={(event: Object, e: React.SyntheticEvent) => {
                        this.setState({
                          ...this.state,
                          selectedEvent: event,
                          eventModalIsOpen: true
                        });
                      }}
                      eventPropGetter={(event, start, end, isSelected) => {
                        const events = event.desc ? JSON.parse(event.desc) : '';
                        let newStyle = {
                          backgroundColor: /(^|\s)online(\s|$)/i.test(events.campuses) || events.campuses.some((x: any) => x.campusCode == "Uzaktan Sınav") ? '#006E49' : '#265985',
                          color: 'white',
                          borderRadius: '4px',
                          border: '1px solid #f64332'
                        };
                        if (
                          (this.props.activeTab === constants.SOLUTION_GET_BY_INVIGILATORS ||
                            this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
                            this.props.activeTab === constants.SOLUTION_GET_PUBLIC_TABLES) &&
                          this.state.filters && this.state.filters.invigilators &&
                          events && events.instructors &&
                          this.state.filters.invigilators.some(x => events.instructors.indexOf(x.label) === -1)
                        ) {
                          // newStyle.backgroundColor = '#f01500';
                          newStyle.backgroundColor = '#f64332';
                        }

                        return {
                          className: '',
                          style: newStyle
                        };
                      }}
                      components={{
                        month: {
                          event: (e: any) => {
                            try {
                              return <div className='tw-truncate'>{e.event.monthTitle}</div>
                            } catch (error) {
                              return <>-</>
                            }
                          }
                        }
                      }}
                    />
                  ) : (
                    <div>
                      <p style={{ textAlign: 'center' }}>
                        {T.t('gen_exam_solution_area_info')}
                      </p>
                      <br />
                      <img
                        id='instructor_hours_area_info'
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width: '40%'
                        }}
                        src={Announcement}
                      />
                    </div>
                  )}
                </div>
                {
                  this.state.eventModalIsOpen && (
                    <DisplayEventModal
                      event={this.state.selectedEvent}
                      modalIsOpen={this.state.eventModalIsOpen}
                      onClose={this.switchEventModalStatus}
                    />
                  )
                }
                {
                  this.state.courseAddModalIsOpen && (
                    <SolutionCourseAddModal
                      solutionId={this.props.solutionId}
                      modalIsOpen={this.state.courseAddModalIsOpen}
                      solutionCourseAddSelectOptions={this.props.solutionCoursePage}
                      onClose={this.switchSolutionCourseAddModalStatus}
                      resultSearchObjectFunction={this.resultSearch}
                    />
                  )
                }
                {
                  this.state.batchChangeExamDatesModalIsOpen && (
                    <SolutionBatchChangeExamDatesModal
                      solutionId={this.props.solutionId}
                      modalIsOpen={this.state.batchChangeExamDatesModalIsOpen}
                      onClose={this.switchSolutionBatchChangeExamDatesModalStatus}
                    />
                  )
                }
                {
                  this.state.reportsDownloadModalIsOpen && (
                    <ReportsDownloadModal
                      modalIsOpen={this.state.reportsDownloadModalIsOpen}
                      onClose={this.switchReportsDownloadModal}
                      solution_id={this.props.solutionId}
                      isPublished={this.props.solutionPublishmentInfo.isPublished}
                    />
                  )
                }
              </div >
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: any
): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    model: store.state.solution_page && store.state.solution_page.solution,
    filters: store.state.solution_page && store.state.solution_page.filters,
    examDates: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    selectOptions: store.state.select_options && store.state.select_options.solutionCoursePage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    user: store.state.user,
    term_id: store.state.term_id,
    settings: store.state.general_settings
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.solution_page) {
    return (
      !!equal(
        prev.state.solution_page && prev.state.solution_page,
        next.state.solution_page && next.state.solution_page
      ) &&
      !!equal(
        prev.state.solution_page && prev.state.solution_page.solution,
        next.state.solution_page && next.state.solution_page.solution
      ) &&
      !!equal(
        prev.state.solution_page && prev.state.solution_page.filters,
        next.state.solution_page && next.state.solution_page.filters
      ) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.exam_dates,
        next.state.examPeriodModal && next.state.examPeriodModal.exam_dates
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.solutionCoursePage,
        next.state.select_options && next.state.select_options.solutionCoursePage
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programs_related_faculty,
        next.state.select_options && next.state.select_options.programs_related_faculty
      ) &&
      !!equal(
        prev.state.general_settings && prev.state.general_settings,
        next.state.general_settings && next.state.general_settings
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionGrid);

export default container;
