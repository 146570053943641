import * as Types from '../types';
import * as actions from '../constants/all';
import * as GT from '../../tools/general-tools';
import { Log } from 'ng2-logger';
const L = Log.create('PlanningReducer');

const PlanningReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case actions.planning.PLANNING_HOURS_CLASSROOM_CREATE_EXCEL_BULK: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          planning_classroom_hours_import_result: result.content
        });
      }
      return state;
    }
    case actions.planning.PLANNING_HOURS_INSTRUCTOR_CREATE_EXCEL_BULK: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          planning_instructor_hours_import_result: result.content
        });
      }
      return state;
    }
    case actions.planning.PLANNING_HOURS_COURSE_CREATE_EXCEL_BULK: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          planning_course_hours_import_result: result.content
        });
      }
      return state;
    }
    default:
      return state;
  }
}

export default PlanningReducer;