import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';

export const CourseInstructorFormValidation = (T: Translator) => Yup.object().shape({
  instructor_code: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200'))
    .matches(/^[^#%$&½?@æéß~!|*]*$/,
      T.t('gen_cannot_enter_special_characters'),
    ),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200'))
    .matches(/^[^#%$&½?@æéß~!|*]*$/,
      T.t('gen_cannot_enter_special_characters'),
    ),
  email: Yup.string().required(T.t('gen_cannot_leave_empty')).email(T.t('gen_invalid_email')),
  daily_max_class: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  weekly_max_day: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  max_time_bw_consecutive_invigilations: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  daily_max_duty: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  title: Yup.string().required(T.t('gen_cannot_leave_empty')),
  staff_type: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  faculty_of_duty_id: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  program_of_duty_id: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  description: Yup.string().max(1000, T.t('gen_can_be_up_to_n').replace('{0}', '1000')),
  mobile: Yup.string().max(20, T.t('gen_can_be_up_to_n').replace('{0}', '20')),
});
