import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, EventPeriodSearchInitialValues } from '../constants/event-period-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';
import Translator from '../../services/translate-factory';
import AppFactory from '../../services/app-factory';

const T = Translator.create();
const AF = AppFactory.create();

function* getEventPeriods(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamPeriodSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getEventPeriodSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.EventPeriodGetSelectOptions(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* searchEventSolution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.EventSolutionSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getEventPeriodData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.EventPeriodGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateEventPeriod(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.EventPeriodUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_event_term_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createEventPeriod(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.EventPeriodCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_event_term_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteEventPeriod(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.EventPeriodDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_event_term_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.EVENT_PERIOD_LIST_SEARCH, EventPeriodSearchInitialValues));
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getEventPeriodList(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.GetTermList());
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createEvent(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.EventCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_event_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateEvent(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.EventUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_event_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteEvent(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.EventDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_event_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.EVENT_PERIOD_LIST_SEARCH, EventPeriodSearchInitialValues));
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* searchAvailableClassrooms(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.EventPeriodSearchAvailableClassrooms(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}


export function* watchEventPeriodSearch() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_LIST_SEARCH, getEventPeriods);
}

export function* watchEventPeriodSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_GET_SELECT_OPTIONS, getEventPeriodSelectOptions);
}

export function* watchEventPeriodSolutionSearch() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_SOLUTION_SEARCH, searchEventSolution);
}

export function* watchEventPeriodGetById() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_GET_BY_ID, getEventPeriodData);
}

export function* watchEventPeriodUpdate() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_UPDATE, updateEventPeriod);
}

export function* watchEventPeriodCreate() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_CREATE, createEventPeriod);
}

export function* watchEventPeriodDelete() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_DELETE, deleteEventPeriod);
}

export function* watchGetEventPeriodList() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_GET_LIST, getEventPeriodList);
}

export function* watchEventPeriodEventCreate() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_EVENT_CREATE, createEvent);
}

export function* watchEventPeriodEventUpdate() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_EVENT_UPDATE, updateEvent);
}

export function* watchEventPeriodEventDelete() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_EVENT_DELETE, deleteEvent);
}

export function* watchEventPeriodAvailableClassroomsSearch() {
  yield takeEvery((action: any) => action.target === constants.EVENT_PERIOD_SEARCH_AVAILABLE_CLASSROOMS, searchAvailableClassrooms);
}