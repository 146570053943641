import { Log } from 'ng2-logger';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as Actions from '../../store/actions/general';
import { routes as Routes } from '../../store/constants/routes';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';
import * as Constants from '../../store/constants/all';
import { SettingsEventModuleStatus } from '../../store/constants/setting-const';
const T = Translator.create();
const L = Log.create('TermSelectorModal');

class TermSelectorModal extends React.Component<Types.ITermSelectorModalProps, Types.ITermSelectorModalState> {
  state: Types.ITermSelectorModalState = {
    termSelectOptions: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.state.selectedTerm = this.state.termSelectOptions.find((t) => t.value == this.props.selectedTermId);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.messageForInstructors();
  }

  static getDerivedStateFromProps(props: Types.ITermSelectorModalProps, state: Types.ITermSelectorModalState) {
    let hasNewState: boolean = false;
    if (props.terms && props.terms.length > 0) {
      hasNewState = true;
      state.termSelectOptions = props.terms.map(
        (term): Types.ISelectOption => {
          return {
            label: `${term.year} ${GT.GetAcademicTermText(term.academic_term)} ${GT.GetTermTypeText(
              term.term_type
            )} / ${term.name} (${term.term_id})`,
            value: term.term_id
          };
        }
      );
    }
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onTermChange = (item: any) => {
    const term = this.props.terms && this.props.terms.find((term) => term.term_id === item.value);
    const term_type = term ? term.term_type : -1;
    this.state.selectedTerm = item;
    this.state.termType = term_type
    this.setState(this.state);
    if (this.props.onTermChange) {
      this.props.onTermChange(item.value, term_type);
    }
  };

  onAddCoursePeriod = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE_PERIODS) + '/create'));
  };

  onAddExamPeriod = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.EXAM_PERIODS) + '/create'));
  };

  onAddEventPeriod = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.EVENT_PERIODS) + '/create'));
  };

  messageForInstructors() {
    if (this.state.termType === 1 && this.props.user && (this.props.user.role === 'e' || this.props.user.role === 'p')) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_welcome'),
          body: (
            <div className="form-input form-group">
              <p className="text-center">{T.t('gen_dear')} {this.props.user.name + ' ' + this.props.user.surname}</p>
              <p>
                {T.t('gen_exam_term_hours_info')}
              </p>
            </div>
          ),
          name: 'welcome_message',
          icon: '',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    } else if (this.state.termType === 0 && this.props.user && (this.props.user.role === 'e' || this.props.user.role === 'p')) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_welcome'),
          body: (
            <div className="form-input form-group">
              <p className="text-center">{T.t('gen_dear')} {this.props.user.name + ' ' + this.props.user.surname}</p>
              <p>
                {T.t('gen_course_term_hours_info')}
              </p>
            </div>
          ),
          name: 'welcome_message',
          icon: '',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    }
  }

  render() {
    let options = this.state.termSelectOptions.sort((a, b) => (b.label.includes('Ders') ? 1 : 0) - (a.label.includes('Ders') ? 1 : 0) || b.value - a.value)
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-md-12 col-sm-12">
            <div className="mt-2 react-select-container">
              <Select
                className="react-select"
                placeholder={T.t('gen_select_term')}
                noOptionsMessage={(): string => T.t('gen_select_no_term')}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                options={options}
                value={this.state.selectedTerm}
                onChange={this.onTermChange}
              />
            </div>
          </div>
        </div>

        {this.props.user && this.props.user.role === 's' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
                , marginBottom: '0.5rem'
              }}
            >
              <button type="button" className="btn btn-gray" onClick={() => this.onAddCoursePeriod()}>
                {T.t('gen_add_course_term')}
              </button>
              <button type="button" className="ml-2 btn btn-gray" onClick={() => this.onAddExamPeriod()}>
                {T.t('gen_add_exam_term')}
              </button>
            </div>
            {
              this.props.general_settings && this.props.general_settings.event_module_status === SettingsEventModuleStatus.Active &&
              <button type="button" style={{ width: 'fit-content' }} className=" btn btn-gray" onClick={() => this.onAddEventPeriod()}>
                {T.t('gen_add_event_term')}
              </button>
            }
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IPageProps): Types.IPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.ITermSelectorModalProps = Object.assign({}, ownProps, {
    terms: store.state.term_list,
    selectedTermId: store.state.term_id,
    general_settings: store.state.general_settings,
    user: store.state.user,

  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(TermSelectorModal);

export default container;
