import * as Types from '../../store/types';

export const actions = {
  DASHBOARD_GET_SESSIONS: 'DASHBOARD:GET_SESSIONS',
  DASHBOARD_GET_METABASE_REPORT: 'DASHBOARD_GET_METABASE_REPORT',
};

export const constants = {
  //course planning tab
  DASHBOARD_COURSE_PLANNING_INSTRUCTOR_COURSE_LOADS: 'DASHBOARD_COURSE_PLANNING_INSTRUCTOR_COURSE_LOADS',
  DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_WEEKLY: 'DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_WEEKLY',
  DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_DAILY: 'DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_DAILY',
  // exam planning tab
  DASHBOARD_EXAM_PLANNING_INSTRUCTOR_COURSE_LOADS: 'DASHBOARD_EXAM_PLANNING_INSTRUCTOR_COURSE_LOADS',
  DASHBOARD_EXAM_PLANNING_CLASSROOM_USAGES_WEEKLY: 'DASHBOARD_EXAM_PLANNING_CLASSROOM_USAGES_WEEKLY',
  DASHBOARD_EXAM_PLANNING_CLASSROOM_USAGES_DAILY: 'DASHBOARD_EXAM_PLANNING_CLASSROOM_USAGES_DAILY'
};

export enum SettingsDashboardStatus {
  Blocked = -1,
  Passive = 0,
  ActiveForCourseTerms = 1,
  ActiveForExamTerms = 2,
  ActiveForAllTerms = 100,
}

//#region Dashboard Course Planning
export const DashboardCoursePlanningInitialValues: Types.IDashboardModalItem = {
  name: '',
  tabManager: [
    { key: constants.DASHBOARD_COURSE_PLANNING_INSTRUCTOR_COURSE_LOADS, value: true },
    { key: constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_WEEKLY, value: false },
    { key: constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_DAILY, value: false },
  ],
  model: {
    dashboard_id: -1,
    report_name: '',
    url: '',
    frame_width: "100%",
    frame_height: "100%"
  }
};

export const DashboardCoursePlanningTabs: any = {
  InstructorCourseLoads: 1,
  ClassroomUsagesWeekly: 2,
  ClassroomUsagesDaily: 3
}
//#endregion

//#region Dashboard Exam Planning
export const DashboardExamPlanningInitialValues: Types.IDashboardModalItem = {
  name: '',
  tabManager: [
    { key: constants.DASHBOARD_EXAM_PLANNING_INSTRUCTOR_COURSE_LOADS, value: true },
    { key: constants.DASHBOARD_EXAM_PLANNING_CLASSROOM_USAGES_WEEKLY, value: false },
    { key: constants.DASHBOARD_EXAM_PLANNING_CLASSROOM_USAGES_DAILY, value: false },
  ],
  model: {
    dashboard_id: -1,
    report_name: '',
    url: '',
    frame_width: "100%",
    frame_height: "100%"
  }
};

export const DashboardExamPlanningTabs: any = {
  InstructorCourseLoads: 1,
  ClassroomUsagesWeekly: 2,
  ClassroomUsagesDaily: 3
}
//#endregion
