import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, ClassroomSearchInitialValues } from '../constants/classroom-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';
import Translator from '../../services/translate-factory';

const T = Translator.create();

function* getClassrooms(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getClassroomData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateClassroom(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_classroom_updated', 'gen_success'));
  } else if (response && response.status === 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createClassroom(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_classroom_created', 'gen_success'));
  } else if (response && response.status === 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteClassroom(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_classroom_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.CLASSROOM_LIST_SEARCH, ClassroomSearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else if (response && response.status === 406) {
    yield put(Actions.Notification('notification_classrooms_exists_in_solutions', 'gen_error', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }

  yield put(Actions.ApiResponse(action, response, content));
}

function* getClassroomSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ClassroomGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getClassroomGetBuildingsByCampuses(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ClassroomGetBuildingsByCampuses(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createClassroomExcelBulk(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ClassroomCreateExcelBulk(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      content = yield MockAPI.GetContent(response);
    } else if (response && response.status === 406) {
      yield put(Actions.Notification('notification_classrooms_exists_in_solutions', 'gen_error', 'danger'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getClassroomChangeAllData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomChangeAllData(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getClassroomImport(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomImportSearch(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getClassroomImportSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ClassroomImportGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getClassroomImportGetBuildingsByCampuses(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ClassroomImportGetBuildingsByCampuses(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createClassroomImport(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomCreateImport(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_classroom_created', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchClassroomSearch() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_LIST_SEARCH, getClassrooms);
}

export function* watchClassroomGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_GET_SELECT_OPTIONS, getClassroomSelectOptions);
}

export function* watchClassroomGetBuildingsByCampuses() {
  yield takeEvery(
    (action: any) => action.target === constants.CLASSROOM_GET_BUILDINGS_BY_CAMPUSES,
    getClassroomGetBuildingsByCampuses
  );
}

export function* watchCreateClassroomExcelBulk() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_CREATE_EXCEL_BULK, createClassroomExcelBulk);
}

export function* watchClassroomGetById() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_GET_BY_ID, getClassroomData);
}

export function* watchClassroomUpdate() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_UPDATE, updateClassroom);
}

export function* watchClassroomCreate() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_CREATE, createClassroom);
}

export function* watchClassroomDelete() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_DELETE, deleteClassroom);
}

export function* watchClassroomChangeAllData() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_CHANGE_ALL_DATA, getClassroomChangeAllData);
}

export function* watchClassroomImportSearch() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_IMPORT_LIST_SEARCH, getClassroomImport);
}

export function* watchClassroomImportGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_IMPORT_GET_SELECT_OPTIONS, getClassroomImportSelectOptions);
}

export function* watchClassroomImportGetBuildingsByCampuses() {
  yield takeEvery(
    (action: any) => action.target === constants.CLASSROOM_IMPORT_GET_BUILDINGS_BY_CAMPUSES, getClassroomImportGetBuildingsByCampuses);
}

export function* watchClassroomImportCreate() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_IMPORT_CREATE, createClassroomImport);
}