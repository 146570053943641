import React from 'react';
import cn, { flexIC, flexRow, gap2 } from '../ui/Tailwind';

interface InputFieldProps extends React.ComponentProps<'input'> {
    id: string;
    label?: string;
    icon?: string | React.ReactNode; // İkon ismi
    subContent?: React.ReactNode,
    error?: string;
    showError?: boolean;
    allowNegative?: boolean;
    disableSpecialCharList?: Array<string>
}

const InputField: React.FC<InputFieldProps> = ({ id, disableSpecialCharList, showError, error, label, icon, subContent, allowNegative = false, ...otherProps }) => {

    const blockNumberKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'e' || e.key === '+' || (!allowNegative && e.key === '-') || (disableSpecialCharList && disableSpecialCharList.includes(e.key))) {
            e.preventDefault();
            return true
        }
        return false
    }

    return (
        <div className="form-input form-group date-picker mt-2">
            <input
                id={id}
                name={id}
                max={otherProps.type === 'number' ? 9999999 : undefined}
                {...otherProps}
                onKeyDown={otherProps.type === 'number' ? blockNumberKeyDown : undefined}
                value={otherProps.value || ""}
            />
            <span className="highlight" />
            <span className="bar" />
            {label && <label htmlFor={id} className="tw-text-sm">{label}</label>}
            {showError && error && <div className="error">{error}</div>}
            {icon && (typeof icon === 'string'
                ? <i className="material-icons">{icon}</i>
                : <div className={cn(flexRow, flexIC, gap2, "tw-absolute tw-top-7 tw-right-1 tw-transform -tw-translate-y-1/2 tw-pointer-events-none tw-text-gray-400")}>
                    {icon}
                </div>)
            }
            {subContent}
        </div>
    );
};

export default InputField;
