export default function cn(...classes: (string | null | { [key: string]: any } | undefined)[]) {
    return classes
        .flatMap(cls => {
            if (typeof cls === 'object' && cls !== null) {
                return Object.keys(cls).filter(key => cls[key]);
            }
            return cls;
        })
        .filter(Boolean)
        .join(' ');
}

export const flexCol = "tw-flex tw-flex-col"
export const flexRow = "tw-flex tw-flex-row"
export const flexCenter = "tw-flex tw-items-center tw-justify-center"
export const flexIC = "tw-items-center"
export const flexJC = "tw-justify-center"
export const flexJB = "tw-justify-between"
export const gap1 = "tw-gap-1"
export const gap2 = "tw-gap-2"
export const gap4 = "tw-gap-4"
export const gridCol1 = "tw-grid tw-grid-cols-1"
export const gridCol2 = "tw-grid tw-grid-cols-2"
export const gridCol3 = "tw-grid tw-grid-cols-3"