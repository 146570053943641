import * as Types from '../types';

import Translator from '../../services/translate-factory';

export const actions = {
  SETTING_RESET_PASSWORD: 'SETTING:RESET_PASSWORD',
  GET_SETTINGS: 'SETTING:GET_SETTINGS',
  GET_GENERAL_SETTINGS: 'SETTING:GET_GENERAL_SETTINGS',
  UPDATE_SETTINGS: 'SETTING:SET_SETTINGS'
};

export const EmailServiceSelectOptions = (T: Translator) => [
  { label: 'Gmail', value: 'Gmail' },
  { label: 'Mandrill', value: 'Mandrill' },
  { label: 'Outlook', value: 'Outlook' },
  { label: 'Yandex', value: 'Yandex' },
  { label: T.t('gen_other'), value: 'other' }
];

export const ClassroomInvigilatorCountSettings = (T: Translator) => [
  { label: T.t('settings_classroom_invigilator_count_settings_opt1'), value: 0 },
  { label: T.t('settings_classroom_invigilator_count_settings_opt2'), value: 1 },
  { label: T.t('settings_classroom_invigilator_count_settings_opt3'), value: 2 }
];

export const InvigilatorAssignTypes = (T: Translator) => [
  { label: T.t('settings_invigilator_assign_type_opt1'), value: 0 },
  { label: T.t('settings_invigilator_assign_type_opt2'), value: 1 }
];

export const TermExamTypes = (T: Translator) => [
  { label: T.t('gen_quiz'), value: 1 },
  { label: T.t('gen_midterm_exam'), value: 2 },
  { label: T.t('gen_midterm_makeup_exam'), value: 3 },
  { label: T.t('gen_final_exam'), value: 4 },
  { label: T.t('gen_final_makeup_exam'), value: 5 },
  { label: T.t('gen_practice_or_presentation'), value: 6 },
  { label: T.t('gen_makeup_exam'), value: 7 },
  { label: T.t('gen_single_course_exam'), value: 8 }
];

export const TermExamTypesLabel = (termExamTypeValue: number, T: Translator): string => {
  const termExamTypeObject = TermExamTypes(T).find(item => item.value === termExamTypeValue);

  return termExamTypeObject ? termExamTypeObject.label : '-';
}

export const InstructorTitles = (T: Translator) => [
  { label: T.t('gen_academic_exp'), value: 1 },
  { label: T.t('gen_coach'), value: 2 },
  { label: T.t('gen_ra'), value: 3 },
  { label: T.t('gen_ra_phd'), value: 4 },
  { label: T.t('gen_researcher'), value: 5 },
  { label: T.t('gen_marine_instructor'), value: 6 },
  { label: T.t('gen_language_instructor'), value: 7 },
  { label: T.t('gen_director'), value: 8 },
  { label: T.t('gen_assoc'), value: 9 },
  { label: T.t('gen_assoc_dr'), value: 10 },
  { label: T.t('gen_dr'), value: 11 },
  { label: T.t('gen_dr_instr'), value: 12 },
  { label: T.t('gen_education_consultant'), value: 13 },
  { label: T.t('gen_med_phys_spc'), value: 14 },
  { label: T.t('gen_lectr'), value: 15 },
  { label: T.t('gen_instr'), value: 16 },
  { label: T.t('gen_instr_dr'), value: 17 },
  { label: T.t('gen_instr_lecturer'), value: 18 },
  { label: T.t('gen_staff'), value: 19 },
  { label: T.t('gen_prof'), value: 20 },
  { label: T.t('gen_prof_dr'), value: 21 },
  { label: T.t('gen_project_adv'), value: 22 },
  { label: T.t('gen_responsible'), value: 23 },
  { label: T.t('gen_technical_exp'), value: 24 },
  { label: T.t('gen_thesis_adv'), value: 25 },
  { label: T.t('gen_exp'), value: 26 },
  { label: T.t('gen_exp_dr'), value: 27 },
  { label: T.t('gen_exp_psyc'), value: 28 }
];

export const InstructorTitlesLabel = (titleValue: number, T: Translator): string => {
  const titleObject = InstructorTitles(T).find(title => title.value === titleValue);

  return titleObject ? titleObject.label : "-";
}

export const ActivityTypes = (T: Translator) => [
  { label: T.t('gen_theoric'), value: 1 },
  { label: T.t('gen_application'), value: 2 },
  { label: T.t('gen_laboratory'), value: 3 },
  { label: T.t('gen_problem_solving'), value: 4 },
  { label: T.t('gen_internship'), value: 5 },
  { label: T.t('gen_practice'), value: 6 },
  { label: T.t('gen_break'), value: 7 },
  { label: T.t('gen_thesis'), value: 8 },
  { label: T.t('gen_seminar'), value: 9 },
  { label: T.t('gen_preparatory_class'), value: 10 },
  { label: T.t('gen_scientific_preparation'), value: 11 },
  { label: T.t('gen_thesis_research'), value: 12 },
  { label: T.t('gen_phd_qualification'), value: 13 },
  { label: T.t('gen_project'), value: 14 }
].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));;

export const ActivityTypesLabel = (activityTypeValue: number, T: Translator): string => {
  const activityTypeObject = ActivityTypes(T).find(activityType => activityType.value === activityTypeValue);

  return activityTypeObject ? activityTypeObject.label : '-';
}

export const ClassroomFeatures = (T: Translator) => [
  { label: T.t('gen_projector'), value: 1 },
  { label: T.t('gen_smartboard'), value: 2 },
  { label: T.t('gen_dvd'), value: 3 },
  { label: T.t('gen_sound_system'), value: 4 },
  { label: T.t('gen_computer'), value: 5 },
  { label: T.t('gen_internet_access'), value: 6 },
  { label: T.t('gen_fixed_curtain'), value: 7 },
  { label: T.t('gen_video'), value: 8 },
  { label: T.t('gen_air_conditioner'), value: 9 }
].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));;


export const ClassroomTypesLabel = (classroomTypeValue: Types.ISelectOption, T: Translator): string => {
  const classroomTypeObject = ClassroomTypes(T).find(classroomType => classroomType.value === classroomTypeValue.value);

  return classroomTypeObject ? classroomTypeObject.label : classroomTypeValue.label;
}

export const ClassroomTypes = (T: Translator) => [
  { label: T.t('gen_safety_room'), value: -56 },
  { label: T.t('gen_projection_room'), value: -55 },
  { label: T.t('gen_post_office'), value: -54 },
  { label: T.t('gen_playing_room'), value: -53 },
  { label: T.t('gen_coffee_bar'), value: -52 },
  { label: T.t('gen_therapy_room'), value: -51 },
  { label: T.t('gen_store'), value: -50 },
  { label: T.t('gen_bookshop'), value: -49 },
  { label: T.t('gen_library'), value: -48 },
  { label: T.t('gen_first_aid'), value: -47 },
  { label: T.t('gen_logistic_lab'), value: -46 },
  { label: T.t('gen_photo_lab'), value: -45 },
  { label: T.t('gen_medical_room'), value: -44 },
  { label: T.t('gen_music_studio'), value: -43 },
  { label: T.t('gen_recording_room'), value: -42 },
  { label: T.t('gen_relax_room'), value: -41 },
  { label: T.t('gen_sound_studio'), value: -40 },
  { label: T.t('gen_theater_hall'), value: -39 },
  { label: T.t('gen_image_processing_lab'), value: -38 },
  { label: T.t('gen_gym'), value: -37 },
  { label: T.t('gen_exam_hall'), value: -36 },
  { label: T.t('gen_cinema_hall'), value: -35 },
  { label: T.t('gen_computer_room'), value: -34 },
  { label: T.t('gen_club_room'), value: -33 },
  { label: T.t('gen_3D_lab'), value: -32 },
  { label: T.t('gen_workshop'), value: -31 },
  { label: T.t('gen_washing_room'), value: -30 },
  { label: T.t('gen_robotics_lab'), value: -29 },
  { label: T.t('gen_wc'), value: -28 },
  { label: T.t('gen_technical_room'), value: -27 },
  { label: T.t('gen_study_room'), value: -26 },
  { label: T.t('gen_studio_appartment'), value: -25 },
  { label: T.t('gen_storage'), value: -24 },
  { label: T.t('gen_stepped_room'), value: -23 },
  { label: T.t('gen_staircase'), value: -22 },
  { label: T.t('gen_sports_facility'), value: -21 },
  { label: T.t('gen_seminar_room'), value: -20 },
  { label: T.t('gen_printer_room'), value: -19 },
  { label: T.t('gen_other'), value: -18 },
  { label: T.t('gen_open_space'), value: -17 },
  { label: T.t('gen_office'), value: -16 },
  { label: T.t('gen_meeting_room'), value: -15 },
  { label: T.t('gen_lobby'), value: -14 },
  { label: T.t('gen_living_room'), value: -13 },
  { label: T.t('gen_kitchen'), value: -12 },
  { label: T.t('gen_flat'), value: -11 },
  { label: T.t('gen_elevator'), value: -10 },
  { label: T.t('gen_dining'), value: -9 },
  { label: T.t('gen_corridor'), value: -8 },
  { label: T.t('gen_common_room'), value: -7 },
  { label: T.t('gen_changing_room'), value: -6 },
  { label: T.t('gen_bedroom'), value: -5 },
  { label: T.t('gen_bathroom'), value: -4 },
  { label: T.t('gen_basement'), value: -3 },
  { label: T.t('gen_balcony'), value: -2 },
  { label: T.t('gen_attic'), value: -1 },
  { label: T.t('gen_only_classroom'), value: 1 },
  { label: T.t('gen_laboratory'), value: 2 },
]

export const ClassroomFeaturesLabel = (classroomFeatureValue: number, T: Translator): string => {
  const classroomFeatureObject = ClassroomFeatures(T).find(classroomFeature => classroomFeature.value === classroomFeatureValue);

  return classroomFeatureObject ? classroomFeatureObject.label : '-';
}

export const ResetPasswordFormInitialValues: Types.IResetPassword = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: ''
};

export const SettingsPageRoutes = {
  ChangePassword: '/password'
};

export enum SettingsEventModuleStatus {
  Blocked = -1,
  Passive = 0,
  Active = 1,
}
