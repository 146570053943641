import { constants } from '../constants/distributor-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const distributorReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.DISTRIBUTOR_GET_DISTRIBUTION_LIST: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          distributor_page: {
            ...state.distributor_page,
            results: result.content ? result.content.data : [],
            filters: result.content ? result.content.filters : [],
          }
        };
      }
    }
    case constants.GET_DISTRIBUTION_IN_PROGRESS_STATUS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content != null) {
        return {
          ...state,
          distribution_in_progress_status: result.content ? result.content : {}
        };
      }
    }
    case constants.DISTRIBUTOR_GET_STATUS_HISTORY: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          distributor_status_history_page: {
            ...state.distributor_status_history_page,
            result: result.content ? result.content : []
          }
        };
      }
    }
    case constants.DISTRIBUTOR_SOLUTION_CHANGE_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            distributorSolutionChangePage: result.content
          }
        });
      }
      return state;
    }
    case constants.DISTRIBUTOR_GET_INFO_SOLUTION_CHANGE: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          distributor_solution_change_page: {
            ...state.distributor_solution_change_page,
            results: result.content ? result.content : []
          }
        };
      }
    }
    case constants.DISTRIBUTOR_INVIGILATOR_CHANGE_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            distributorInvigilatorChangePage: result.content
          }
        });
      }
      return state;
    }
    case constants.DISTRIBUTOR_GET_INFO_INVIGILATOR_CHANGE: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          distributor_invigilator_change_page: {
            ...state.distributor_invigilator_change_page,
            results: result.content ? result.content : []
          }
        };
      }
    }
    case constants.DISTRIBUTOR_SINGLE_EXAM_CHANGE_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            distributorSingleExamChangePage: result.content
          }
        });
      }
      return state;
    }
    case constants.DISTRIBUTOR_GET_INFO_SINGLE_EXAM_CHANGE: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          distributor_single_exam_change_page: {
            ...state.distributor_single_exam_change_page,
            results: result.content ? result.content : []
          }
        };
      }
    }
    case constants.DISTRIBUTOR_SINGLE_COURSE_CHANGE_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            distributorSingleCourseChangePage: result.content
          }
        });
      }
      return state;
    }
    case constants.DISTRIBUTOR_GET_INFO_SINGLE_COURSE_CHANGE: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          distributor_single_course_change_page: {
            ...state.distributor_single_course_change_page,
            results: result.content ? result.content : []
          }
        };
      }
    }
    case constants.DISTRIBUTOR_CLASSROOM_CHANGE_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            distributorClassroomChangePage: result.content
          }
        });
      }
      return state;
    }
    case constants.DISTRIBUTOR_GET_INFO_CLASSROOM_CHANGE: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          distributor_classroom_change_page: {
            ...state.distributor_classroom_change_page,
            results: result.content ? result.content : []
          }
        };
      }
    }
    default: {
      return state;
    }
  }
};

export default distributorReducer;
