import * as Types from '../store/types';
import AppFactory from './app-factory';

import { Log } from 'ng2-logger';
const L = Log.create('PerculusApiClient');

declare var appSettings: Types.IAppSettings;

let _instance: PerculusApiClient;
export default class PerculusApiClient {
  _apiUrl: string = appSettings.API_URL || '';
  _accessToken: string = '';
  _appFactory: AppFactory | undefined;

  constructor(useSingleton: boolean = true) {
    if (_instance && useSingleton) {
      return _instance;
    } else {
      _instance = this;
    }
  }

  RequestAsync(method: string = 'GET', path: string, data: any = null, qs: any = null): Promise<Response> {
    if (qs) {
      let qs_params: Array<string> = [];
      for (let k in qs) {
        L.d('RequestAsync qs:' + k + ':' + qs[k]);
        if (qs.hasOwnProperty(k)) {
          qs_params.push(encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]));
        }
      }
      if (qs_params.length > 0) {
        path = path + (path.indexOf('?') > -1 ? '&' : '?') + qs_params.join('&');
      }
    }
    L.d(
      'RequestAsync method:' +
        method +
        ' path:' +
        path +
        ' hasToken:' +
        (this._accessToken ? true : false) +
        ' data: ' +
        (data ? JSON.stringify(data) : '') +
        ' qs: ' +
        (qs ? JSON.stringify(qs) : '')
    );
    return fetch(this._apiUrl + path, {
      method: method,
      body: data === null ? null : JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        // Authorization: 'Bearer ' + this._accessToken,
        // 'X-IAPI-Owner': window.location.origin
      }
    });
  }

  RequestXMLHttp(method: string = 'GET', path: string, insecure: boolean = false): XMLHttpRequest {
    L.d('RequestXMLHttp method: ' + method + ' path: ' + path + ' insecure: ' + insecure);
    var _xhr = new XMLHttpRequest();
    _xhr.open(method, this._apiUrl + path);
    if (!insecure) {
      _xhr.setRequestHeader('Authorization', 'Bearer ' + this._accessToken);
    }
    return _xhr;
  }

  async GetResponse(fn: Promise<Response>): Promise<Response> {
    return fn
      .then((response: Response) => {
        if (response.status === 401) {
          // Token not found or expired
          if (this._appFactory) {
            this._appFactory.logOff();
          }
        }
        if (response.status === 403) {
          // TODO: permission denied
        } else {
          //
        }
        return response;
      })
      .catch(function(e: any) {
        L.error('API Response Error : ', e);
        return e;
      });
  }

  async GetContent(response: Response): Promise<any> {
    return response
      .json()
      .then(function(content: any) {
        return content;
      })
      .catch(function(e: any) {
        return e;
      });
  }

  async GetWellKnownUnit(): Promise<Response> {
    return await this.RequestAsync('GET', '/integration/well-known-unit');
  }

  async GetAuthUser(): Promise<Response> {
    return await this.RequestAsync('GET', '/integration/authuser');
  }

  async GetAuthToken(model: Types.ILoginView): Promise<Response> {
    return await this.RequestAsync('POST', '/integration', model);
  }

  async SessionSearch(model: Types.IPagedQuery): Promise<Response> {
    return await this.RequestAsync('POST', '/session/search', model);
  }

  async GetSessionById(session_id: string, qs: any = null): Promise<Response> {
    return await this.RequestAsync('GET', '/session/' + session_id, null, qs);
  }

  async SessionCreate(model: Types.ISessionPost): Promise<Response> {
    return await this.RequestAsync('POST', '/session', model);
  }

  async SessionUpdate(model: Types.ISessionPost): Promise<Response> {
    return await this.RequestAsync('PUT', '/session/' + model.session_id, model);
  }

  async UserSearch(model: Types.IPagedQuery): Promise<Response> {
    return await this.RequestAsync('POST', '/user/search', model);
  }

  async UserCreate(model: Types.IUserPost): Promise<Response> {
    return await this.RequestAsync('POST', '/user', model);
  }

  async UserUpdate(model: Types.IUserPost): Promise<Response> {
    return await this.RequestAsync('PUT', '/user/' + model.user_id, model);
  }

  async UserGetById(userId: string, qs: any = null): Promise<Response> {
    return await this.RequestAsync('GET', '/user/' + userId, null, qs);
  }

  async UserDelete(userIds: string[]): Promise<Response> {
    return await this.RequestAsync('DELETE', '/user', userIds);
  }

  async GroupSearch(model: Types.IPagedQuery): Promise<Response> {
    return await this.RequestAsync('POST', '/group/search', model);
  }

  async GroupCreate(model: Types.IGroupPost): Promise<Response> {
    return await this.RequestAsync('POST', '/group', model);
  }

  async GroupUpdate(model: Types.IGroupPost): Promise<Response> {
    return await this.RequestAsync('PUT', '/group/' + model.group_id, model);
  }

  async GroupGetById(groupId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/group/' + groupId);
  }

  async GroupDelete(groupId: string): Promise<Response> {
    return await this.RequestAsync('DELETE', '/group', groupId);
  }

  async GroupGetSelectOptions(group_ids: string[] = []): Promise<Response> {
    return await this.RequestAsync('POST', '/group/select-options', group_ids);
  }

  async GroupGetSessionAttendeeSelectOptions(session_id: string): Promise<Response> {
    return await this.RequestAsync('GET', '/group/' + session_id + '/select-options');
  }

  async UserBrowse(model: Types.IUserBrowseQuery): Promise<Response> {
    return await this.RequestAsync('POST', '/user/browse', model);
  }

  async SessionSelectOptions(model: Types.IPagedQuery): Promise<Response> {
    return await this.RequestAsync('POST', '/session/select-options', model);
  }

  async SessionAttendeeSearch(model: Types.IAttendeePagedQuery): Promise<Response> {
    return await this.RequestAsync('POST', '/session/' + model.urlSessionId + '/attendees/search', model);
  }

  async AttendeeCreate(model: Types.IPostAttendees): Promise<Response> {
    return await this.RequestAsync('POST', '/session/' + model.session_id + '/attendees', model.items);
  }

  async SessionAttendeeDelete(model: Types.IAttendeeDelete): Promise<Response> {
    return await this.RequestAsync('DELETE', '/session/' + model.session_id + '/attendees', model.attendance_codes);
  }

  async SessionAttendeeUpdateRole(model: Types.IAttendeeUpdateRole): Promise<Response> {
    return await this.RequestAsync('PUT', '/session/attendees/role/' + model.role, model.attendance_codes);
  }

  async UserResetPassword(model: Types.IResetPasswordPost): Promise<Response> {
    return await this.RequestAsync('PUT', '/user/' + model.user_id + '/changePassword', model);
  }

  async UserCreateBulk(model: Types.IUserImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/user/import', model);
  }

  async DashboardGetSessions(): Promise<Response> {
    return await this.RequestAsync('GET', '/session/dashboard-sessions');
  }

  async GroupUsersUpdate(model: Types.IUserGroupsPost): Promise<Response> {
    return await this.RequestAsync('PUT', '/group/user-groups', model);
  }

  async UserUpdateRole(model: Types.IUserUpdateRole): Promise<Response> {
    return await this.RequestAsync('PUT', '/user/role/' + model.role, model.user_ids);
  }

  async SendForgotPassword(model: Types.IForgotView): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/forget-password', model);
  }

  async ResetPassword(model: Types.IForgotView): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/reset-password', model);
  }
}
