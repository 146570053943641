import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';

export const CombineExamFormValidation = (T: Translator) => Yup.object().shape({
  course_code: Yup.string().required(T.t('gen_cannot_leave_empty')),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')),
  exam_duration: Yup.number().required(T.t('gen_cannot_leave_empty')).moreThan(0, T.t('gen_must_be_greater_than_zero')),
  exam_session: Yup.number().required(T.t('gen_cannot_leave_empty')).moreThan(0, T.t('gen_must_be_greater_than_zero')),
  invigilator_status: Yup.number().required(T.t('gen_cannot_leave_empty')),
  invigilator_gap_before_exam: Yup.number().required(T.t('gen_cannot_leave_empty')).moreThan(-1),
  invigilator_gap_after_exam: Yup.number().required(T.t('gen_cannot_leave_empty')).moreThan(-1),
  classroom_combine_status: Yup.string().required(T.t('gen_cannot_leave_empty')),
  description: Yup.string().max(1000, T.t('gen_can_be_up_to_n').replace('{0}', '1000'))
});
