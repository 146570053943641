import React from 'react';
import { Slider } from 'antd'
import Translator from '../../services/translate-factory';
import cn, { flexIC, flexJB, flexRow } from '../ui/Tailwind';
import InputField from './default-input';

interface RangeInputFieldProps {
    id: string;
    minLimit?: number;
    maxLimit?: number;
    minValue: number | undefined;
    maxValue: number | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    unit?: string;
    setSlider: (values: number[]) => void;
    T: Translator
    radioElements?: { label: string, value: string }[],
    radioValue?: string,
    radioOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    step?: string;
}

const RangeInputField: React.FC<RangeInputFieldProps> = ({
    id,
    minLimit = 0,
    maxLimit = 200,
    minValue,
    maxValue,
    onChange,
    label,
    unit = "",
    setSlider,
    T,
    radioElements,
    radioValue,
    radioOnChange,
    step = "1"
}) => {

    const clearNumberKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key === 'e' || e.key === '+' || e.key === '-') {
            e.preventDefault();
        }
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

        let parsedValue = parseInt(e.currentTarget.value, 10);
        if ((!isNaN(parsedValue)) || e.currentTarget.value === '') {
            onChange(e)
        }
    }

    return (
        <div className='react-select-container tw-p-6 tw-bg-gray-100 tw-rounded-lg'>
            <div className={cn(flexRow, flexIC, flexJB)}>
                <label>{label} {unit.length > 0 && `(${unit})`}</label>
                {radioElements &&
                    <div>
                        {radioElements.map((radio, index) => (
                            <div key={radio.value} className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`radio${id}`}
                                    id={`radio${id}${index}`}
                                    value={radio.value}
                                    onChange={radioOnChange}
                                    checked={radioValue === radio.value}
                                />
                                <label className="form-check-label" htmlFor={`radio${id}${index}`}>{radio.label}</label>
                            </div>
                        ))}
                    </div>
                }
            </div>
            <div className='tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2'>
                <div className="form-input form-group date-picker tw-w-full">
                    <InputField
                        id={`min${id}`}
                        name={`min${id}`}
                        value={minValue || ""}
                        onKeyDown={clearNumberKeyDown}
                        onChange={onChangeHandler}
                        type="number"
                        min={0}
                        step={step}
                        className={unit && "tw-pr-8"}
                        inputMode='numeric'
                        required
                        icon={unit && <span>{unit}</span>}
                        label={T.t('gen_minimum')}
                    />
                </div>
                {" - "}
                <div className="form-input form-group date-picker tw-w-full">
                    <InputField
                        id={`max${id}`}
                        name={`max${id}`}
                        value={maxValue || ""}
                        onKeyDown={clearNumberKeyDown}
                        step={step}
                        min={0}
                        onChange={onChangeHandler}
                        type="number"
                        className={unit && "tw-pr-8"}
                        icon={unit && <span>{unit}</span>}
                        inputMode='numeric'
                        label={T.t('gen_maximum')}
                        required
                    />
                </div>

            </div>
            <Slider
                marks={{
                    [minLimit]: `${minLimit}${unit}`,
                    [maxLimit]: `${maxLimit}${unit}`
                }}
                range
                min={minLimit}
                max={maxLimit}
                step={Number(step)}
                value={[minValue || -1, maxValue || -1]}
                tooltip={{
                    formatter(value) {
                        return `${value} ${unit}`
                    },
                }}
                onChange={setSlider}
            />
        </div>
    );
};

export default RangeInputField;
