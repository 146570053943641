import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { ChangeAlldataModalInitialValues } from '../../store/constants/classroom-const';
import Translator from '../../services/translate-factory';
import { SectionTypes } from '../../store/constants/enums';

const T = Translator.create();
const L = Log.create('HoursExportModal');

function getInitialState(): any {
  const initialValues: any = {
    termID: -1,
    sectionType: -1,
    selected_id: 1,
  };
  return Object.assign({}, initialValues);
}

class HoursExportModal extends Component<any, any> {
  state = getInitialState();

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setCloseModal = () => {
    this.setClose();
  };

  onDownload(values: any, FormActions: FormikActions<Types.IFilterChangeAllDataModal>) {
    let sectionType = this.props.sectionType;
    if (values.selected_type_ids == -1) {
      switch (sectionType) {
        case SectionTypes.Planning_Classrooms:
          sectionType = SectionTypes.Planning_NonAvailable_Classrooms;
          break;
        case SectionTypes.Planning_Instructors:
          sectionType = SectionTypes.Planning_NonAvailable_Instructors;
          break;
        case SectionTypes.Planning_Courses:
          sectionType = SectionTypes.Planning_NonAvailable_Courses;
          break;
      }
    }

    const model = {
      termId: this.props.termId,
      sectionType: sectionType,
    };

    this.props.dispatch(Actions.ApiRequest(Constants.building.DOWNLOAD_ALL_DATA, model, ''));

    this.setCloseModal();
  }

  render() {
    let listOptions = [
      { label: T.t('gen_hours_availabilities'), value: 1 },
      { label: T.t('gen_hours_non_availabilities'), value: -1 },
    ]

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_download_planning_hours')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={ChangeAlldataModalInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  this.onDownload(values, actions);
                }}
              >
                {(props: FormikProps<Types.IFilterChangeAllDataModal>) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="col-md-12 pb-3">
                            {T.t('gen_download_planning_hours_description')}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_selection')}</label>
                                <Select
                                  id='select_type'
                                  className="react-select"
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_type_ids',
                                      list.value
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                          {
                            props.values.selected_type_ids !== undefined ?
                              <div className="col-6 pt-3 pb-3">
                                <button
                                  id='button_download'
                                  type="button"
                                  className="btn btn-green"
                                  onClick={() => props.handleSubmit()}
                                >
                                  {T.t('gen_download')}
                                </button>
                              </div> : null
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IClassroomPageProps): Types.IClassroomPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IClassroomPageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return true;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(HoursExportModal);

export default container;
