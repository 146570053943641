import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';

export const FormValidation = (T: Translator) => Yup.object().shape({
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  year: Yup.number().required(T.t('gen_cannot_leave_empty')),
  academic_term: Yup.number().required(T.t('gen_cannot_leave_empty')),
  exam_type: Yup.number().required(T.t('gen_cannot_leave_empty')),
  description: Yup.string().required(T.t('gen_cannot_leave_empty')).max(1000, T.t('gen_can_be_up_to_n').replace('{0}', '1000'))
});
