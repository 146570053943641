import React, { Component } from 'react';
import { Formik, FormikProps, FormikActions } from 'formik';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { Log } from 'ng2-logger';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';

import * as Types from '../../store/types';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { BuildingSearchInitialValues } from '../../store/constants/building-const';

import Spinner from '../../components/templates/spinner';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';

import Translator from '../../services/translate-factory';

const T = Translator.create();

const equal = require('deep-equal');
const L = Log.create('BuildingImportForm');

class BuildingImportForm extends Component<Types.IBuildingImportFormProps, Types.IBuildingImportFormState> {
  state: Types.IBuildingImportFormState = {
    filters: BuildingSearchInitialValues,
    filterIsOpen: false,
    all_ids: [],
    selected_ids: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.init();
    document.addEventListener('keydown', this.handleKeyDown);
  }
  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.setCloseForm();
    }
  };
  componentDidUpdate(prevProps: any, prevState: any) {
    if (!prevProps.formIsOpen && this.props.formIsOpen) {
      this.init();
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.init();
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  init() {
    this.state = {
      filters: Object.assign({}, BuildingSearchInitialValues),
      filterIsOpen: false,
      all_ids: [],
      selected_ids: []
    };
    this.state.filters.page = 1;
    this.setState(this.state);
    this.searchBuildings();
    this.getBuildingSelectOptions();
  }

  searchBuildings() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.building.BUILDING_IMPORT_LIST_SEARCH, this.state.filters, 'building-import-form-spin')
    );
  }

  getBuildingSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.building.BUILDING_IMPORT_GET_SELECT_OPTIONS, 'building-import-form-spin'));
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchBuildings();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchBuildings();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, BuildingSearchInitialValues);
    this.setState(this.state);
    this.searchBuildings();
  };

  onFilterBuilding(model: Types.IFilterBuilding, FormActions: FormikActions<Types.IFilterBuilding>) {
    this.setState(prev => ({
      ...prev,
      filterIsOpen: true,
      filters: {
        ...model,
        page: 1
      },
      selected_ids: [],
    }))
    this.searchBuildings();
    FormActions.setSubmitting(false);
  }

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.building.BUILDING_IMPORT_LIST_SEARCH,
            this.state.filters,
            'building-import-form-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onSelectBuilding = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onFormSave(props: Types.IBuildingImportFormState, FormActions: FormikActions<Types.IBuildingImportFormState>) {
    this.disableAllChildren(true);
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status === 200) {
        this.setClose(true);
      }
      this.disableAllChildren(false);
      FormActions.setSubmitting(false);
    };
    const building_ids = props.selected_ids;
    this.props.dispatch(
      Actions.ApiRequest(Constants.building.BUILDING_IMPORT_CREATE, building_ids, 'building-import-form-spin', resultCallback)
    );
  };

  static getDerivedStateFromProps(props: Types.IBuildingImportFormProps, state: Types.IBuildingImportFormState) {
    let hasNewState: boolean = false;
    if (hasNewState) {
      return state;
    }
    else return null;
  }

  disableAllChildren = (isDisabled: boolean) => {
    const disableOnLoading = document.getElementById('disableOnLoading');

    if (disableOnLoading) {
      disableOnLoading.querySelectorAll('input, button, textarea, select').forEach((element) => {
        if (element instanceof HTMLInputElement ||
          element instanceof HTMLButtonElement ||
          element instanceof HTMLTextAreaElement ||
          element instanceof HTMLSelectElement) {
          element.disabled = isDisabled;
        }
      });
    }
  };

  render() {
    let buildingList = this.props.results;
    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Formik
          enableReinitialize={true}
          initialValues={this.state}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
        >
          {(props: FormikProps<Types.IBuildingImportFormState>) => {
            const { handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div id="disableOnLoading">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <Spinner name="building-import-form-spin" />
                          <h5 className="modal-title">
                            <span>
                              <i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_building')}
                            </span>
                          </h5>
                          <button
                            id='button_close'
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.setCloseForm}
                          >
                            <i className="material-icons">close</i>
                            <span>ESC</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="white-container mt-4">
                            <div className="row align-items-center mb-1">
                              <div className="col-md-12">
                                <div className="col-md-12 text-right">
                                  <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                                    <button
                                      id='button_filter'
                                      type='button'
                                      className="category-tag-square tag-glass float-right ml-3 mr-3"
                                      style={{ margin: '5px' }}
                                      onClick={() => {
                                        this.state.filterIsOpen = !this.state.filterIsOpen;
                                        this.setState(this.state);
                                      }}
                                    >
                                      <i className="material-icons mr-2">filter_list</i>
                                      <span>{T.t('gen_filter')}</span>
                                    </button>
                                  </div>
                                </div>
                                {/* FILTER STARTS HERE  */}
                                <div
                                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                                  id="advance-search"
                                >
                                  <div className="advance-search d-block mt-3" style={{}}>
                                    <Formik
                                      initialValues={BuildingSearchInitialValues}
                                      enableReinitialize={true}
                                      onSubmit={(values, actions) => {
                                        this.onFilterBuilding(values, actions);
                                      }}
                                      onReset={this.onFormReset}
                                    >
                                      {(props: FormikProps<Types.IFilterBuilding>) => {
                                        return (
                                          <form onSubmit={props.handleSubmit}>
                                            <div className="row">
                                              <div className="col-12">
                                                <h6>{T.t('gen_filter_parameters')}</h6>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="form-input form-group date-picker">
                                                  <input
                                                    id="building_code"
                                                    name="building_code"
                                                    value={props.values.building_code}
                                                    onChange={props.handleChange}
                                                    type="text"
                                                    required
                                                  />
                                                  <span className="highlight" />
                                                  <span className="bar" />
                                                  <label htmlFor="building_code">{T.t('gen_code')}</label>
                                                  <i className="material-icons">filter_1</i>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="form-input form-group date-picker">
                                                  <input
                                                    id="name"
                                                    name="name"
                                                    value={props.values.name}
                                                    onChange={props.handleChange}
                                                    type="text"
                                                    required
                                                  />
                                                  <span className="highlight" />
                                                  <span className="bar" />
                                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                                  <i className="material-icons">title</i>
                                                </div>
                                              </div>
                                              <div className="col-md-4 form-input">
                                                <div className="react-select-container">
                                                  <h6>{T.t('gen_campus')}</h6>
                                                  <Select
                                                    id='select_campus'
                                                    className="react-select"
                                                    isMulti={true}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={false}
                                                    options={
                                                      this.props.selectOptions && this.props.selectOptions.campuses
                                                        ? this.props.selectOptions.campuses
                                                        : []
                                                    }
                                                    placeholder={T.t('gen_select_campus')}
                                                    value={props.values.campuses}
                                                    onChange={(
                                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                    ) => {
                                                      const list: Array<Types.ISelectOption> = options
                                                        ? (options as Array<Types.ISelectOption>)
                                                        : [];
                                                      props.setFieldValue('campuses', list);
                                                      props.setFieldValue(
                                                        'campus_ids',
                                                        list.map((item) => item.value)
                                                      );
                                                    }}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <hr />
                                            <div className="row mt-3">
                                              <div className="col-6">
                                                <button
                                                  id="button_arrow_upward"
                                                  type="button"
                                                  onClick={() => {
                                                    this.state.filterIsOpen = false;
                                                    this.setState(this.state);
                                                  }}
                                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                                >
                                                  <i className="material-icons">arrow_upward</i>
                                                </button>
                                                <button
                                                  id="button_delete_sweep"
                                                  type="reset"
                                                  onClick={props.handleReset}
                                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                                >
                                                  <i className="material-icons">delete_sweep</i>
                                                </button>
                                              </div>
                                              <div className="col-6 text-right">
                                                <button
                                                  id="button_search"
                                                  type="button"
                                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                                  onClick={() => props.handleSubmit()}
                                                  disabled={props.isSubmitting}
                                                >
                                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                                </button>
                                              </div>
                                            </div>
                                          </form>
                                        );
                                      }}
                                    </Formik>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                                      <thead>
                                        <tr>
                                          <th data-cell="select">
                                            <div className="tick-radio position-relative">
                                              <input
                                                id='select_all'
                                                type="checkbox"
                                                className="form-radio"
                                                checked={this.checkAllIdsSelected()}
                                                onChange={this.onSelectAll}
                                              />
                                            </div>
                                          </th>
                                          <SortedColumn
                                            datacell="building_code"
                                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                            title={T.t('gen_code')}
                                            sortkey="building_code"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                          <SortedColumn
                                            datacell="name"
                                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                            title={T.t('gen_name')}
                                            sortkey="name"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                          <SortedColumn
                                            datacell="campus"
                                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                            title={T.t('gen_campus')}
                                            sortkey="campus"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                          <SortedColumn
                                            datacell="address"
                                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                            title={T.t('gen_address')}
                                            sortkey="address"
                                            sortedcolumn={this.state.filters.order_by}
                                            sort={this.sort}
                                          />
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {buildingList && buildingList.length
                                          ? buildingList.map((item: any) => (
                                            <tr key={'building-' + item.building} data-title={item.name}>
                                              <td data-cell="select">
                                                <div className="tick-radio position-relative">
                                                  <input
                                                    id='select_building'
                                                    type="checkbox"
                                                    className="form-radio"
                                                    checked={
                                                      this.state.selected_ids &&
                                                      this.state.selected_ids.indexOf(item.building_id ? item.building_id : -1) > -1
                                                    }
                                                    data-id={item.building_id}
                                                    onChange={this.onSelectBuilding}
                                                  />
                                                </div>
                                              </td>
                                              <td scope="row" data-label="No" className="text-center">
                                                {item.building_code}
                                              </td>
                                              <td data-label={T.t('gen_name')} className="text-center">{item.name}</td>
                                              <td data-label={T.t('gen_campus')} className="text-center">{item.campus_name}</td>
                                              <td data-label={T.t('gen_address')} className="text-center">
                                                {item && item.address ? item.address : '-'}
                                              </td>
                                            </tr>
                                          ))
                                          : null}
                                      </tbody>
                                    </table>
                                    <div className="row-options justify-content-end">
                                      <div
                                        className="page-sorting d-flex align-items-center justify-content-center"
                                        style={{ marginTop: '5px' }}
                                      >
                                        {this.props.results && this.props.results.length > 0 && (
                                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Spinner name="building-import-form-spin" />
                        <div className="modal-footer d-block">
                          <div className="row">
                            <div className="col-md">
                              <button
                                id='button_cancel'
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-gray min-auto"
                                aria-label="Close"
                                onClick={this.setCloseForm}
                              >
                                {T.t('gen_cancel')}
                              </button>
                            </div>
                            <div className="col-md text-md-right">
                              <button
                                id='button_save'
                                type="button"
                                data-dismiss="modal"
                                aria-label="alert-success"
                                disabled={(this.state.selected_ids && this.state.selected_ids.length < 1) || props.isSubmitting}
                                onClick={() => props.handleSubmit()}
                                className="btn btn-green"
                              >
                                <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IBuildingImportFormProps): Types.IBuildingImportFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IBuildingImportFormProps = Object.assign({}, ownProps, {
    user: store.state.user,
    results: store.state.building_import_form && store.state.building_import_form.results,
    filters: store.state.building_import_form && store.state.building_import_form.filters,
    selectOptions: store.state.select_options && store.state.select_options.buildingImportForm
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.building_import_form) {
    return (
      !!equal(
        prev.state.user,
        next.state.user
      ) &&
      !!equal(
        prev.state.building_import_form && prev.state.building_import_form.results,
        next.state.building_import_form && next.state.building_import_form.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.buildingImportForm,
        next.state.select_options && next.state.select_options.buildingImportForm
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(BuildingImportForm);

export default container;
