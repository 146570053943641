import { put, takeEvery, all, take, select } from 'redux-saga/effects';
import { constants, SyncBuildingSearchInitialValues } from '../constants/sync-const';
import { Log } from 'ng2-logger';
import AplanApiClient from '../../services/mock-api-client';
import * as Types from '../types';
import * as Actions from '../actions/general';
import MockAPIClient from '../../services/mock-api-client';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('user-saga');

function* getSyncBuildings(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SyncBuildingSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncPrograms(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SyncProgramSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncCampuses(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SyncCampusSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncClassrooms(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SyncClassroomSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncFaculties(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SyncFacultySearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncInstructors(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SyncInstructorSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncCourses(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SyncCourseSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncStudents(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SyncStudentSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getIntegration(action: Types.IApiRequestAction): Generator<any, any, any> {
  const AAPI: AplanApiClient = new AplanApiClient();
  let response: Response;
  let content: any = null;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield AAPI.GetResponse(AAPI.GetIntegration(action.body));
    if (response && response.status === 200) {
      content = yield AAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('Bir sorun oluştu! Lütfen tekrar deneyin.', 'Dikkat!', 'danger'));
    }
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* addMultiWithIntegration(action: Types.IApiRequestAction): Generator<any, any, any> {
  const AAPI: AplanApiClient = new AplanApiClient();
  const response: Response = yield AAPI.GetResponse(AAPI.AddMultiWithIntegration(action.body));
  let content: any = yield AAPI.GetContent(response);

  if (response && response.status == 200) {
    yield put(Actions.Notification('Seçilenler başarıyla eklendi!', 'Başarılı'));
  } else {
    yield put(Actions.Notification('Bir sorun oluştu! Lütfen tekrar deneyin.', 'Dikkat!', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

// Synch Add To Main Tables

function* getSyncBuildingAddToMain(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncBuildingAddToMain(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncCampusAddToMain(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncCampusAddToMain(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncClassroomAddToMain(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncClassroomAddToMain(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncFacultyAddToMain(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncFacultyAddToMain(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncProgramAddToMain(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncProgramAddToMain(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncInstructorAddToMain(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncInstructorAddToMain(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncCourseAddToMain(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncCourseAddToMain(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncStudentAddToMain(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncStudentAddToMain(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

// Synchronization For Modal List START

function* getSyncModalSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncGetModalSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncCampusSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncGetCampusOptions(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncBuildingSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncGetBuildingOptions(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncProgramSelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncGetProgramOptions(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncFacultySelectOptions(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncGetFacultyOptions(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

// Sending Data To "Start Synchronization"

function* getSyncSendSyncType(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncSendSyncType(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else if (response && response.status === 409) {
      content = yield MockAPI.GetContent(response);
      yield put(
        Actions.ShowModal({
          body: content,
          title: T.t('gen_pending'),
          name: 'gen_pending',
          icon: 'warning',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

// Veri Aktarma Sayfası

function* getSyncJobStatus(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncJobStatus(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

// Displaying Data In Table

function* getDisplayCampusData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncSendSyncType(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

// Senkronizasyon İşlerini Listele

function* getSyncListSyncJobs(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncListSyncJobs(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSyncGetFetchedData(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SyncGetFetchedData(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

// Synchronization For Modal List END

function* createSynchronizastion(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SyncCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status == 200) {
    yield put(Actions.Notification('Seknronizasyon Oluşturuldu!', 'Başarılı'));
  } else {
    yield put(Actions.Notification('Bir sorun oluştu! Lütfen tekrar deneyin.', 'Dikkat!', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchGetIntegration() {
  yield takeEvery((action: any) => action.target === constants.GET_INTEGRATION, getIntegration);
}

export function* watchAddMultiWithIntegration() {
  yield takeEvery((action: any) => action.target === constants.ADD_MULTI_WITH_INTEGRATION, addMultiWithIntegration);
}

export function* watchSynchronizationCreate() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_CREATE, createSynchronizastion);
}

export function* watchSyncGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_GET_SELECT_OPTIONS, getSyncSelectOptions);
}

export function* watchSyncBuildingSearch() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_BUILDING_LIST_SEARCH, getSyncBuildings);
}

export function* watchSyncProgramSearch() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_PROGRAM_LIST_SEARCH, getSyncPrograms);
}

export function* watchSyncCampusSearch() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_CAMPUS_LIST_SEARCH, getSyncCampuses);
}

export function* watchSyncClassroomSearch() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_CLASSROOM_LIST_SEARCH, getSyncClassrooms);
}

export function* watchSyncFacultySearch() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_FACULTY_LIST_SEARCH, getSyncFaculties);
}

export function* watchSyncInstructorSearch() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_INSTRUCTOR_LIST_SEARCH, getSyncInstructors);
}

export function* watchSyncCourseSearch() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_COURSE_LIST_SEARCH, getSyncCourses);
}

export function* watchSyncStudentSearch() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_STUDENT_LIST_SEARCH, getSyncStudents);
}

// Synch Add To Main Tables

export function* watchSyncBuildingAddToMain() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_BUILDING_ADD_TO_MAIN, getSyncBuildingAddToMain);
}

export function* watchSyncCampusAddToMain() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_CAMPUS_ADD_TO_MAIN, getSyncCampusAddToMain);
}

export function* watchSyncClassroomAddToMain() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_CLASSROOM_ADD_TO_MAIN, getSyncClassroomAddToMain);
}

export function* watchSyncFacultyAddToMain() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_FACULTY_ADD_TO_MAIN, getSyncFacultyAddToMain);
}

export function* watchSyncProgramAddToMain() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_PROGRAM_ADD_TO_MAIN, getSyncProgramAddToMain);
}

export function* watchSyncInstructorAddToMain() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_INSTRUCTOR_ADD_TO_MAIN, getSyncInstructorAddToMain);
}

export function* watchSyncCourseAddToMain() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_COURSE_ADD_TO_MAIN, getSyncCourseAddToMain);
}

export function* watchSyncStudentAddToMain() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_STUDENT_ADD_TO_MAIN, getSyncStudentAddToMain);
}

// Synchronization For Modal List START

export function* watchSyncGetModalSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_MODAL_SELECT_OPTIONS, getSyncModalSelectOptions);
}

export function* watchSyncGetCampusOptions() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_CAMPUS_SELECT_OPTIONS, getSyncCampusSelectOptions);
}

export function* watchGetBuildingOptions() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_BUILDING_SELECT_OPTIONS, getSyncBuildingSelectOptions);
}

export function* watchSyncGetProgramOptions() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_PROGRAM_SELECT_OPTIONS, getSyncProgramSelectOptions);
}

export function* watchGetFacultyOptions() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_FACULTY_SELECT_OPTIONS, getSyncFacultySelectOptions);
}

// Sending Data To "Start Synchronization"

export function* watchSendSyncType() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_MODAL_SEND_SYNCTYPE, getSyncSendSyncType);
}

// Getting Data For "Veri Aktarma Sayfası"

export function* watchSyncJobStatus() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_GET_SYNC_JOB_STATUS, getSyncJobStatus);
}

// Displaying Data In Table

export function* watchDisplayCampusData() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_DISPLAY_CAMPUS_DATA, getDisplayCampusData);
}

// Senkronizasyon İşlerini Listele

export function* watchSyncListSyncJobs() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_LIST_SYNC_JOBS, getSyncListSyncJobs);
}

export function* watchSyncGetFetchedData() {
  yield takeEvery((action: any) => action.target === constants.SYNCHRONIZATION_GET_FETCHED_DATA, getSyncGetFetchedData);
}

// Synchronization For Modal List END