import React from 'react';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import * as Types from '../../store/types';
import { Route, Redirect } from 'react-router-dom';
import AppFactory from '../../services/app-factory';
import * as Actions from '../../store/actions/general';

import { Log } from 'ng2-logger';
const L = Log.create('PrivateRoute');
const AF = AppFactory.create();

export class PrivateRoute extends React.Component<Types.IRoutePorps> {
  render() {
    const renderPrivate = (props: any) => {
      if (AF.hasLoggedOn() && this.props.roles && AF.inRole(this.props.roles)) {
        return React.cloneElement(this.props.page, { ...props });
      }
      if (AF.hasLoggedOn() && this.props.dispatch) {
        this.props.dispatch(Actions.Notification('notification_no_auth_blocked_message', 'gen_warning', 'warning'));
      }
      return <Redirect to={GT.Route(Routes.LOGIN)} />;
    };

    let route: any = <Route {...this.props} render={renderPrivate} />;

    if (!AF.hasLoggedOn() && this.props.path === window.location.pathname) {
      window.location.pathname = GT.Route(Routes.LOGIN);
      route = null;
    }
    return route;
  }
}

export default PrivateRoute;
