import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SortedColumn from '../../../components/table/sorted-column';
import * as Types from '../../../store/types';
import * as Constants from '../../../store/constants/all';
import Translator from '../../../services/translate-factory';
const T = Translator.create();
const Logger = Log.create('ExamPeriodForm');

class CourseHoursListPageTableHeaders extends Component<any, any> {

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  checker = (arr: Array<number>, target: Array<number>): boolean => {
    if (arr.length && target.length) {
      return target.every((item: number) => arr.includes(item));
    } else return false;
  };

  checkAllSelected = (): boolean => {
    const all_ids = this.props.all_ids ? this.props.all_ids : [];
    const selected_ids = this.props.selected_ids ? this.props.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }
  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  render() {
    let tableHeaders = null;

    let all_ids_on_list: Array<number> = [];
    if (this.props.courseList && this.props.courseList.length > 0) {
      this.props.courseList.map((item: Types.IExamPeriod_CourseItem) => {
        all_ids_on_list.push(item.course_id);
      });
    }

    if (this.props.compactTable) {
      tableHeaders = (
        <tr className="table-course-hours-classrooms-tr-headers">
          <th data-cell="select" className="table-course-hours-classrooms-th-1">
            <div className="tick-radio position-relative">
              <input
                id="select_all_courses"
                type="checkbox"
                className="form-radio"
                checked={this.checkAllSelected()}
                onChange={this.props.fnSelectAll}
              />
            </div>
          </th>
          <th scope="col">
            <span className="text-center">{T.t('gen_status_hour_assignment')} </span>
          </th>
          <th scope="col">
            <span className="text-center">{T.t('gen_status_classroom_assignment')} </span>
          </th>
          <SortedColumn
            datacell="activity_no"
            className="d-none d-lg-table-cell d-xl-table-cell text-center examp-compt-header-2"
            title={T.t('gen_activity_no') + ' - ' + T.t('gen_code') + ' - ' + T.t('gen_name')}
            sortkey="activity_no"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="program"
            className="d-none d-lg-table-cell d-xl-table-cell text-center examp-compt-header-1"
            title={T.t('gen_program')}
            sortkey="program"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="instructors"
            className="d-none d-lg-table-cell d-xl-table-cell text-center examp-compt-header-1"
            title={T.t('gen_instructors')}
            sortkey="instructor"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="lesson_count"
            className="d-none d-lg-table-cell d-xl-table-cell text-center examp-compt-header-1"
            title={T.t('gen_lesson_count') + ' - ' + T.t('gen_activity_type') + ' - ' + T.t('gen_lesson_location') + ' - ' + T.t('gen_week')}
            sortkey="lesson_count"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
        </tr>
      );
    } else {
      tableHeaders = (
        <tr>
          <th data-cell="select">
            <div className="tick-radio position-relative">
              <input
              id="select_all_courses1"
                type="checkbox"
                className="form-radio"
                checked={this.checkAllSelected()}
                onChange={this.props.fnSelectAll}
              />
            </div>
          </th>
          <SortedColumn
            datacell="status"
            title={T.t('gen_status')}
            sortkey="status"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <th scope="col" className="d-none d-lg-table-cell d-xl-table-cell text-center">
            {T.t('gen_hours')}
          </th>
          <th scope="col" className="text-center">
            {T.t('gen_classrooms')}
          </th>
          <SortedColumn
            datacell="activity_no"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_activity_no')}
            sortkey="activity_no"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="course_code"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_code')}
            sortkey="course_code"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="course_name"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_name')}
            sortkey="course_name"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="activity_type"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_activity_type')}
            sortkey="activity_type"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="lesson_count"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_lesson_count')}
            sortkey="lesson_count"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <th scope="col" className="text-center">
            {T.t('gen_lesson_location')}
          </th>
          <SortedColumn
            datacell="education_type"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_education_type')}
            sortkey="education_type"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="faculty"
            className="d-none d-lg-table-cell d-xl-table-cell table-course-hours-classrooms-th-5 text-center"
            title={T.t('gen_faculty')}
            sortkey="faculty"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="program"
            className="d-none d-lg-table-cell d-xl-table-cell table-course-hours-classrooms-th-5 text-center"
            title={T.t('gen_connected_programs')}
            sortkey="program"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="grade"
            className="d-none d-lg-table-cell d-xl-table-cell text-center text-center"
            title={T.t('gen_grade')}
            sortkey="grade"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="section"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_section')}
            sortkey="section"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="instructors"
            className="d-none d-lg-table-cell d-xl-table-cell table-course-hours-classrooms-th-5 text-center"
            title={T.t('gen_instructors')}
            sortkey="instructor"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="student_count"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_student_count')}
            sortkey="student_count"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="week"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_week')}
            sortkey="week"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <SortedColumn
            datacell="course_type"
            className="d-none d-lg-table-cell d-xl-table-cell text-center"
            title={T.t('gen_course_type')}
            sortkey="course_type"
            sortedcolumn={this.props.filters.order_by}
            sort={this.props.fnColumnSorter}
          />
          <th scope="col">
            {T.t('gen_description')}
          </th>
          <th scope="col" className="text-right">
            <span className="text-right">{T.t('gen_actions')}</span>
          </th>
        </tr>
      );
    }
    return <thead>{tableHeaders}</thead>;
  }
}

export default CourseHoursListPageTableHeaders;
