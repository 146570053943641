import * as Types from "../types";

export const actions = {
    FILE_SEARCH: 'SESSION:FILE_SEARCH',
    FILE_CREATE: 'FILE:CREATE',
    FILE_UPLOAD: 'FILE:UPLOAD',
    FILE_UPLOAD_RESULT: 'FILE:UPLOAD_RESULT',
    FILE_DELETE: 'FILE:DELETE',
    FILE_GET_SHARED_FOLDER: 'FILE:GET_SHARED_FOLDER'
};

export const FileInitialValues: Types.IFileView = {
    file_id: '',
    dir_id: '',
    session_id: '',
    name: '',
    description: '',
    type: '',
    directory: false,
    shared: false,
    icon: 'icon-empty',
    path: '',
    wrapper: '',
    file_status: '',
    container: ''
};

export const FilterInitialValues: Types.IFilePagedQuery = {
    dir_guid: '',
    session_guid: '',
    order_by: '',
    page: 1,
    size: 20,
    query: '',
    total: -1,
    start_date: '',
    end_date: '',
    name: '',
    type: '',
    creatorIds: [],
    creators: [],
};

export const FileFilterTypes: Array<Types.ISelectOption> = [
    { label: 'file_application_types', value: 'application' },
    { label: 'file_video_types', value: 'video' },
    { label: 'file_image_types', value: 'image' },
];

export const UploadAllowedFileTypes: Array<any> = [
    { value: '.jpg' },
    { value: '.jpeg' },
    { value: '.png' },
    { value: '.pdf' },
    { value: '.mp4' },
    { value: '.mp3' },
    { value: '.acc' }
];
