import * as Types from '../types';
import Translator from '../../services/translate-factory';

export const constants = {
  BACKUP_LIST_SEARCH: 'BACKUP:LIST_SEARCH',
  BACKUP_CREATE: 'BACKUP:CREATE',
  BACKUP_RESTORE: 'BACKUP:RESTORE',
  BACKUP_UPDATE: 'BACKUP:UPDATE',
  BACKUP_DELETE: 'BACKUP:DELETE',
  BACKUP_GET_BY_ID: 'BACKUP:GET_BY_ID',
  BACKUP_GET_SELECT_OPTIONS: 'BACKUP:GET_SELECT_OPTIONS',
};

export const BackupTypes = (T: Translator) => [
  { label: T.t('gen_database_backup'), value: 0 },
  { label: T.t('gen_database_restore'), value: 1 },
  { label: T.t('gen_system_restore_point'), value: 2 },
];

export const BackupSearchInitialValues: Types.IFilterBackup = {
  order_by: '',
  types: [],
  status: [],
  select_all: false,
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const BackupFormInitialValues: Types.IBackupItem = {
  no: 0,
  description: '',
  backup_id: undefined,
  type: 0,
  backup_size: '',
  created_date: ''
};