import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { TermTypes } from '../../store/constants/exam-period-const';
import * as Types from '../../store/types';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import * as GT from '../../tools/general-tools';

import Translator from '../../services/translate-factory';
import { FormValidation } from './validations/form-validation';
import { EventPeriodFormInitialValues } from '../../store/constants/event-period-const';
const T = Translator.create();


function getInitialState(): Types.IEventPeriodFormState {
    const initialValues: Types.IEventPeriodFormState = {
        model: Object.assign({}, EventPeriodFormInitialValues),
        isSaveButtonDisabled: false
        , course_term_list: [],
        exam_term_list: [],
        locale: GT.getLocaleFromLangCode()
    };
    return Object.assign({}, initialValues);
}

class CopyEventPeriodForm extends Component<Types.ICopyFormProps, Types.IEventPeriodFormState> {
    state: Types.IEventPeriodFormState = getInitialState();

    setClose = (refresh: boolean = false) => {
        if (this.props.onClose) {
            this.props.onClose(refresh);
        }
    };

    setCloseForm = () => {
        this.setClose();
    };

    onFormSave = (model: Types.ITermItem, FormActions: FormikActions<Types.ITermItem>) => {
        const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            this.setState((prev) => ({
                ...prev,
                isSaveButtonDisabled: false
            }));
            if (result && result.code) {
                let errors: any = {};
                if (result.details) {
                    const validations: Array<Types.IValidationResponse> = result.details;
                    validations.forEach((m: Types.IValidationResponse) => {
                        errors[m.field] = m.message[0];
                    });
                }
                FormActions.setErrors(errors);
            }
            if (status === 200 || status === 201) {
                this.setClose(true);
            }
        };
        let newModel = {
            status: model.status,
            name: model.name,
            academic_term: model.academic_term,
            term_type: model.term_type,
            year: model.year,
            calendar_status: model.calendar_status,
            description: model.description,
            term_id: model.term_id,
            exam_type: model.exam_type
        }
        this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_COPY, newModel, 'exam-period-form-spin', resultCallback));
    };

    static getDerivedStateFromProps(props: Types.ICopyFormProps, state: Types.IEventPeriodFormState) {
        let hasNewState: boolean = false;
        if (props.terms) {
            const createTermOption = (term: any) => ({
                label: `${term.year} ${GT.GetAcademicTermText(term.academic_terms)} ${GT.GetTermTypeText(term.term_type)} / ${term.name} (${term.term_id})`,
                value: term.term_id
            });

            props.terms.forEach((term) => {
                if (term.term_type === 0) {
                    state.course_term_list.push(createTermOption(term));
                } else if (term.term_type === 1) {
                    state.exam_term_list.push(createTermOption(term));
                }
            });
        }
        if (props.formIsOpen && props.examPeriodId && props.examPeriodId != state.model.term_id) {
            state.model.term_id = props.examPeriodId;
            hasNewState = true;
        }

        if (props.form && props.examPeriodId && props.examPeriodId == state.model.term_id) {
            state.model = props.form;
            hasNewState = true;
        }

        if (hasNewState) {
            return state;
        } else if (!props.examPeriodId && state.model.term_id) {
            return getInitialState();
        } else return null;
    }
    getYearFound() {
        return (this.props.terms!.filter((i: any) => this.props.selectedTermId && this.props.selectedTermId == i.term_id))[0];
    }
    render() {
        let yearFound = this.getYearFound()
        let currentYear = (yearFound && yearFound.year) || new Date().getFullYear();
        let lastYear = currentYear - 1;
        let nextYear = currentYear + 1;
        let listOfYears = [
            { label: lastYear && lastYear.toString(), value: lastYear },
            { label: currentYear && currentYear.toString(), value: currentYear },
            { label: nextYear && nextYear.toString(), value: nextYear }
        ];
        return (
            <Modal
                modalClassName="modal-from-right"
                style={{ maxWidth: '100%', left: '0', display: 'inline' }}
                className="pt-0"
                isOpen={this.props.formIsOpen}
            >
                <Spinner name="exam-period-form-spin" />
                <Formik
                    initialValues={this.state.model}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        this.onFormSave(values, actions);
                    }}
                    validationSchema={FormValidation(T)}
                >
                    {(props: FormikProps<Types.ITermItem>) => {
                        const { values, handleChange, handleBlur, handleSubmit } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="" id="addNew">
                                    <div className="modal-dialog" aria-role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    <i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_copy_term')}
                                                </h5>
                                                <button id='button_close' type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseForm}>
                                                    <i className="material-icons">close</i>
                                                    <span>ESC</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="add-custom-tag mb-3">
                                                            <div className="react-select-container">
                                                                <label>{T.t('gen_year')}</label>
                                                                <Select
                                                                    id='select_year'
                                                                    className="react-select"
                                                                    isMulti={false}
                                                                    closeMenuOnSelect={true}
                                                                    options={listOfYears}
                                                                    placeholder={T.t('gen_select_year')}
                                                                    value={listOfYears.find(option => option.value === values.year)}
                                                                    onChange={(option: any) => props.setFieldValue('year', option.value)}
                                                                />
                                                                <ErrorMessage component="div" className="error" name="year" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="add-custom-tag mb-3">
                                                            <div className="react-select-container">
                                                                <label>{T.t('gen_term')}</label>
                                                                <Select
                                                                    id='select_term'
                                                                    className="react-select"
                                                                    isMulti={true}  // Multi-select özelliği aktif edildi
                                                                    filterOption={(option: any, query: any) =>
                                                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                    }
                                                                    closeMenuOnSelect={false}  // Multi-select'te menü kapatılmaz
                                                                    options={TermTypes(T)}
                                                                    placeholder={T.t('gen_select_term')}
                                                                    value={
                                                                        values.academic_terms && values.academic_terms.length > 0
                                                                            ? TermTypes(T).filter(option => values.academic_terms && values.academic_terms.includes(option.value))
                                                                            : []
                                                                    }
                                                                    onChange={(selectedOptions: any) => {
                                                                        props.setFieldValue('academic_terms', selectedOptions ? selectedOptions.map((option: any) => option.value) : []);
                                                                    }}
                                                                    noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                                                />
                                                                <ErrorMessage component="div" className="error" name="academic_terms" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="react-select-container">
                                                            <label>{T.t('gen_course_term')}</label>
                                                            <Select
                                                                className="react-select"
                                                                placeholder={T.t('gen_select_course_term')}
                                                                noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                                                filterOption={(option: any, query: any) =>
                                                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                }
                                                                options={this.state.course_term_list}
                                                                isMulti={false}
                                                                value={values.course_term && {
                                                                    value: values.course_term.term_id,
                                                                    label: values.course_term.name || ""
                                                                }}
                                                                onChange={(selectedOption: any) => {
                                                                    props.setFieldValue('course_term',
                                                                        selectedOption && {
                                                                            term_id: selectedOption.value,
                                                                            name: selectedOption.label
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="react-select-container">
                                                            <label>{T.t('gen_exam_term')}</label>
                                                            <Select
                                                                className="react-select"
                                                                placeholder={T.t('gen_select_exam_term')}
                                                                noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                                                filterOption={(option: any, query: any) =>
                                                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                }
                                                                options={this.state.exam_term_list}
                                                                isMulti={false}
                                                                value={values.exam_term && {
                                                                    value: values.exam_term.term_id,
                                                                    label: values.exam_term.name || ""
                                                                }}
                                                                onChange={(selectedOption: any) => {
                                                                    props.setFieldValue('exam_term',
                                                                        selectedOption && {
                                                                            term_id: selectedOption.value,
                                                                            name: selectedOption.label
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12 form-input form-group with-icon">
                                                        <input
                                                            id="name"
                                                            name="name"
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            required
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label htmlFor="name">{T.t('gen_name')}</label>
                                                        <ErrorMessage component="div" className="error" name="name" />
                                                    </div>
                                                    <div className="col-md-12 form-input form-group with-icon">
                                                        <textarea
                                                            name="description"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            rows={2}
                                                            id="description"
                                                            value={values.description}
                                                            placeholder={T.t('gen_description') + ' ' + T.t('gen_max_char_200')}
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label htmlFor="description" />
                                                        <ErrorMessage component="div" className="error" name="description" />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-12 form-input form-group with-icon">
                                                        <Flatpickr
                                                            placeholder={T.t('gen_select_dates')}
                                                            value={values.time_range && values.time_range.start && values.time_range.end ? [moment(values.time_range.start).toDate(), moment(values.time_range.end).toDate()] : []}
                                                            options={{
                                                                dateFormat: 'd.m.Y',
                                                                mode: 'range',
                                                                locale: this.state.locale,
                                                                defaultDate: values.time_range && values.time_range.start && values.time_range.end ? [moment(values.time_range.start).toDate(), moment(values.time_range.end).toDate()] : []
                                                            }}
                                                            onChange={(selectedDates) => {
                                                                if (selectedDates.length === 2) {
                                                                    const startDate = moment(selectedDates[0]).format('YYYY-MM-DD');
                                                                    const endDate = moment(selectedDates[1]).format('YYYY-MM-DD');
                                                                    props.setFieldValue('time_range', { start: startDate, end: endDate });
                                                                }
                                                            }}
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label htmlFor="term_date_range">{T.t('gen_start_and_end_dates')}</label>
                                                        <ErrorMessage component="div" className="error" name="time_range" />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-6">
                                                        <div className="text-left">
                                                            <h6>{T.t('gen_status')}</h6>
                                                            <div className="tick-radio position-relative d-inline-block">
                                                                <Checkbox name="status" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer d-block">
                                                <div className="row">
                                                    <div className="col-md">
                                                        <button
                                                            id='button_cancel'
                                                            type="button"
                                                            data-dismiss="modal"
                                                            className="btn btn-gray min-auto"
                                                            aria-label="Close"
                                                            onClick={this.setCloseForm}
                                                        >
                                                            {T.t('gen_cancel')}
                                                        </button>
                                                    </div>
                                                    <div className="col-md text-md-right">
                                                        <button
                                                            id='button_save'
                                                            type="button"
                                                            data-dismiss="modal"
                                                            aria-label="alert-success"
                                                            onClick={() => props.handleSubmit()}
                                                            className="btn btn-green"
                                                        >
                                                            <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </Modal>
        );
    }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICopyFormProps): Types.ICopyFormProps => {
    if (!store || !store.state) {
        return ownProps;
    }
    const newProps: Types.ICopyFormProps = Object.assign({}, ownProps, {
        request_id: store.state.exam_period_page && store.state.exam_period_page.requestId,
        request_status: store.state.exam_period_page && store.state.exam_period_page.requestStatus,
        selectedTermId: store.state.term_id,
        user: store.state.user,
        terms: store.state.term_list,
        selectOptions: store.state.select_options && store.state.select_options.termsPage,
    });
    return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    // False etmek zorundayız çünkü eski formu tutuyor. Her defasında render edilmesi lazım.
    return false;
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(CopyEventPeriodForm);

export default container;
