import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { ChangeAllDataModalProgramInitialValues } from '../../store/constants/program-const';
import Translator from '../../services/translate-factory';
import Checkbox from '../../components/checkboxes/single-checkbox';

const T = Translator.create();
const L = Log.create('ChangeAllDataModal');

let is_max_gap_between_courses_error: boolean = false;
let is_daily_max_class_error: boolean = false;
let is_min_class_interval_bw_campuses_error: boolean = false;

function getInitialState(): Types.ChangeAllDataModalState {
  const initialValues: Types.ChangeAllDataModalState = {
    all_ids: [],
    selected_ids: [],
  };
  return Object.assign({}, initialValues);
}

class ChangeAllDataModal extends Component<any, Types.ChangeAllDataModalState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.ChangeAllDataModalState) {
    let hasNewState: boolean = false;
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onChangeAllData(values: any, FormActions: FormikActions<Types.IProgramFilterChangeAllDataModal>) {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.setClose(true);
      }
    };

    let model;
    switch (values.selected_type_ids) {
      case 0:
        model = {
          selected_type: 0,
          term_id: this.props.term_id,
          max_gap_between_courses: values.max_gap_between_courses,
          program_ids: this.props.selected_ids
        }
        break;
      case 1:
        model = {
          selected_type: 1,
          term_id: this.props.term_id,
          daily_max_class: values.daily_max_class,
          program_ids: this.props.selected_ids
        }
        break;
      case 2:
        model = {
          selected_type: 2,
          term_id: this.props.term_id,
          min_class_interval_bw_campuses: values.min_class_interval_bw_campuses,
          program_ids: this.props.selected_ids
        }
        break;

      default:
        break;
    }

    this.props.dispatch(
      Actions.ApiRequest(Constants.program.PROGRAM_CHANGE_ALL_DATA, model, 'program-list-spin', resultCallback)
    );
  }

  render() {

    let listOptions = this.props.term_type === 1 ? [
      { label: T.t('gen_daily_max_course'), value: 1 },
      { label: T.t('gen_min_gap_between_campuses'), value: 2 }
    ] : [
      { label: T.t('gen_max_gap_between_courses'), value: 0 },
      { label: T.t('gen_daily_max_course'), value: 1 },
      { label: T.t('gen_min_gap_between_campuses'), value: 2 }
    ];

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_changing_all_data')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={ChangeAllDataModalProgramInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  this.onChangeAllData(values, actions);
                }}
              >
                {(props: FormikProps<Types.IProgramFilterChangeAllDataModal>) => {
                  const { values, handleChange, errors, handleBlur, handleSubmit } = props;
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="col-md-12 pb-3">
                            {T.t('gen_program_change_all_data_description')}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_change_all_data')}</label>
                                <Select
                                  className="react-select"
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_type_ids',
                                      list.value
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                          {
                            props.values.selected_type_ids === 0 ?
                              <div className="col-md-8">
                                <div className="col-md-6 form-input form-group with-icon">
                                  <input
                                    id="max_gap_between_courses"
                                    name="max_gap_between_courses"
                                    value={values.max_gap_between_courses}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="max_gap_between_courses">{T.t('gen_max_gap_between_courses')}</label>
                                </div>
                                {((values.max_gap_between_courses || values.max_gap_between_courses === 0) && (values.max_gap_between_courses < 1 || values.max_gap_between_courses > 7)) ? (
                                  is_max_gap_between_courses_error = true,
                                  <div className="error">{T.t('gen_max_gap_between_courses') + ' ' + T.t('gen_between_values_error').replace('{0}', '0').replace('{1}', '8')}</div>
                                ) : (
                                  is_max_gap_between_courses_error = false
                                )}
                              </div> :
                              props.values.selected_type_ids === 1 ?
                                <div className="col-md-8">
                                  <div className="col-md-6 form-input form-group with-icon">
                                    <input
                                      id="daily_max_class"
                                      name="daily_max_class"
                                      value={values.daily_max_class}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="number"
                                      required
                                    />
                                    <span className="highlight" />
                                    <span className="bar" />
                                    <label htmlFor="daily_max_class">{T.t('gen_daily_max_course')}</label>
                                  </div>
                                  {((values.daily_max_class) && (values.daily_max_class < 1)) ? (
                                    is_daily_max_class_error = true,
                                    <div className="error">{T.t('gen_no_negative_value_can_be_entered_error')}</div>
                                  ) : (
                                    is_daily_max_class_error = false
                                  )}
                                </div> :
                                props.values.selected_type_ids === 2 ?
                                  <div className="col-md-8">
                                    <div className="col-md-6 form-input form-group with-icon">
                                      <input
                                        id="min_class_interval_bw_campuses"
                                        name="min_class_interval_bw_campuses"
                                        value={values.min_class_interval_bw_campuses}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="number"
                                        required
                                      />
                                      <span className="highlight" />
                                      <span className="bar" />
                                      <label htmlFor="min_class_interval_bw_campuses">{T.t('gen_min_gap_between_campuses')}</label>
                                    </div>
                                    {((values.min_class_interval_bw_campuses) && (values.min_class_interval_bw_campuses < 1)) ? (
                                      is_min_class_interval_bw_campuses_error = true,
                                      <div className="error">{T.t('gen_no_negative_value_can_be_entered_error')}</div>
                                    ) : (
                                      is_min_class_interval_bw_campuses_error = false
                                    )}
                                  </div> : null
                          }
                          {
                            props.values.selected_type_ids !== undefined ?
                              <div className="col-6 pt-3 pb-3">
                                <button
                                  type="button"
                                  className="btn btn-green"
                                  disabled={is_max_gap_between_courses_error || is_daily_max_class_error || is_min_class_interval_bw_campuses_error}
                                  onClick={() => props.handleSubmit()}
                                >
                                  {T.t('gen_change')}
                                </button>
                              </div> : null
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IProgramPageProps): Types.IProgramPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IProgramPageProps = Object.assign({}, ownProps, {
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeAllDataModal);

export default container;