import { Log } from 'ng2-logger';
import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/templates/aplan-header';
import SimplePage from '../../components/templates/simple-page';
import * as Actions from '../../store/actions/general';
import { routes as Routes } from '../../store/constants/routes';
import { SettingsPageRoutes } from '../../store/constants/setting-const';
import * as Types from '../../store/types';
import * as GeneralTools from '../../tools/general-tools';
import * as GT from '../../tools/general-tools';
import MainLayout from '../layouts/main-layout';
import PasswordResetForm from './reset-password-form';
import Translator from '../../services/translate-factory';
import * as Constants from '../../store/constants/all';
import { Formik, FormikActions, FormikProps, ErrorMessage } from 'formik';
import Select from 'react-select';
import { UserFormInitialValues } from '../../store/constants/user-const';
import UserFormValidation from '../user/validations/user-form-val';
import Spinner from '../../components/templates/spinner';

const L = Log.create('ProfilePage');
const T = Translator.create();

function getInitialState(): Types.IProfilePageState {
  const initialValues: Types.IProfilePageState = {
    resetPasswordFormIsOpen: false
  };
  return Object.assign({}, initialValues);
}

class ProfilePage extends React.Component<Types.IProfilePageProps, Types.IProfilePageState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    // if (this.props.authUser) {
    //   this.props.dispatch(
    //     Actions.ApiRequest(Constants.user.USER_GET_BY_ID, this.props.authUser.userId, 'user-form-spin', undefined, {
    //       mode: 'edit'
    //     })
    //   );
    // }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  onOpenPasswordForm = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.USER_SETTINGS, SettingsPageRoutes.ChangePassword)));
    this.setState({ resetPasswordFormIsOpen: true });
  };

  static getDerivedStateFromProps(props: Types.IProfilePageProps, state: Types.IProfilePageState) {
    let hasNewState: boolean = false;
    // if (props.match && props.match.params.id) {
    //   if (props.match.params.id === SettingsPageRoutes.ChangePassword.substr(1)) {
    //     hasNewState = true;
    //     state.resetPasswordFormIsOpen = true;
    //   }
    // } else {
    //   hasNewState = true;
    //   state.resetPasswordFormIsOpen = false;
    // }
    return hasNewState ? state : null;
  }

  passwordResetFormOnClose = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.USER_SETTINGS)));
    this.setState({ resetPasswordFormIsOpen: false });
  };

  onFormSave = (model: Types.IUserPost, FormActions: FormikActions<Types.IUserPost>) => {
    // if (model.lang) {
    //     T.changeLanguage(model.lang, true);
    // }
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          if (validations && Array.isArray(validations)) {
            validations.forEach((m: Types.IValidationResponse) => {
              errors[m.field] = m.message[0];
            });
          }
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        if (this.props.authUser) {
          this.props.dispatch(
            Actions.ApiRequest(Constants.user.USER_GET_BY_ID, this.props.authUser.userId, 'user-form-spin', undefined, {
              mode: 'edit'
            })
          );
        }
      }
    };

    this.props.dispatch(
      Actions.ApiRequest(Constants.user.USER_PROFILE_UPDATE, model, 'user-form-spin', resultCallback)
    );

    FormActions.setSubmitting(false);
  };

  getFormState = () => {
    // if (this.props.form) {
    //   return Object.assign({}, this.props.form);
    // }
    if (this.props.authUser) {
      return Object.assign({ login_allowed: true, active: true }, this.props.authUser);
    }
    return UserFormInitialValues;
  };

  render() {
    // const languages: Array<Types.ISelectOption> = GT.Languages().map(m => { return { label: T.t(m.label), value: m.value}; });
    return (
      <MainLayout header={<Header />}>
        <Spinner name="user-form-spin" />
        <div className="page-container profile-page">
          <div className="page-content">
            <div className="container">
              <div className="row align-items-center page-title">
                <div className="col-md-6">
                  <h6 className="mb-0 ">Kişisel Bilgiler</h6>
                </div>
                <div className="col-md-6 text-right"></div>
              </div>

              <div className="page-items" style={{ marginTop: '20px' }}>
                <Formik
                  initialValues={this.getFormState()}
                  enableReinitialize={true}
                  validationSchema={UserFormValidation}
                  onSubmit={this.onFormSave}
                >
                  {(props: FormikProps<Types.IUserPost>) => {
                    let isUser = props.values.role === 'u';
                    return (
                      <form onSubmit={props.handleSubmit} autoComplete="Off">
                        {this.props.authUser ? (
                          <div className="row">
                            <div className="container mt-1 col-12 col-md-8 col-sm-12 pt-4">
                              <div className="row">
                                <input type="hidden" name="user_id" value={props.values.user_id} />
                                <div className="col-12 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <div className="form-input">
                                      <input
                                        id='name'
                                        name="name"
                                        type="text"
                                        value={props.values.name}
                                        disabled={isUser}
                                        onChange={isUser ? undefined : props.handleChange}
                                        onBlur={props.handleBlur}
                                        autoComplete="off"
                                      />
                                      <span className="highlight"></span>
                                      <span className="bar"></span>
                                      <label>Adı</label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="name" />
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <div className="form-input">
                                      <input
                                        id="surname"
                                        type="text"
                                        value={props.values.surname}
                                        disabled={isUser}
                                        onChange={isUser ? undefined : props.handleChange}
                                        onBlur={props.handleBlur}
                                      />
                                      <span className="highlight"></span>
                                      <span className="bar"></span>
                                      <label>Soyadı</label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="surname" />
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <div className="form-input">
                                      <input
                                        id="username"
                                        type="text"
                                        value={props.values.username}
                                        disabled={isUser}
                                        onChange={isUser ? undefined : props.handleChange}
                                        onBlur={props.handleBlur}
                                        autoComplete="off"
                                        readOnly={true}
                                        onFocus={(e) => {
                                          e && e.currentTarget && e.currentTarget.removeAttribute('readonly');
                                        }}
                                      />
                                      <span className="highlight"></span>
                                      <span className="bar"></span>
                                      <label>Kullanıcı Adı</label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="username" />
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <div className="form-input">
                                      <input
                                        id="email"
                                        type="text"
                                        value={props.values.email}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                      />
                                      <span className="highlight"></span>
                                      <span className="bar"></span>
                                      <label>E-Posta</label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="email" />
                                  </div>
                                </div>
                                {/* <div className="col-12 col-md-6 col-sm-12">
                                                        <div className="react-select-container">
                                                            <label>{T.t('gen_lang')}</label>
                                                            <Select
                                                                className='react-select'
                                                                options={languages}
                                                                value={props.values.lang ? languages.find(r => r.value === props.values.lang) : null}
                                                                placeholder={T.t('gen_lang_select')}
                                                                onChange={(item: any) =>
                                                                    props.setFieldValue('lang', item.value)
                                                                }
                                                            />
                                                        </div>
                                                    </div> */}
                                {/* <div className="col-12 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <div className="form-input">
                                      <input
                                        id="mobile"
                                        type="text"
                                        value={props.values.mobile}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                      />
                                      <span className="highlight"></span>
                                      <span className="bar"></span>
                                      <label>Cep Telefonu (###) ### ####</label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="mobile" />
                                  </div>
                                </div> */}
                                <div className="col-md text-md-right">
                                  <button
                                    id='change_password'
                                    type="button"
                                    className="btn btn-secondary ml-1 mt-1"
                                    onClick={this.onOpenPasswordForm}
                                  >
                                    <i className="material-icons">lock</i>&nbsp;Şifre Değiştir
                                  </button>
                                  {/* <button
                                    type="submit"
                                    className="btn btn-green ml-1 mt-1"
                                    disabled={props.isSubmitting}
                                  >
                                    <i className="material-icons">done</i>&nbsp;{T.t('gen_save')}
                                  </button> */}
                                </div>
                              </div>
                            </div>
                            <div
                              className="container mt-1 col-12 col-md-4 col-sm-12 pt-4"
                              style={{ backgroundColor: '#eeede9' }}
                            >
                              <div className="row">
                                <div className="col-12 col-md-12 col-sm-12">
                                  <div className="form-input form-group with-icon">
                                    <input
                                      id='creation_date'
                                      name="creationDate"
                                      type="text"
                                      value={GeneralTools.DateTimeOffsetToDateTime(this.props.authUser.creationDate)}
                                      required={false}
                                      disabled={true}
                                    />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label>Kayıt Tarihi</label>
                                    <div className="icon"></div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-12 col-sm-12">
                                  <div className="form-input form-group with-icon">
                                    <input
                                      id='last_login_date'
                                      name="lastLoginDate"
                                      type="text"
                                      value={GeneralTools.DateTimeOffsetToDateTime(this.props.authUser.lastLoginDate)}
                                      required={false}
                                      disabled={true}
                                    />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label>Son Giriş Tarihi</label>
                                    <div className="icon"></div>
                                  </div>
                                </div>
                                {/* {this.props.form.expires_at && (
                              <div className="col-12 col-md-12 col-sm-12">
                                <div className="form-input form-group with-icon">
                                  <input
                                    name="lastLoginDate"
                                    type="text"
                                    value={GeneralTools.DateTimeOffsetToDateTime(this.props.form.expires_at)}
                                    required={false}
                                    disabled={true}
                                  />
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  <label>{T.t('user_expiration_date')}</label>
                                  <div className="icon"></div>
                                </div>
                              </div>
                            )} */}

                                <PasswordResetForm
                                  user_id={this.props.authUser.userId}
                                  formIsOpen={this.state.resetPasswordFormIsOpen}
                                  onClose={this.passwordResetFormOnClose}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IProfilePageProps): Types.IProfilePageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    authUser: store.state.user
    // form: store.state.profile_pages && store.state.profile_pages.form ? store.state.profile_pages.form : undefined
  });

  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
  // if (next.state.profile_pages) {
  //     return (!!equal(prev.state.profile_pages, next.state.profile_pages));
  // } else {
  //     return (true);
  // }
};

const dispatchProps = (dispatch: any) => ({
  dispatch
});

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(ProfilePage);

export default container;
